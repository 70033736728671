import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Subject } from "rxjs";
import { IResponse } from "../../interfaces/IResponse";
import { takeUntil } from "rxjs/operators";
import { apiOfflineAsync } from "../../layouts/pages/pages.component";

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  parameters: any = {}
  private ngUnsubscribe: Subject<void> = new Subject<void>()

  // requestOptions: any = new HttpHeaders({ 'Content-Type': 'application/json' })
  requestOptionsFormData: any = new HttpHeaders({ 'Content-Type': 'multipart/form-data' })

  private requestOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
      // outros cabeçalhos personalizados aqui, se necessário
    })
  };

  constructor(
    private httpClient: HttpClient,
  ) { }

  getCards(param: string) {
    const url = `${environment.apiUrl}home/${param}`

    this.ngUnsubscribe.next()
    return this.httpClient.get<IResponse>(url).pipe(takeUntil(this.ngUnsubscribe));
  }

  async setJobParameters(body: any) {
    const url = `${environment.apiUrl}job-delete-process`
    try {
      await this.httpClient.post<IResponse>(url, body, this.requestOptions).toPromise()
    } catch (error: any) {
      return await apiOfflineAsync(error)
    }
  }

  getCardsArrays(param: string) {
    const url = `${environment.apiUrl}home/cards/${param}`
    this.ngUnsubscribe.next()
    return this.httpClient.get<IResponse>(url).pipe(takeUntil(this.ngUnsubscribe));
  }
}
