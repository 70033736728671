import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IResponse } from '../../interfaces/IResponse';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CorporationService {
  API_PATH: string
  private ngUnsubscribe: Subject<void> = new Subject<void>()
  parameters: any = {}

  private requestOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  requestOptionsFormData: any = new HttpHeaders({ 'Content-Type': 'multipart/form-data' })

  constructor(
    private httpClient: HttpClient,
    private router: Router,
  ) {
    this.API_PATH = environment.apiUrl;
  }

  private getURLSearchParams({ environment, corporation, search }: any = {}) {
    let params = new URLSearchParams();

    if (environment && environment.slug) {
      params.set('environment', environment.slug);
    }

    if (corporation && corporation.slug) {
      params.set('corporation', corporation.slug);
    }

    if (search) {
      params.set('search', search);
    }

    return params.toString();
  }

  async navigate(route: string) {
    return await this.router.navigate([route]);
  }

  async redirectError(error: any) {
    if (error.status == 401) {
      return await this.navigate('/login');
    }
    return error.error;
  }

  async getCorporations() {
    return await this.httpClient.get<IResponse>(`${this.API_PATH}corporations`).toPromise();
  }

  async getCorporationOne(id: number) {
    return await this.httpClient.get<IResponse>(`${this.API_PATH}corporations/${id}`).toPromise();
  }

  async setCorporation(body: any, isNew: boolean = true, params: any = {}) {
    const data = JSON.parse(body.get('body'))
    try {
      if (isNew) {
        return await this.httpClient.post<IResponse>(
          `${this.API_PATH}corporations?${this.getURLSearchParams({ params })}`,
          body,
          this.requestOptionsFormData
        ).toPromise();
      }
      return await this.httpClient.put<IResponse>(
        `${this.API_PATH}corporations/${data.corporation.uuid}`,
        body,
        this.requestOptionsFormData
      ).toPromise();
    } catch (error: any) {
      return await this.redirectError(error);
    }
  }

  async deleteCorporation(id: number) {
    try {
      return await this.httpClient.delete<IResponse>(`${this.API_PATH}corporations/${id}`).toPromise()
    } catch (error: any) {
      return await this.redirectError(error);
    }
  }

  async deleteCorporationLandscape(uuid: number) {
    try {
      return await this.httpClient.delete<IResponse>(`${this.API_PATH}corporations-landscape/${uuid}`).toPromise()
    } catch (error: any) {
      return await this.redirectError(error);
    }
  }

  getCorporationTree({ search, environment }: any = {}): Observable<IResponse> {
    const url = `${this.API_PATH}corporations/tree?${this.getURLSearchParams({ search, environment })}`;

    this.ngUnsubscribe.next()
    return this.httpClient.get<IResponse>(url)
      .pipe(
        takeUntil(this.ngUnsubscribe)
      );
  }

  async getCorporationType() {
    return await this.httpClient.get<IResponse>(`${this.API_PATH}corporations-type`).toPromise();
  }

  async getSegments() {
    return await this.httpClient.get<IResponse>(`${this.API_PATH}segments`).toPromise();
  }

  async getCorporationLandscape() {
    return await this.httpClient.get<IResponse>(`${this.API_PATH}corporations-landscape`).toPromise();
  }

  async getCorporationTypeProcedure() {
    return await this.httpClient.get<IResponse>(`${this.API_PATH}corporations-type-procedure`).toPromise();
  }

  async getGuidelineProjectType() {
    return await this.httpClient.get<IResponse>(`${this.API_PATH}corporations-guideline-project-type`).toPromise();
  }

  async setGuidelineProjectType(body: any, isNew: boolean = true) {
    try {
      if (isNew) {
        return await this.httpClient.post<IResponse>(
          `${this.API_PATH}corporations-guideline-project-type`,
          body,
          this.requestOptions
        ).toPromise();
      }
    } catch (error: any) {
      return await this.redirectError(error);
    }
  }

  async setCorporationLandscape(body: any, isNew: boolean = true) {
    try {
      if (isNew) {
        return await this.httpClient.post<IResponse>(
          `${this.API_PATH}corporations-landscape`,
          body,
          this.requestOptions
        ).toPromise();
      }
    } catch (error: any) {
      return await this.redirectError(error);
    }
  }

  async setCorporationTypeProcedure(body: any, isNew: boolean = true) {
    try {
      if (isNew) {
        return await this.httpClient.post<IResponse>(
          `${this.API_PATH}corporations-type-procedure`,
          body,
          this.requestOptions
        ).toPromise();
      }
    } catch (error: any) {
      return await this.redirectError(error);
    }
  }

  async setCorporationType(body: any, isNew: boolean = true) {
    try {
      if (isNew) {
        return await this.httpClient.post<IResponse>(
          `${this.API_PATH}corporations-type`,
          body,
          this.requestOptions
        ).toPromise();
      }
    } catch (error: any) {
      return await this.redirectError(error);
    }
  }

  async getCorporationsProfilesTree(parameters: any = {}, user_uuid: any = null) {
    let url = `${environment.apiUrl}corporations/list/profiles?${this.getURLSearchParams(parameters)}`
    if (user_uuid) url += `user_uuid=${user_uuid}`
    return await this.httpClient.get<IResponse>(url).toPromise()
  }

  async setSegment(body: any, isNew: boolean = true) {
    try {
      if (isNew) {
        return await this.httpClient.post<IResponse>(
          `${this.API_PATH}segments`,
          body,
          this.requestOptions
        ).toPromise()
      }
      return await this.httpClient.put<IResponse>(
        `${this.API_PATH}segments/${body.uuid}`,
        body,
        this.requestOptions
      ).toPromise()
    } catch (error: any) {
      return await this.redirectError(error);
    }
  }

  async getCorporationSegments() {
    return await this.httpClient.get<IResponse>(`${this.API_PATH}segments`).toPromise()
  }

}
