<div class="all-content">
  <div class="top">
    <div class="toggle"></div>
    <div class="div-icon-button">
      <button class="save" (click)="exitModal()">
        {{ getLanguages('back') }}
      </button>
      <button class="save" (click)="save()">
        {{ getLanguages('save') }}
      </button>
      <i class="fas fa-xmark" (click)="exitModal('all')"></i>
    </div>
  </div>

  <div class="title">
    {{ getLanguages('instances/modal/user/linkProfiles')}}
  </div>

  <div class="row">
    <span class="name-environment"> {{ parameters.environment.name }} </span>
  </div>

  <div class="row">
    <span class="sub-title-class"> {{ getLanguages('profiles/screens/users') }} </span>
  </div>

  <div class="row-cards">
    <div class="card-name" *ngFor="let user of parameters.arrayUsers">
      {{ user.name }}

      <i class="fas fa-xmark" (click)="remove(user.uuid)"></i>
    </div>
  </div>

  <div class="row" *ngIf="getAccessProfileMaster()">
    <div class="display-flex">
      <span>{{ getLanguages('users/modal/create/profile_master') }} </span>
      <mat-checkbox class="check" [ngModel]="data.is_admin_master"
        (change)="data.is_admin_master = !data.is_admin_master"></mat-checkbox>
    </div>
  </div>

  <div class="row" *ngIf="getAccessProfileEnvornment()">
    <div class="item w-1">
      <span class="sub-title-class">{{ getLanguages('users/modal/create/profile_environment') }} </span>

      <div class="display-flex">
        <button class="select-nav" style="width: 33%!important;" [ngClass]="data.is_admin_master ? 'disabled' : ''"
          [disabled]="data.is_admin_master" [matMenuTriggerFor]="profileEnvironment">
          <span>
            <ng-container>
              {{ selectedProfileEnvironment?.uuid ? selectedProfileEnvironment.name : getLanguages('search') }}
            </ng-container>
          </span>
          <i class="fas fa-caret-down"></i>
        </button>
        <span *ngIf="selectedProfileEnvironment?.uuid && !data.is_admin_master" class="clear"
          (click)="selectedProfileEnvironment = {name: 'Select'}"> {{ getLanguages('clear') }} </span>
        <mat-menu #profileEnvironment="matMenu">
          <div class="menu">
            <div class="input" (click)="$event.stopPropagation()">
              <input type="text" [(ngModel)]="searchProfileEnvironment" placeholder="{{getLanguages('search')}}">
              <i class="fa-solid fa-magnifying-glass"></i>
            </div>
            <div class="scroll">
              <ng-container *ngFor="let profile of profilesEnvironment">
                <ng-container *ngIf="profile.name.toLowerCase().includes(searchProfileEnvironment.toLowerCase())">
                  <div class="item-menu" (click)="choseProfileEnvironment(profile)">
                    <span [ngClass]="{'valid-span' : profile.uuid == selectedProfileEnvironment.uuid}">
                      {{ profile.name }}
                    </span>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </mat-menu>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="item w-1">
      <span class="sub-title-class">{{ getLanguages('users/modal/create/profile_corporation') }} </span>
    </div>
  </div>
  <div class="div-table">
    <table mat-table [dataSource]="dataSource" [ngClass]="data.is_admin_master || selectedProfileEnvironment?.uuid ? 'table-disabled' : '' ">
      <ng-container matColumnDef="Select">
        <th mat-header-cell *matHeaderCellDef>
        </th>
        <td mat-cell *matCellDef="let element; let i = index;">
          <mat-checkbox
            class="checkbox"
            [color]="task.color"
            [(ngModel)]="element.selected"
            (change)="element.selected = !element.selected;setSelectionCorporationProfile(element)"
            *ngIf="!data.is_admin_master && !selectedProfileEnvironment?.uuid "
            >
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="Corporation">
        <th mat-header-cell *matHeaderCellDef>
          <span> {{ getLanguages('users/modal/create/corporation') }} </span>
        </th>
        <td mat-cell *matCellDef="let element; let i = index;">
          <span class="title-corporation">
              {{element.corporation.name}}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="Profile">
        <th mat-header-cell *matHeaderCellDef>
          <span> {{ getLanguages('users/modal/create/profile') }} </span>
        </th>
        <td mat-cell *matCellDef="let element; let i = index;">
            <button class="select-nav nav-table" id="select_profile" [matMenuTriggerFor]="selectProfile" [disabled]="!element.selected" [ngClass]="!element.selected ? 'disabled no-pointer': ''">
              <span> 
                  <ng-container>
                    {{ setSelectionCorporationsProfilesArray.length > 0 && element.index >= 0 && setSelectionCorporationsProfilesArray[element.index] && setSelectionCorporationsProfilesArray[element.index].profile ? setSelectionCorporationsProfilesArray[element.index].profile.name : getLanguages('search') }}
                  </ng-container>
              </span>
              <i class="fas fa-caret-down"></i>
            </button>
            <mat-menu #selectProfile="matMenu">
              <div class="menu">
                <div class="input" (click)="$event.stopPropagation()" >
                  <input type="text" [(ngModel)]="element.searchProfile" placeholder="{{getLanguages('search')}}">
                  <i class="fa-solid fa-magnifying-glass"></i>
                </div>
                <div class="scroll">
                  <ng-container *ngFor="let profile of element.profiles">
                    <ng-container *ngIf="profile.name.toLowerCase().includes(element.searchProfile.toLowerCase())">
                      <div class="item-menu" (click)="setSelectionCorporationProfile(null, profile, element.index)">
                        <span [attr.id]="profile.slug" [ngClass]="{'valid-span' : profile.name == (setSelectionCorporationsProfilesArray[element.index] && setSelectionCorporationsProfilesArray[element.index].profile && setSelectionCorporationsProfilesArray[element.index].profile.name)}">
                          {{ profile.name }}
                        </span>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </mat-menu>
        </td>
      </ng-container>
      <ng-container matColumnDef="Status">
        <th mat-header-cell *matHeaderCellDef>
          <span> {{ getLanguages('users/modal/create/status') }} </span>
        </th>
        <td mat-cell *matCellDef="let element; let i = index;">
          <button class="select-nav nav-table" id="select_status" [matMenuTriggerFor]="status" [disabled]="!element.selected || parameters.readonly" [ngClass]="!element.selected || parameters.readonly ? 'no-pointer': ''">
            <span>
                <ng-container>
                  {{ setSelectionCorporationsProfilesArray.length > 0 && element.index >= 0 && setSelectionCorporationsProfilesArray[element.index]?.status?.label ? getNames(setSelectionCorporationsProfilesArray[element.index].status.label) : getLanguages('search') }}
                </ng-container>
            </span>
            <i class="fas fa-caret-down"></i>
          </button>
          <mat-menu #status="matMenu">
            <div class="menu">
              <div class="scroll">
                <ng-container *ngFor="let status of statusArray">
                    <div class="item-menu" (click)="setSelectionCorporationProfile(null, null, element.index, { status })">
                      <span [attr.id]="status.label.en" [ngClass]="{'valid-span' : status.label == (setSelectionCorporationsProfilesArray[element.index] && setSelectionCorporationsProfilesArray[element.index].status && setSelectionCorporationsProfilesArray[element.index].status.label )}">
                        {{ getNames(status.label) }}
                      </span>
                    </div>
                </ng-container>
              </div>
            </div>
          </mat-menu>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <div class="background-modals" *ngIf="isShowAlert">

    <ng-container *ngIf="isShowAlert">
      <app-changes-link-users (toggleAlert)="toggleAlert()" (close)="close()">
      </app-changes-link-users>
    </ng-container>
  </div>