import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { BusinessSolutionsService } from 'src/app/services/business-solutions/business-solutions.service';
import { getLanguageCookie } from 'src/assets/language';
import { Subscription } from 'rxjs';
import { systemPermissionsNew } from 'src/app/helpers/systemPermissions';
import { localStorageToJsonAsync} from "../../helpers/cookieToJson";
import { addAlert, apiOfflineAsync, PagesComponent, removeAlert} from "../../layouts/pages/pages.component";
import { TreeDragDropService, TreeNode } from 'primeng/api';
import { parse, stringify } from "circular-json";
import { asyncForEach } from "../../helpers/asyncForEach";
import { v4 } from 'uuid';
import { TooltipPosition } from '@angular/material/tooltip';
import { FormControl } from '@angular/forms';
import { ClipboardService } from 'src/app/services/clipboard/clipboard.service';
import { to64decode } from 'src/app/helpers/base64';
import { PersonasService } from "../../services/personas/personas.service";
import { UsersService } from 'src/app/services/users/users.service';
import { FunctionService } from 'src/app/services/function.service';
import { CorporationsSettingsService } from 'src/app/services/corporations-settings/corporations-settings.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { PageTechnologiesService } from 'src/app/services/page-technologies/page-technologies.service';
import { ProjectService } from 'src/app/services/project/project.service';
import { addTaskXml, deleteTasksXml, generateBpmnXml, updateTaskXml } from 'src/app/helpers/bpmnXmlGenerator';
import { ExtendedAttributeService } from 'src/app/services/extended_attribute/extended-attribute.service';
import { environment } from 'src/app/environments/environment';
import { extractTasksFromBpmnXml } from 'src/app/helpers/bpmnJsonGenerator';
import { filterArraySubstringTree } from 'src/app/helpers/filterArraySubstringTree';
import { guidelineArray } from 'src/assets/const/processGuidelineJson';
import { fixedLevels } from 'src/assets/const/fixedLevelsJson';
import { Router } from '@angular/router';

declare global {
  interface Window {
    MyComponents: {
      AlertComponent: any;
      BpmnComponent: any;
    };
  }
}

@Component({
  selector: 'app-business-tree',
  templateUrl: './business-tree.component.html',
  styleUrls: ['./business-tree.component.scss'],
  providers: [TreeDragDropService]
})

export class BusinessTreeComponent{
  @Input() parameters: any = {}

  @ViewChild('scrollContainerJourneys') scrollContainerJourneys!: ElementRef;

  private subscription: Subscription = new Subscription();

  files!: any[];
  filesOld!: any[]

  menuLevelArray: any = []

  frequencyArray: any = []

  attachments_deleted: any = []

  queueAlert: any = []
  queueTime: any = []

  openTree: boolean = false;

  hasSelectedFile = false;
  properties: any = {}

  selectedFlag: any = { lang: 'en', file: 'us.svg' };
  journeyArray: any = []

  business_solution: any = {}
  personas: any = {}
  technologies: any = {}
  guidelineProject: any = {}

  attachmentArray: any = []
  arrayPersonas: any = []
  arrayTechnologies: any = []
  arrayGuidelineProject: any = []

  arraySumFte: any = {}
  nameBpmn: string = ''
  isShow: boolean = false;
  isShowEdit: boolean = false;
  isShowDelete: boolean = false;
  isShowImport: boolean = false;
  isChangeTree: boolean = false;
  isChangeTreeModalExit: boolean = false;
  isBpmn: boolean = false;
  showGuidelineLink: boolean = false
  isEdited = false;
  xlsFiles: any
  noData: boolean = false;
  nodeDropArray: any = []
  isLoading: boolean = false;
  isLoadingExport: boolean = false;
  selectedNode: any;
  isSelectedNode = false;
  searchText: string = '';
  text: string = '';

  objectModal: any = {}

  data: any
  selectedFile: any;
  formData: FormData = new FormData()

  isPaste = false;
  copyFile: any
  cloneFile: any
  cloneOrigin: any

  isLoadingClipboard = false;

  defaultColumns: any = []

  positionOptions: TooltipPosition[] = ['below'];
  position = new FormControl(this.positionOptions[0]);
  optionsArray: any = []

  isShowCreateNameModal = false;

  viewNumber: number = 0
  viewJourney: number = -1
  totalNumberProccess: number = 0;

  openJourneys: boolean = false;
  guidelineArray: any = guidelineArray
  
  nameVersion: string = ''
  extendedAttributeArray: any = []

  filterArray: any = []
  filterCursor: number = 0
  isSearch: boolean = false

  constructor(
    private businessSolutionsService: BusinessSolutionsService,
    private personasService: PersonasService,
    private usersService: UsersService,
    private cdr: ChangeDetectorRef,
    private clipboardService: ClipboardService,
    public sharedData: FunctionService,
    private router: Router,
    private corporationsSettingsService: CorporationsSettingsService,
    private technologiesService: PageTechnologiesService,
    private projectGuidelineService: ProjectService,
    private extendedAttribute: ExtendedAttributeService
  ) {}

  async ngOnInit() {
    this.properties = await localStorageToJsonAsync()
    await this.getCorporationSettings()
    await this.getCorporationExtendedAttribute()
    await this.getOptionsArray()
    await this.getPersonasTree()
    await this.getGuidelineProjectTree()
    await this.getTechnologiesTree()
    await this.getBusinessTree()
    await this.updateFiles({}, true)
    await this.recursiveCalculateFteOne(this.files)
    await this.recursiveCalculateSumFte(this.files)

    if(localStorage.getItem('node_clipboard')){
      const node = to64decode(localStorage.getItem('node_clipboard'))
      this.isPaste = true;
      this.copyFile = { ...node, uuid: v4() }
      delete this.copyFile.data
      this.copyFile.description.processOwner = this.properties.me
      this.copyFile.userCreated = this.properties.me.name ?? '',
      this.copyFile.userUpdated = this.properties.me.name ?? '',
      this.copyFile.created_at = this.formatDate(new Date()),
      this.copyFile.updated_at = this.formatDate(new Date())

      localStorage.removeItem('node_clipboard')
    }      
  }

  getNameCorporation(): string{
    return this.properties?.corporation?.name || ""
  }

  async activateBpmn(array: any, node?: any){
    if(!node){
      node = {
        label: this.properties?.corporation?.name || "",
        uuid: this.properties?.corporation?.uuid || "",
        temp_master: 'ROOT',
        level: 0
      }
    }

    this.isBpmn = true;
    let obj: any
    const body = {
      node: {
        label: node.label,
        uuid: node.uuid,
        master: node?.temp_master == 'ROOT' ? 'ROOT' : node.uuid,
        level: node.level
      },
      array: array
    }
    obj = await this.getDiagram(body)
    

    // Carregar os componentes externos
    this.loadExternalScript(environment.bundleUrl)
    .then(() => {
    // console.log('Scripts dos componentes externos carregados com sucesso.');
    this.nameBpmn = node.label

    if (window.MyComponents && typeof window.MyComponents.BpmnComponent === 'function') {
      new window.MyComponents.BpmnComponent({
        target: document.getElementById('external-bpmn-component'),
        props: {
          xml: obj.xml,
          title: node.label,
          save: this.getLanguages('save'),
          array: array
        },
        onSave: (obj: any) => this.saveXmlDiagram(obj, body.node)  // Usa o uuid do node
      })
      } else {
        // console.error('BpmnComponent não está disponível como função.');
        // console.log('Tipo de window.MyComponents.BpmnComponent:', typeof window.MyComponents.BpmnComponent);
        // console.log('Conteúdo de window.MyComponents.BpmnComponent:', window.MyComponents.BpmnComponent);
      }
    })
    .catch((error) => {
      // console.error('Erro ao carregar os scripts dos componentes externos:', error);
    });
  }

  async getDiagram(body: any): Promise<any>{
    let obj: any = { 
      type: 'post',
      xml: generateBpmnXml(body.array, body.node.label)
    }
    try {
      const response: any = await this.businessSolutionsService.getDiagram(body.node.uuid)
      if(response.data?.xml){
        obj = {
          type: 'put', 
          xml: await this.synchronismToXml({xml: response.data?.xml, array: body.array})
        }
      }
    } catch (error: any) {
      apiOfflineAsync(error)
    }

    return obj
  }

  async saveXmlDiagram(obj: any, node: any){
    try{
      const response: any= await this.businessSolutionsService.setXmlDiagram(obj, node.uuid)
      if(response.status == '201'){
        this.isBpmn = false;
        await this.synchronismToJson(obj, node)
        addAlert('success', response.message)
      }else{
        addAlert('danger', response.message)
      }
    }catch(error: any){
      apiOfflineAsync(error)
    }
  }

  async synchronismToJson(body: any, node: any) {
    this.isEdited = true;
  
    let array: any = extractTasksFromBpmnXml(body.xml);
  
    let newTask: any;
    const taskMap = new Map(array.map((task: any) => [task.uuid, task]));
  
    body.array.forEach((item: any) => {
      const matchedTask: any = taskMap.get(item.uuid);
      if (matchedTask) {
        item.label = matchedTask.label;
      } else {
        item.status = 'canceled';
      }
    });
  
    const bodyMap = new Map(body.array.map((item: any) => [item.uuid, item]));
    array.forEach(async (task: any) => {
      if (!bodyMap.has(task.uuid) && task.label !== '') {
        newTask = {
          master: node.master,
          children: [],
          data: '',
          label: task.label,
          created_at: this.formatDate(new Date()),
          updated_at: this.formatDate(new Date()),
          userCreated: this.properties?.me?.name ?? '',
          userUpdated: this.properties?.me?.name ?? '',
          level: node.level + 1,
          size: 1,
          key: task.uuid,
          uuid: task.uuid,
          status: 'new',
          rows: {
            strategy: [],
            skills: [],
          },
          columns: {
            strategy: {
              columns: this.defaultColumns.columns,
              columnsName: this.defaultColumns.columnsName,
            },
          },
          maturity: {},
          personas: [],
          personasClassification: [],
          technologies_table: {},
          attachments: [],
          description: {},
          indicators: [],
          complements: [],
          configurations: [],
          integrations: [],
          rules: [],
          extendedAttributes: [],
          transactions: [],
          project: {},
        };
        body.array.push(newTask);
      }
    });
  
    body.array.forEach((current: any) => {
      if (!current.complements) {
        current.complements = [];
      }
  
      const currentTask = taskMap.get(current.uuid);
      if (currentTask) {
        const { prev, next }: any = currentTask;
  
        // Função auxiliar para adicionar ou substituir complementos
        const addOrReplaceComplement = (complementArray: any[], newComplement: any) => {
          const index = complementArray.findIndex((item: { uuid: string }) => item.uuid === newComplement.uuid);
          if (index !== -1) {
            complementArray[index] = newComplement; // Substitui o complemento existente
          } else {
            complementArray.push(newComplement); // Adiciona novo complemento
          }
        };
  
        // Preenche os predecessores (prev)
        prev.forEach((predecessorUuid: string) => {
          const predecessorTask: any = taskMap.get(predecessorUuid.replace("Task_", ""));
          if (predecessorTask) {
            addOrReplaceComplement(current.complements, {
              uuid: predecessorTask.uuid,
              label: predecessorTask.label,
              type: {
                slug: "predecessor",
                name: { en: "Predecessor", pt: "Antecessor", es: "Predecesor" },
              },
            });
          } 
        });
  
        // Preenche os sucessores (next)
        next.forEach((successorUuid: string) => {
          const successorTask: any = taskMap.get(successorUuid.replace("Task_", ""));
          if (successorTask) {
            addOrReplaceComplement(current.complements, {
              uuid: successorTask.uuid,
              label: successorTask.label,
              type: {
                slug: "successor",
                name: { en: "Successor", pt: "Sucessor", es: "Sucesor" },
              },
            });
          }
        });
      } 
    });
    
    this.noData = this.files.length === 0;
  }
  
  
  

  async synchronismToXml(body: any): Promise<string>{
    let result: string = ''
    const array = [...body.array] 
    const existingTasks = extractTasksFromBpmnXml(body.xml).map((child: any) => child.uuid);
    const tasksToAdd = array.filter((item: any) => 
      !existingTasks.includes(item.uuid) && item.status !== 'canceled'
    );
    const tasksToRemove = existingTasks.filter((uuid) => {
      const isNotInBodyArray = !array.some((item: any) => item.uuid === uuid);
      const isCanceled = array.some((item: any) => item.uuid === uuid && item.status == 'canceled');
      
      return isNotInBodyArray || isCanceled;
    });
    result = deleteTasksXml(body.xml, tasksToRemove)
    result = addTaskXml(result, tasksToAdd)
    result = updateTaskXml(result, array)
    
    return result
  }

  private loadExternalScript(scriptUrl: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const scriptElement = document.createElement('script');
      scriptElement.src = scriptUrl;
      scriptElement.onload = () => resolve();
      scriptElement.onerror = () => reject(new Error(`Failed to load script ${scriptUrl}`));
      document.body.appendChild(scriptElement);
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event: any): void {
    if (this.isEdited) {
      event.returnValue = true;
    }
  }

  async ngAfterViewInit() {
    if(!this.scrollContainerJourneys || !this.scrollContainerJourneys.nativeElement){
      return
    }

    this.scrollContainerJourneys.nativeElement.scrollLeft = 0;
  }

  scrollLeft(param?: string) {
    const viewportWidth = window.innerWidth;
    const vwToPixels = viewportWidth / 100;

    if(param == 'journeys'){
      this.scrollContainerJourneys.nativeElement.scrollLeft -= 25 * vwToPixels;
    }
  }

  scrollRight(param?: string) {
    const viewportWidth = window.innerWidth;
    const vwToPixels = viewportWidth / 100;

    if(param == 'journeys'){
      this.scrollContainerJourneys.nativeElement.scrollLeft += 25 * vwToPixels;
    }
  }

  navigate(url: string){
    this.router.navigate([url])
  }

  getAccessGuideline(){
    let flag: boolean = false;
    this.properties?.screens?.settings?.forEach((screen: any)=> {
      // if(module?.slug == 'settings'){
      //   module.screens.forEach((screen: any) => {
      //     if(screen.slug == 'standards'){
      //       flag = true
      //     }
      //   });
      // }
      if(screen?.slug == 'standards'){
        flag = true
      }
    })

    return flag
  }

  async getCorporationExtendedAttribute() {
    try {
      const response: any = await this.extendedAttribute.getCorporationExtendedAttribute();
      if(response.data){
        // this.extendedAttributeArray = response.data
        this.extendedAttributeArray = response.data
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  async getCorporationSettings() {
    try {
      const response: any = await this.corporationsSettingsService.getCorporationService();
      if(!response.data?.corporation_settings?.uuid && !response.data?.principal_settings?.uuid){
        this.showGuidelineLink = true
      }

      // if(!response.data?.corporation_settings?.uuid && response.data?.principal_settings?.uuid){
         
      //   const updatedGuidelineArray = [...this.guidelineArray];
      //   const backendLevels = response.data.principal_settings.json;

      //   for (let levelKey in backendLevels) {
      //     if (backendLevels.hasOwnProperty(levelKey)) {
      //       const levelIndex = parseInt(levelKey.split('_')[1], 10) - 1;
      //       const newItem = backendLevels[levelKey];

      //       if (levelIndex >= 0 && levelIndex < updatedGuidelineArray.length) {
      //         updatedGuidelineArray[levelIndex] = {
      //           ...updatedGuidelineArray[levelIndex],
      //           ...(typeof newItem === 'object' && newItem !== null ? newItem : {})
      //         };
      //       }
      //     }
      //   }

      //   this.guidelineArray = updatedGuidelineArray;
      // }

      if (response && response.status && response.data?.corporation_settings?.uuid) {
         
        const updatedGuidelineArray = [...this.guidelineArray];
        const backendLevels = response.data.corporation_settings.json;

        for (let levelKey in backendLevels) {
          if (backendLevels.hasOwnProperty(levelKey)) {
            const levelIndex = parseInt(levelKey.split('_')[1], 10) - 1;
            const newItem = backendLevels[levelKey];

            if (levelIndex >= 0 && levelIndex < updatedGuidelineArray.length) {
              updatedGuidelineArray[levelIndex] = {
                ...updatedGuidelineArray[levelIndex],
                ...(typeof newItem === 'object' && newItem !== null ? newItem : {})
              };
            }
          }
        }

        this.guidelineArray = updatedGuidelineArray;

      }else {
        throw new Error('Erro ao consultar array de status!\nConsulte o administrador do sistema!');
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  async initParameters(level?: number) {
    this.parameters = {}
    this.parameters.inputs = {}
    this.parameters.parent = {}
    this.parameters.isEdit = false
    this.parameters.business = this.properties.corporation.corporation_name
    this.parameters.item = {}
    this.parameters.item.maturity = {}
    this.parameters.item.attachments = []
    this.parameters.item.personas = []
    this.parameters.item.technologies_table = {}
    this.parameters.item.project = {}
    this.parameters.item.level = level

    let uuid = v4()
    this.parameters.item.uuid = uuid
    this.parameters.item.key = uuid
    this.parameters.optionsArray = this.optionsArray
    this.parameters.arrayOrganograms = this.findOrganogramNodes(this.arrayPersonas)
    this.parameters.extendedAttributeArray = this.extendedAttributeArray
    this.parameters.item.rows = {
      strategy: [],
      skills: [],
    }

    this.parameters.item.columns = {
      strategy: {
        columns: this.defaultColumns.columns,
        columnsName: this.defaultColumns.columnsName
      },
    }

    this.parameters.personas = this.arrayPersonas
    this.parameters.arrayTechnologies = this.arrayTechnologies
    this.parameters.guidelineArray = this.guidelineArray
    this.parameters.arrayGuidelineProject = this.arrayGuidelineProject
  }

  async getPersonasTree() {
    try {
      const response: any = await this.personasService.getPersonasTree(this.parameters).toPromise();
      if (response && response.data) {
        this.personas = parse(response.data)

        this.arrayPersonas = this.personas.json;
      } else {
        throw new Error('Erro ao consultar àrvore de personas!\nConsulte o administrador do sistema!');
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  async getTechnologiesTree() {
    try {
      const response: any = await this.technologiesService.getTechnologiesTree(this.parameters).toPromise();
      if (response && response.data) {
        this.technologies = parse(response.data)

        this.arrayTechnologies = this.technologies.json;
      } else {
        throw new Error('Erro ao consultar àrvore de tecnologias!\nConsulte o administrador do sistema!');
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  onFileInputChange(event: Event): void {
    this.xlsFiles = event.target as HTMLInputElement;
    this.hasSelectedFile = this.xlsFiles.files && this.xlsFiles.files.length > 0;
  }

  async getOptionsArray() {
    try {
      const response: any = await this.businessSolutionsService.getOptionsArray();
      this.objectModal.optionsArray = {}
      if (response && response.data && response.data.frequency) {
        this.defaultColumns = response.data.defaultColumns
        this.optionsArray = response.data
        this.frequencyArray = response.data.frequency
        await this.getUsers()
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  async getUsers(status: any = null) {
    try {
      const response: any = await this.usersService.getUsers(status, { owner: true}).toPromise();

      if (response && response.data) {
        this.optionsArray.usersArray = response.data
      } else {
        throw new Error('Erro ao consultar array de status!\nConsulte o administrador do sistema!');
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  async getBusinessTree() {
    this.attachmentArray = this.businessSolutionsService.attachmentArray ?? []
    this.isLoading = true;
    this.isShowImport = false

    try {
      const response: any = await this.businessSolutionsService.getBusinessTree(this.parameters).toPromise();

      if (response && response.data) {
        this.business_solution = parse(response.data)
        this.files = this.business_solution.json;
        this.filesOld = this.business_solution.json;
        this.isShowImport = false;
        this.noData = this.files.length == 0
        this.nameVersion = this.business_solution?.name ? this.business_solution?.name : 'Default'
        this.collapseAll()
      } else {
        throw new Error('Erro ao consultar àrvore de negócios!\nConsulte o administrador do sistema!');
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
    this.isLoading = false;

  }

  searchNodes(): void {
    // this.files = this.filesOld.filter((node) => this.filterNode(node, this.text, true));
    // this.verifyNoData()
    this.filterCursor = 0;
    this.isSearch = true
    this.filterArray = filterArraySubstringTree(this.files, this.text)
    this.next()
  }

  verifyNoData() {
    if(this.files.length == 0){
      this.noData = true;
      return
    }
    this.noData = false;
  }

  private filterNode(node: TreeNode, query: string, includeParents: boolean = false): boolean {
    if(!node.label){
      return false
    }
    const labelMatches = node.label.toLowerCase().includes(query.toLowerCase());

    if (labelMatches) {
      return true;
    }

    if (node.children) {
      const anyChildMatches = node.children.some((child) => this.filterNode(child, query, true));

      if (anyChildMatches) {
        return true;
      }
    }

    return false;
  }

  async flipTree(){
    this.openTree = !this.openTree;
    if(this.openTree){
      return this.expandAll()
    }
    return this.collapseAll()
  }

  async expandAll() {
    this.files?.forEach((node) => {
      this.expandRecursive(node, true);
    });
  }

  async collapseAll() {
    if(!this.files || !this.files.length){
      return
    }
    this.files?.forEach((node) => {
      this.expandRecursive(node, false);
    });
  }

  expandRecursive(node: any, isExpand: boolean) {
    this.openTree = isExpand
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach((childNode: any) => {
        this.expandRecursive(childNode, isExpand);
      });
    }
  }

  async getBusinessTreeNew(node?: any){
    if(Object.keys(node).length > 0){
      this.parameters.parent.uuid = node.uuid;
      this.parameters.parent.label = node.label;
    }
    this.parameters.item.status = 'new'
  }

  findMasterClone(uuid: string): any {
    let nodeMasterKey: any;

    const foreachTree = (node: any) => {
      if (node.uuid == uuid) {
        nodeMasterKey = node;
        return node
      }

      if (node.children && node.children.length > 0) {
        node.children.forEach(foreachTree);
      }
    };

    this.files?.forEach(foreachTree);
    return nodeMasterKey;
  }

  cloneNode(node: any, event: Event){
    this.isPaste = true;

    if(event){
      event.stopPropagation()
    }

    let uuid = v4()
    this.copyFile = { ...node, clone_by: node.uuid, uuid: uuid, key: uuid };
    delete this.copyFile.parent

    this.recursiveUpdateCopyChildren(this.copyFile, { slug: 'initial_config'})
    
    this.copyFile = JSON.parse(JSON.stringify(this.copyFile));

    this.recursiveUpdateCopyChildren(this.copyFile, { slug: 'clone'})

    delete this.copyFile.attachments
    this.copyFile.attachments = []

    if (node.attachments.length > 0) {
      node.attachments.forEach((item: any) => this.copyFile.attachments.push({ ...item, is_copied: true}))
    }

    // let uuid = v4()
    // this.copyFile = {}
    // this.copyFile = { ...node, uuid, key: uuid };
    // delete this.copyFile.parent
    // delete this.copyFile.children
    // this.copyFile = JSON.parse(JSON.stringify(this.copyFile));

    // this.copyFile.children = []
    // this.copyFile.clone_by = node.uuid;
    // delete this.copyFile.clones
    // delete this.copyFile.fte
    // delete this.copyFile.fte_cost
    // if (node.clone_by) {
    //   this.copyFile.clone_by = node.clone_by;

    //   let parent = this.findMasterClone(node.clone_by)

    //   this.cloneOrigin = parent

    //   if (parent.uuid) {
    //     this.setUpdateTreeItem(parent.uuid, parent)
    //   }
    // } else {
    //   this.cloneOrigin = node
    // }
  }

  findAndAddChild(cloneByKey: number, childUuid: number) {
    const traverseTree = (currentNode: any) => {
      if (currentNode.uuid === childUuid) {
        currentNode.children = currentNode.children || [];
        currentNode.children.push(childUuid);
      } else if (currentNode.children) {
        currentNode.children.forEach((child: any) => traverseTree(child));
      }
    };

    this.files?.forEach((node: any) => traverseTree(node));
  }

  async finishedCopyClone(){
    this.isPaste = false;
    this.files?.forEach((item: any) => {
      item.is_cut = false
      this.recursiveUpdateCopyChildren(item, { slug: 'initial_config', type: 'no-cut'})
    });
    this.cdr.detectChanges()
    delete this.copyFile
  }

  async recursiveUpdateCopyChildren(obj: any, parameters?: any){
    await obj?.children?.forEach(async (nodeChild: any) => {
      if(parameters?.slug == 'initial_config'){
        delete nodeChild.parent

        if(parameters?.type == 'cut'){
          nodeChild.is_cut = true
        }else if(parameters?.type == 'no-cut'){
          nodeChild.is_cut = false
        }
      }else if(parameters?.slug == 'code_and_level'){
        delete nodeChild.data
        nodeChild.level = parameters.level + 1
        if(!this.copyFile?.is_cut){
          nodeChild.uuid = v4()
          nodeChild.key = nodeChild.uuid
        }

        nodeChild.master = obj.uuid
      }else if(parameters?.slug == 'clipboard'){
        delete nodeChild.clone_by
        delete nodeChild.clones
        delete nodeChild.is_approved
        nodeChild.uuid = v4()
        nodeChild.key = nodeChild.uuid
        delete nodeChild.maturity
        delete nodeChild.complements
        delete nodeChild.data
        delete nodeChild.personas
        delete nodeChild.technologies
        delete nodeChild.technologies_table
        nodeChild.description.fte = 0
        nodeChild.description.fte_one = 0
        nodeChild.description.fte_cost = 0
        nodeChild.description.fte_cost_one = 0
        nodeChild.rows.strategy = []
        let arrayAttachments = nodeChild.attachments
        delete nodeChild.attachments
        nodeChild.attachments = []
        if (arrayAttachments.length > 0) {
          arrayAttachments.forEach((item: any) => nodeChild.attachments.push({ ...item, is_copied: true, uuid: v4() }))
        }
      }else if(parameters?.slug == 'clone'){
        delete nodeChild.parent
        delete nodeChild.data

        if(!nodeChild.clone_by){
          nodeChild.clone_by = nodeChild.uuid 
        }

        nodeChild.level = parameters.level + 1
        nodeChild.uuid = v4()
        nodeChild.key = nodeChild.uuid
      }

      if(nodeChild.children){
        await this.recursiveUpdateCopyChildren(nodeChild, { ...parameters, level: nodeChild.level})
      }
    });
  }

  async copyClipboard(node: any, event: Event){
    let copyFile: any = {}
    this.isLoadingClipboard = true;
    if(event){
      event.stopPropagation()
    }

    if (node.clone_by) {
      node = this.findMasterClone(node.clone_by)
    }

    copyFile = {}
    copyFile = {...node};
    delete copyFile.parent

    await this.recursiveUpdateCopyChildren(copyFile, { slug: 'initial_config' })

    copyFile = await JSON.parse(JSON.stringify(copyFile));

    await this.recursiveUpdateCopyChildren(copyFile, { slug: 'clipboard' })

    delete copyFile.clone_by
    delete copyFile.clones
    delete copyFile.is_approved
    copyFile.uuid = v4()
    copyFile.key = copyFile.uuid
    delete copyFile.maturity
    delete copyFile.complements
    delete copyFile.master
    delete copyFile.data
    delete copyFile.personas
    delete copyFile.technologies
    delete copyFile.technologies_table
    copyFile.description.fte = 0
    copyFile.description.fte_one = 0
    copyFile.description.fte_cost = 0
    copyFile.description.fte_cost_one = 0
    copyFile.rows.strategy = []
    let arrayAttachments = copyFile.attachments
    delete copyFile.attachments
    copyFile.attachments = []
    
    if (arrayAttachments.length > 0) {
      arrayAttachments.forEach((item: any) => copyFile.attachments.push({ ...item, is_copied: true, uuid: v4() }))
    }

    try {
      const response: any = await this.clipboardService.setNodeToClipboard(copyFile)
      if ([201, 202].includes(response.status)) {
        this.isLoadingClipboard = false;
        addAlert('success', response.message)
      } else{
        this.isLoadingClipboard = false;
        addAlert('danger', response.message)
      }
    } catch (error: any) {
      this.isLoadingClipboard = false;
      await apiOfflineAsync(error)
      this.isLoading = false;
    }
  }

  async copyNode(node: any, event: Event, type?: string){
    this.isPaste = true;
    if (event) {
      event.stopPropagation()
    }

    if (node.clone_by) {
      node = this.findMasterClone(node.clone_by)
    }

    let uuid    
    if(type == 'cut'){
      node.is_cut = true
      uuid = node.uuid
    }else{
      // uuid = v4()
    }

    this.copyFile = { ...node, uuid: uuid, key: uuid };
    delete this.copyFile.parent

    this.recursiveUpdateCopyChildren(this.copyFile, { slug: 'initial_config', type: type})
    
    this.copyFile = JSON.parse(JSON.stringify(this.copyFile));

    delete this.copyFile.attachments
    this.copyFile.attachments = []

    if (node.attachments.length > 0) {
      node.attachments.forEach((item: any) => this.copyFile.attachments.push({ ...item, is_copied: true}))
    }

    delete this.copyFile.clone_by
    delete this.copyFile.clones
    delete this.copyFile.is_approved

    // if(type == 'cut'){
    //   node.is_cut = true
    // }else if(type == 'no-cut'){
    //   node.is_cut = false
    // }
  }

  showPaste(node: any): boolean{
    let flag: boolean = true
    
    if(node?.children?.length > 0){
      node.children.forEach((item: any) => {
        if(item?.label == this.copyFile?.label){
          flag = false
        }
      });
    }

    return flag
  }

  async pasteCopyNode(node: any, event: Event){
    if(event){
      event.stopPropagation()
    }
    
    // if(this.cloneOrigin){
    //   this.cloneOrigin.clones = this.cloneOrigin.clones ?? []
    //   this.cloneOrigin.clones.push(this.copyFile.uuid)
    //   delete this.cloneOrigin
    // }
    this.recursiveUpdateCopyChildren(this.copyFile, { slug: 'initial_config', type: 'no-cut'})

    if(node && node?.children){
      this.copyFile.master = node.uuid
      node.children.push(this.copyFile)
      this.recursiveUpdateCopyChildren(node, { slug: 'code_and_level', level: node.level })
      this.copyFile.is_cut = false
      delete this.copyFile
    }

    this.filesOld = [...this.files]

    this.filesOld = this.filesOld
      .map(removeCutNodes)
      .filter((item: any): item is any => item !== null);

    this.files = [...this.filesOld]

    await this.updateFiles()
    this.finishedCopyClone()
  }

  async loadModal(type: number, node: any = {}, event?: Event){
    this.isShowEdit = false;
    this.isShow = false;
    if(type == 0 || this.isPaste){
      return
    }else if(type == 1){
      await this.showCreateItemModal(node, event)
      return
    }else if(type == 2){
      if(!this.getSystemPermission('business_solutions', 'view_process')){
        return
      }
      node = this.selectedFile
      await this.showEditItemModal(node)
      return
    }else if(type == 3){
      await this.showDeleteItemModal(node)
      return
    }
  }

  setUpdateTreeItem (uuid: string, node: any) {
    this.files?.forEach((item: any, index: number) => {
      if (uuid == item.uuid) {
        this.files[index] = node
      } else if (item.children.length > 0) {
        this.setUpdateTreeItemRecursive(item.children, uuid, node)
      }
    })
  }

  setUpdateTreeItemRecursive (array: any, uuid: string, node: any) {
    array.forEach((item: any, index: number) => {
      if (uuid == item.uuid) {
        array[index] = node
      } else if (item.children.length > 0) {
        this.setUpdateTreeItemRecursive(item.children, uuid, node)
      }
    })
  }

  async showCreateItemModal(node: any = {}, event?: Event) {
    if(event){
      event.stopPropagation()
    }
    this.selectedFile = node
    this.cdr.detectChanges();
    await this.initParameters(node.level + 1);

    this.parameters.isEdit = true;
    await this.getBusinessTreeNew(node);

    // if (!this.parameters.parent.code) this.parameters.parent.label = this.properties.corporation.name;
    // this.parameters.business = this.properties.corporation.corporation_name

    if(Object.keys(node).length === 0){
      this.parameters.item.master = 'ROOT'
    }

    this.isShow  = true;
    this.objectModal = { ...this.parameters }
    this.objectModal.is_new = true;
    this.objectModal.files = [ ...this.files ]
    this.objectModal.filesOld = [ ...this.filesOld ]

    // if(!node.data){
    //   this.objectModal.isJourney = true
    // }

  }

  async showEditItemModal(node: any = {}, event?: Event) {
    if (!node) {
      return;
    }

    this.cdr.detectChanges();
    await this.initParameters();

    if (!node.rows || !node.rows.skills) {
      node.rows = node.rows ? {...node.rows, skills: []} : {skills: []}
    }

    this.parameters.item = { ...node }
    this.parameters.children_array = []
    delete this.parameters.item.parent

    if (this.parameters.item.children) {
      this.parameters.item.children.forEach((item: any) => {
        this.parameters.children_array.push(item.uuid);
      });
    }

    delete this.parameters.item.children
    this.parameters.item = JSON.parse(JSON.stringify(this.parameters.item));
    this.parameters.item.description = {...node.description}

    this.parameters.business = this.properties.corporation.corporation_name

    this.objectModal = { ...this.parameters }
    this.objectModal.is_new = false;
    this.objectModal.files = this.files
    this.objectModal.filesOld = this.filesOld
    this.objectModal.attachments_deleted = this.attachments_deleted
    this.isShowEdit = true;
  }

  async showDeleteItemModal(node: any = {}, event?: Event) {
    if(event){
      event.stopPropagation();
    }

    this.isShowEdit = false;
    this.isShow = false;

    this.parameters.item = { ...node }
    this.objectModal = { ...this.parameters }
    this.objectModal.files = this.files
    this.objectModal.filesOld = this.filesOld

    if(!node){
      this.openCloseModalDelete(false)
      return
    }
    this.openCloseModalDelete(true)
  }

  openCloseModalDelete (option: boolean = true) {
    this.isShowDelete = option;
  }

  async updateFiles(event?: any, ngOnInit?: any) {
    let flagIsImport = false; 

    if(!ngOnInit){
      this.isEdited = true;
      this.sharedData.isChangeTree = true
    }

    this.totalNumberProccess = 0

    if(event){
      if (event.files) {
        this.files = event.files;
        this.filesOld = event.filesOld ?? event.files;
        flagIsImport = true
      }
      if (event.isShow) {
        this.isShow = event.isShow
      }
    }

    this.verifyNoData()

    this.noData = this.files.length == 0

    this.journeyArray = []
    this.files?.forEach((item: any) => {
      this.recursiveUpdateSize(item, 1, flagIsImport);
      this.journeyArray.push({
        name: item.label,
        icon: item.icon,
        indicators: item.indicators,
        total_process: this.updateTotalProcess(item.children),
        personas: item.personas?.filter((persona: any) => persona.cost > 0).length
      })
    });

    if(event?.item){
      this.selectedFile = event.item

      this.selectedFile.fte = this.selectedFile.fte_one 
      this.selectedFile.fte_cost = this.selectedFile.fte_cost_one

      await this.findClones()
    }


    await this.recursiveUpdateValueIsClonned(this.files, 1)
    await this.recursiveUpdateValueIsClonned(this.files, 2)
    await this.recursiveCalculateFteOne(this.files)
    await this.recursiveCalculateSumFte(this.files)
  }

  async sumFte(item: any){
    // item.description.fte_cost -= item.description.fte_cost_one
    // item.description.fte -= item.description.fte_one

    // item.description.fte_cost_one = 0
    // item.description.fte_one = 0

    item.description.fte_cost = item.description.fte = 0
    item.description.fte_cost_one = item.description.fte_one = 0


    // item.personas?.forEach((persona: any) => {
    //   item.description.fte_cost_one += (iterateTreeAndFindNode(this.arrayPersonas, persona.uuid)  * persona.cost)
    //   item.description.fte_one += persona.cost;
    // });
    await item.personas?.forEach((persona: any) => {
      if (this.findNodeByUUID(this.arrayPersonas, persona.uuid) != null && !this.findOrganogramNodes(this.arrayPersonas).find((item: any) => item.uuid === persona.uuid)) {
        item.description.fte_cost_one += (iterateTreeAndFindNode(this.arrayPersonas, persona.uuid) * persona.cost);
        item.description.fte_one += persona.cost;
      }
    });    

    item.description.fte += item.description.fte_one
    item.description.fte_cost += item.description.fte_cost_one
  }

  async recursiveCalculateFteOne(tree: any){
    // tree.forEach(async (node: any) => {
    //   if (node.children && node.children.length > 0) {
    //     await this.recursiveCalculateFteOne(node.children);
    //   }
    //   await this.sumFte(node)
    // })

    for (const node of tree) {
      if (node.children && node.children.length > 0) {
        await this.recursiveCalculateFteOne(node.children);
      }
      await this.sumFte(node)
    }
  }

  async recursiveCalculateSumFte(tree: any){
    for (const node of tree) {
      if (node.children && node.children.length > 0) {
        this.recursiveCalculateSumFte(node.children);
        node.description.fte = node.description?.fte_one
        node.description.fte_cost = node.description?.fte_cost_one
        node.children.forEach((item: any) => {
          node.description.fte += item.description.fte
          node.description.fte_cost += item.description.fte_cost
        });
      }
    }
  }

  async recursiveUpdateValueIsClonned(array: any, part: number){
    if(part == 1){
      await asyncForEach(array, async (node: any) => {
        node.is_cloned = false
  
        if (node.children && node.children.length > 0) {
          await this.recursiveUpdateValueIsClonned(node.children, 1)
        }
      }) 
    }else{
      await asyncForEach(array, async (node: any) => {
        if(node.clone_by){
          if(this.findMasterClone(node.clone_by)){
            this.findMasterClone(node.clone_by).is_cloned = true
          }
        }
  
        if (node.children && node.children.length > 0) {
          await this.recursiveUpdateValueIsClonned(node.children, 2)
        }
      })
    }
  }

  async findClones(){
    const nodeCloned = {
      attachments: this.selectedFile.attachments,
      columns: this.selectedFile.columns,
      complements: this.selectedFile.complements,
      configurations: this.selectedFile.configurations,
      description: this.selectedFile.description,
      indicators: this.selectedFile.indicators,
      integrations: this.selectedFile.integrations,
      is_approved: this.selectedFile.is_approved,
      maturity: this.selectedFile.maturity,
      personas: this.selectedFile.personas,
      technologies_table: this.selectedFile.technologies_table,
      project: this.selectedFile.project,
      skills: this.selectedFile.skills,
      rules: this.selectedFile.rules,
      transactions: this.selectedFile.transactions,
      label: this.selectedFile.label,
      rows: this.selectedFile.rows,
      status: this.selectedFile.status
    };
    await this.recursiveAsyncForeach(this.files, nodeCloned)

    delete this.selectedFile;
    this.cdr.detectChanges();
  }

  async recursiveAsyncForeach(array: any, nodeCloned: any) {
    await asyncForEach(array, async (node: any) => {
      if (this.selectedFile.uuid == node.clone_by) {
        Object.assign(node, nodeCloned)
      }

      if (node.children && node.children.length > 0) {
        await this.recursiveAsyncForeach(node.children, nodeCloned)
      }
    })
  }

  updateTotalProcess(array: any[]): number {
    let total = 0;

    if (array && array.length > 0) {
      for (let i = 0; i < array.length; i++) {
        const item = array[i];
        total++;

        if (item.children && item.children.length > 0) {
          total += this.updateTotalProcess(item.children);
        }
      }
    }

    return total;
  }

  formatDate(date: Date): string {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);
  
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  }

  recursiveUpdateSize(item: any, level: number, flagIsImport: boolean) {
    item.description = item.description ? item.description : {}
    if(flagIsImport){
      item.userCreated = this.properties?.me?.name ? this.properties?.me?.name : '',
      item.userUpdated = this.properties?.me?.name ? this.properties?.me?.name : '',
      item.created_at = this.formatDate(new Date()),
      item.updated_at = this.formatDate(new Date())
    }
  
    item.level = item.level ? item.level : level;

    if (item.children && item.children.length > 0) {
      item.size = item.children.reduce((acc: number, child: any) => {
        return acc + this.recursiveUpdateSize(child, level + 1, flagIsImport);
      }, 0);
    } else {
      if(flagIsImport){
        item.userCreated =this.properties?.me?.name ? this.properties?.me?.name : '',
        item.userUpdated = this.properties?.me?.name ? this.properties?.me?.name : '',
        item.created_at = this.formatDate(new Date()),
        item.updated_at = this.formatDate(new Date())
      }
      item.size = 0;
    }
    this.totalNumberProccess++
    return item.size + 1;
  }

  navigateOriginNode(event: any){
    this.selectedFile = this.findMasterClone(event)
    this.loadModal(2, this.selectedFile = this.findMasterClone(event))  
  }

  async getTreeXls(type: string = '') {
    this.isLoadingExport = true;
    try {
      const response: Blob = await this.businessSolutionsService.getTreeXls({
        environment: this.properties.environment,
        corporation: this.properties.corporation,
        json: type == 'json',
        xls: type == 'xls'
      });

      await this.businessSolutionsService.downloadJsonXls(response, `${this.properties?.corporation?.slug}-business-tree`, type)
      addAlert('success', this.getLanguages('businessSolution/top/info/export/success'))

    } catch (error: any) {
      await apiOfflineAsync(error)
    }
    this.isLoadingExport = false;
  }

  async toggleUpload() {
    this.isShowImport = !this.isShowImport;
    this.text = ''

    if(!this.isShowImport && this.filesOld !== this.files){
      this.files = this.filesOld
    }
  }

  async toggleNameVersions(){
    this.isShowCreateNameModal = !this.isShowCreateNameModal
  }

  getSystemPermission(screen: string, action?: string){
    return systemPermissionsNew(screen, action)
  }

  isFirstColumn(node: any): boolean {
    if(node.master == 'ROOT' || !node.master){
      return true
    }
    return false
  }

  getClass(node: any): string{
    switch (this.getParentCount(node)) {
      case 1: return 'fas fa-folder-tree';
      case 2: return 'fas fa-folder';
      case 3: return 'fas fa-square';
      case 4: return 'fas fa-diagram-project';
      default: return 'fas fa-file'
    }
  }

  getIconLevel(level: number): string{
    return this.guidelineArray[level - 1]?.acronym ?? '-'
  } 

  getParentCount(node: any): number {
    let parentCount = 0;
    let parentNode = node;

    while (parentNode && parentNode.parent) {
      parentCount++;
      parentNode = parentNode.parent;
    }
    return parentCount + 1;
  }

  async saveTree(version: any = null, uuid: any = null){
    await this.setFormData(version)
    this.isLoading = true;
    this.isShow = false;
    this.isShowEdit = false;
    this.clearFilter()
    delete this.selectedFile

    try {
      const response: any = await this.businessSolutionsService.setBusinessTree(this.formData, uuid)
      if ([201, 202].includes(response.status)) {
        addAlert('success', response.message)
        this.attachments_deleted = []

        await this.getBusinessTree()

        this.isLoading = false;
        this.isEdited = false;
        this.sharedData.isChangeTree = false

        if(localStorage.getItem('flagIndicator') && localStorage.getItem('flagIndicator') != ''){
          this.sharedData.triggerDashboardReload();
        }
        if(this.isChangeTreeModalExit){
          this.router.navigate([this.sharedData.route])
          this.isChangeTreeModalExit = false;
        }
      } else{
        addAlert('danger', response.message)
        this.isLoading = false;
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
      this.isLoading = false;
    }
  }

  async setFormData(version?: any) {
    this.formData = new FormData()
    if(version){
      this.formData.append('name', version.name)
      if(version.reason){
        this.formData.append('reason', version.reason)
      }
    }
    this.formData.append('json', stringify(this.filesOld))
    this.formData.append('attachments_deleted', stringify(this.attachments_deleted))
    await asyncForEach(this.attachmentArray, async (item: any) => {
      if (item.file) this.formData.append('files', item.file)
    })
  }

  getCircleClass(node: any){
    switch (node.status) {
      case 'new': return 'circle-new';
      case 'progress': return 'circle-progress';
      case 'test': return 'circle-test';
      case 'homologation': return 'circle-homologation';
      case 'finished': return 'circle-concluded';
      case 'canceled': return 'circle-canceled';
      default: node.status = 'new'; return 'circle-new'
    }
  }

  isResponsive(): boolean {
    return window.innerWidth >= 900;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {}

  getNodeIntegrations(node: any){
    node.integrations = node.integrations ?? []

    const inputs = node.integrations.filter((integration: any) => integration.type === 'input');
    const outputs = node.integrations.filter((integration: any) => integration.type === 'output');

    let aux = [...inputs, ...outputs];

    aux  = aux.map((integration: any) => {
      if (integration.type === 'input') {
        return { ...integration, type: 'in' };
      } else if (integration.type === 'output') {
        return { ...integration, type: 'out' };
      } else {
        return integration;
      }
    });

    return aux;
  }

  getToggle(op: any, integrations: any, $event: Event){
    if(!integrations || integrations.length == 0){
      return
    }else{

    }

    op.toggle($event)
  }

  async choseTreeJourney(index: any){
    if(index == this.viewJourney){
      this.viewJourney = -1
      this.files = [...this.filesOld]
    }else if(index != null && index != undefined){
      this.files = [this.filesOld[index]]
      this.viewJourney = index
    }
  }

  getNameFrequency(slug: string){
    let text = ''
    this.frequencyArray.forEach((frequency: any) => {
      if(frequency.slug == slug){
        text = frequency.name[to64decode(localStorage.getItem('language')).lang]
      }
    });
    return text
  }

  async toggleChangeTree(){
    this.isChangeTree = false
    this.sharedData.isShowModalChangeTree = false
    this.sharedData.isChangeTree = false
    if(!this.sharedData.isShowModalChangeTree && this.sharedData.route != ''){
      await this.router.navigate([this.sharedData.route]);
      localStorage.removeItem('flagIndicator')
      this.sharedData.route = ''
    }
  }

  onNodeExpand(event: any){
    if(event.node.master == 'ROOT' || event.node.master == null){
      this.files?.forEach((node: any) => {
        if(node.uuid != event.node.uuid){
          node.expanded = false;
          this.expandRecursive(node, false);
        }
      })
    }
  }

  closeModalChange(){
    this.sharedData.isShowModalChangeTree = false
    this.isChangeTreeModalExit = true
  }

  hasError(uuid: string): boolean{
    if(!this.sharedData.arrayDuplicateName || this.sharedData.arrayDuplicateName?.length == 0){
      return false
    }

    return this.sharedData.arrayDuplicateName.includes(uuid)
  }

  @ViewChild('menuTrigger') menuTrigger!: MatMenuTrigger;

  async verifyAddNewLevel(level: number = 1, node: any = {}, event?: any, menuTrigger?: MatMenuTrigger){
    let array: any = [];
   
    let subArray = this.guidelineArray.slice(level - 1);
    this.menuLevelArray = []
    
    if(event){
      event.stopPropagation()  
    }

    if(node?.level >= 0){
      subArray = this.guidelineArray.slice(node.level);
      // if(this.guidelineArray[node.level]?.mandatory){
      //   this.loadModal(1, node, event)
      //   return
      // }
    }
    // else if(this.guidelineArray[level - 1]?.mandatory){
    //   this.loadModal(1)
    //   return
    // }
 
    for (const item of subArray) {
      if (!item?.name) {
        break; 
      } else {
        array.push(item);
      }
  
      if (item?.mandatory) {
        break;
      }
    }



    if(array?.length == 0){
      // this.loadModal(1, node, event)
      // return
      array.push({ name: 'Default', level: node?.level + 1 })
    }

    this.menuLevelArray = array

    if (menuTrigger) {
      menuTrigger.openMenu();
    }

    this.menuLevelArray.push(...fixedLevels)
  }

  getModifiedNode(node: any, level: number) {
    return { ...node, level: level };
  }

  findOrganogramNodes(nodes: any[], result: any[] = []): any[] {
    for (const node of nodes) {

      if (node.data.type === 'organogram') {
        result.push({label: node.label, uuid: node.uuid});
      }
  
      if (node.children && node.children.length > 0) {
        this.findOrganogramNodes(node.children, result);
      }
    }
  
    return result;
  }

  async getGuidelineProjectTree() {
    this.noData = false
    // this.isLoading = true;

    try {
      const response: any = await this.projectGuidelineService.getProjectGuidelineTree(({...this.parameters})).toPromise();
      // this.isLoading = false;

      if (response && response.data) {
        this.guidelineProject = parse(response.data)

        this.arrayGuidelineProject = this.guidelineProject.json;
      } else {
        throw new Error('Erro ao consultar guideline de projetos!\nConsulte o administrador do sistema!');
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  flipJourneys(){
    this.openJourneys = !this.openJourneys;
  }

  next() {
    if (this.filterCursor < this.filterArray.length) {
      this.filterCursor++;
      this.scrollToNode(this.filterArray[this.filterCursor - 1]);
    }
  }

  // Função para ir ao item anterior no filterArray
  previous() {
    if (this.filterCursor > 1) {
      this.filterCursor--;
      this.scrollToNode(this.filterArray[this.filterCursor - 1]);
    }
  }

  scrollToNode(uuid: string) {
    const node = this.findNodeByUUID(this.files, uuid); // Procura o nó na árvore
    if (node) {
      this.selectedFile = node; // Define o nó selecionado
      this.expandParentNodes(node); // Expande os nós pais, se necessário

      setTimeout(() => {
        const element = document.getElementById(uuid);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 0)
    }
  }

  // Função para expandir todos os nós pais para garantir que o nó esteja visível
  expandParentNodes(node: any) {
    let parentNode = this.findNodeByUUID(this.files, node.master); // Encontra o pai usando `master`
    while (parentNode) {
      parentNode.expanded = true;  // Expande o nó pai
      parentNode = this.findNodeByUUID(this.files, parentNode.master); // Move para o próximo nó pai
    }
  }

  // Função recursiva para encontrar um nó pelo UUID na árvore
  findNodeByUUID(nodes: any[], uuid: string): any {
    for (let node of nodes) {
      if (node.uuid === uuid) {
        return node;
      }
      if (node.children) {
        const found = this.findNodeByUUID(node.children, uuid);
        if (found) return found;
      }
    }
    return null;
  }

  clearFilter(){
    this.isSearch = false; 
    this.filterArray = [];
    this.text = ''
  }

}

function removeCutNodes(node: any): any | null {
  if (node.is_cut === true) {
    return null;
  }

  if (node.children) {
    node.children = node.children
      .map(removeCutNodes)
      .filter((child: any): child is any => child !== null);
  }

  return node;
}


function iterateTreeAndFindNode(tree: any[], uuidToFind: string): number{
  for (const node of tree) {
    if (node.uuid === uuidToFind) {
      return (node.data.cost_min + node.data.cost_max)/2;
    }

    if (node.children && node.children.length > 0) {
      const foundNode = iterateTreeAndFindNode(node.children, uuidToFind);
      if (foundNode) {
        return foundNode;
      }
    }
  }

  return 0;
}