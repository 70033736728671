import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CookieService } from 'ngx-cookie-service';
import { to64decode } from 'src/app/helpers/base64';
import { systemPermissionsNew } from 'src/app/helpers/systemPermissions';
import { addAlert, apiOfflineAsync } from 'src/app/layouts/pages/pages.component';
import { MaturityService } from 'src/app/services/maturity/maturity.service';
import { VersionsService } from 'src/app/services/versions/versions.service';
import { getLanguageCookie } from 'src/assets/language';
import {v4} from "uuid";

@Component({
  selector: 'app-maturity',
  templateUrl: './maturity.component.html',
  styleUrls: ['./maturity.component.scss']
})
export class MaturityComponent {
  @ViewChild('textarea', { static: false }) textarea!: ElementRef;

  searchText: string = ''

  isLoading!: boolean;
  noData!: boolean;

  dataSource: any = {};
  filteredDatasource: any = {};
  displayedColumns: string[] = ['current', 'last_change', 'delete'];

  currentPage = 1;
  currentPageReq = 1;
  itemsPerPage = 10;

  clicked: any = {}
  clickedByUser: any = {}

  objectModal: any = {}

  isShowConfirm: boolean = false;
  isShowDelete: boolean = false;

  lengthData = 0
  isEdited: boolean = false;

  constructor(
    private maturityService: MaturityService
    ){
      this.dataSource.data = [];
      this.filteredDatasource.data = [];
  }

  async ngOnInit(){
    await this.getQuestions()
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  getSystemPermission(screen: string, action: string){
    return systemPermissionsNew(screen, action)
  }

  getNames(name: any){
    return name[to64decode(localStorage.getItem('language')).lang]
  }

  search() {
    const searchText = this.searchText.toLowerCase()
    this.filteredDatasource.data = this.dataSource.data.filter( (item: { question: any;}) => item.question.toLowerCase().includes(searchText));
  }

  async getQuestions(){
    this.isLoading = true;

    try {
      const response: any = await this.maturityService.getQuestions().toPromise();

      if (response && response.data) {
        response.data.sort((a: any, b: any) => a.question.localeCompare(b.question));
        this.dataSource.data = response.data;
        this.filteredDatasource.data = response.data;
        this.lengthData = response.data.length
      } else {
        throw new Error('Erro ao consultar àrvore de negócios!\nConsulte o administrador do sistema!');
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }

    this.isLoading = false;
    this.noData = false
  }

  async setQuestions(){
    this.isLoading = true;
    this.isEdited = false;
    let temp: any = [];
    
    this.dataSource.data.forEach((question: any) => {
      if(question.question != '' && question.question != null){
        temp.push(question)
      }
    });

    const body = {
      array: temp
    }

    try {
      const response: any = await this.maturityService.setQuestions(body, this.lengthData)
      if ([201, 202].includes(response.status)) {
        addAlert('success', response.message)
        this.isLoading = false;
        await this.getQuestions()
      } else{
        addAlert('danger', response.message)
        this.isLoading = false;
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
      this.isLoading = false;
    }
  }

  getDisplayedRows(): any[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.filteredDatasource.data.slice(startIndex, endIndex);
  }

  choseItemsPage(number: number){
    this.itemsPerPage = number;
  }

  onPageChange(newPage: number){
    this.currentPage = newPage;
    this.currentPageReq = this.currentPage;
  }

  getTotalPages() {
    return Math.ceil(this.filteredDatasource.data.length / this.itemsPerPage);
  }

  async checkValue(event: any) {
    if (event.key === "Enter") {
      if(this.currentPageReq > this.getTotalPages()){
        this.currentPageReq = this.getTotalPages()
        this.currentPage = this.currentPageReq
        return
      }

      if(this.currentPageReq < 1){
        this.currentPageReq = 1
        this.currentPage = this.currentPageReq
        return
      }

      this.currentPage = this.currentPageReq
    }
  }

  newQuestion(){
    this.dataSource.data.unshift(
        {
          uuid: v4(),
          question: '' ,
          is_active: false,
          is_new: true,
        }
      )

    this.isEdited = true;
  }

  updateQuestion(card: any, event: any) {
    card.question = event.target.innerText;
  }

  autoResize(event: any) {
    this.isEdited = true
    const element = this.textarea.nativeElement;
    element.style.overflow = 'hidden';
    element.style.height = 'auto';
    element.style.height = (element.scrollHeight) + 'px';
  }


}
