import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';
import { LoginComponent } from '../pages/login/login.component';
import { asyncForEach } from "../helpers/asyncForEach";
import { localStorageToJsonAsync } from "../helpers/cookieToJson";
import { addAlert } from "../layouts/pages/pages.component";
import { getLanguageCookie } from "../../assets/language";
import { CookieService } from "ngx-cookie-service";
import { to64decode } from '../helpers/base64';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private cookieService: CookieService,
    private router: Router
  ) {}

  async canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const response = await this.authService.authExpired();
    const properties = await localStorageToJsonAsync()
    // const routes: any = []

    let flag = false;

    if (!response?.success) {
      await this.router.navigate(['/login']);
      return false;
    }

    if(state.url == '/' || JSON.parse(to64decode(localStorage.getItem('me')).is_admin_master)){
      return true
    }

    if(properties.profile.permissions){
      let screens: any = JSON.parse(properties.profile.permissions)
      screens.forEach((screen: any) => {
        if(`/${screen.screen}` == state.url){
          flag = true
        }
      });
    }

    // if (properties.permissions) {
    //   await asyncForEach(Object.keys(properties.permissions), async (key_module: any) => {
    //     let module = properties.permissions[key_module]

    //     await asyncForEach(Object.keys(module), async (key_screen: any) => {
    //       let screen = module[key_screen]

    //       routes.push(screen.screen_url)

    //       if (screen.screen_url == state.url && !screen.screen_permission) {
    //         addAlert('danger', `${getLanguageCookie('without_permission', localStorage.getItem('language'))}`)
    //         await this.router.navigate(['/']);
    //         return
    //       }
    //     })
    //   })
    // }

    // if (!routes.includes(state.url) && state.url != "/") {
    //   addAlert('danger', `${getLanguageCookie('without_permission', localStorage.getItem('language'))}`)
    //   await this.router.navigate([this.router.routerState.snapshot.url]);
    //   return false
    // }
    
    if(!flag){
      addAlert('danger', `${getLanguageCookie('without_permission', localStorage.getItem('language'))}`)
      await this.router.navigate(['/']);
      return false
    }

    return true;
  }
}




