import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { getLanguageCookie } from 'src/assets/language';
import { Subscription } from 'rxjs';
import { systemPermissionsNew } from 'src/app/helpers/systemPermissions';
import { localStorageToJsonAsync } from "../../helpers/cookieToJson";
import { addAlert, apiOfflineAsync } from "../../layouts/pages/pages.component";
import { TreeNode } from 'primeng/api';
import { v4 } from 'uuid';
import { PersonasService } from "../../services/personas/personas.service";
import { parse, stringify } from "circular-json";
import { sortArrayOfObjects } from 'src/app/helpers/sortArrayOfObjects';
import { UserEnvironmentsService } from 'src/app/services/userEnvironments/user-environments.service';
@Component({
  selector: 'app-personas',
  templateUrl: './personas.component.html',
  styleUrls: ['./personas.component.scss']
})
export class PersonasComponent {
  @Input() parameters: any = {}
  private subscription: Subscription = new Subscription();

  files!: TreeNode[];
  filesOld!: TreeNode[]

  queueAlert: any = []
  queueTime: any = []

  openTree: boolean = false;

  properties: any = {}

  selectedFlag: any = {
    lang: 'en',
    file: 'us.svg'
  };

  personas: any = {}
  formData: any

  isShow: boolean = false;
  isShowEdit: boolean = false;
  isShowDelete: boolean = false;

  isEdited = false;
  noData = true;

  isLoading: boolean = false;
  selectedNode: any;
  isSelectedNode = false;
  searchText: string = '';
  text: string = '';

  objectModal: any = {}

  data: any
  selectedFile: any;

  corporationPersonasArray: any = []
  searchCorporationPersonas: string = ''
  selectedCorporationPeronas: any = {
    name: '',
    uuid: ''
  }

  flagImportedTree: boolean = false

  constructor(
    private cdr: ChangeDetectorRef,
    private personasService: PersonasService,
    private userEnvironmentService: UserEnvironmentsService
  ) {

  }

  async ngOnInit() {
    this.properties = await localStorageToJsonAsync()
    await this.getPersonasTree();
    this.updateFiles({}, true)
    await this.getUserCorporations()
  }

  async getUserCorporations() {
    try {
      let response = await this.userEnvironmentService.getUserCorporations()
      if (response && response.data) {
        this.corporationPersonasArray = sortArrayOfObjects([...response.data.filter((item: any) => item?.uuid != this.properties?.corporation?.uuid)]);
      }
    } catch {

    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getLanguages(file: string) {
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  async choseItemSelect(type: string, value: any) {
    switch (type) {
      case 'corporationPersona':
        this.selectedCorporationPeronas = value;
        this.isEdited = true
        await this.getPersonasTree()
        break;
    }
  }

  async getPersonasTree() {
    this.noData = false
    this.isLoading = true;

    try {
      const response: any = await this.personasService.getPersonasTree({ ...this.parameters, corporation_uuid: this.selectedCorporationPeronas.uuid }).toPromise();
      this.isLoading = false;
      if (response && response.data) {
        this.personas = parse(response.data)

        if (this.selectedCorporationPeronas.uuid) {
          delete this.selectedCorporationPeronas
          delete this.personas.uuid
        }

        this.files = this.personas.json;
        this.filesOld = this.personas.json;

        this.isLoading = false;
        this.noData = this.files.length == 0
        await this.flipTree()
      } else {
        throw new Error('Erro ao consultar àrvore de personas!\nConsulte o administrador do sistema!');
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  searchNodes(): void {
    this.files = this.filesOld.filter((node) => this.filterNode(node, this.text, true));
    this.verifyNoData()
  }

  verifyNoData() {
    if (this.files.length == 0) {
      this.noData = true;
      return
    }
    this.noData = false;
  }

  private filterNode(node: TreeNode, query: string, includeParents: boolean = false): boolean {
    if (!node.label) {
      return false
    }
    const labelMatches = node.label.toLowerCase().includes(query.toLowerCase());

    if (labelMatches) {
      return true;
    }

    if (node.children) {
      const anyChildMatches = node.children.some((child) => this.filterNode(child, query, true));

      if (anyChildMatches) {
        return true;
      }
    }

    return false;
  }


  async flipTree() {
    this.openTree = !this.openTree;
    if (this.openTree) {
      return this.expandAll()
    }
    return this.collapseAll()
  }

  async expandAll() {
    this.files.forEach((node) => {
      this.expandRecursive(node, true);
    });
  }

  async collapseAll() {
    if (!this.files || !this.files.length) {
      return
    }
    this.files.forEach((node) => {
      this.expandRecursive(node, false);
    });
  }

  expandRecursive(node: TreeNode, isExpand: boolean) {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach((childNode) => {
        this.expandRecursive(childNode, isExpand);
      });
    }
  }

  async loadModal(type: number, node: any = {}, event?: Event) {
    this.isShowEdit = false;
    this.isShow = false;

    if (type == 0) {
      this.selectedFile = null
      return
    } else if (type == 1) {
      await this.showCreateItemModal(node, event)
      return
    } else if (type == 2) {
      if (!this.getSystemPermission('organizational_structure', 'view')) {
        return
      }
      node = this.selectedFile
      await this.showEditItemModal(node)
      return
    } else if (type == 3) {
      await this.showDeleteItemModal(node)
      return
    }
  }

  setUpdateTreeItem(uuid: number, node: any) {
    this.files.forEach((item: any, index: number) => {
      if (uuid == item.uuid) {
        this.files[index] = node
      } else if (item.children.length > 0) {
        this.setUpdateTreeItemRecursive(item.children, uuid, node)
      }
    })
  }

  setUpdateTreeItemRecursive(array: any, uuid: number, node: any) {
    array.forEach((item: any, index: number) => {
      if (uuid == item.uuid) {
        array[index] = node
      } else if (item.children.length > 0) {
        this.setUpdateTreeItemRecursive(item.children, uuid, node)
      }
    })
  }

  async showCreateItemModal(node: any = {}, event?: Event) {
    if (event) {
      event.stopPropagation()
    }
    this.selectedFile = node

    this.cdr.detectChanges();
    this.parameters.isEdit = true;
    this.parameters.isCreate = true
    this.parameters.item = { uuid: v4() }
    this.parameters.item.master = this.selectedFile.uuid ?? 'ROOT'
    this.parameters.item.label = ''
    this.parameters.item.data = { cost: '' }
    this.parameters.item.rows = {}
    this.parameters.item.columns = {}
    this.parameters.isCreate = true

    this.isShow = true;
    this.objectModal = { ...this.parameters }
    this.objectModal.is_new = true;
    this.objectModal.personas = [...this.filesOld]
  }

  async showEditItemModal(node: any = {}, event?: Event) {
    if (!node) {
      return;
    }

    this.cdr.detectChanges();
    this.parameters.item = { ...node }
    delete this.parameters.item.children
    delete this.parameters.item.parent
    this.parameters.item = JSON.parse(JSON.stringify({ ...this.parameters.item }));
    if (!this.parameters.item.rows) this.parameters.item.rows = {}
    if (!this.parameters.item.rows.skills) this.parameters.item.rows.skills = []
    this.parameters.isCreate = false
    this.parameters.isEdit = false

    delete this.parameters.item.parent
    delete this.parameters.item.children

    this.objectModal = { ...this.parameters }
    this.objectModal.personas = this.filesOld
    this.objectModal.formData = this.formData
    this.isShowEdit = true;
  }

  async showDeleteItemModal(node: any = {}, event?: Event) {
    if (event) {
      event.stopPropagation();
    }

    this.isShowEdit = false;
    this.isShow = false;

    this.parameters.item = { ...node }
    this.objectModal = { ...this.parameters }
    this.objectModal.files = this.files
    this.objectModal.filesOld = this.filesOld

    if (!node || !node.data) {
      this.openCloseModalDelete(false)
      return
    }
    this.openCloseModalDelete(true)
  }

  openCloseModalDelete(option: boolean = true) {
    this.isShowDelete = option;
  }

  updateFiles(event?: any, ngOnInit?: boolean) {
    this.text = '';


    if (!ngOnInit) {
      this.isEdited = true;
    }

    if (event) {
      if (event.files) {
        this.files = event.files;
        this.filesOld = event.filesOld ?? event.files;
      }
      if (event.isShow) {
        this.isShow = event.isShow;
      }
    }

    this.verifyNoData();
    this.noData = this.files.length == 0;

    this.files?.sort((a: any, b: any) => {
      if (a.data.type !== 'organogram' && b.data.type == 'organogram') {
        return -1;
      } else if (a.data.type !== 'organogram' && b.data.type == 'organogram') {
        return 1;
      } else {
        return 0;
      }
    });

    this.files?.forEach((item: any) => {
      this.recursiveUpdateSize(item);
    });

        
    this.formData = event.formData ? event.formData : new FormData()
    this.formData.append('json', stringify(this.filesOld))

    // for (const key of this.formData.keys()) {
    //   console.log(key, this.formData.get(key));
    // }
  }

  recursiveUpdateSize(item: any) {
    if (item.children && item.children.length > 0) {

      item.children?.sort((a: any, b: any) => {
        if (a.data.type !== 'organogram' && b.data.type == 'organogram') {
          return -1;
        } else if (a.data.type !== 'organogram' && b.data.type == 'organogram') {
          return 1;
        } else {
          return 0;
        }
      });

      item.size = item.children?.reduce((acc: number, child: any) => {
        return acc + this.recursiveUpdateSize(child);
      }, 0);
    } else {
      item.size = 0;
    }
    return item.size + 1;
  }

  getSystemPermission(screen: string, action: string) {
    return systemPermissionsNew(screen, action)
  }

  async saveTree(isNew: boolean = true) {
    this.isEdited = false;
    this.isLoading = true;
    this.isShow = false;
    this.isShowEdit = false;

    delete this.selectedFile

    // await this.setFormData()
    try {
      const response: any = await this.personasService.setPersonasTree(this.formData, this.personas.uuid)
      if ([201, 202].includes(response.status)) {
        addAlert('success', response.message)
        await this.getPersonasTree()
        this.flagImportedTree = false
        this.isLoading = false;
      } else {
        addAlert('danger', response.message)
        this.isLoading = false;
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
      this.isLoading = false;
    }
  }

  // async setFormData() {
  //   this.formData = new FormData()
  //   this.formData.append('json', stringify(this.filesOld))
  // }

  isResponsive(): boolean {
    return window.innerWidth >= 900;
  }

  getParentCount(node: any): number {
    let parentCount = 0;
    let parentNode = node;

    while (parentNode && parentNode.parent) {
      parentCount++;
      parentNode = parentNode.parent;
    }

    // Adicione 1 para incluir o próprio nó na contagem
    return parentCount + 1;
  }

  async getTreeXls(type: string = '') {
    this.isLoading = true;
    try {
      const response: Blob = await this.personasService.getTreeXls({
        environment: this.properties.environment,
        corporation: this.properties.corporation,
        json: type == 'json',
        xls: type == 'xls'
      });

      await this.personasService.downloadJsonXls(response, `${this.properties?.corporation?.slug}-persona-tree`, type)
      addAlert('success', this.getLanguages('businessSolution/top/info/export/success'))

    } catch (error: any) {
      await apiOfflineAsync(error)
    }
    this.isLoading = false;
  }

  getIcon(slug: string): string {
    let icon: string = ''

    switch (slug) {
      case 'persona':
        icon = 'fa-user-tie'
        break;
      case 'organogram':
        icon = 'fa-sitemap'
        break;
      case 'organization':
        icon = 'fa-building'
        break;
      default:
        break;
    }

    return icon
  }
}


