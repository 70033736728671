import { Component, Input } from '@angular/core';
import { to64decode } from 'src/app/helpers/base64';
import { getLanguageCookie } from 'src/assets/language';
import { MatTableDataSource } from '@angular/material/table';
import { v4 } from "uuid";
import { addAlert } from "../../../../layouts/pages/pages.component";

@Component({
  selector: 'app-complements',
  templateUrl: './complements.component.html',
  styleUrls: ['./complements.component.scss']
})
export class ComplementsComponent {
  @Input() parameters: any = {}
  @Input() dimensions: any = {}

  selectedFlag: any = {
    lang: 'en',
    file: 'us.svg'
  };

  dataSource!: MatTableDataSource<any>;
  displayedColumns: string[] = ['name','type','actions'];
  currentPage = 1;
  currentPageReq = 1;
  itemsPerPage = 10;

  typeArray: any = []
  searchType: string = '';
  selectedType: any = {
    name: ''
  }

  nodeArray: any = []
  searchNode: string = '';
  selectedNode: any = {
    label: ''
  }

  is_new_complement: boolean = true;

  currentComplement: any = {}

  constructor() {
    this.dataSource = new MatTableDataSource();
  }

  async ngOnInit(){
    await this.getBusinessTree()
    await this.getOptionsArray()
    this.dataSource.data = this.parameters.item.complements ?? []
  }

  getNames(name: any){
    return name[to64decode(localStorage.getItem('language')).lang]
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  isData(){
    return this.dataSource.data.length > 0
  }

  getDisplayedRows(): any[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.dataSource.data.slice(startIndex, endIndex);
  }

  getColumns(){
    return this.displayedColumns.slice(1)
  }

  getItemsPerPage(){
    return this.itemsPerPage
  }

  getCurrentPages(): number{
    return this.currentPage
  }

  choseItemsPage(number: number){
    this.itemsPerPage = number;
    this.getDisplayedRows()
  }

  onPageChange(newPage: number, text: string): void {
    this.currentPage = newPage;
  }

  getTotalPages(): number {
    return Math.ceil(this.dataSource.data.length / this.itemsPerPage);
  }

  async checkValue() {
    let total = this.getTotalPages()
    if (this.currentPage > total) this.currentPage = total;
  }

  choseItemSelect(type: string, value: any, item: any){
    switch (type) {
      case 'type':
        this.selectedType = value;
        item.type = value.slug;
        break;
      case 'node':
        this.selectedNode = value;
        item.label = value.label;
        item.uuid = value.uuid
        break;
      default:
        break;
    }
  }

  async getOptionsArray() {
    this.typeArray = this.parameters.optionsArray.typeComplement
    this.selectedType.name = this.typeArray[0].name
  }

  async selectComplement(item: any){
    this.currentComplement = { ...item }
    this.is_new_complement = false;
    this.typeArray.forEach((type:any) => {
      if(type.slug == item.type.slug){
        this.selectedType = type
      }
    });

    this.nodeArray.forEach((node:any) => {
      if(node.label == item.label){
        this.selectedNode = node
      }
    });
  }

  async newComplement(){
    this.currentComplement = {
      uuid: v4(),
      label: '',
      type: '',
    };

    this.selectedType = {...this.typeArray[0]}

    this.selectedNode = {...{}}

    this.is_new_complement = true;
  }

  async saveComplement(){
    this.currentComplement.type = this.selectedType

    if(!this.parameters.isEdit){
      return
    }
    let inputs: any = []

    if (!this.currentComplement.label || this.currentComplement.label == '') {
      inputs.push('Name')
    }

    if (inputs.length > 0) {
      addAlert('orange', `${this.getLanguages('mandatoryField')}${inputs.join(`, `)}`)
      return
    }

    this.dataSource.data.find((item: any, index: number) => {
      if(item.uuid == this.currentComplement.uuid){
        this.dataSource.data[index] = this.currentComplement
        this.is_new_complement = false
      }
    })

    if(this.is_new_complement){
      this.dataSource.data.push(this.currentComplement)
    }
    await this.newComplement()
  }

  async deleteComplement(uuid: string) {
    this.dataSource.data = this.dataSource.data.filter(item => item.uuid !== uuid);
    this.parameters.item.complements = this.dataSource.data
    await this.newComplement()
  }

  isNodeInComplements(node: any): boolean {
    return this.parameters.item.complements.includes(node.label);
  }

  async getBusinessTree() {
    let array: any = [];
    this.nodeCodeRecursive(this.parameters.filesOld, array, 'ROOT');
    this.nodeArray = array;
  
    // Sincronizar `complements` com base em `nodeArray`
    if (this.parameters.item.complements) {
      // Filtrar e atualizar `complements`
      this.parameters.item.complements = this.parameters.item.complements
        .filter((complement: { uuid: string, label: string }) => {
          // Encontra o item correspondente na `nodeArray`
          const matchedNode = this.nodeArray.find(
            (node: { uuid: string; status: string }) =>
              node.uuid === complement.uuid && node.status !== 'canceled'
          );
  
          if (matchedNode) {
            // Atualiza o label se o item correspondente existir e não estiver cancelado
            complement.label = matchedNode.label;
            return true; // Mantém o item no array de complements
          }
  
          // Remove o item se não houver correspondência ou estiver cancelado
          return false;
        });
    }
  }
  

  nodeCodeRecursive(node: any, array: any, key?: any){
    node.forEach((child: any) => {
      if(child.status != 'canceled'){
        array.push(this.getRow(child, key))
      }

      if(child.children && child.children.length > 0){
        this.nodeCodeRecursive(child.children, array, child.key)
      }
    });
  }

  getRow (child: any, master: any) {
    let row: any = {
      master: master,
      key: child.key,
      uuid: child.uuid ?? v4(),
      data: child.data,
      label: child.label,
      status: child.status,
      size: child.children ? child.children.length : 0,

      is_approved: child.is_approved ?? false,

      rows: child.rows ?? [],
      columns: child.columns ?? [],
      clones: child.clones ?? [],
      clone_by: child.clone_by ?? 0,
      maturity: child.maturity ?? {},
      attachments: child.attachments ?? [],
      description: child.description ?? {},
      indicators: child.indicators ?? [],
      complements: child.complements ?? [],
    }

    if (child.is_delete) {
      row = { ...row, is_delete: child.is_delete }
    }

    return row
  }

  isNodeView(node: any){
    let bool = node.is_delete;
    if (node.uuid == this.parameters.item.uuid) {
      bool = true;
    }

    this.dataSource.data.forEach((element: any) => {
      if(element.uuid == node.uuid){
        bool = true
      }
    });

    return bool
  }

}
