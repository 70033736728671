import { to64decode } from "./base64"

// export function systemPermissions(permission: any, module: string, screen: string, action: string, isbase64: boolean = true):any  {
//     if (permission && isbase64){
//         permission = to64decode(permission)
//     }

//     if (!permission || !permission[module] || !permission[module][screen]) {
//         return false
//     }

//     if (['create', 'read', 'update', 'delete', 'screen_permission'].includes(action)) {
//       return permission[module][screen][action] ?? false
//     }

//     if (!permission[module][screen].specials) {
//       permission[module][screen]['specials'] = {}
//     }

//     if (!permission[module][screen]['specials'][action]) {
//       permission[module][screen]['specials'][action] = false
//     }

//     return permission[module][screen]['specials'][action];
// }

export function systemPermissionsNew(screen: string, action?: string):any  {

  const me = localStorage.getItem('me')

  if(me && JSON.parse(to64decode(localStorage.getItem('me')).is_admin_master) || screen == 'home'){
    return true
  }

  let access = false;
  try {
    if(!to64decode(localStorage.getItem('profile')).permissions){
      return false
    }

    const permissions = JSON.parse(to64decode(localStorage.getItem('profile')).permissions)

    permissions.forEach((permission: any) => {
      if(permission.screen == screen){
        if(!action){
          access = true
          return
        }else if(permission.permissions.includes(action)){
          access = true
          return
        }
      }
    });
    
  } catch (error) {
    access = false
    console.log(error)
  }
  return access
}