<div class="all-content">
    <div class="top">
        <div class="toggle"></div>
        <div class="div-icon-button">
          <button class="save" (click)="exitModal()">
            {{ getLanguages('back') }}
          </button>
          <button class="save" (click)="next()" *ngIf="arrayUsers.length > 0">
            {{ getLanguages('next') }}
          </button>
          <i class="fas fa-xmark" (click)="exitModal('all')"></i>
        </div>
    </div>

    <div class="title">
      {{ getLanguages('instances/modal/user/selectUsers')}}
    </div>
    
    <div class="row">
      <span class="name-environment"> {{ parameters.environment.name }} </span>
    </div>

    <div class="div-search">
      <div class="search">
          <input type="text" [(ngModel)]="searchText" (keyup.enter)="searchUser()">
      </div>
      <button (click)="searchUser()">
          {{ getLanguages('search') }}
      </button>
    </div>

    <div class="row content">
      <div class="div-table" *ngIf="isData() && !isLoading">
        <table mat-table [dataSource]="getDisplayedRows()" class="table">
          <ng-container matColumnDef="Check">
            <th mat-header-cell *matHeaderCellDef style="width: 6vh!important;"></th>
            <td mat-cell *matCellDef="let element; let i = index;" style="width: 6vh!important;">
              <mat-checkbox
                class="checkbox"
                [color]="task.color"
                [ngModel]="element.selected"
                [disabled]="element.selected"
                (change)="setSelectUser({ uuid: element.uuid, name: element.name })"
                >
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef>
              <span> {{ getLanguages('instances/modal/user/name') }} </span>
            </th>
            <td mat-cell *matCellDef="let element" class="pointer">
              <span> {{ element.name }} </span>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="Email">
            <th mat-header-cell *matHeaderCellDef>
              <span> {{ getLanguages('instances/modal/user/email') }} </span>
            </th>
            <td mat-cell *matCellDef="let element" class="pointer">
              <span> {{ element.email }} </span>
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
      <div class="no-data" *ngIf="!isData() && !isLoading">
  
        <div class="circle">
          <i class="fas fa-exclamation"></i>
        </div>
  
        {{ getLanguages('errors/noData') }}
      </div>
      <div class="loading" *ngIf="isLoading">
        <section>
          <div class="loading-spinner"></div>
          <span class="span">
            {{ getLanguages('loading') }}
          </span>
        </section>
      </div>
    </div>

    <div class="div-paginator" *ngIf="isData() && !isLoading">
      <div class="paginator">
        <span>
          {{ getLanguages('businessSolution/modal/strategy/itensPage') }}
        </span>
        <button [matMenuTriggerFor]="menu" class="itens-per-page">
          {{ getItemsPerPage() }}
        </button>
        <mat-menu #menu="matMenu" >
          <button mat-menu-item (click)="choseItemsPage(5)">5</button>
          <button mat-menu-item (click)="choseItemsPage(10)">10</button>
        </mat-menu>
        <span>
          {{ getLanguages('businessSolution/modal/strategy/totalPages') }}
        </span>
        <span class="totalPages">
          {{ getTotalPages()}}
        </span>
        <button
          [ngClass]="getCurrentPages() === 1 ? 'disabled-arrow' : 'arrow'"
          [disabled]="getCurrentPages() === 1"
          (click)="onPageChange(getCurrentPages() - 1)">
          <i class="fa-solid fa-angle-left"></i>
        </button>
        <input type="number" [ngModel]="getCurrentPages()" disabled (blur)="checkValue($event)">
        <button
          [ngClass]="getCurrentPages() == getTotalPages() ? 'disabled-arrow' : 'arrow'"
          [disabled]="getCurrentPages() == getTotalPages()"
          (click)="onPageChange(getCurrentPages() + 1)">
          <i class="fa-solid fa-angle-right"></i>
        </button>
      </div>
    </div>
</div>

<div 
  class="background-modals" 
  [ngStyle]="{ 'background-color': isShowLinkProfile ? 'transparent' : '' }" 
  *ngIf="isShowLinkProfile || isShowAlert">
  
  <ng-container *ngIf="isShowLinkProfile">
    <app-link-profile 
      (togleLinkProfile)="togleLinkProfile($event)" 
      [parameters]="parameters">
    </app-link-profile>
  </ng-container>

  <ng-container *ngIf="isShowAlert">
    <app-changes-link-users 
      (toggleAlert)="toggleAlert()" 
      (close)="close()">
    </app-changes-link-users>
  </ng-container>
</div>

