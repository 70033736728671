<div class="all-content">
    <div class="top">
        {{ getLanguages('guideline') }}
    </div>

    <div class="row" *ngIf="showBanner">
      <div class="banner">
        <div class="toggle-left"></div>
        <i class="fas fa-lightbulb"></i>
        <span>
          {{ getLanguages('guideline/banner') }}
        </span>
        <i class="fas fa-xmark" (click)="showBanner = false"></i>
      </div>
    </div>

    <div class="noData">
      <!-- <div class="icon">
           <i class="fa-solid fa-exclamation"></i>
       </div>
       <span>
           {{ getLanguages('errors/noData')}}
       </span> -->

       <b> {{ getLanguages('guideline/import') }} </b>

       <button class="button-menu" [matMenuTriggerFor]="standardsCorporationsGuideline" *ngIf="getSystemPermission('standards', 'import_guideline')">
         <span>
           {{ selectedCorporationGuideline?.name ? selectedCorporationGuideline.name : getLanguages('select') }}
         </span>
         <i class="fa-solid fa-caret-down"></i>
       </button>

       <mat-menu #standardsCorporationsGuideline="matMenu">
         <div class="menu">
           <div class="input" (click)="$event.stopPropagation()">
             <input type="text" [(ngModel)]="searchCorporationGuideline" placeholder="{{ getLanguages('search') }}">
             <i class="fa-solid fa-magnifying-glass"></i>
           </div>
           <div class="scroll">
             <ng-container *ngFor="let corporationGuideline of corporationGuidelineArray">
               <ng-container *ngIf="corporationGuideline.name.toLowerCase().includes(searchCorporationGuideline.toLowerCase())">
                 <div class="item-menu" (click)="choseItemSelect('corporationGuideline', corporationGuideline)">
                   <span [ngClass]="corporationGuideline?.uuid == selectedCorporationGuideline?.uuid ? 'valid-span' : 'invalid-span' ">
                     {{ corporationGuideline.name }}
                   </span>
                 </div>
               </ng-container>
             </ng-container>
           </div>
         </div>
       </mat-menu> 

     </div>

    <div class="content">
      <div class="component" *ngIf="!isLoading">
          <div class="row" *ngFor="let item of guidelineArray; let i = index">
            <div class="div-level">
              <div class="top-level">
                <img [src]="'assets/level_' + item.level + '.svg'" alt="{{ item.level }}">
                <span>
                  {{ getLanguages('guideline/level') }} {{ item.level }}
                </span>
                <span class="add_level pointer" (click)="addLevel(i)" *ngIf="!item.name && getSystemPermission('standards', 'parametrize_level')">
                  {{ getLanguages('guideline/add_level') }}
    
                  <i class="fas fa-plus"></i>
                </span>
              </div>
              <div class="bottom-level" *ngIf="item?.name">
                <span> {{ item.name }} </span>
                <div class="right-bottom-level">
                  <span *ngIf="item.mandatory"> {{ getLanguages('guideline/mandatory') }} </span>
                  <i class="fas fa-pen-to-square pointer" (click)="edit(item)" *ngIf="getSystemPermission('standards', 'edit_level')"></i>
                </div>
              </div>
            </div>
          </div>
      </div>
    
      <div class="component" *ngIf="showForm">
        <app-form-standards [requestParameters]="parameters" (exitForm)="showForm = false" (getCorporationSettings)="updateGuidelineByForm($event)"></app-form-standards>
      </div>
    </div>
</div>

<!-- <div class="row">
  <div class="item item-4">
      <span> {{ getLanguages('businessSolution/modal/addCode/sla_unit') }} </span>
      <button class="button-menu" [matMenuTriggerFor]="slaUnitMenu">
        <span>
          {{ getNames(selectedSlaUnit.name) }}
        </span>
        <i class="fa-solid fa-caret-down" ></i>
      </button>

      <mat-menu #slaUnitMenu="matMenu">
        <div class="menu">
          <div class="input" (click)="$event.stopPropagation()">
            <input type="text" [(ngModel)]="searchSlaUnit" placeholder="{{ getLanguages('search') }}">
            <i class="fa-solid fa-magnifying-glass"></i>
          </div>
          <div class="scroll">
            <ng-container *ngFor="let slaUnit of slaUnitArray">
              <ng-container *ngIf="getNames(slaUnit.name).toLowerCase().includes(searchSlaUnit.toLowerCase())">
                <div class="item-menu" (click)="choseItemSelect('sla_unit', slaUnit)">
                  <span [ngClass]="getNames(slaUnit.name) == getNames(selectedSlaUnit.name) ? 'valid-span' : 'invalid-span'">
                    {{ getNames(slaUnit.name) }}
                  </span>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </mat-menu>
    </div>
</div> -->
