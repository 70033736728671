import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { to64decode } from 'src/app/helpers/base64';

@Component({
  selector: 'app-indicator',
  templateUrl: './indicator.component.html',
  styleUrls: ['./indicator.component.scss']
})
export class IndicatorComponent {
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  @Input() cardArray: any;
  @Input() slug!: any;
  @Input() type!: boolean; //true title, false subtitle
  @Input() loading!: boolean;
  @Output() flipedCard = new EventEmitter<string>();
  @Output() scrollScreen = new EventEmitter<string>();

  old_item: any;

  cardNoArray: any = [1,2,3,4]

  constructor(){}

  getNames(name: any){
    return name[to64decode(localStorage.getItem('language')).lang]
  }

  scrollLeft(elementsToScroll: number = 4) {
    const container = this.scrollContainer.nativeElement;
    const elementWidth = container.clientWidth * 0.25; // 25% da largura do componente
    const scrollAmount = (elementsToScroll * elementWidth);
    container.scrollLeft -= scrollAmount;
  }

  scrollRight(elementsToScroll: number = 4) {
    const container = this.scrollContainer.nativeElement;
    const elementWidth = container.clientWidth * 0.25; // 25% da largura do componente
    const scrollAmount = (elementsToScroll * elementWidth);
    container.scrollLeft += scrollAmount;
  }

  flipCard(item: any){
    this.scrollScreen.emit()
    if(this.type){
      if(item == this.old_item){
        return
      }
      this.flipedCard.emit(item.slug);
      this.old_item = item;
      this.cardArray.forEach((card: any) => {
        card.flip = false
      });
        
    }
    item.flip = !item.flip;
  }

  getTitle(): any{
    if(this.slug == 'environment'){
      return this.getNames({en: 'Environment', pt: 'Ambiente'})
    }
    if(this.slug == 'corporations'){
      return this.getNames({en: 'Corporation', pt: 'Corporação'})
    }
    if(this.slug == 'users'){
      return this.getNames({en: 'Users', pt: 'Usuários'})
    }
    if(this.slug == 'business_solutions'){
      return this.getNames({en: 'Process', pt: 'Processos'})
    }
    return this.getNames({en: 'Enterprise', pt: 'Empresarial'})
  }
}
