import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CorporationService } from 'src/app/services/corporation/corporation.service';
import { asyncForEach } from "../../../../helpers/asyncForEach";
import { UsersService } from 'src/app/services/users/users.service';
import { getLanguageCookie } from 'src/assets/language';
import { to64decode } from 'src/app/helpers/base64';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { addAlert, apiOfflineAsync } from "../../../../layouts/pages/pages.component";


@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})

export class CreateUserComponent {
  @Output() toggleUserModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() getUsers: EventEmitter<void> = new EventEmitter<void>();
  @Input() properties: any = {}
  @Input() parameters: any = {}

  @ViewChild(MatTable) table!: MatTable<any>;
  displayedColumns: any = ['Select', 'Corporation', 'Profile', 'Status']

  task: any = {
    color: 'seccondary',
  };

  environment_administrator: boolean = false

  selection: any = [];
  user: any = { is_admin_master: 0, access: { environment: [], corporation: [] } }
  setSelectionCorporationsProfilesArray: any = [];
  dataSource: any;

  selectedCorporations: { [corporationId: string]: boolean } = {};

  selectedFlag: any = {
    lang: 'en',
    file: 'us.svg'
  };

  statusArray = [ { value: true , label: {en: 'Active', pt: 'Ativo'} }, { value: false , label: {en: 'Inactive', pt: 'Inativo'}}]
  selectedStatus: any =  { name: this.statusArray[0].label };
  profileArray: any = []
  searchProfile: string = ""
  filteredProfileArray: any;

  selectedProfileEnvironment: any = {name: 'Select'}
  searchProfileEnvironment: string = ''

  isLoading!: boolean;
  isTreeEmpty!: boolean;

  constructor(
    private userService: UsersService,
    private corporationsService: CorporationService) {}

  async ngOnInit(){
    this.user.access.environment.push(this.properties.environment.slug)
    await this.getCorporationsProfiles();
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  getNames(name: any){
    return name[to64decode(localStorage.getItem('language')).lang]
  }

  choseStatus(status: any) {
    this.user.is_active = status.value;
    this.selectedStatus.name = status.value ? {en: 'Active', pt: 'Ativo'} : {en: 'Inactive', pt: 'Inativo'}
  }

  async validationProfile () {
    let inputs: any = []

    if(!this.user.is_admin_master){
      if(!this.selectedProfileEnvironment?.uuid){
        if (this.setSelectionCorporationsProfilesArray.length == 0) {
          addAlert('orange', `Por favor selecione uma corporação!`)
          return false
        }
  
        let selectedCount: number = 0
        await asyncForEach(this.setSelectionCorporationsProfilesArray, async (item: any) => {
          if (item.selected) selectedCount++
          if (item.selected && (!item.status || !item.profile)) {
            inputs.push(`${item.corporation.name}`)
          }
        })
  
        if (selectedCount == 0) {
          addAlert('orange', `Por favor selecione uma corporação!`)
          return false
        }
      }
    }

    if (inputs.length > 0) {
      addAlert('orange', `Por favor selecione um perfil|status para as corporações: ${inputs.join(`, `)}`)
      return false
    }
    return true
  }

  choseProfileEnvironment(profile: any){
    this.selectedProfileEnvironment = profile
  } 

  async setUser(){
    if (!await this.validationProfile()) {
      return;
    }

    this.user.profileEnvironment = this.selectedProfileEnvironment?.uuid ? this.selectedProfileEnvironment : null 

    let body = {
      user: this.user,
      corporationsprofiles: this.setSelectionCorporationsProfilesArray
    }

    try {
      const response:any = await this.userService.setUsers(this.parameters, body, true);
      if (response && [201, 202].includes(response.status) ) {
        addAlert('success', response.message)
      } else {
        addAlert('danger', response.message)
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
    await this.modalClose()
  }

  exitModal(hide: boolean = true): void {
    if (!hide) {
      return
    }

    this.getUsers.emit()
    this.toggleUserModal.emit();
  }

  async modalClose() {
    await Promise.all([ this.exitModal() ]);
  }

  async getCorporationsProfiles() {
    this.isLoading = true;
    this.dataSource = new MatTableDataSource<any>();
    try {
      const response: any = await this.corporationsService.getCorporationsProfilesTree(this.parameters);
      if (response && response.data){
        this.dataSource = new MatTableDataSource<any>(response.data)
        this.isLoading = false;
        this.isTreeEmpty = response.data.length == 0;
      } else {
        throw new Error('Erro ao consultar àrvore de corporations!\nConsulta o administrador do sistema!');
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  isAllSelected() {
    let numSelected: number = 0;
    this.dataSource.data.map((item: { selected: any; }) => { numSelected = numSelected + (item.selected ? 1 : 0) });
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  async toggleAllRows() {
    let status: any = !this.isAllSelected()
    await asyncForEach(this.dataSource.data, async (item: any, index: number) => {
      await this.setSelectionCorporationProfile(item, null, -1, { isAll: true, status: status })
    });
  }

  async setSelectionCorporationProfile (element: any = null, profile: any = null, index: number = -1, { isAll = false, status }: any = {}) {
    if (isAll) element.selected = status == 'ACTIVE'

    if (element && !profile && !status) {
      if (!isAll) element.selected = !element.selected

      let indexRemove: any = -1;

      await asyncForEach(this.setSelectionCorporationsProfilesArray, async (item: any, item_index: any) => {
        if (item.corporation.uuid == element.corporation.uuid) indexRemove = item_index
      })

      if (indexRemove >= 0) {
        this.setSelectionCorporationsProfilesArray[indexRemove].selected = element.selected
        this.setSelectionCorporationsProfilesArray[indexRemove].status = status

        if (element.selected) {
          this.user.access.corporation.push(element.corporation.slug)
        } else {
          this.user.access.corporation.splice(indexRemove, 1)
        }
        return;
      }

      if (!isAll) element.selected = true;

      this.setSelectionCorporationsProfilesArray.push({ corporation: element.corporation, selected: true })
      element.index = this.setSelectionCorporationsProfilesArray.length - 1

      if (!this.user.access.corporation.includes(element.corporation.slug))
        this.user.access.corporation.push(element.corporation.slug)
    }

    if (!element && profile && !status && index >= 0) {
      this.setSelectionCorporationsProfilesArray[index] = { ...this.setSelectionCorporationsProfilesArray[index], profile }
    }

    if (!element && !profile && status && index >= 0) {
      this.setSelectionCorporationsProfilesArray[index] = { ...this.setSelectionCorporationsProfilesArray[index], status }
    }
  }

  getAccessProfileEnvornment(): boolean{
    return this.properties?.me?.is_admin_master || this.properties?.profile?.type == 'environment'
  }

  getAccessProfileMaster(): boolean{
    return this.properties?.me?.is_admin_master
  }
}
