import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { getLanguageCookie } from 'src/assets/language';
import { Subscription } from 'rxjs';
import { systemPermissionsNew } from 'src/app/helpers/systemPermissions';
import { localStorageToJsonAsync } from "../../helpers/cookieToJson";
import { FunctionService } from 'src/app/services/function.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  properties: any = {}
  parameters: any = {}

  noData: boolean = false;
  isLoading: boolean = true;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  private subscription: Subscription = new Subscription();

  currentPage = 1;
  itemsPerPage = 10;

  isShowModal: boolean = false;
  isShowDelete: boolean = false;

  task: any = {
    color:  'accent'
  };

  statusArray: any = [
    {id: 1, name: 'ACTIVE'},
    {id: 2, name: 'INACTIVE'},
  ]

  searchText: string = '';

  dataSource: any;
  filteredDatasource: any;

  displayedColumns: string[] = ['Name', 'Type', 'Status', 'Updated_at', 'Created_at', 'Action'];


  constructor(
    private profileService: ProfileService,
    public sharedData: FunctionService
  ) {
    this.dataSource = new MatTableDataSource([]);
    this.filteredDatasource = new MatTableDataSource([]);
  }

  async ngOnInit(){
    await this.getProperties()
    await this.getProfiles();
  }

  async getProperties () {
    this.properties = await localStorageToJsonAsync()
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  async getProfiles(event?: any) {
    this.dataSource = new MatTableDataSource<any>();
    this.isLoading = true

    try{
      const response: any = await this.profileService.getProfiles();
      if (response?.data) {
        if (this.properties.me.profile?.type === 'environment' || this.properties.me.is_admin_master) {
          this.dataSource = new MatTableDataSource<any>(response.data);
          this.filteredDatasource = new MatTableDataSource<any>(response.data);
        } else {
          const filteredData = response.data.filter((profile: { type: string; }) => profile.type === 'organization');
          this.dataSource = new MatTableDataSource<any>(filteredData);
          this.filteredDatasource = new MatTableDataSource<any>(filteredData);
        }
      }
    }catch(error: any){
      console.log(error)
    }

    this.isLoading = false
  }

  choseItemsPage(number: number){
    this.itemsPerPage = number;
    this.getDisplayedRows()
  }

  onPageChange(newPage: number): void {
    this.currentPage = newPage;
  }

  getDisplayedRows(): any[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.filteredDatasource.data.slice(startIndex, endIndex);
  }

  getTotalPages() {
    return Math.ceil(this.filteredDatasource.data.length / this.itemsPerPage);
  }

  async checkValue(event: any) {
    let total = this.getTotalPages()

    if (this.currentPage > total) {
      this.currentPage = total;
    }
  }

  toggleModal(){
    this.isShowModal = !this.isShowModal;

    this.parameters = {}
  }

  toggleModalEdit(data: any = {}){
    if(!systemPermissionsNew('profiles', 'edit_profile')){
      return
    }

    this.parameters = data
    this.isShowModal = !this.isShowModal;
  }

  searchProfiles() {
    const searchText = this.searchText.toLowerCase()
    this.filteredDatasource.data = this.dataSource.data.filter( (item: { name: any;}) => item.name.toLowerCase().includes(searchText));
  }

  getSystemPermission(screen: string, action: string){
    return systemPermissionsNew(screen, action)
  }

  getItemsPerPage(){
    return this.itemsPerPage
  }

  getCurrentPages(): number{
    return this.currentPage
  }

  showDelete(row: any){
    this.parameters = row
    this.isShowDelete = true;
  }

  formatDateTime(isoDate: string): string {
    if (!isoDate) return '';
  
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Meses começam em 0
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }
  

}

