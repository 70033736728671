<div class="all-content">
    <div class="top">
      {{ getLanguages('project_guideline') }}
    </div>
    <div class="row">
      <div class="div-search">
        <div class="search">
            <input type="text" [(ngModel)]="text" (keyup.enter)="searchNodes()">
        </div>
        <button (click)="searchNodes()">
            {{ getLanguages('search') }}
        </button>
      </div>
      <div class="div-buttons">
        <div class="button-save">
            <button (click)="saveTree()" matTooltip="{{ getLanguages('save') }}" [disabled]="!isEdited" class="save button-tooltip" [ngClass]="!isEdited ? 'disabled no-pointer' : ''" *ngIf="getSystemPermission('project_guideline', 'save_structure')">
              {{ getLanguages('save') }}
            </button>
        </div>
        <div class="button-save" *ngIf="getSystemPermission('project_guideline', 'export')">
          <button class="save" [matMenuTriggerFor]="selectExport" *ngIf="filesOld && filesOld.length > 0">
            {{ getLanguages('businessSolution/top/info/export') }}
            <i class="fas fa-angle-down" style="margin-left: 2vh;"></i>
          </button>
          <mat-menu #selectExport="matMenu">
            <button (click)="getTreeXls('json')" class="menu-button">
              {{ getLanguages('businessSolution/top/info/export/json') }}
            </button>
            <button (click)="getTreeXls('xls')" class="menu-button">
              {{ getLanguages('businessSolution/top/info/export/xls') }}
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  
    <div class="content">
      <div class="title">
        <span>
          {{ getLanguages('project_guideline/tree') }}
        </span>
        <i class="fa-solid fa-chevron-right" [ngClass]="!openTree ? 'openned' : 'closed'" (click)="flipTree()"></i>
        <i class="fas fa-plus" (click)="loadModal(1)" *ngIf="getSystemPermission('project_guideline', 'create')"></i>
      </div>
    <div class="content-content" >
      <div class="tree" [ngStyle]="{'width': isResponsive() && (isShow || isShowEdit) ? '50%' : '100%'}">
        <div class="noData" *ngIf="noData && !isLoading">
          <div class="icon">
              <i class="fa-solid fa-exclamation"></i>
          </div>
          <span>
              {{ getLanguages('errors/noData')}}
          </span>
  
          <b> {{ getLanguages('project_guideline/import') }} </b>
  
  
          <button class="button-menu" [matMenuTriggerFor]="slaUnitMenu">
            <span>
              {{ selectedCorporationGuidelineProject?.name ? selectedCorporationGuidelineProject.name : getLanguages('select') }}
            </span>
            <i class="fa-solid fa-caret-down"></i>
          </button>
  
          <mat-menu #slaUnitMenu="matMenu">
            <div class="menu">
              <div class="input" (click)="$event.stopPropagation()">
                <input type="text" [(ngModel)]="searchCorporationGuidelineProject" placeholder="{{ getLanguages('search') }}">
                <i class="fa-solid fa-magnifying-glass"></i>
              </div>
              <div class="scroll">
                <ng-container *ngFor="let corporationGuidelineProject of corporationGuidelineProjectArray">
                  <ng-container *ngIf="corporationGuidelineProject.name.toLowerCase().includes(searchCorporationGuidelineProject.toLowerCase())">
                    <div class="item-menu" (click)="choseItemSelect('corporationGuidelineProject', corporationGuidelineProject)">
                      <span [ngClass]="corporationGuidelineProject?.uuid == selectedCorporationGuidelineProject?.uuid ? 'valid-span' : 'invalid-span' ">
                        {{ corporationGuidelineProject.name }}
                      </span>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </mat-menu>
        </div>
        <p-tree class="ptree"
          [value]="files"
          *ngIf="!isLoading && !noData"
          selectionMode="single"
          [(selection)]="selectedFile"
          (onNodeSelect)="loadModal(2, $event)"
          >
            <ng-template let-node pTemplate="default">
              <div class="pcontent">
                <div class="ptitle">
                    <i class="fas" [ngClass]="getIcon(node.level)"></i>
                    <b> {{ (node && node.label ? node.label : '') }} </b>
                </div>
                <div class="picons">
                  <div class="button" matTooltip="{{ getLanguages('technologies/delete') }}" *ngIf="getSystemPermission('project_guideline', 'delete') && (!node.children || node.children.length == 0)" (click)="showDeleteItemModal(node, $event)">
                    <i class="fas fa-trash i"></i>
                  </div>
                  <div class="button" matTooltip="{{ getLanguages('technologies/add') }}" *ngIf="getSystemPermission('project_guideline', 'create') && node.level < 4" (click)="loadModal(1, node, $event)">
                    <i class='fas fa-plus i'></i>
                  </div>
                </div>
              </div>
            </ng-template>
          </p-tree>
          <app-loading *ngIf="isLoading"></app-loading>
        </div>
        <div class="form-modal"  *ngIf="isShow || isShowEdit" [ngStyle]="{'width': !isResponsive() ? '100%' : 'calc(50% - 1vh)'}">
          <app-crud-projects *ngIf="isShow"  (toggleModal)="loadModal(0)" (getGuidelineProjectTree)="getGuidelineProjectTree()" [properties]="properties" [parameters]="objectModal" (updateFiles)="updateFiles($event)"></app-crud-projects>
          <app-crud-projects *ngIf="isShowEdit" (toggleModal)="loadModal(0)" (getGuidelineProjectTree)="getGuidelineProjectTree()" [properties]="properties" [parameters]="objectModal" (updateFiles)="updateFiles($event)"></app-crud-projects>
        </div>
      </div>
    </div>
  </div>
  
  <div class="background-modals" *ngIf="isShowDelete">
    <app-delete-component *ngIf="isShowDelete" (toggleDeleteGidelineProject)="openCloseModalDelete(false)" (updateFiles)="updateFiles($event)" [parameters]="objectModal" [type]="6"></app-delete-component>
  </div>
  