import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { apiOfflineAsync } from 'src/app/layouts/pages/pages.component';
import { UsersService } from 'src/app/services/users/users.service';
import { getLanguageCookie } from 'src/assets/language';

@Component({
  selector: 'app-link-user',
  templateUrl: './link-user.component.html',
  styleUrls: ['./link-user.component.scss']
})
export class LinkUserComponent {  
  @Output() toggleModal: EventEmitter<string> = new EventEmitter<string>();
  @Input() parameters: any

  searchText: string = ''
  dataSource: any = {}
  filteredDataSource: any = {}
  isLoading: boolean = false
  displayedColumns = ['Check', 'Name', 'Email']

  task: any = {
    color: 'accent'
  };

  currentRowIndex: number = 0
  currentPage: number = 1
  itemsPerPage: number = 5
  arrayUsers: any[] = []

  isShowLinkProfile: boolean = false;
  isShowAlert: boolean = false;

  typeExit?: string = ''
  constructor(private usersService: UsersService){
    this.dataSource = new MatTableDataSource<any>([{name: 'Adonis', email: 'email'}])
    this.filteredDataSource = new MatTableDataSource<any>([{name: 'Adonis', email: 'email'}])
  }

  async ngOnInit(){
    await this.getUsers()
  }

  getLanguages(file: string) {
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  exitModal(slug?: string){
    this.typeExit = slug;
    this.isShowAlert = true
  }

  close(){
    if(this.typeExit == 'all'){
      this.toggleModal.emit('all')
      return 
    }
    this.toggleModal.emit()
  }

  toggleAlert(){
    this.isShowAlert = false
  }
  
  togleLinkProfile(event: any){
    this.isShowLinkProfile = false

    if(event == 'all'){
      this.toggleModal.emit('all')
      return 
    }
  }

  searchUser() {
    this.currentPage = 1
    let searchText = this.searchText.toLowerCase()
    this.filteredDataSource.data = this.dataSource.data.filter(
      (item: { name: any; email: any; }) =>
        (item.name && item.name.toLowerCase().includes(searchText))
        || (item.email && item.email.toLowerCase().includes(searchText))
    )
  }

  isData() {
    return this.filteredDataSource.data.length > 0
  }

  getItemsPerPage(){
    return this.itemsPerPage
  }

  getCurrentPages(): number{
    return this.currentPage
  }

  choseItemsPage(number: number){
    return this.itemsPerPage = number;
  }

  getTotalPages(): number {
    return Math.ceil(this.filteredDataSource.data.length / this.itemsPerPage);
  }

  onPageChange(newPage: number){
    return this.currentPage = newPage;
  }

  async checkValue(event: any) {
    let total = this.getTotalPages()
    if (this.currentPage > total) {
      this.currentPage = total;
    }
    return
  }

  getDisplayedRows(): any[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.filteredDataSource.data.slice(startIndex, endIndex);
  }

  async getUsers() {
    this.isLoading = true;
    try {
      let response: any
        response = await this.usersService.getUsersAllSystem(this.parameters.environment.uuid)

        if (response && response.data) {
          this.dataSource = new MatTableDataSource<any>(response.data)
          this.filteredDataSource = new MatTableDataSource<any>(response.data)
        }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
    this.isLoading = false;
  }

  setSelectUser(user: { uuid: string; name: string }): void {
    const index = this.arrayUsers.findIndex((u) => u.uuid === user.uuid);
    if (index > -1) {
      this.arrayUsers.splice(index, 1);
    } else {
      this.arrayUsers.push(user);
    }
  }
  

  next(){
    this.parameters.arrayUsers = this.arrayUsers
    this.isShowLinkProfile = true
  }

}
