import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { to64decode } from 'src/app/helpers/base64';
import { getLanguageCookie } from 'src/assets/language';
import { MatTableDataSource } from '@angular/material/table';
import { BusinessSolutionsService } from 'src/app/services/business-solutions/business-solutions.service';
import { v4 } from "uuid";
import { sortArrayOfObjects } from 'src/app/helpers/sortArrayOfObjects';
import { AttachmentsService } from 'src/app/services/attachments/attachments.service';
import { addAlert } from 'src/app/layouts/pages/pages.component';

export const arrayExtensions: string[] = ['pdf', 'ppt', 'pptx', 'pptm', 'doc', 'docx', 'odt', 'rtf', 'csv', 'txt', 'xml', 'json', 'xls', 'xlsx', 'jpeg', 'png', 'mpp', 'mpt']
@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent {
  @Input() parameters: any = {}
  @Input() dimensions: any = {}

  @ViewChild('fileInput') fileInput!: ElementRef;
  that: any = {}

  formData: FormData = new FormData();

  dataSource!: MatTableDataSource<any>;
  displayedColumns: string[] = ['extension','surname', 'name', 'tab', 'type' ,'action'];

  currentPage = 1;
  currentPageReq = 1;
  itemsPerPage = 10;

  selectedFlag: any = {
    lang: 'en',
    file: 'us.svg'
  };
  isLoading: boolean = false
  tabArray: any = []
  typeArray: any = []

  searchTab: string = ''
  searchType: string = ''

  constructor(
    private businessSolutionsService: BusinessSolutionsService,
    private attachmentsService: AttachmentsService
  ) {
    this.dataSource = new MatTableDataSource();
  }

  async ngOnInit(){
    if (this.parameters.item.attachments.length > 0) {
      this.dataSource = new MatTableDataSource<any>(this.parameters.item.attachments)
    }else{
      this.parameters.item.attachments = []
      this.dataSource = new MatTableDataSource<any>(this.parameters.item.attachments)
    }
    await this.getOptionsArray()
  }

  getNames(name: any){
    return name[to64decode(localStorage.getItem('language')).lang]
  }

  async getOptionsArray() {
    this.tabArray = this.parameters.optionsArray.tabArray
    this.typeArray = sortArrayOfObjects(this.parameters.optionsArray.attachmentsTypeEnum)
  }

  setThat() {
    this.that = this
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  getDisplayedRowsAttachment(): any[]{
    const temp = this.dataSource.data.filter((item: any) => !item.is_delete);
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;

    return [...temp].slice(startIndex, endIndex);
  }

  async handleFileInput(event: any) {
    const files = event.target.files;

    for (let i = 0; i < files.length; i++) {
      const file: File = files[i];

      let extension: any = file.name.split('.')
      extension = extension.pop()

      if(arrayExtensions.includes(extension.toLowerCase())){
        let row = {
          uuid: v4(),
          name: file.name,
          surname: file.name,
          file: file,
          is_create: true,
          is_delete: false,
          is_copied: false
        }
        await this.saveAttachment(row)
      }else{
        addAlert('orange', this.getLanguages('errors/invalidFormatInputAttachments'))
      }
    }
  }

  async saveAttachment (row: any, is_delete: boolean = false) {
    const index = this.dataSource.data.findIndex((item: any) => item.uuid == row.uuid)
    if (index >= 0) {
      if (is_delete) {
        row.is_delete = true
        if(row.file == undefined){
          this.parameters.attachments_deleted.push(row)
        }

      }
      this.dataSource.data[index] = row
      this.parameters.item.attachments = this.dataSource.data
      await this.businessSolutionsService.setAttachmentArray(row)
      return
    }
    this.dataSource.data.push(row)
    this.parameters.item.attachments = this.dataSource.data
    await this.businessSolutionsService.setAttachmentArray(row)
  }

  getClassIcon(name: string): string{
    let extension: any = name.split('.')
    extension = extension.pop()

    if (['png', 'jpg', 'jpeg', 'gif'].includes(extension)) return 'fa-regular fa-file-image'
    if (['mp4', 'avi'].includes(extension)) return 'fa-regular fa-file-video'
    if (['txt'].includes(extension)) return 'fa-regular fa-file-lines'
    if (['doc', 'docx'].includes(extension)) return 'fa-regular fa-file-word'
    if (['pdf'].includes(extension)) return 'fa-regular fa-file-pdf'
    if (['xls', 'xlsx'].includes(extension)) return 'fa-regular fa-file-excel'
    if (['pptx', 'ppt'].includes(extension)) return 'fa-regular fa-file-powerpoint'
    return 'fa-regular fa-file'
  }

  openFileInput() {
    this.fileInput.nativeElement.click();
  }

  async downloadAttachment(row: any) {
    this.isLoading = true

    if(!row?.file){
      try {
        this.attachmentsService.downloadAttachment(row.fullname).subscribe(response => {
          const blob = new Blob([response], { type: 'application/octet-stream' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = row.name;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
          this.isLoading = false
        }, error => {
          console.log(error)
          addAlert('orange', this.getLanguages('errors/attachmentsNotFound'))
          this.isLoading = false
        });
      } catch (error) {
        console.log(error)
        addAlert('orange', this.getLanguages('errors/attachmentsNotFound'))
        this.isLoading = false
      }
    }else{
      const url = window.URL.createObjectURL(row.file);
      const a = document.createElement('a');
      a.href = url;
      a.download = row.name || row.file.name;  // Define o nome do arquivo para download
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      this.isLoading = false;
    }
  }

  choseTab(attachment: any, tab: any){
    attachment.tab = tab.slug
  }

  choseType(attachment: any, type: any){
    attachment.typeAttachment = type.slug
  }
}
