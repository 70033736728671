import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IAuth } from '../../interfaces/IAuth';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { to64decode, to64encode } from "../../helpers/base64";
import { apiOfflineAsync } from "../../layouts/pages/pages.component";
import { asyncForEach } from "../../helpers/asyncForEach";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public httpClient: HttpClient

  private header: any = []
  private requestOptions: any = []

  public token: any = {};
  public me: any = {};
  public environment: any = {};
  public environmentsCount: any = 0;
  public corporation: any = {};
  public corporationsCount: any = 0;
  API_PATH: any = '';

  constructor(
    private HttpClient: HttpClient,
    private router: Router
  ) {
    this.httpClient = HttpClient;
    this.API_PATH = environment.apiUrl;

    this.header['Content-Type'] = 'application/json';
    this.requestOptions = { headers: new HttpHeaders(this.header) };
  }

  async setCookie({
    environment = null,
    corporation = null,
    me = null,
    modules = null,
    screens = null,
    profile = null,
    permissions = null,
    language = null,
    config = null,
    user_environment = null,
    user_corporation = null,
    users_environments = null,
    users_corporations = null,
  }: any = {}) {
    if (environment) {
      localStorage.removeItem('environment');
      localStorage.setItem('environment', to64encode(environment));
    }
    if (corporation) {
      localStorage.removeItem('corporation');
      localStorage.setItem('corporation', to64encode(corporation));
    }
    if (me) {
      localStorage.removeItem('me');
      localStorage.setItem('me', to64encode(me));
    }
    if (modules) {
      localStorage.removeItem('modules');
      localStorage.setItem('modules', to64encode(modules));
    }
    if (screens) {
      localStorage.removeItem('screens');
      localStorage.setItem('screens', to64encode(screens));
    }
    if (profile) {
      localStorage.removeItem('profile');
      localStorage.setItem('profile', to64encode(profile));
    }
    if (permissions) {
      localStorage.removeItem('permissions');
      localStorage.setItem('permissions', to64encode(permissions));
    }
    if (language) {
      localStorage.removeItem('language');
      localStorage.setItem('language', to64encode(language));
    }
    if (config) {
      localStorage.removeItem('config');
      localStorage.setItem('config', to64encode(config));
    }
    if (user_corporation) {
      localStorage.removeItem('user_corporation');
      localStorage.setItem('user_corporation', to64encode(user_corporation));
    }
    if (user_environment) {
      localStorage.removeItem('user_environment');
      localStorage.setItem('user_environment', to64encode(user_environment));
    }
    if (users_corporations) {
      localStorage.removeItem('users_corporations');
      localStorage.setItem('users_corporations', to64encode(users_corporations));
    }
    if (users_environments) {
      localStorage.removeItem('users_environments');
      localStorage.setItem('users_environments', to64encode(users_environments));
    }
  }

  setCookieLanguage() {
    if (!localStorage.getItem('language')) {
      localStorage.setItem('language', to64encode({ lang: 'en', file: 'us.svg' }));
    }
  }

  async redirectError(error: any) {
    if (error.status == 401) {
      return await this.navigate('/login');
    }
    return error.error;
  }

  async navigate(route: string) {
    return await this.router.navigate([route]);
  }

  async deleteCookie(values: any = []) {
    let array: any = document.cookie.split("; ")

    await asyncForEach(array, async (item: any) => {
      if (item == '') return;

      let split: any = item.split('=')

      if (values.includes(split[0])) {
        return
      }

      localStorage.removeItem(split[0])
    })
  }

  private getURLSearchParams({ environment, corporation }: any = {}) {
    let params = new URLSearchParams();

    if (!environment && localStorage.getItem('environment')) {
      environment = to64decode(localStorage.getItem('environment'))
    }

    if (!corporation && localStorage.getItem('corporation')) {
      corporation = to64decode(localStorage.getItem('corporation'))
    }

    if (environment && environment.slug) {
      params.set('environment', environment.slug);
    }

    if (corporation && corporation.slug) {
      params.set('corporation', corporation.slug);
    }

    return params.toString();
  }

  async setVersions(version_ui: any = null) {
    if (!version_ui) {
      return
    }
    return await this.httpClient.post<IAuth>(`${this.API_PATH}versions`, { version_ui }).toPromise()
  }

  async login({ email, password }: any = {}) {
    return await this.httpClient.post<IAuth>(`${this.API_PATH}auth/login`, { email, password }, this.requestOptions).toPromise();
  }

  async register(data: any) {
    return await this.httpClient.post<any>(
      `${this.API_PATH}users/register`,
      data,
      this.requestOptions
    ).toPromise();
  }

  async getMe() {
    return await this.httpClient.get<any>(`${this.API_PATH}me?${this.getURLSearchParams()}`).toPromise()
  }

  async authExpired() {
    try {
      return await this.httpClient.get<any>(`${this.API_PATH}auth/expired`).toPromise()
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  async sendResetPassword(email: any) {
    try {
      return this.httpClient.get<any>(`${this.API_PATH}auth/reset/password/${email}`).toPromise()
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  async setUserPassword(user_uuid: string, password: string) {
    return this.httpClient.put<any>(
      `${this.API_PATH}auth/reset/password/${user_uuid}`,
      { password },
      this.requestOptions
    ).toPromise()
  }
}
