
<div class="all-content" id="all-content-create-user">
    <div class="top">
      <div class="toggle"></div>

      <div class="save" (click)="isEdit = true" *ngIf="!isEdit && parameters.editable"> 
        {{ getLanguages('edit') }}
      </div>

      <div class="save cancel" (click)="cancel()" *ngIf="isEdit && parameters?.uuid"> 
        {{ getLanguages('cancel') }}
      </div>

      <div class="save" (click)="save()" *ngIf="isEdit"> 
        {{ getLanguages('save') }}
      </div>
      <i class="fas fa-xmark" (click)="exitModal()"></i>
    </div>
    <div class="row row-title">
      <div class="title">
        {{ parameters?.uuid ? (parameters.editable ? getLanguages('profiles/modal/edit') : getLanguages('profiles/modal/view')) : getLanguages('profiles/modal/create') }}    
      </div>
    </div>

    <div class="row" style="margin-top: 2vh;">
      <div class="item w-3">
        <span>{{ getLanguages('profiles/modal/profileName') }} <b> * </b> </span>
        <input type="text" [disabled]="!isEdit" [(ngModel)]="profile.name" >
      </div>
      <div class="item w-3">
        <span>{{ getLanguages('profiles/modal/profileType') }} <b> * </b> </span>
        <button class="button-menu" [disabled]="parameters?.uuid" [ngClass]="parameters?.uuid ? 'disabled' : ''" [matMenuTriggerFor]="profile_type">
          <span>
            {{ profile.type ? getLabel(profile.type) : getLanguages('select') }}
          </span>
          <i class="fa-solid fa-caret-down" *ngIf="isEdit"></i>
        </button>
  
        <mat-menu #profile_type="matMenu">
          <div class="menu">
            <div class="input" (click)="$event.stopPropagation()">
              <input type="text" [(ngModel)]="searchType" placeholder="{{ getLanguages('search') }}">
              <i class="fa-solid fa-magnifying-glass"></i>
            </div>
            <div class="scroll">
              <ng-container *ngFor="let item of arrayType">
                <ng-container *ngIf="getNames(item.name).toLowerCase().includes(searchType.toLowerCase())">
                  <div class="item-menu" (click)="choseItemSelect('type', item.slug)">
                    <span [ngClass]="item.slug == profile.type ? 'valid-span' : 'invalid-span'">
                      {{ getNames(item.name) }}
                    </span>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </mat-menu> 
      </div>
      <div class="item w-3">
        <span>{{ getLanguages('profiles/modal/status') }} <b> * </b> </span>
        <button class="button-menu" [disabled]="!isEdit" [ngClass]="!isEdit ? 'disabled' : ''" [matMenuTriggerFor]="profile_status">
          <span>
            {{ getLanguages('users/content/table/status/' + (profile.status ? 'true' : 'false')) }}
          </span>
          <i class="fa-solid fa-caret-down" *ngIf="isEdit"></i>
        </button>
  
        <mat-menu #profile_status="matMenu">
          <div class="menu">
            <div class="scroll">
              <ng-container *ngFor="let item of arrayStatus">
                <ng-container>
                  <div class="item-menu" (click)="choseItemSelect('status', item.slug)">
                    <span [ngClass]="(item.slug == 'active' && profile.status) || (item.slug == 'inactive' && !profile.status)  ? 'valid-span' : 'invalid-span'">
                      {{ getNames(item.name) }}
                    </span>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </mat-menu> 
      </div>
    </div>

    <div class="row">
      <div class="item w-1">
        <span> {{ getLanguages('businessSolution/modal/addCode/description') }} </span>
        <textarea [disabled]="!isEdit" [(ngModel)]="profile.description" ></textarea>
      </div>
    </div>
  
    <div class="table">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="Screen">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              class="checkbox"
              [color]="task.color"
              [ngModel]="isAllSelectedScreen()"
              (change)="toggleAllRowsScreen()"
              [disabled]="!isEdit">
            </mat-checkbox>
            <span (click)="toggleAllRowsScreen()" class="pointer">
              {{ getLanguages('profiles/screens') }} 
            </span>
          </th>
          <td mat-cell *matCellDef="let element; let i = index;">
            <mat-checkbox
              class="checkbox"
              [color]="task.color"
              [ngModel]="element.access"
              (change)="setAcessScreen(element);"
              [disabled]="!isEdit"
              >
            </mat-checkbox>
            <span class="title-corporation pointer" (click)="setAcessScreen(element);">
              {{ getLanguages('profiles/screens/' + element.screen_slug) }}
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="Permissions">
          <th mat-header-cell *matHeaderCellDef class="td-50">
            <span> {{ getLanguages('profiles/permissions') }} </span>
          </th>
          <td mat-cell *matCellDef="let element; let i = index;" class="td-50">
            <button class="select-nav nav-table" [matMenuTriggerFor]="permission" [disabled]="!element?.access || !isEdit" [ngClass]="!element?.access || !isEdit ? 'no-pointer disabled-select': ''">
              <span>
                  <ng-container>
                    {{ getQuantityPermissionCheck(element) != 0 ? getQuantityPermissionCheck(element) : getLanguages('select') }}
                  </ng-container>
              </span>
              <i class="fas fa-caret-down"></i>
            </button>
            <mat-menu #permission="matMenu">
              <div class="menu" (click)="$event.stopPropagation()">
                <div class="input" (click)="$event.stopPropagation()">
                  <input type="text" [(ngModel)]="searchPermission" placeholder="{{ getLanguages('search') }}">
                  <i class="fa-solid fa-magnifying-glass"></i>
                </div>
                <div class="scroll">
                  <div class="item-menu" *ngIf="!searchPermission">
                    <mat-checkbox
                      class="checkbox"
                      [color]="task.color"
                      [ngModel]="isAllSelectedPermission(element)"
                      (click)="$event.stopPropagation()"
                      (change)="toggleAllRowsPermission(element);"
                      >
                    </mat-checkbox>
                    <span (click)="toggleAllRowsPermission(element);">
                      {{ getLanguages('profiles/all') }}
                    </span>
                  </div>
                  <ng-container *ngFor="let permission of element.permissions">
                    <div class="item-menu" *ngIf="getLanguages('profiles/permissions/' + element.screen_slug + '/' + permission.slug).toLowerCase().includes(searchPermission.toLowerCase())">
                      <mat-checkbox
                        class="checkbox"
                        [color]="task.color"
                        [ngModel]="isPermissionChecked(element.screen_slug, permission.slug)"
                        (click)="$event.stopPropagation()"
                        (change)="setSelectionProfilePermission(element.screen_slug, permission);"
                        >
                      </mat-checkbox>
                      <span [ngClass]="{'valid-span' : getLanguages('profiles/permissions/' + element.screen_slug + '/' + permission.slug).toLowerCase().includes(searchPermission.toLowerCase) }" (click)="$event.stopPropagation();setSelectionProfilePermission(element.screen_slug, permission);">
                        {{ getLanguages('profiles/permissions/' + element.screen_slug + '/' + permission.slug) }}            
                      </span>
                    </div>
                  </ng-container>
                </div>
              </div>
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>  
    </div>
  </div>
  
  
