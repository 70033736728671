export const fixedLevels: any = [
  {
    name: 'Improvement',
    acronym: 'IM',
    level: -1
  },
  {
    name: 'Gap',
    acronym: 'GA',
    level: -2
  },
  {
    name: 'Backlog',
    acronym: 'BK',
    level: -3
  },
  {
    name: 'Bug',
    acronym: 'BU',
    level: -4
  },
  {
    name: 'ToBe',
    acronym: 'TB',
    level: -5
  }
];