import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { asyncForEach } from 'src/app/helpers/asyncForEach';
import { to64decode, to64encode } from 'src/app/helpers/base64';
import { addAlert, apiOfflineAsync } from 'src/app/layouts/pages/pages.component';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { permissionJson } from 'src/assets/const/permissionJson';
import { getLanguageCookie } from 'src/assets/language';

export interface Profile {
  uuid: string,
  name: string,
  type: string,
  status: any,
  description: string,
  permissions: any[]
}

@Component({
  selector: 'app-form-profiles',
  templateUrl: './form-profiles.component.html',
  styleUrls: ['./form-profiles.component.scss']
})

export class FormProfilesComponent {
  @Input() parameters: any
  @Output() toggleModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() getProfiles: EventEmitter<void> = new EventEmitter<void>();

  displayedColumns: any = ['Screen', 'Permissions']
  dataSource: any;

  task: any = {
    color: 'seccondary',
  };

  permission_json: any = permissionJson

  isEdit: boolean = true // quando for o modal de editar, deve vir como false
  profile: Profile = {
    uuid: '',
    name: '',
    type: '',
    status: true,
    description: '',
    permissions: []
  };

  searchType: string = ''
  searchPermission: string = ''
  temp: any
  arrayType: any = [
    { slug: 'organization', name: { en: 'Organization', pt: 'Organização' } },
    { slug: 'environment', name: { en: 'Environment', pt: 'Ambiente' } },
  ]

  arrayStatus: any = [
    { slug: 'active', name: { en: 'Active', pt: 'Ativo' } },
    { slug: 'inactive', name: { en: 'Inactive', pt: 'Inativo' } },
  ]

  constructor(private profileService: ProfileService){
    this.dataSource = new MatTableDataSource<any>();
  }

  async ngOnInit(){
    if(Object.keys(this.parameters).length > 0){
      this.profile = JSON.parse(JSON.stringify(this.parameters))
      this.profile.permissions = JSON.parse(this.parameters.permissions)
      this.isEdit = false

      this.temp = JSON.parse(JSON.stringify(this.profile))
    }

    await this.createTable()
  }

  findAccess(screen: any): boolean {
    let flag = false;
  
    this.profile.permissions.forEach((item: any) => {
      const itemSlug = item.screen?.trim().toLowerCase();
      const screenSlug = screen.slug?.trim().toLowerCase();
    
      if (itemSlug === screenSlug) {
        flag = true;
      }
    });
  
    return flag;
  }
  
  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  getNames(name: any){
    return name[to64decode(localStorage.getItem('language')).lang]
  }

  getLabel(slug: string): string{
    let text: any

    this.arrayType.forEach((item: any) => {
      if(slug == item.slug){
        text = item.name
      }
    });

    const language = to64decode(localStorage.getItem('language')).lang || 'en';
    return text ? text[language] : '';  
  }

  exitModal(hide: boolean = true): void {
    if (!hide) {
      return
    }

    // this.getUsers.emit()
    this.toggleModal.emit();
  }

  choseItemSelect(type: string, value: any){
    switch (type) {
      case 'type':
        this.profile.type = value;
        break;
      case 'status':
        this.profile.status = value == 'active' ? true : false;
        break;
    }
  }

  async createTable(){
    let array: any[] = []

    this.permission_json?.forEach((module: any) => {
      module?.screens?.forEach((screen: any) => {
        array.push({
          module_slug: module.module,
          screen_slug: screen.slug,
          access: this.parameters ? this.findAccess(screen) : screen.access,
          permissions: screen.permissions,
        })
      });
    });

    this.dataSource = new MatTableDataSource<any>(array)
  }

  verifyProfile(): boolean{
    if(!this.profile.name || !this.profile.type){
      addAlert('orange', this.getLanguages('mandatoryField'))
      return false
    }


    if(this.profile.permissions.length == 0){
      addAlert('orange', this.getLanguages('fillOnePermission'))
      return false
    }

    return true
  }

  async save() {
    if(!this.verifyProfile()){
      return
    }

    this.profile.status = this.profile.status ? 'active' : 'inactive' 

    try {
      const response: any = await this.profileService.setProfile(this.profile, this.parameters?.uuid ? false : true)
      if (response && [200, 201, 202].includes(response.status)) {
        addAlert('success', response.message);
        this.getProfiles.emit();
        this.toggleModal.emit()
      } else {
        addAlert('danger', response.message)
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  setSelectionProfilePermission(element: any, permission: any, status?: boolean) {
    let screenObject = this.profile.permissions.find(
      (screen: any) => screen.screen === element
    );
  
    if (!screenObject) {
      console.error(`Screen object for ${element} not found!`);
      return;
    }
  
    // Atualizar imediatamente o estado de 'access' com base no parâmetro 'status'
    let access = screenObject.permissions.some((p: string) => p === permission.slug);
    if (status !== undefined) {
      permission.access = status; // Atualiza imediatamente o acesso local
    } else {
      permission.access = !access; // Inverte o estado de 'access' se 'status' não for fornecido
    }
  
    // Agora, com base no novo valor de 'permission.access', adicionar ou remover a permissão
    if (permission.access) {
      // Adicionar permissão se não estiver presente
      if (!access) {
        screenObject.permissions.push(permission.slug);
      }
    } else {
      // Remover permissão se estiver presente
      if (access) {
        screenObject.permissions = screenObject.permissions.filter(
          (p: string) => p !== permission.slug
        );
      }
    }
  }
  
  isAllSelectedPermission(element: any): boolean {
    return Array.isArray(element.permissions) && element.permissions.every((permission: { slug: string }) => 
      this.isPermissionChecked(element.screen_slug, permission.slug)
    );
  }

  getQuantityPermissionCheck(element: any){
    if (!Array.isArray(element.permissions)) {
      return 0;
    }
  
    const checkedPermissions = element.permissions.filter((permission: { slug: string }) =>
      this.isPermissionChecked(element.screen_slug, permission.slug)
    );
  
    return checkedPermissions.length;
  }
  
  async toggleAllRowsPermission(element: any) {
    let status: boolean = !this.isAllSelectedPermission(element);
    
    for (let item of element.permissions) {
      await this.setSelectionProfilePermission(element.screen_slug, item, status);
    }
  } 

  isPermissionChecked(screenSlug: string, permissionSlug: string): boolean {
    const screen = this.profile.permissions.find(p => p.screen === screenSlug);
    return screen?.permissions.includes(permissionSlug) || false;
  }

  async setAcessScreen(element: any, status?: boolean) {  
    if (status !== undefined) {
      element.access = status;
    } else {
      element.access = !element.access;
    }
  
    if (element.access) {
      if (!this.profile.permissions.some(p => p.screen === element.screen_slug)) {
        this.profile.permissions.push({ screen: element.screen_slug, permissions: [] });
      }
    } else {
      this.profile.permissions = this.profile.permissions.filter(p => p.screen !== element.screen_slug);
    }
  }

  isAllSelectedScreen() {
    let numSelected: number = 0;
    this.dataSource.data.map((item: { access: any; }) => { numSelected = numSelected + (item.access ? 1 : 0) });
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  async toggleAllRowsScreen() {
      let status: any = !this.isAllSelectedScreen()
      await asyncForEach(this.dataSource.data, async (item: any, index: number) => {
        await this.setAcessScreen(item, status)
      });
  }

  cancel(){
    this.isEdit = false;
    this.profile = JSON.parse(JSON.stringify(this.temp));

    this.createTable()
  }
  
}
