import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IResponse } from '../../interfaces/IResponse';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentsService {
  API_PATH: string
  header: any = []
  requestOptions: any = []
  requestOptionsFormData: any = new HttpHeaders({ 'Content-Type': 'multipart/form-data' })

  constructor(
    private httpClient: HttpClient,
    private router: Router,
  ) {
    this.API_PATH = environment.apiUrl;

    this.header['Content-Type'] = 'application/json';
    this.requestOptions = { headers: new HttpHeaders(this.header) };
  }

  async navigate(route: string) {
    return await this.router.navigate([route]);
  }

  async redirectError(error: any) {
    if (error.status == 401) {
      return await this.navigate('/login');
    }
    return error.error;
  }

  async getEnvironments() {
    return await this.httpClient.get<IResponse>(`${this.API_PATH}environments`).toPromise();
  }

  async setEnvironment(body: any, isNew: boolean = true, id: number = 0) {
    try {
      if (isNew) {
        return await this.httpClient.post<IResponse>(
          `${this.API_PATH}environments`,
          body,
          this.requestOptionsFormData
        ).toPromise();
      }
      return await this.httpClient.put<IResponse>(
        `${this.API_PATH}environments/${id}`,
        body,
        this.requestOptionsFormData
      ).toPromise();
    } catch (error: any) {
      return await this.redirectError(error);
    }
  }

  async deleteEnvironment (id: number) {
    try {
      return await this.httpClient.delete<IResponse>(`${this.API_PATH}environments/${id}`).toPromise()
    } catch (error: any) {
      return await this.redirectError(error);
    }
  }

}
