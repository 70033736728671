import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { getLanguageCookie } from 'src/assets/language';
import { UserEnvironmentsService } from "../../../../services/userEnvironments/user-environments.service";
import { EnvironmentsService } from "../../../../services/environments/environments.service";
import { asyncForEach } from "../../../../helpers/asyncForEach";
import { apiOfflineAsync } from "../../../../layouts/pages/pages.component";
import { sortArrayOfObjects } from 'src/app/helpers/sortArrayOfObjects';
import { systemPermissionsNew } from 'src/app/helpers/systemPermissions';


@Component({
  selector: 'app-user-instances',
  templateUrl: './user-instances.component.html',
  styleUrls: ['./user-instances.component.scss']
})
export class UserInstancesComponent {
  @Input() properties: any = {}
  @Input() parameters: any = {}

  @Output() toggleInstanceUserModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() getInstances: EventEmitter<void> = new EventEmitter<void>();

  private header: any = []

  instances: any = [{}]
  searchInstance: string = ""
  filteredInstanceArray: any = [{}];

  selectedInstancesNames: any = []
  selectedInstances: any = []

  searchText: any;

  task: any = {
    color: 'accent'
  };

  data: any = {
    //valor daquela instancia passada por parametro
  }

  dataUser: any = {}

  userAll: any = {
    currentRowIndex: 0,
    currentPage: 1,
    itemsPerPage: 5,
    searchText: '',
    isLoading: false,
    displayedColumns: ['Name', 'Telephone', 'Email'],
    dataSource: {},
    filteredDataSource: {},
  }

  userInstance: any = {
    currentRowIndex: 0,
    currentPage: 1,
    itemsPerPage: 5,
    searchText: '',
    isLoading: false,
    displayedColumns: ['Name', 'Email'],
    dataSource: {},
    filteredDataSource: {},
  }

  isShowLinkUser = false
  isShowCreateUser = false
  isShowUser = false;
  isLoading = false;

  constructor(
    private environmentsService: EnvironmentsService,
    private userEnvironmentsService: UserEnvironmentsService,
  ) {
    this.header['Content-Type'] = 'application/json';
  }

  async ngOnInit() {
    this.userAll.dataSource = new MatTableDataSource<any>([])
    this.userAll.filteredDataSource = new MatTableDataSource<any>([])
    this.userInstance.dataSource = new MatTableDataSource<any>([])
    this.userInstance.filteredDataSource = new MatTableDataSource<any>([])

    await this.getInstance()
    await this.getUsers();

    await this.selectedInstancesNames.push(this.parameters.environment.name)
    await this.selectedInstances.push(this.parameters.environment.uuid.toString())
  }

  getLanguages(file: string) {
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  exitModal(hide: boolean = true): void {
    if (!hide) {
      return
    }

    this.getInstances.emit()
    this.toggleInstanceUserModal.emit();
  }

  toggleInstanceViewUserModal(event?: void) {
    this.isShowUser = !this.isShowUser;
  }

  initModalViewUser(user: any) {
    this.dataUser = user,
      this.isShowUser = true;
  }

  async getInstance() {
    try {
      const response: any = await this.environmentsService.getEnvironments()
      if (response.status == 200 && response && response.data) {
        this.instances = response.data
        this.filteredInstanceArray = sortArrayOfObjects(this.instances)
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  async getUsers() {
    this.isLoading = true;
    try {
      let response: any
        response = await this.userEnvironmentsService.getUserEnvironments(
          this.properties.me.uuid,
          [this.parameters.environment.uuid.toString()]
        )

        if (response && response.data) {
          this.userInstance.dataSource = new MatTableDataSource<any>(response.data)
          this.userInstance.filteredDataSource = new MatTableDataSource<any>(response.data)
        }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
    this.isLoading = false;
  }

  customFilterPredicate(): (data: any, filter: string) => boolean {
    const filterFunction = function (data: any, filter: string): boolean {
      const searchTermLower = filter.toLowerCase();
      return (
        data.name.toLowerCase().includes(searchTermLower) ||
        data.telephone.toLowerCase().includes(searchTermLower) ||
        data.email.toLowerCase().includes(searchTermLower)
      );
    };
    return filterFunction;
  }

  
  onPageChange(newPage: number): void {
    this.userInstance.currentPage = newPage;
  }

  getDisplayedRows(): any[] {
    const startIndex = (this.userInstance.currentPage - 1) * this.userInstance.itemsPerPage;
    const endIndex = startIndex + this.userInstance.itemsPerPage;
    return this.userInstance.filteredDataSource.data.slice(startIndex, endIndex);
  }

  getTotalPages(): number {
    return Math.ceil(this.userInstance.filteredDataSource.data.length / this.userInstance.itemsPerPage);
  }

  async checkValue(event: any) {
    let total = await this.getTotalPages()
    if (this.userInstance.currentPage > total) {
      this.userInstance.currentPage = total;
    }
  }

  filterInstance() {
    this.filteredInstanceArray = this.instances.filter((intance: { name: string }) =>
      intance.name.toLowerCase().includes(this.searchInstance.toLowerCase())
    );
  }

  async toggleInstanceArray(instance: any, event?: Event) {
    if (instance.slug == this.parameters.environment.slug) {
      return;
    }
    if (event) {
      event.stopPropagation()
    }

    const clickedInstance = this.instances.find((inst: any) => inst === instance);

    if (clickedInstance) {
      clickedInstance.click = !clickedInstance.click;

      if (clickedInstance.click) {
        this.selectedInstancesNames.push(clickedInstance.name);
        this.selectedInstances.push(clickedInstance.uuid);
      } else {
        const index = this.selectedInstancesNames.indexOf(clickedInstance.name);
        if (index !== -1) {
          this.selectedInstancesNames.splice(index, 1);
          this.selectedInstances.splice(index, 1);
        }
      }
    }

    await this.getUsers()
  }

  choseAccess(user: any) {
    user.access = !user.access
  }

  async modalClose() {
    await Promise.all([this.exitModal()]);
  }

  async validUsersEnvironmentsAccess() {
    let array = this.userInstance.dataSource.data;
    let users: any = []
    await asyncForEach(array, async (item: any) => {
      users.push({ user_uuid: item.uuid, status: item.access })
    })

    return users;
  }

  searchUser() {
    let searchText = this.searchText.toLowerCase()
    this.userInstance.filteredDataSource.data = this.userInstance.dataSource.data.filter(
      (item: { name: any; telephone: any; email: any; }) =>
        (item.name && item.name.toLowerCase().includes(searchText))
        || (item.email && item.email.toLowerCase().includes(searchText))
    )
  }

  getItemsPerPage() {
    return this.userInstance.itemsPerPage
  }

  getCurrentPages(): number {
    return this.userInstance.currentPage
  }

  choseItemsPage(number: number, text?: string) {
    this.userInstance.itemsPerPage = number;
    this.getDisplayedRows()
  }

  isData() {
    return this.userInstance.filteredDataSource.data.length > 0
  }

  togleCreateUser() {
    this.isShowCreateUser = !this.isShowCreateUser
  }

  togleLinkUser(event?: any){
    if(event == 'all'){
      this.toggleInstanceUserModal.emit();
    }
    this.isShowLinkUser = !this.isShowLinkUser
  }

  getSystemPermission(screen: string, action: string){
    return systemPermissionsNew(screen, action)
  }
}
