// export const extractTasksFromBpmnXml = (xml: string): { uuid: string, label: string }[] => {
//     const parser = new DOMParser();
//     const xmlDoc = parser.parseFromString(xml, 'application/xml');
  
//     // Seleciona todas as tasks dentro do XML
//     const tasks = xmlDoc.getElementsByTagName('task');
//     const taskArray: { uuid: string, label: string }[] = [];
  
//     // Itera sobre as tasks e monta o array de {uuid, label}
//     for (let i = 0; i < tasks.length; i++) {
//       const task = tasks[i];
//       const id = task.getAttribute('id');
//       let uuid;
      
//       // Verifica se o ID começa com "Task_" ou "Activity_"
//       if (id?.startsWith('Task_')) {
//         uuid = id.replace('Task_', ''); // Remove o prefixo "Task_"
//       } else if (id?.startsWith('Activity_')) {
//         uuid = id.replace('Activity_', ''); // Remove o prefixo "Activity_"
//       }
      
//       const label = task.getAttribute('name') || '';
    
//       if (uuid) {
//         taskArray.push({ uuid, label });
//       }
//     }
    
  
//     return taskArray;
//   };
  

// export const extractTasksFromBpmnXml = (xml: string): { uuid: string, label: string }[] => {
//   const parser = new DOMParser();
//   const xmlDoc = parser.parseFromString(xml, 'application/xml');

//   // Mapeia as tasks e atividades por ID e armazena a lista de fluxo de sequência
//   const tasksMap: { [key: string]: { uuid: string, label: string } } = {};
//   const sequenceFlowMap: { [key: string]: string } = {};
//   const taskArray: { uuid: string, label: string }[] = [];

//   // Extrair tasks e atividades e armazená-las com o ID
//   const tasks = xmlDoc.getElementsByTagName('task');
//   for (let i = 0; i < tasks.length; i++) {
//     const task = tasks[i];
//     const id = task.getAttribute('id');
//     let uuid;

//     if (id) {
//       if (id.startsWith('Task_')) {
//         uuid = id.replace('Task_', '');
//       } else if (id.startsWith('Activity_')) {
//         uuid = id.replace('Activity_', '');
//       }

//       const label = task.getAttribute('name') || '';
//       if (uuid) {
//         tasksMap[id] = { uuid, label };
//       }
//     }
//   }

//   // Mapeia as conexões de sequência para manter a ordem do fluxo
//   const sequenceFlows = xmlDoc.getElementsByTagName('sequenceFlow');
//   for (let i = 0; i < sequenceFlows.length; i++) {
//     const flow = sequenceFlows[i];
//     const sourceRef = flow.getAttribute('sourceRef');
//     const targetRef = flow.getAttribute('targetRef');
//     if (sourceRef && targetRef) {
//       sequenceFlowMap[sourceRef] = targetRef;
//     }
//   }

//   // Busca o evento inicial como ponto de partida do fluxo
//   let currentId = "StartEvent_1" in sequenceFlowMap ? sequenceFlowMap["StartEvent_1"] : null;

//   // Caso não exista um evento de início, tenta encontrar um task que não seja target de outro elemento
//   if (!currentId) {
//     currentId = Object.keys(tasksMap).find(id => !Object.values(sequenceFlowMap).includes(id)) || null;
//   }
  
//   // Define a ordem do fluxo e coleta os tasks na sequência correta
//   const visitedTasks = new Set<string>();
//   while (currentId) {
//     if (tasksMap[currentId]) {
//       taskArray.push(tasksMap[currentId]);
//       visitedTasks.add(currentId);
//     }
//     currentId = sequenceFlowMap[currentId];
//   }

//   // Adiciona as tasks desconectadas
//   for (let id in tasksMap) {
//     if (!visitedTasks.has(id)) {
//       taskArray.push(tasksMap[id]);
//     }
//   }

//   return taskArray;
// };

export const extractTasksFromBpmnXml = (
  xml: string
): { uuid: string; label: string; prev: string[]; next: string[] }[] => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xml, "application/xml");

  const tasksMap: { [key: string]: { uuid: string; label: string; prev: string[]; next: string[] } } = {};
  const sequenceFlowMap: { [key: string]: string[] } = {};
  const gatewayTypes = ["exclusiveGateway", "parallelGateway", "inclusiveGateway"];

  const tasks = xmlDoc.getElementsByTagName("task");
  for (let i = 0; i < tasks.length; i++) {
    const task = tasks[i];
    const id = task.getAttribute("id");
    let uuid;

    if (id) {
      if (id.startsWith("Task_")) {
        uuid = id.replace("Task_", "");
      } else if (id.startsWith("Activity_")) {
        uuid = id.replace("Activity_", "");
      }

      const label = task.getAttribute("name") || "";
      if (uuid) {
        tasksMap[id] = { uuid, label, prev: [], next: [] };
      }
    }
  }

  const gateways = new Set<string>();
  gatewayTypes.forEach((type) => {
    const gatewayElements = xmlDoc.getElementsByTagName(type);
    for (let i = 0; i < gatewayElements.length; i++) {
      const gateway = gatewayElements[i];
      const id = gateway.getAttribute("id");
      if (id) gateways.add(id);
    }
  });

  const sequenceFlows = xmlDoc.getElementsByTagName("sequenceFlow");
  for (let i = 0; i < sequenceFlows.length; i++) {
    const flow = sequenceFlows[i];
    const sourceRef = flow.getAttribute("sourceRef");
    const targetRef = flow.getAttribute("targetRef");

    if (sourceRef && targetRef) {
      if (!sequenceFlowMap[sourceRef]) {
        sequenceFlowMap[sourceRef] = [];
      }
      sequenceFlowMap[sourceRef].push(targetRef);

      if (tasksMap[sourceRef] && tasksMap[targetRef]) {
        tasksMap[sourceRef].next.push(targetRef);
        tasksMap[targetRef].prev.push(sourceRef);
      } else if (tasksMap[targetRef] && gateways.has(sourceRef)) {
        const gatewaySource = Object.entries(sequenceFlowMap).find(
          ([, targets]) => targets.includes(sourceRef)
        )?.[0];

        if (gatewaySource && tasksMap[gatewaySource]) {
          tasksMap[targetRef].prev.push(gatewaySource);
        }
      }
    }
  }

  let currentId = "StartEvent_1" in sequenceFlowMap ? sequenceFlowMap["StartEvent_1"][0] : null;
  if (!currentId) {
    currentId = Object.keys(tasksMap).find(id => !Object.values(sequenceFlowMap).flat().includes(id)) || null;
  }

  const taskArray: { uuid: string; label: string; prev: string[]; next: string[] }[] = [];
  const visitedTasks = new Set<string>();

  const stack = currentId ? [currentId] : [];
  while (stack.length > 0) {
    const currentTaskId = stack.pop();

    if (currentTaskId && tasksMap[currentTaskId] && !visitedTasks.has(currentTaskId)) {
      taskArray.push(tasksMap[currentTaskId]);
      visitedTasks.add(currentTaskId);

      const nextTasks = sequenceFlowMap[currentTaskId] || [];
      const gatewayTasks: string[] = [];

      for (const nextTaskId of nextTasks) {
        if (gateways.has(nextTaskId)) {
          sequenceFlowMap[nextTaskId]?.forEach((parallelTask) => {
            if (!visitedTasks.has(parallelTask)) {
              stack.push(parallelTask);
              gatewayTasks.push(parallelTask);
            }
          });
        } else if (!visitedTasks.has(nextTaskId)) {
          stack.push(nextTaskId);
        }
      }

      // Adiciona as tasks de cada caminho paralelo antes de outras tasks no fluxo
      stack.push(...gatewayTasks);
    }
  }

  for (let id in tasksMap) {
    if (!visitedTasks.has(id)) {
      taskArray.push(tasksMap[id]);
    }
  }

  return taskArray;
};






