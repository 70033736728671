import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { getLanguage, getLanguageCookie, getLanguageParam } from 'src/assets/language';
import { localStorageToJsonAsync } from "../../helpers/cookieToJson";
import { systemPermissionsNew } from "../../helpers/systemPermissions";
import { FunctionService } from 'src/app/services/function.service';
import { version_ui } from 'src/app/environments/tag_version';
import { AuthService } from 'src/app/services/auth/auth.service';
import { menuJson } from 'src/assets/const/menuJson';
@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent {
  properties: any = {}
  @Output() toggleSidebarEvent = new EventEmitter<void>();
  @Output() navigationSideBar = new EventEmitter<void>();
  @Input() parameters: any = {}

  public menuJson: any = menuJson

  previousValue!: string;

  versions: any = {}

  isOpen = false;
  isClick = false;

  item: any = {
    foundation: {
      isShow: false,
      child: []
    },
    adm: {
      isShow: false,
      child: []
    }
  }

  child = [
    {
      name: 'corporation',
      isShow: false
    },
    {
      name: 'instances',
      isShow: false
    }
  ]

  constructor(private router: Router,
    private cookieService: CookieService,
    private authService: AuthService,
    public functionService: FunctionService
  ) {
    this.versions.version_ui = version_ui
  }

  async ngOnInit() {
    this.properties = await localStorageToJsonAsync()
    this.getCurrentUrl()
    await this.setVersions()
  }

  async setVersions() {
    try {
      const response: any = await this.authService.setVersions(this.versions.version_ui)
      if (response.data) {
        this.versions = response.data
      }
    } catch (error) {
      this.versions = {}
    }
  }

  getCurrentUrl() {
    return this.router.url;
  }

  getLanguageParam(name: any) {
    if (name.en == 'Standards') {
      name = {
        en: 'Guideline',
        es: 'Guideline',
        pt: 'Guideline'
      }
    }
    return getLanguageParam(name, localStorage.getItem('language'))
  }

  getLanguages(file: string) {
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  getClassItem(item: string) {
    return `fas icon ${item} `
  }

  expanded(item: any, haveChild: boolean = false) {
    if (!haveChild) {
      this.navigate(item.url)
      return
    }
    item.isOpen = !item.isOpen
  }


  async navigate(route: string) {
    if (this.router.url == '/business_solutions') {
      if (this.functionService.isChangeTree == true) {
        this.functionService.isShowModalChangeTree = true
        this.functionService.route = route
        return
      }
    }

    if (route == '/home') {
      route = '/'
    }
    this.toggle(true)
    await this.router.navigate([route]);
    this.navigationSideBar.emit();
  }

  async flipChild(index: string) {
    this.item[index].isShow = !this.item[index].isShow
  }

  protected readonly JSON = JSON;
  protected readonly getLanguage = getLanguage;

  getSystemPermission(screen: string) {
    return systemPermissionsNew(screen)
  }

  toggle(click: boolean = true, flag?: boolean) {
    if (click) {
      this.isOpen = !this.isOpen
      this.isClick = !this.isOpen;
      return
    }
    if (flag != undefined) {
      this.isOpen = flag
      this.isClick = false
    }
  }

  onMouseOver() {
    this.isOpen = true
    // this.toggle(false, true)
  }

  onMouseOut() {
    this.isOpen = false

    // this.toggle(false, false)
  }

  hasPermissionForModule(moduleSlug: string): boolean {
    if(moduleSlug == 'home'){
      return true
    } 
    const screens = this.menuJson['screens'][moduleSlug];
    return screens.some((screen: { [x: string]: string; }) => this.getSystemPermission(screen['slug']));
  }

}


