import { Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChild, Renderer2, HostListener, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { getLanguageCookie } from 'src/assets/language';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import slugify from "slugify";
import { asyncForEach } from "../../../../helpers/asyncForEach";
import { sortArrayOfObjects } from 'src/app/helpers/sortArrayOfObjects';

declare var PrimeFaces: any;

@Component({
  selector: 'app-strategy',
  templateUrl: './strategy.component.html',
  styleUrls: ['./strategy.component.scss']
})
export class StrategyComponent {
  @Input() parameters: any = {}
  @Input() dimensions: any = {}
  @Output() parametersParent = new EventEmitter<string>();
  @ViewChildren('inputCell') inputCells!: QueryList<ElementRef>;
  @ViewChildren('textboxElement') textboxElements!: QueryList<ElementRef>;

  isResizing = false;
  resizingTextboxIndex: number | null = null;

  currentRowIndex!: number;
  textareaHeights: {[key: string]: string} = {};
  keys = ['Backspace', 'Delete']

  corporationStrategys: any = []
  filteredCorporationStrategys: any = []

  inputsKeys: any = ['corporation_dimension']

  strategyRows: any = new MatTableDataSource<any>([
    { corporation_dimension: {}, scope: '', description: '' }
  ])

  selectionStrategy = new SelectionModel<any>(true, []);

  task: any = {
    color:  'accent'
  };

  selectedFlag: any = {
    lang: 'en',
    file: 'us.svg'
  };

  search: string = '';
  currentPage = 1;
  currentPageReq = 1;
  itemsPerPage = 5;
  itemsTotal = 0

  constructor(){}

  async ngOnInit(){
    if (this.parameters.item.rows.strategy?.length >= 0) {
      this.strategyRows = new MatTableDataSource<any>(this.parameters.item.rows.strategy);
    }

    this.corporationStrategys = this.dimensions.strategy
    this.filteredCorporationStrategys = sortArrayOfObjects(this.dimensions.strategy)
  }

  choseItemsPage(number: number){
    this.itemsPerPage = number;
    this.getDisplayedRows()
  }

  onPageChange(newPage: number): void {
    this.currentPage = newPage;
  }

  getDisplayedRows(): any[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.strategyRows.data.slice(startIndex, endIndex);
  }

  getTotalPages(): number {
    return Math.ceil(this.strategyRows.data.length / this.itemsPerPage);
  }

  adjustTextareaHeight(event: Event, rowId: string, columnId: string): void {
    const textarea = event.target as HTMLTextAreaElement;
    const rowElement = textarea.closest('tr'); // Encontrar o elemento da linha atual

    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;

    if (parseInt(textarea.style.height) > parseInt(getComputedStyle(textarea).maxHeight)) {
      textarea.style.overflowY = 'scroll';
    } else {
      textarea.style.overflowY = 'hidden';
    }

    // Ajustar a altura de todos os textareas na mesma linha
    if(rowElement){

      const textareasInRow = rowElement.querySelectorAll('textarea');
      textareasInRow.forEach((area: HTMLTextAreaElement) => {
        area.style.height = textarea.style.height;

      });
    }else{
      return
    }
  }

  startResizing(index: number) {
    this.isResizing = true;
    this.resizingTextboxIndex = index;

    document.addEventListener('mousemove', this.resizeTextboxes);
    document.addEventListener('mouseup', this.stopResizing);
  }

  resizeTextboxes = (e: MouseEvent) => {
    if (!this.isResizing || this.resizingTextboxIndex === null) return;

    const textboxElement = this.textboxElements.toArray()[this.resizingTextboxIndex].nativeElement;
    const rowIndex = Math.floor(this.resizingTextboxIndex / this.parameters.item.columns.strategy.columns.slice(1).length);

    const newHeight = e.clientY - textboxElement.getBoundingClientRect().top;

    const textboxesInRow =
      this.textboxElements.filter(
        (el, idx) => Math.floor(idx / this.parameters.item.columns.strategy.columns.slice(1).length) === rowIndex
      );

    textboxesInRow.forEach((el) => {
      el.nativeElement.style.height = `${newHeight}px`;
    });
  };

  stopResizing = () => {
    this.isResizing = false;
    this.resizingTextboxIndex = null;

    document.removeEventListener('mousemove', this.resizeTextboxes);
    document.removeEventListener('mouseup', this.stopResizing);
  };

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  async addColumn() {
    if (this.parameters.item.columns.strategy.columns.length === 10) {
      return;
    }

    let newColumnIndex = 1;
    while (this.parameters.item.columns.strategy.columns.includes(`Column${newColumnIndex}`)) {
      newColumnIndex++;
    }

    const newColumnName = `Column${newColumnIndex}`;
    const newColumnSlug = slugify(newColumnName, { lower: true, trim: true });

    // Adicionar a nova coluna à matriz de colunas
    this.parameters.item.columns.strategy.columns.push(newColumnName);

    // Adicionar as informações da nova coluna ao objeto columnsName
    this.parameters.item.columns.strategy.columnsName[newColumnName] = {
      field: newColumnName.toLowerCase(),
      slug: newColumnSlug
    };

    // Inicializar os valores da nova coluna nas linhas existentes
    this.strategyRows.data.forEach((row: any) => {
      row[newColumnSlug] = '';
    });

    // Emitir as alterações
  }


  async addRow() {
    this.strategyRows.data.push({});

    this.strategyRows._updateChangeSubscription();

    await this.updateSlugs()
    this.currentPage = this.getTotalPages();
  }

  // checkboxLabelStrategy(row?: any): string {
  //   if (!row) {
  //     return `${this.isAllSelectedStrategy() ? 'deselect' : 'select'} all`;
  //   }
  //   return `${this.selectionStrategy.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  // }

  isAllSelectedStrategy() {
    const numSelected = this.selectionStrategy.selected.length;
    const numRows = this.strategyRows.data.length;
    return numSelected === numRows;
  }

  toggleAllRowsStrategy() {
    if (this.isAllSelectedStrategy()) {
      this.selectionStrategy.clear();
      return;
    }
    this.selectionStrategy.select(...this.strategyRows.data);
  }

  async handleCellKeyDown(event: KeyboardEvent, rowIndex: number, columnIndex: number, columnName: string) {
  //   if (columnIndex < 0) {
  //     this.currentRowIndex = rowIndex;
  //     return;
  //   }

  //   if (event.key === 'ArrowUp' && rowIndex > 0) {
  //     event.preventDefault();
  //     this.focusCell(rowIndex - 1, columnIndex);
  //   } else if (event.key === 'ArrowDown' && rowIndex < this.strategyRows.data.length - 1) {
  //     event.preventDefault();
  //     this.focusCell(rowIndex + 1, columnIndex);
  //   } else if (event.key === 'ArrowLeft' && columnIndex >= 1) {
  //     event.preventDefault();
  //     this.focusCell(rowIndex, columnIndex - 1);
  //   } else if (event.key === 'ArrowRight' && columnIndex < this.parameters.item.columns.strategy.columns.length - 1) {
  //     event.preventDefault();
  //     this.focusCell(rowIndex, columnIndex + 1);
  //   } else if (event.key === 'Enter') {
  //     event.preventDefault();
  //     if (rowIndex < this.strategyRows.data.length - 1) {
  //       this.focusCell(rowIndex + 1, columnIndex);
  //     }
  //   }
  }


  async updateSlugs() {
    let slugs: any = []
    await asyncForEach(this.parameters.item.columns.strategy.columns, async (column: any, indexColumn: number)=> {
      if (indexColumn == 0 || this.inputsKeys.includes(this.parameters.item.columns.strategy.columnsName[column].slug)) {
        return;
      }
      this.parameters.item.columns.strategy.columnsName[column].slug_old = this.parameters.item.columns.strategy.columnsName[column].slug
      this.parameters.item.columns.strategy.columnsName[column].slug = slugify(this.parameters.item.columns.strategy.columnsName[column].field, { lower: true, trim: true })
      slugs.push(this.parameters.item.columns.strategy.columnsName[column].slug)
    })

    await asyncForEach(this.strategyRows.data, async (item: any) => {
      await asyncForEach(this.parameters.item.columns.strategy.columns, async (column: any, indexColumn: number)=> {
        if (indexColumn == 0 || this.inputsKeys.includes(this.parameters.item.columns.strategy.columnsName[column].slug)) {
          return;
        }
        item[this.parameters.item.columns.strategy.columnsName[column].slug] = item[this.parameters.item.columns.strategy.columnsName[column].slug_old]
      })

      await asyncForEach(Object.keys(item), async (key: any) => {
        if (!slugs.includes(key) && !this.inputsKeys.includes(key)) {
          delete item[key]
        }
      })
    })

    await asyncForEach(this.parameters.item.columns.strategy.columns, async (column: any, indexColumn: number) => {
      if (indexColumn === 0 || this.inputsKeys.includes(this.parameters.item.columns.strategy.columnsName[column].slug)) {
        return;
      }
      delete this.parameters.item.columns.strategy.columnsName[column].slug_old
      this.parameters.item.columns.strategy.columnsName[column].slug = slugify(this.parameters.item.columns.strategy.columnsName[column].field, { lower: true, trim: true });
      slugs.push(this.parameters.item.columns.strategy.columnsName[column].slug);
    });

    this.parameters.item.rows.strategy = this.strategyRows.data
  }

  // Função para focar uma célula específica
  focusCell(row: number, col: number) {
    const cellId = `mytextarea-${row}-${this.parameters.item.columns.strategy.columns.slice(1)[col]}`;
    const cellElement = document.getElementById(cellId);
    if (cellElement) {
      cellElement.focus();
    }
  }

  async removeSelectedRows() {
    const selectedRowsIndices: number[] = [];

    // Obter os índices das linhas selecionadas
    this.selectionStrategy.selected.forEach((selectedRow: any) => {
      const index = this.parameters.item.rows.strategy.findIndex((row: any) => row === selectedRow);
      if (index !== -1) {
        selectedRowsIndices.push(index);
      }
    });

    // Remover as linhas selecionadas da matriz
    selectedRowsIndices.sort((a, b) => b - a); // Ordenar em ordem decrescente para não afetar os índices
    selectedRowsIndices.forEach((index: number) => {
      this.parameters.item.rows.strategy.splice(index, 1);
    });

    await this.checkValue()
    if (this.strategyRows.data.length > (this.itemsPerPage * this.currentPage)) {
      this.currentPage = this.currentPage - 1
    }

    // Limpar seleção
    this.selectionStrategy.clear();
  }

  async removeColumn(columnIndex: number) {
    columnIndex++;''
    if (columnIndex >= 1 && columnIndex < this.parameters.item.columns.strategy.columns.length) {
      const columnNameToRemove = this.parameters.item.columns.strategy.columns[columnIndex];
      const columnSlugToRemove = this.parameters.item.columns.strategy.columnsName[columnNameToRemove].slug;

      // Remover a coluna da matriz de colunas
      this.parameters.item.columns.strategy.columns.splice(columnIndex, 1);

      // Remover as informações da coluna do objeto columnsName
      delete this.parameters.item.columns.strategy.columnsName[columnNameToRemove];

      // Remover os valores da coluna das linhas existentes
      this.strategyRows.data.forEach((row: any) => {
        delete row[columnSlugToRemove];
      });
    }
  }

  async checkValue() {
    let total = this.getTotalPages()
    if (this.currentPage > total) this.currentPage = total;
  }

  choseStrategy(strategy: any, element: any){
    element.corporation_dimension = { uuid: strategy.uuid, name: strategy.name }
  }

  filterByArray() {
    this.filteredCorporationStrategys = this.corporationStrategys.filter((item: { corporation_dimension: any }) =>
      item.corporation_dimension.name.toLowerCase().includes(this.search.toLowerCase())
    )

    this.filteredCorporationStrategys = sortArrayOfObjects(this.filteredCorporationStrategys)
  }
}
