import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { MaturityService } from 'src/app/services/maturity/maturity.service';
import { getLanguageCookie } from 'src/assets/language';
import {apiOfflineAsync} from "../../../../layouts/pages/pages.component";

@Component({
  selector: 'app-aba-maturity',
  templateUrl: './maturity.component.html',
  styleUrls: ['./maturity.component.scss']
})
export class AbaMaturityComponent {

  @Input() parameters: any = {}
  @Input() dimensions: any = {}
  @Output() parametersParent = new EventEmitter<string>();

  dataSource: any = {};
  maturity: number = 50;
  noData = true

  stars: any = [0, 25, 50, 75, 100]

  constructor(
    private maturityService: MaturityService
  ){
    this.dataSource.data = [];
  }

  async ngOnInit(){
    await this.getQuestions()
  }

  getValue(): number{
    let total = 0;
    let i = 0;

    this.dataSource.data.forEach((item: any) => {
      if (!this.parameters.item.maturity.hasOwnProperty(item.uuid)) {
        this.parameters.item.maturity[item.uuid] = 0;
      }

      if(item.is_active){
        i++;
        total = this.parameters.item.maturity[item.uuid] + total
      }
    });

    if(i == 0){
      this.noData = true;
      return 0
    }
    this.noData = false
    const average = total / i;

    // Formatando o valor com duas casas decimais
    return +average.toFixed(2);
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  async getQuestions(){
    // 'YYYY-MM-DD HH:MI:SS'
    // this.isLoading = true;

    try {
      const response: any = await this.maturityService.getQuestions().toPromise();

      if (response && response.data) {
        response.data.sort((a: any, b: any) => a.question.localeCompare(b.question));
        this.dataSource.data = response.data;
      } else {
        throw new Error('Erro ao consultar àrvore de negócios!\nConsulte o administrador do sistema!');
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }

    // this.isLoading = false;
    // this.noData = false
  }

  toggleStar(uuid: any, value: number){
    if(!this.parameters.isEdit){
      return
    }else{
      this.parameters.item.maturity[uuid] = value
    }
  }
}
