<div class="all-content">
  <div class="top">
    <div class="title">
      {{ parameters.isCreate ? getLanguages('personas/add') : parameters.isEdit ? getLanguages('personas/edit') : getLanguages('personas/view')}}
    </div>

    <i class="fas fa-xmark" (click)="exitModal()"></i>
  </div>
  <div class="buttons">
    <div class="save" (click)="setPersonasTree()" *ngIf="(isEdit || parameters.isCreate) && getSystemPermission('organizational_structure', 'create')">
      {{ getLanguages('save')}}
    </div>
    <div class="save" [ngClass]="isEdit? 'edit' : '' " (click)="editBusinessTree()" *ngIf="!parameters.isCreate && getSystemPermission('organizational_structure', 'edit')">
      {{ isEdit ? getLanguages('cancel') : getLanguages('edit') }}
    </div>
  </div>

  <div class="row">
      <div class="item item-2">
          <span> {{ getLanguages('personas/name') }} </span>
          <input [disabled]="!parameters.isEdit" type="text" [(ngModel)]="parameters.item.label">
      </div>
      <div class="item item-2">
        <span> {{ getLanguages('personas/type') }} </span>
        <button class="button-menu" 
        [matMenuTriggerFor]="personasMenu" 
        [disabled]="!parameters.isEdit || parameters?.item?.master == 'ROOT' || (parameters?.item?.size > 0 && parameters?.item?.data?.type == 'organogram')" 
        [ngClass]="!parameters.isEdit || parameters?.item?.master == 'ROOT' || (parameters?.item?.size > 0 && parameters?.item?.data?.type == 'organogram') ? 'disabled' : ''">
          <span>
            {{ getNames(selectedPersonas.name) }}
          </span>
          <i class="fa-solid fa-caret-down" [ngClass]="!parameters.isEdit ? 'disabled' : ''"></i>
        </button>
        <mat-menu #personasMenu="matMenu">
          <div class="menu">
            <div class="input" (click)="$event.stopPropagation()">
              <input type="text" [(ngModel)]="searchPersonas" placeholder="{{ getLanguages('search') }}">
              <i class="fa-solid fa-magnifying-glass"></i>
            </div>
            <div class="scroll">
              <ng-container *ngFor="let personas of personasArray">
                <ng-container *ngIf="getNames(personas.name).toLowerCase().includes(searchPersonas.toLowerCase())">
                  <div class="item-menu" (click)="choseItemSelect('personas', personas)">
                    <span [ngClass]="getNames(personas.name) == getNames(selectedPersonas.name) ? 'valid-span' : 'invalid-span'">
                      {{ getNames(personas.name) }}
                    </span>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </mat-menu>
      </div>
  </div>

  <!-- <div class="row" *ngIf="selectedPersonas.slug == 'organization'">
    <div class="item item-2">
      <span>{{ getLanguages('corporation/modal/followUp') }}</span>
      <button class="button-menu"  [ngClass]="!parameters.isEdit ? 'disabled' : '' " [disabled]="!parameters.isEdit" [matMenuTriggerFor]="select">
        <span>
            <ng-container *ngIf="selectedSegment.name != 'Select'; else searchBlock">
               {{ selectedSegment.name }}
            </ng-container>
            <ng-template #searchBlock>
                {{ getLanguages('search') }}
            </ng-template>
        </span>
        <i class="fas fa-caret-down"></i>
      </button>
      <mat-menu #select="matMenu">
        <div class="menu">
          <div class="input" (click)="$event.stopPropagation()">
            <input type="text" [(ngModel)]="searchFollow" placeholder="{{ getLanguages('search') }}">
            <i class="fa-solid fa-magnifying-glass"></i>
          </div>
          <div class="scroll">
            <ng-container *ngFor="let item of segmentArray">
                <div class="item-menu" (click)="chooseSegment(item)" *ngIf="item.name?.toLowerCase().includes(searchFollow.toLowerCase())">
                  <span [ngClass]="{'valid-span' : item.name == selectedSegment.name}"> {{ item.name }} </span>
                </div>
            </ng-container>
          </div>
        </div>
      </mat-menu>
    </div>
    <div class="item item-2">
      <span>{{ getLanguages('corporation/modal/type') }}</span>
      <button class="button-menu"  [ngClass]="!parameters.isEdit ? 'disabled' : '' " [disabled]="!parameters.isEdit" [matMenuTriggerFor]="selectTypeCorporation">
        <span>
            <ng-container *ngIf="selectedCorporationType.name != 'Select'; else searchBlock">
               {{ selectedCorporationType.name }}
            </ng-container>
            <ng-template #searchBlock>
                {{ getLanguages('search') }}
            </ng-template>
        </span>
        <i class="fas fa-caret-down"></i>
      </button>
      <mat-menu #selectTypeCorporation="matMenu">
        <div class="menu">
          <div class="input" (click)="$event.stopPropagation()">
            <input type="text" [(ngModel)]="searchType" placeholder="{{ getLanguages('search') }}">
            <i class="fa-solid fa-magnifying-glass"></i>
          </div>
          <div class="scroll">
            <ng-container *ngFor="let typeCorporation of corporationTypeArray">
              <ng-container *ngIf="typeCorporation.name.toLowerCase().includes(searchType.toLowerCase())">
                <div class="item-menu" (click)="choseTypeCorporation(typeCorporation)" *ngIf="typeCorporation.name?.toLowerCase().includes(searchType.toLowerCase())">
                  <span [ngClass]="{'valid-span' : typeCorporation.name == selectedCorporationType.name}"> {{ typeCorporation.name }} </span>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </mat-menu>
    </div>
  </div>

  <div class="row" *ngIf="selectedPersonas.slug == 'organization'">
    <div class="item item-2">
        <span> {{ getLanguages('instances/content/table/acronym') }} </span>
        <input [disabled]="!parameters.isEdit" type="text" [(ngModel)]="parameters.item.data.acronym">
    </div>
    <div class="item item-2">
      <span>{{ getLanguages('corporation/modal/status') }}</span>
      <button class="button-menu"  [ngClass]="!parameters.isEdit ? 'disabled' : '' " [disabled]="!parameters.isEdit" [matMenuTriggerFor]="status">
        <span>
            <ng-container>
              {{ selectedStatus?.name ? getNames(selectedStatus.name ) : getLanguages('select') }}
            </ng-container>
        </span>
        <i class="fas fa-caret-down"></i>
      </button>
      <mat-menu #status="matMenu">
        <div class="menu">
          <div class="scroll">
            <ng-container *ngFor="let status of statusArray">
                <div class="item-menu" (click)="choseStatus(status)">
                  <span [ngClass]="{'valid-span' : status.value == parameters.item.data.is_active}">
                    {{ getNames(status.label) }}
                  </span>
                </div>
            </ng-container>
          </div>
        </div>
      </mat-menu>
    </div>
  </div>

  <div class="row" *ngIf="selectedPersonas.slug == 'organization'">
    <div class="image">
      <div class="icon"  *ngIf="!displayedImage">
        <i class="fas fa-upload"></i>
      </div>

      <img [src]="displayedImage" *ngIf="displayedImage"/>

      <div class="button-upload pointer" (click)="openFileInput()">
        {{ getLanguages('instances/content/upload_image') }}
      </div>
      <input type="file" class="button-upload" #fileInput style="display: none" (change)="fileChangeEvent($event)">

    </div>
  </div> -->

  <div class="row" *ngIf="selectedPersonas.slug == 'persona'">
    <div class="item item-2">
        <span> {{ getLanguages('personas/cost_min') }} </span>
        <input [disabled]="!parameters.isEdit" type="number" min="0"  [(ngModel)]="parameters.item.data.cost_min">
    </div>

    <div class="item item-2">
      <span> {{ getLanguages('personas/cost_max') }} </span>
      <input [disabled]="!parameters.isEdit" type="number" min="0"  [(ngModel)]="parameters.item.data.cost_max">
    </div>
  </div>
  
  <div class="row" *ngIf="selectedPersonas.slug == 'persona'">
    <div class="item item-2">
      <span> {{ getLanguages('personas/seniority') }} </span>
      <button class="button-menu" 
      [matMenuTriggerFor]="seniorityMenu" 
      [disabled]="!parameters.isEdit" 
      [ngClass]="!parameters.isEdit ? 'disabled' : ''">
        <span>
          {{ getNames(selectedSeniority.name) }}
        </span>
        <i class="fa-solid fa-caret-down" [ngClass]="!parameters.isEdit ? 'disabled' : ''"></i>
      </button>
      <mat-menu #seniorityMenu="matMenu">
        <div class="menu">
          <div class="input" (click)="$event.stopPropagation()">
            <input type="text" [(ngModel)]="searchSeniority" placeholder="{{ getLanguages('search') }}">
            <i class="fa-solid fa-magnifying-glass"></i>
          </div>
          <div class="scroll">
            <ng-container *ngFor="let item of seniorityArray">
              <ng-container *ngIf="getNames(item.name).toLowerCase().includes(searchSeniority.toLowerCase())">
                <div class="item-menu" (click)="choseItemSelect('seniority', item)">
                  <span [ngClass]="getNames(item.name) == getNames(selectedSeniority.name) ? 'valid-span' : 'invalid-span'">
                    {{ getNames(item.name) }}
                  </span>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </mat-menu>
    </div>

    <div class="item item-2">
      <span> {{ getLanguages('personas/level') }} </span>
      <input [disabled]="!parameters.isEdit" (input)="validateLevel($event)" (keypress)="allowOnlyNumbers($event)" type="number" min="0" max="10" [(ngModel)]="parameters.item.data.level">
    </div>
  </div>
 
  <div class="row">
    <div class="title-skills" *ngIf="parameters?.item?.data?.type == 'persona'">
      <span> {{ getLanguages('personas/skills') }} </span>
      <i class="fa-solid fa-chevron-right" [ngClass]="!openSkills ? 'openned' : 'closed'" (click)="flipSkills()"></i>
      <i class="fa-solid fa-plus" (click)="addSkill()" *ngIf="getSystemPermission('organizational_structure', 'create_skills')" [ngClass]="!parameters.isEdit ? 'no-pointer' : 'pointer'"></i>

      <button class="save button-skills" [disabled]="!parameters.isEdit" (click)="exitModalSkills()" [ngClass]="!parameters.isEdit ? 'no-pointer disabled' : 'pointer'">
        {{ getLanguages('businessSolution/modal/skills/search') }}
      </button>
    </div>
    <div class="skills" *ngIf="parameters.item.data.type == 'persona'" [ngClass]="openSkills ? 'openned-skills' : 'closed-skills'">
      <div class="skill" *ngFor="let skill of skillsArray">
        <input type="text" [(ngModel)]="skill.name" [disabled]='!parameters.isEdit || skill.in_use'>
        <i class="fas fa-xmark" (click)="removeSkill(skill.uuid)" *ngIf="getSystemPermission('organizational_structure', 'remove_skills') || skill.in_use == undefined" [ngClass]="!parameters.isEdit ? 'no-pointer' : 'pointer'"></i>
      </div>

      <div class="noData" *ngIf="skillsArray && skillsArray.length == 0">
        <div class="icon">
            <i class="fa-solid fa-exclamation"></i>
        </div>
        <span>
            {{ getLanguages('errors/noData')}}
        </span>
      </div>
    </div>
  </div>
</div>

<div class="background-modals" *ngIf="isShowSkills">
  <app-skills-crud *ngIf="isShowSkills" (toggleExit)="exitModalSkills()" (getArray)="getArraySkillsPersona($event)" [parameters]="parameters"></app-skills-crud>

  <!-- <div class="modal-create-type" *ngIf="createType">
    <div class="top-create-type">
      <div class="toggle-create-type"></div>

      <div class="div-icon-button-create-type">
        <div class="save" (click)="setCorporationType()">
          {{ getLanguages('save')}}
        </div>
        <i class="fas fa-xmark" (click)="createType = false"></i>
      </div>
    </div>

    <div class="row-create-type">
      <div class="title-create-type">
        {{ getLanguages('corporation/modal/newType/title') }}
      </div>
    </div>

    <div class="row-create-type">
      <div class="item-create-type">
        <span> {{ getLanguages('corporation/modal/newType') }}</span>
        <input type="text" [(ngModel)]="newType.name">
      </div>
    </div>
  </div>

  <div class="modal-create-type" *ngIf="createSegment">
    <div class="top-create-type">
      <div class="toggle-create-type"></div>

      <div class="div-icon-button-create-type">
        <div class="save" (click)="setSegment()">
          {{ getLanguages('save')}}
        </div>
        <i class="fas fa-xmark" (click)="createSegment = false"></i>
      </div>
    </div>

    <div class="row-create-type">
      <div class="title-create-type">
        {{ getLanguages('corporation/modal/newSegment/title') }}
      </div>
    </div>

    <div class="row-create-type">
      <div class="item-create-type">
        <span> {{ getLanguages('corporation/modal/newSegment') }}</span>
        <input type="text" [(ngModel)]="newSegment.name">
      </div>
    </div>
  </div>

  <div class="showImageCrop" *ngIf="showCrop">
    <div class="content-crop">
      <i class="fas fa-xmark" (click)="saveAndClose(false)"></i>
      <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="true"
      [aspectRatio]="4 / 4"
      format="png"
      (imageCropped)="imageCropped($event)"
      ></image-cropper>
  
      <button (click)="saveAndClose()">
        {{ getLanguages('save')}}
      </button>
    </div>
  </div> -->
</div>


