<div class="all-content">
  <app-side-bar id="side-bar" (navigationSideBar)="navigationSideBar($event)"></app-side-bar>
  <div class="right">
    <div class="nav-bar shadow">
      <div class="left-nav">

        <div class="image shadow-2">
          <span *ngIf="!displayedImage || displayedImage === 'null'">
            {{ getName(selectedCorporation?.name) }}
          </span>
          <img *ngIf="displayedImage && displayedImage !== 'null'" [src]="displayedImage">
        </div>

        <div class="select-nav" (click)="showMenu('corporation')">
          <span>
              <ng-container *ngIf="selectedCorporation; else searchBlock">
                 {{ selectedCorporation?.name }}
              </ng-container>
              <ng-template #searchBlock>
                  {{ getLanguages('search') }}
              </ng-template>
          </span>
          <i class="fas fa-caret-down"></i>
        </div>
        <div class="myMatMenu shadow" *ngIf="showMenuType == 'corporation'" (mouseleave)="showMenuType = ''">
          <div class="menu">
            <div class="input" (click)="$event.stopPropagation()">
              <input type="text" [(ngModel)]="corporation.search" placeholder="{{getLanguages('search')}}" (input)="filterByArray('corporation')">
              <i class="fa-solid fa-magnifying-glass"></i>
            </div>
            <div class="scroll">
              <ng-container *ngFor="let item of corporation.filteredArray">
                  <div class="item-menu" (click)="chooseCorporation(item)">
                    <span [ngClass]="{'valid-span' : item?.uuid == selectedCorporation?.uuid}"> {{ item?.name }} </span>
                  </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div class="right-nav">

        <div class="title-instance-top">
          {{ selectedInstance.name }}
        </div>

        <div class="div-logo-title">
          <img src="../../../assets/logo_title.png" alt="OnePage" class="logo-title">
        </div>
        
        <i class="fas fa-gear pointer" (click)="toggleConfigurations()"></i>
        <section>
          <div class="img-nav button-tooltip" (click)="showMenuType = 'user'">
            <span>
              {{ getName(properties.me?.name) }}
            </span>
          </div>
          <div class="toggle-tooltip margin-right-tooltip">
            <div class="item-op">
              {{getLanguages('pages/info/user')}}
            </div>
          </div>
        </section>

        <div class="myMatMenu menu-user shadow" *ngIf="showMenuType == 'user'" (mouseleave)="showMenuType = ''">
          <ng-container *ngFor="let config of configArray">
            <button class="button-icon" (click)="navigate(config.route, config.exit)">
              <span> {{ config.name[properties.language.lang] }} </span>
            </button>
          </ng-container>
        </div>
      </div>
    </div>

    <section class="section">
      <div class="dashboard" *ngIf="getIndicators()">
        <app-dashboard-home (clickedCard)="flipedCard($event)" ></app-dashboard-home>
      </div>
      <div class="content" *ngIf="router.url != '/' && router.url != null">
        <router-outlet></router-outlet>
      </div>
    </section>

  </div>
</div>

<ng-container *ngIf="isLoadingChooseInstance">
  <div class="background-modals">
    <app-loading-instance [instance]="selectedItem"> </app-loading-instance>
  </div>
</ng-container>


<ng-container *ngIf="isShowConfigurations">
  <div class="background-modals">
    <app-general-settings [properties]="properties" (toggleConfigurations)="toggleConfigurations()" (chooseEnvironment)="chooseEnvironment($event)"> </app-general-settings>
  </div>
</ng-container>

<div class="alerts">
  <ng-container *ngFor="let alert of getQueueAlert()">
    <app-alert text="{{alert.text}}" type="{{alert.type}}" (remove)="setRemoveAlert(alert.id, $event)"></app-alert>
  </ng-container>
</div>
