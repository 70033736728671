import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CorporationService } from 'src/app/services/corporation/corporation.service';
import { FunctionService } from 'src/app/services/function.service';
import { to64decode, to64encode } from 'src/app/helpers/base64';
import { getLanguageCookie } from 'src/assets/language';
import { Subscription } from 'rxjs';
import { addAlert, apiOfflineAsync } from "../../../../layouts/pages/pages.component";
import { localStorageToJsonAsync } from "../../../../helpers/cookieToJson";
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { stringify } from 'circular-json';
import { v4 } from 'uuid';
import { sortArrayOfObjects } from 'src/app/helpers/sortArrayOfObjects';
import { systemPermissionsNew } from 'src/app/helpers/systemPermissions';


@Component({
  selector: 'app-edit-foundation',
  templateUrl: './edit-foundation.component.html',
  styleUrls: ['./edit-foundation.component.scss']
})
export class EditFoundationComponent {
  @Input() properties: any = {}
  @Input() parameters: any = {}

  @Output() toggleFoundationModalEdit: EventEmitter<void> = new EventEmitter<void>();
  @Output() getCorporationTree: EventEmitter<void> = new EventEmitter<void>();
  private subscription: Subscription = new Subscription();

  isPrincipal = false;

  createType = false;
  createSegment = false;

  newType = { name: null };
  newSegment = { name: null };

  isEdit = false;
  selectedRegistrationType: string = '';

  selectedFlag: any = {
    lang: 'en',
    file: 'us.svg'
  };


  formData: FormData = new FormData()
  file: any = null
  showCrop = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  displayedImage: any;

  statusArray = [{ value: true, label: { en: 'Active', pt: 'Ativo' } }, { value: false, label: { en: 'Inactive', pt: 'Inativo' } }]
  selectedStatus: any = this.statusArray[0];

  constructor(
    private functionService: FunctionService,
    private corporationService: CorporationService,
  ) { }

  data: any = {
    corporation: {
      corporation_uuid: null,
      corporation_type_uuid: null,
      corporation_name: '',
      follow_up: '',
      is_active: null,
      acronym: ''
    },
  }

  temp: any = {
    corporation: {
      corporation_uuid: null,
      corporation_type_uuid: null,
      corporation_name: '',
      follow_up: '',
      is_active: null,
      acronym: ''
    },
  }

  corporation: any = {}

  selectedCorporation: any = { name: 'Select' };

  selectedCorporationType: any = { name: 'Select' };
  selectedSegment: any = { name: 'Select' };

  corporationTypeArray: any = [];
  segmentArray: any = []

  searchType: string = '';
  searchFollow: string = '';

  async ngOnInit() {
    this.properties = await localStorageToJsonAsync()
    this.isPrincipal = this.parameters.is_principal;
    this.temp.corporation.is_active = this.statusArray[0].value;

    this.corporation.filteredArray = sortArrayOfObjects(this.properties.users_corporations.corporations)

    this.selectedStatus = this.parameters.is_active ? this.statusArray[0] : this.statusArray[1]
    this.displayedImage = this.parameters.url

    await this.getCorporationType()
    await this.getCorporationSegments()

    await this.getCorporationOne(this.parameters.uuid)
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getLanguages(file: string) {
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  async initCorporationType() {
    this.corporationTypeArray = []
    if (this.getSystemPermission('corporations', 'create_type')) {
      this.corporationTypeArray.push({ name: 'New +' })
    }
  }

  getSystemPermission(screen: string, action: string) {
    return systemPermissionsNew(screen, action)
  }

  async initSegment() {
    this.segmentArray = []
    if (this.getSystemPermission('corporations', 'create_segment')) {
      this.segmentArray.push({ name: 'New +' })
    }
  }

  async getCorporationOne(id: number) {
    try {
      const response: any = await this.corporationService.getCorporationOne(id)
      if (response.data) {
        this.selectedSegment.name = response.data.segment ? response.data.segment.name : 'Select';
        this.selectedCorporationType.name = response.data.corporationType ? response.data.corporationType.name : 'Select';
        this.data.corporation = response.data.corporation

        this.data.corporation.corporation_uuid = null
        this.selectedCorporation = { name: 'Select' }
        if (!this.isPrincipal) {
          this.data.corporation.corporation_uuid = response.data.corporation.corporation.uuid
          this.selectedCorporation = response.data.corporation.corporation
        }

        this.temp = JSON.parse(JSON.stringify(this.data));
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  async setCorporationType() {
    try {
      const response: any = await this.corporationService.setCorporationType(this.newType);
      if (response && [201, 202].includes(response.status)) {
        addAlert('success', response.message);
        await this.getCorporationType();
      } else {
        addAlert('danger', response.message)
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
    this.createType = false;
    this.newType.name = null
  }

  async getCorporationType() {
    try {
      const response: any = await this.corporationService.getCorporationType();
      if (response && response.data) {
        await this.initCorporationType();
        this.corporationTypeArray.push(...sortArrayOfObjects(response.data))
      } else {
        addAlert('danger', response.message)
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  async getCorporationSegments() {
    try {
      const response: any = await this.corporationService.getSegments();
      if (response && response.data) {
        await this.initSegment();
        this.segmentArray.push(...sortArrayOfObjects(response.data))
      } else {
        addAlert('danger', response.message)
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  setValidsInputs() {
    let inputs: any = []

    if (!this.temp.corporation.corporation_name || this.temp.corporation.corporation_name == '') {
      inputs.push(this.getLanguages('corporation/modal/corporationName'))
    }

    if (inputs.length > 0) {
      addAlert('orange', `${this.getLanguages('mandatoryField')}${inputs.join(`, `)}`)
      return false
    }
    return true
  }

  async setCorporation() {
    if (!this.setValidsInputs()) {
      return;
    }

    this.data = this.temp
    this.data.corporation.name = this.data.corporation.corporation_name
    this.data.corporation.is_principal = this.isPrincipal;
    try {
      await this.setFormData()
      const response: any = await this.corporationService.setCorporation(this.formData, false, {});
      if (response && [201, 202].includes(response.status)) {
        addAlert('success', response.message);

        if (this.data.corporation.uuid == to64decode(localStorage.getItem('corporation'))?.uuid) {
          localStorage.setItem('image_corporation', URL.createObjectURL(this.croppedImage.blob))
          delete this.data.corporation.url
          localStorage.setItem('corporation', to64encode(this.data.corporation))
          this.functionService.triggerGetUserCorporationReload();
        }

        this.exitModal()
        window.location.reload()
      } else {
        addAlert('danger', response.message)
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  chooseDomainTree(corporation: any) {
    this.selectedCorporation.name = corporation.name;
    this.temp.corporation.corporation_uuid = corporation.uuid
  }

  ////////////////// Segment
  chooseSegment(type: any) {
    if (type.name == "New +") {
      this.createSegment = true;
      return
    }
    this.selectedSegment.name = type.name;
    this.temp.corporation.follow_up = type.uuid
  }

  async setSegment() {
    try {
      const response: any = await this.corporationService.setSegment(this.newSegment)
      if (response && [201, 202].includes(response.status)) {
        addAlert('success', response.message);
        await this.getCorporationSegments();
      } else {
        addAlert('danger', response.message)
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
    this.createSegment = false;
    this.newSegment.name = null
  }

  chooseTypeCorporation(type: any) {
    if (type.name == "New +") {
      this.createType = true;
      return
    }
    this.selectedCorporationType.name = type.name;
    this.temp.corporation.corporation_type_uuid = type.uuid
  }

  exitModal(hide: boolean = true): void {
    if (!hide) {
      return
    }

    this.getCorporationTree.emit()
    this.toggleFoundationModalEdit.emit();
  }

  chooseStatus(state: any) {
    this.selectedStatus = state;
    this.temp.corporation.is_active = state.value
  }

  filterByArray(slug: string) {
    if (slug == 'corporation') {
      this.corporation.filteredArray = this.properties.users_corporations.corporations.filter((item: { name: string }) => {
        item.name.toLowerCase().includes(this.corporation.search.toLowerCase())
      })

      this.corporation.filteredArray = sortArrayOfObjects(this.corporation.filteredArray)
    }
  }

  getNames(name: any) {
    return name[to64decode(localStorage.getItem('language'))?.lang]
  }

  async editCancel() {
    this.isEdit = !this.isEdit

    if (!this.isEdit) {
      await this.cancel()
      await this.ngOnInit()
    }
  }

  async cancel() {
    this.temp = JSON.parse(JSON.stringify(this.data));
  }

  @ViewChild('fileInput') fileInput!: ElementRef;
  openFileInput() {
    this.fileInput.nativeElement.click();
  }

  fileChangeEvent(event: any): void {
    const fileInput = event.target;
    if (fileInput.files.length > 0) {
      const fileSize = fileInput.files[0].size;
      const maxSizeInBytes = 2 * 1024 * 1024;

      if (fileSize > maxSizeInBytes) {
        addAlert('orange', this.getLanguages('error/maxSizeBytes'))
        fileInput.value = '';

        return
      }
    }


    const allowedFormats = ['image/png', 'image/jpeg', 'image/jpg'];
    const file = event.target.files[0];

    if (!allowedFormats.includes(file.type)) {
      addAlert('orange', this.getLanguages('error/onlyPng'))
      return;
    }

    this.imageChangedEvent = event;
    this.showCrop = true;
  }

  saveAndClose(flag: boolean = true) {
    this.showCrop = false;

    if (!flag) {
      return
    }

    this.displayedImage = URL.createObjectURL(this.croppedImage.blob);


    const blob = this.croppedImage.blob;
    const fileReader = new FileReader();

    fileReader.readAsArrayBuffer(blob);
    fileReader.onload = () => {
      let arrayBuffer = fileReader.result as ArrayBuffer;
      this.file = new File([arrayBuffer], `${v4()}.png`, { type: 'image/png' })
    };
  }

  async setFormData() {
    this.formData = new FormData()
    this.formData.append('body', stringify(this.data))
    this.formData.append('file', this.file)
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event;
  }
}
