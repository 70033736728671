export const permissionJson: any =[
  {
    module: "operation",
    screens: [
      {
        slug: "business_solutions",
        access: false,
        permissions: [
          { slug: "view_process", access: false },
          { slug: "create_process", access: false },
          { slug: "delete_process", access: false },
          { slug: "edit_process", access: false },
          { slug: "copy_process", access: false },
          { slug: "clone_process", access: false },
          { slug: "transfer_process", access: false },
          { slug: "save_tree", access: false },
          { slug: "import", access: false },
          { slug: "export", access: false },
          { slug: "bpmn", access: false },
          { slug: "cut_proccess", access: false },
          { slug: "copy_identifier", access: false }
        ]
      },
      {
        slug: "clipboard",
        access: false,
        permissions: [
          { slug: "link", access: false }
        ]
      },
      {
        slug: "versioning",
        access: false,
        permissions: [
          { slug: "change_version", access: false },
          { slug: "edit", access: false },
          { slug: "delete", access: false }
        ]
      }
    ]
  },
  {
    module: "configurations",
    screens: [
      {
        slug: "corporations",
        access: false,
        permissions: [
          { slug: "create", access: false },
          { slug: "edit", access: false },
          { slug: "delete", access: false },
          { slug: "dimension", access: false },
          { slug: "business_tree", access: false },
          { slug: "organizational_structure", access: false },
          { slug: "create_segment", access: false },
          { slug: "create_type", access: false }
        ]
      },
      {
        slug: "organizational_structure",
        access: false,
        permissions: [
          { slug: "view", access: false },
          { slug: "create", access: false },
          { slug: "edit", access: false },
          { slug: "delete", access: false },
          { slug: "save_structure", access: false },
          { slug: "create_skills", access: false },
          { slug: "export", access: false },
          { slug: "remove_skills", access: false }

        ]
      },
      {
        slug: "standards",
        access: false,
        permissions: [
          { slug: "parametrize_level", access: false },
          { slug: "edit_level", access: false },
          { slug: "import_guideline", access: false }
        ]
      },
      {
        slug: "technologies",
        access: false,
        permissions: [
          { slug: "view", access: false },
          { slug: "create", access: false },
          { slug: "edit", access: false },
          { slug: "delete", access: false },
          { slug: "set_nature", access: false },
          { slug: "save_structure", access: false },
          { slug: "import", access: false },
          { slug: "export", access: false },
          { slug: "delete_nature", access: false }
        ]
      },
      {
        slug: "maturity",
        access: false,
        permissions: [
          { slug: "create", access: false },
          { slug: "edit", access: false },
          { slug: "save_list", access: false }
        ]
      },
      {
        slug: "project_guideline",
        access: false,
        permissions: [
          { slug: "view", access: false },
          { slug: "create", access: false },
          { slug: "edit", access: false },
          { slug: "delete", access: false },
          { slug: "save_structure", access: false },
          { slug: "export", access: false },
          { slug: "create_type", access: false }
        ]
      }
    ]
  },
  {
    module: "administrator",
    screens: [
      {
        slug: "environments",
        access: false,
        permissions: [
          { slug: "create", access: false },
          { slug: "edit", access: false },
          { slug: "delete", access: false },
          { slug: "create_user", access: false },
          // { slug: "link_user", access: false }
        ]
      },
      {
        slug: "activities",
        access: false,
        permissions: [
          { slug: "view", access: false }
        ]
      },
      {
        slug: "users",
        access: false,
        permissions: [
          { slug: "view_user", access: false },
          { slug: "create_user", access: false },
          { slug: "edit_access", access: false },
          { slug: "delete_user", access: false },
          { slug: "choose_approval", access: false }
        ]
      },
      {
        slug: "profiles",
        access: false,
        permissions: [
          { slug: "create_profile", access: false },
          { slug: "edit_profile", access: false },
          { slug: "delete_profile", access: false }
        ]
      }
    ]
  }
]