export const iconsJson: any = [
    {
      class: 'fa-solid fa-building-columns'
    },
    {
      class: 'fa-solid fa-bell-concierge'
    },
    {
      class: 'fa-solid fa-bag-shopping'
    },
    {
      class: 'fa-solid fa-file-contract'
    },
    {
      class: 'fa-solid fa-address-book'
    },
    {
      class: 'fa-solid fa-users-between-lines'
    },
    {
      class: 'fa-solid fa-industry'
    },
    {
      class: 'fa-solid fa-bullhorn'
    },
    {
      class: 'fa-solid fa-chess-knight'
    },
    {
      class: 'fa-solid fa-hospital-user'
    },
    {
      class: 'fa-solid fa-file-signature'
    },
    {
      class: 'fa-solid fa-file-invoice'
    },
    {
      class: 'fa-solid fa-cash-register'
    },
    {
      class: 'fa-solid fa-calculator'
    },
    {
      class: 'fa-solid fa-triangle-exclamation'
    },
    {
      class: 'fa-solid fa-cart-shopping'
    },
    {
      class: 'fa-solid fa-user-tie'
    },
    {
      class: 'fa-solid fa-file-invoice-dollar'
    },
    {
      class: 'fa-solid fa-shop'
    },
    {
      class: 'fa-solid fa-phone'
    },
    {
      class: 'fa-solid fa-headset'
    },
    {
      class: 'fa-solid fa-credit-card'
    },
    {
      class: 'fa-solid fa-pen-ruler'
    },
    {
      class: 'fa-solid fa-gears'
    },
    {
      class: 'fa-solid fa-medal'
    }
  ]