import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { getLanguageCookie } from 'src/assets/language';
import { CorporationDimensionsService } from "../../../../services/corporation-dimensions/corporation-dimensions.service";
import { addAlert, apiOfflineAsync } from 'src/app/layouts/pages/pages.component'
import { localStorageToJsonAsync }from 'src/app/helpers/cookieToJson';
import { systemPermissionsNew } from 'src/app/helpers/systemPermissions';
import { fixedLevels } from 'src/assets/const/fixedLevelsJson';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent {
  @ViewChild('buttonContainer') buttonContainer!: ElementRef;
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;

  @Output() toggleModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() getBusinessTree: EventEmitter<void> = new EventEmitter<void>();
  @Output() updateFiles: EventEmitter<any> = new EventEmitter<any>();

  @Input() properties: any = {}
  @Input() parameters: any = {}

  protected readonly Object = Object;

  selectedFlag: any = { lang: 'en', file: 'us.svg' };
  dataSource: any;

  arrayDimension: any = []
  viewDimension: number = 0;
  title = 'Add Code';
  icon = 'fa-diagram-next';
  nameProcess!: string;

  columns: any = {
    strategy: {},
    people: {},
    technology: {}
  }

  dimensions: any = {
    strategy: [],
    people: [],
    technology: []
  }
  tabArray: any = []
  alert: any

  count: number = 0

  newNode: any
  isChangeTree: boolean = false;

  hasNameError: boolean = false

  constructor(
    private corporationDimensionsService: CorporationDimensionsService,
  ) {
  }

  async ngOnInit() {
    this.properties = await localStorageToJsonAsync()

    await this.getDimensions()
    await this.toggleDimension(1)
    await this.initDescription()
    await this.initIndicators()
    this.getKey()
    // this.parameters.item.level = this.parameters.item.level : 1

    if(this.parameters.guidelineArray[this.parameters.item.level - 1]?.tabs?.length > 0){
      this.tabArray = this.parameters.optionsArray.tabArray.filter(
        (tab: any) => this.parameters.guidelineArray[this.parameters.item.level - 1]?.tabs?.includes(tab.slug) 
      || tab.slug == 'addCode')
    }else{
      this.tabArray = this.parameters.optionsArray.tabArray
    }
  }

  ngAfterViewInit() {
    this.scrollContainer.nativeElement.scrollLeft = 0;
  }

  scrollLeft() {
    this.scrollContainer.nativeElement.scrollLeft -= 250;
  }

  scrollRight() {
    this.scrollContainer.nativeElement.scrollLeft += 250;
  }

  getSystemPermission(screen: string, action: string){
    return systemPermissionsNew(screen, action)
  }

  async initDescription(){
    this.parameters.item.description = this.parameters.item.description ?? {}
    this.parameters.item.description.importance = this.parameters.item.description.importance ?? ''
    this.parameters.item.description.frequency = this.parameters.item.description.frequency ?? ''
    this.parameters.item.description.fte = this.parameters.item.description.fte ?? 0
    this.parameters.item.description.fte_cost = this.parameters.item.description.fte_cost ?? 0
    this.parameters.item.description.fte_one = this.parameters.item.description.fte_one ?? 0
    this.parameters.item.description.fte_cost_one = this.parameters.item.description.fte_cost_one ?? 0
    this.parameters.item.description.sla_unit = this.parameters.item.description.sla_unit ?? ''
    this.parameters.item.description.sla = this.parameters.item.description.sla ?? ''
    this.parameters.item.description.description = this.parameters.item.description.description ?? ''
    this.parameters.item.description.link = this.parameters.item.description.link ?? ''
    this.parameters.item.description.strategy = this.parameters.item.description.strategy ?? ''
  }

  async initIndicators(){
    this.parameters.item.indicators = this.parameters.item.indicators ?? []
    this.parameters.item.complements = this.parameters.item.complements ?? []
    this.parameters.item.configurations = this.parameters.item.configurations ?? []
    this.parameters.item.integrations = this.parameters.item.integrations ?? []
    this.parameters.item.rules = this.parameters.item.rules ?? []
    this.parameters.item.transactions = this.parameters.item.transactions ?? []
    this.parameters.item.project = this.parameters.item.project ?? []
    this.parameters.item.description.processOwner = this.properties.me
    this.parameters.item.extendedAttributes = this.parameters.item.extendedAttributes ?? []
    this.parameters.item.links = this.parameters.item.links ?? []
  }

  async getDimensions(){
    try{
      const response: any = await this.corporationDimensionsService.getDimensions()
      if (response.data) {
        this.dimensions = response.data
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  async toggleDimension(number: number = 1){
    this.viewDimension = number
  }

  ngOnChanges(changes: SimpleChanges) {
    this.exitModal(false)
  }

  exitModal(hide: boolean = true): void {
    if (!hide) {
      return
    }
    if(this.parameters.item.label != null && this.parameters.item.label != ''){
      this.isChangeTree = true;
      return
    }
    this.toggleModal.emit(this.alert);
  }


  async modalClose() {
    // await Promise.all([ this.getBusinessTree.emit(), this.exitModal() ]);
    await Promise.all([ this.exitModal() ]);

  }

  async parametersValidation () {
    let inputs: any = []

    // this.parameters.item.code = this.parameters.inputs.code;
    if (this.parameters.parent.code) {
      this.parameters.item.code = `${this.parameters.parent.code}.${this.parameters.item.code}`
    }

    if(!this.parameters.item.label){
      inputs.push(this.getLanguages('businessSolution/modal/addCode/process'))
    }

    if (inputs.length > 0) {
      addAlert('orange', `${this.getLanguages('mandatoryField')}${inputs.join(`, `)}`)
      return false
    }
    return true
  }

  recursiveCount(item: any) {
    this.count++;
    if (item.children && item.children.length > 0) {
      item.children.forEach((child: any) => {
        this.recursiveCount(child);
      });
    }
  }

  getKey() {
    this.count = 0; // Certifique-se de reiniciar o contador
    this.parameters?.filesOld?.forEach((item: any) => {
      this.recursiveCount(item);
    });
  }

  getLabel(label: string): string{
    const index = this.parameters.item?.level * (-1)

    if(this.parameters.item?.level < 0){
      label = '[' + fixedLevels[index - 1]?.acronym + '] ' +  label
    }
    return label
  }

  async setBusinessTree() {
    Object.keys(this.parameters.item.rows).forEach((dimension: any) => {
      this.parameters.item.rows[dimension] = this.parameters.item.rows[dimension].filter((item: any) => {
        return item.corporation_dimension !== null && item.corporation_dimension !== undefined;
      });
    });

    if (!await this.parametersValidation()) {
      return
    }

    this.newNode = {
      master: this.parameters.parent && this.parameters.parent.uuid ? this.parameters.parent.uuid : 'ROOT',
      children: [],
      data: this.parameters.item.data,
      label: this.getLabel(this.parameters.item.label),
      created_at: this.formatDate(new Date()),
      updated_at: this.formatDate(new Date()),
      userCreated: this.properties?.me?.name ?? '',
      userUpdated: this.properties?.me?.name ?? '',
      parent: undefined,
      level: this.parameters.item.level,
      size: 1,
      key: this.parameters.item.key,
      status: this.parameters.item.status,
      rows: this.parameters.item.rows,
      columns: this.parameters.item.columns,
      maturity: this.parameters.item.maturity ?? {},
      personas: this.parameters.item.personas ?? [],
      personasClassification: this.parameters.item.personasClassification ?? [],
      technologies_table: this.parameters.item.technologies_table ?? {},
      attachments: this.parameters.item.attachments ?? [],
      description: {...this.parameters.item.description},
      indicators: this.parameters.item.indicators ?? [],
      complements: this.parameters.item.complements ?? [],
      configurations: this.parameters.item.configurations ?? [],
      integrations: this.parameters.item.integrations ?? [],
      rules: this.parameters.item.rules ?? [],
      extendedAttributes: this.parameters.item.extendedAttributes ?? [],
      links: this.parameters.item.links ?? [],
      transactions: this.parameters.item.transactions ?? [],
      project: this.parameters.item.project ?? {},
      uuid: this.parameters.item.uuid
    }

    await this.updateDateTimeExtendedAttribute()

    if(!this.parameters.parent.uuid){
      if(!this.validateName(this.parameters.filesOld)){
        this.parameters.filesOld.push(this.newNode)
        this.hasNameError = false;
      }else{
        addAlert('orange', this.getLanguages('errors/businessTree/SameNameElement'))
        this.hasNameError = true;
        return
      }
    }else{
      this.parameters.filesOld.forEach((item: any) => {
        if(item.uuid == this.parameters.parent.uuid){
          if(!this.validateName(item.children)){
            item.children.push(this.newNode)
            this.hasNameError = false;
          }else{
            addAlert('orange', this.getLanguages('errors/businessTree/SameNameElement'))
            this.hasNameError = true;
            return
          }
        }else{
          if(item.children.length > 0){
            this.recursiveForEach(item.children)
          }
        }
      });
    }

    if(!this.hasNameError){
      this.updateFiles.emit({ files: this.parameters.filesOld } )
      this.toggleModal.emit();
    }
  }

  formatDate(date: Date): string {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);
  
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  }

  validateName(array: any): boolean{
    let returnValue: boolean = false;
    if(!array || array?.length == 0 ){
      return false
    }
    array.forEach((child: any) => {
      if(this.newNode.label == child.label){
        returnValue = true
      }
    });
    return returnValue
  }

  async updateDateTimeExtendedAttribute(){
    this.parameters.item.extendedAttributes.forEach((item: any) => {
      item.created_date = this.formatDate(new Date())
      item.updated_date = this.formatDate(new Date())
    });
  }

  recursiveForEach(array: any){
    array.forEach(async (item: any) => {
      if(item.uuid == this.parameters.parent.uuid){
        if(!this.validateName(item.children)){
          item.children.push(this.newNode)
          this.hasNameError = false;
        }else{
          addAlert('orange', this.getLanguages('errors/businessTree/SameNameElement'))
          this.hasNameError = true;
          return
        }
      }else{
        if(item.children.length > 0){
          this.recursiveForEach(item.children)
        }
      }
    });
  }

  getDimension(){
    let value = ''
    if (this.viewDimension == 2) value = 'strategy'
    if (this.viewDimension == 3) value = 'people'
    if (this.viewDimension == 4) value = 'transactions'
    if (this.viewDimension == 5) value = 'technology'
    if (this.viewDimension == 6) value = 'rules'
    if (this.viewDimension == 7) value = 'maturity'
    if (this.viewDimension == 8) value = 'attachments'
    if (this.viewDimension == 9) value = 'indicators'
    if (this.viewDimension == 10) value = 'complements'
    if (this.viewDimension == 11) value = 'configurations'
    if (this.viewDimension == 12) value = 'integrations'
    if (this.viewDimension == 13) value = 'project'
    if (this.viewDimension == 14) value = 'extendedAttributes'
    if (this.viewDimension == 15) value = 'links'

    return value == '' ? '' : `- ${this.getLanguages(`businessSolution/modal/${value}/title`)}`
  }

  getNameEntity(){
    let value = ''
    const level = this.parameters?.item?.level
    const index = level * (-1)
    if(!this.parameters?.guidelineArray){
      value = '' 
    }else{
      value = this.parameters?.guidelineArray[level - 1]?.name ?? ''
    }

    if(!value || value == undefined){
      value = '' 
    }

    if(level < 0){
      value = this.getLanguages('businessSolution/modal/create/title') + ' ' + fixedLevels[index - 1]?.name
    }else{
      value = this.getLanguages('businessSolution/modal/create/title') + ' ' + value
    }

    return value
  }

  toggleChangeTree(event: any){
    this.isChangeTree = !this.isChangeTree

    if(event){
      return
    }

    if(!this.isChangeTree){
      this.toggleModal.emit();
    }
  }
}
