<!-- <div class="all-content">
  <div class="left">
    <img src="../../../assets/logo_title_white.png" class="logo-title">
  </div>
  <div class="right">
    <div class="title">

      <span>
        {{getLanguages('auth/password')}}
      </span>
      <div class="tog"></div>
    </div>

    <div class="inputs">
      <div class="password">
        <input type="text" placeholder="{{getLanguages('auth/password/password')}}" (keyup.enter)="setUserPassword()" [type]="showPass ? 'text' : 'password'" [(ngModel)]="password" (input)="validatePassword()" autocapitalize="none">
        <i [ngClass]="showPass ? 'fa-regular fa-eye' : 'fa-regular fa-eye-slash' " (click)="showPass = !showPass" ></i>

      </div>
      <div class="password">
        <input type="text" placeholder="{{getLanguages('auth/password/confirm_password')}}" (keyup.enter)="setUserPassword()" [type]="showPassConfirm ? 'text' : 'password'" [(ngModel)]="password_confirm" (input)="validatePassword()" autocapitalize="none">
        <i [ngClass]="showPass ? 'fa-regular fa-eye' : 'fa-regular fa-eye-slash' " (click)="showPassConfirm = !showPassConfirm"></i>
      </div>

      <div class="validator">
        <div class="item">
          <input checked type="checkbox" id="checkbox" [(ngModel)]="hasLetters" [ngClass]="{'valid-check' : hasLetters}">
          <span [ngClass]="{'valid-span' : hasLetters}"> {{getLanguages('auth/password/letters')}}</span>
        </div>
        <div class="item">
          <input checked type="checkbox" id="checkbox" [(ngModel)]="hasNumbers" [ngClass]="{'valid-check' : hasNumbers}">
          <span [ngClass]="{'valid-span' : hasNumbers}"> {{getLanguages('auth/password/numbers')}}</span>
        </div>
        <div class="item">
          <input checked type="checkbox" id="checkbox" [(ngModel)]="isLengthValid" [ngClass]="{'valid-check' : isLengthValid}">
          <span [ngClass]="{'valid-span' : isLengthValid}"> {{getLanguages('auth/password/lenght')}}</span>
        </div>
        <div class="item">
          <input checked type="checkbox" id="checkbox" [(ngModel)]="isSame" [ngClass]="{'valid-check' : isSame}">
          <span [ngClass]="{'valid-span' : isSame}"> {{getLanguages('auth/password/same')}}</span>
        </div>

      </div>

      <button class="buttom" (click)="setUserPassword()"
      [disabled]="!(hasLetters && hasNumbers && isLengthValid && isSame)"
      [ngClass]="{'enabled': hasLetters && hasNumbers && isLengthValid && isSame,
      'disabled': !(hasLetters && hasNumbers && isLengthValid && isSame)}"
      >
        {{getLanguages('auth/password/send')}}
      </button>
    </div>
  </div>


  <div class="bottom">
    <div class="tag">
      <span>UI: {{ versions?.version_ui }}</span>
      <span>API: {{ versions?.version_api }}</span>
    </div>

    <button [matMenuTriggerFor]="flagMenu" matTooltip="{{getLanguages('pages/info/language')}}" class="flag-button">
      <i class="fas fa-globe"></i>
      <span> {{ language.lang.toUpperCase() }} </span>
      <i class="fas fa-caret-down"></i>
    </button>

    <mat-menu #flagMenu="matMenu" class="flag-menu">
      <button *ngFor="let flag of flagArray" (click)="selectFlag(flag)" class="button-icon">
        <span> {{ flag.lang.toUpperCase() }} </span>
      </button>
    </mat-menu>
  </div>

  <div class="loading" *ngIf="isLoading">
    <div class="loading-spinner"></div>
    <span>
      {{ getLanguages('loading') }}
    </span>
  </div>
</div> -->

<div class="all-content">
  <div class="left"></div>
  <div class="right">
    <div class="top">
      <div class="img"></div>
    </div>
    <div class="content">
      <span class="title"> {{ getLanguages('auth/password')}} </span>
      <div class="inputs">
        <section>
          <input type="password" (input)="validatePassword()" placeholder="{{ getLanguages('auth/login/password')}}" (keyup.enter)="setUserPassword()"  [type]="showPass ? 'text' : 'password'" [(ngModel)]="password" autocapitalize="none">
          <i [ngClass]="showPass ? 'fa-regular fa-eye' : 'fa-regular fa-eye-slash' " (click)="showPass = !showPass"></i>
        </section>
        <section>
          <input type="password" (input)="validatePassword()" placeholder="{{getLanguages('auth/password/confirm_password')}}" (keyup.enter)="setUserPassword()"  [type]="showPassConfirm ? 'text' : 'password'" [(ngModel)]="password_confirm" autocapitalize="none">
          <i [ngClass]="showPassConfirm ? 'fa-regular fa-eye' : 'fa-regular fa-eye-slash' " (click)="showPassConfirm = !showPassConfirm"></i>
        </section>

        <div class="validator">
          <div class="item">
            <input type="checkbox" [checked]="hasLetters" disabled [ngClass]="{'valid-check' : hasLetters}">
            <span [ngClass]="{'valid-span' : hasLetters}"> {{getLanguages('auth/password/letters')}}</span>
          </div>
          <div class="item">
            <input type="checkbox" [checked]="hasNumbers" disabled [ngClass]="{'valid-check' : hasNumbers}">
            <span [ngClass]="{'valid-span' : hasNumbers}"> {{getLanguages('auth/password/numbers')}}</span>
          </div>
          <div class="item">
            <input type="checkbox" [checked]="isLengthValid" disabled [ngClass]="{'valid-check' : isLengthValid}">
            <span [ngClass]="{'valid-span' : isLengthValid}"> {{getLanguages('auth/password/lenght')}}</span>
          </div>
          <div class="item">
            <input type="checkbox" [checked]="isSame" disabled [ngClass]="{'valid-check' : isSame}">
            <span [ngClass]="{'valid-span' : isSame}"> {{getLanguages('auth/password/same')}}</span>
          </div>          
        </div>

        <button (click)="setUserPassword()"
          [disabled]="!(hasLetters && hasNumbers && isLengthValid && isSame)"
          [ngClass]="{'enabled': hasLetters && hasNumbers && isLengthValid && isSame,
          'disabled': !(hasLetters && hasNumbers && isLengthValid && isSame)}">
          {{getLanguages('auth/password/send')}}
        </button>
      
      </div>
      
    </div>
  </div>

  <div class="bottom">
    <div class="tag">
      <span>UI: {{ versions?.version_ui }}</span>
      <span>API: {{ versions?.version_api }}</span>
    </div>

    <button [matMenuTriggerFor]="flagMenu" matTooltip="{{getLanguages('pages/info/language')}}" class="flag-button">
      <i class="fas fa-globe"></i>
      <span> {{ language && language.lang ? language.lang.toUpperCase() : 'en' }} </span>
      <i class="fas fa-caret-down"></i>
    </button>

    <mat-menu #flagMenu="matMenu" class="flag-menu">
      <button *ngFor="let flag of flagArray" (click)="selectFlag(flag)" class="button-icon">
        <span> {{ flag.lang.toUpperCase() }} </span>
      </button>
    </mat-menu>
  </div>

  <div class="loading" *ngIf="isLoading">
    <div class="loading-spinner"></div>
    <span>
      {{ getLanguages('loading') }}
    </span>
  </div>
</div>

<div class="alerts">
  <ng-container *ngFor="let alert of queueAlert">
    <app-alert text="{{alert.text}}" type="{{alert.type}}" (remove)="removeAlertLogin(alert.id, $event)"></app-alert>
  </ng-container>
</div>
