<div class="all-content">
    <div class="top">
        <div class="toggle"></div>
        <i class="fas fa-xmark" (click)="exitModalNewTransaction()"></i>
    </div>
    <div class="row">
        <span class="title">
            {{ getLanguages('new_transaction/title') }}
        </span>
    </div>
    <div class="row">
        <div class="item item-1">
            <span> {{ getLanguages('technologies/id_trasaction') }} </span>
            <input type="text" [(ngModel)]="transactionLabel">
        </div>
    </div>
    <div class="row">
        <div class="item item-1">
            <span>  {{ getLanguages('activities/description') }} </span> 
            <input type="text" [(ngModel)]="transactionDescription">
        </div>
    </div>
    
    <div class="row">
        <div class="save" (click)="saveTransaction()">
            {{ getLanguages('save')}}
        </div>
    </div>
</div>