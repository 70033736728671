<div class="all-content">
  <div class="top">
    {{ getLanguages('users/content/top') }}
  </div>
  <div class="row">
    <div class="div-search">
      <div class="search">
          <input type="text" [(ngModel)]="searchText" (keyup.enter)="searchUser()">
      </div>
      <button (click)="searchUser()">
          {{ getLanguages('search') }}
      </button>
    </div>
  </div>
  <div class="row">
    <div class="buttons">
      <ng-container *ngIf="getSystemPermission('users', 'edit_access')">
        <button (click)="togleCreateUserEdit($event)" [disabled]="!isShowEdit()" [ngClass]="!isShowEdit() ? 'disabled' : isShowUserEdit ? 'edit': '' ">
          {{ getLanguages('edit') }}
        </button>
      </ng-container>
      <button (click)="togleCreateUser($event)" *ngIf="getSystemPermission('users', 'create_user')"> {{ getLanguages('users/modal/create/title') }}</button>
    </div>
  </div>

  <div class="div-table">
    <div class="table">
        <table mat-table [dataSource]="getDisplayedRows()" *ngIf="!isLoading && isData()">
          <ng-container matColumnDef="Select" *ngIf="getSystemPermission('users', 'edit_access')" >
            <th mat-header-cell *matHeaderCellDef >
            <!-- <mat-checkbox
                (change)="$event ? toggleAllRow() : null"
                [color]="task.color"
                [checked]="isAllSelected()"
                [aria-label]="checkboxLabelUsers()"
                class="checkbox"
                >
            </mat-checkbox> -->
            </th>
            <td mat-cell *matCellDef="let element;let index;">
              <mat-checkbox
                *ngIf="isCheckboxVisible(element)"
                (click)="$event.stopPropagation(); setSelection(element)"
                [color]="task.color"
                [checked]="getSelection(element)"
                [aria-label]="checkboxLabelUsers(index, element)"
                class="checkbox">
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> ID </th>
            <td mat-cell *matCellDef="let row"> {{ row.uuid }} </td>
          </ng-container>

          <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef> {{ getLanguages('users/content/table/name') }}  </th>
            <td mat-cell *matCellDef="let element" (click)="togleCreateUserEdit($event, true, element.uuid)"> {{element.name}} </td>
          </ng-container>

          <ng-container matColumnDef="Email">
            <th mat-header-cell *matHeaderCellDef> {{ getLanguages('users/content/table/email') }} </th>
            <td mat-cell *matCellDef="let element" (click)="togleCreateUserEdit($event, true, element.uuid)">
                <span> {{element.email}} </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="Profile">
            <th mat-header-cell *matHeaderCellDef>{{ getLanguages('users/content/table/profile') }}</th>
            <td mat-cell *matCellDef="let element" (click)="togleCreateUserEdit($event, true, element.uuid)">
              <span>{{ element.user_profile?.name || (element.is_admin_master ? getLanguages('is_admin_master') : '') }}</span>
            </td>
          </ng-container>          

          <!-- <ng-container matColumnDef="Status">
            <th mat-header-cell *matHeaderCellDef> {{ getLanguages('users/content/table/status') }} </th>
            <td mat-cell *matCellDef="let element">
              {{ !element?.is_admin_master ? (element?.user_corporation?.status === 'ACTIVE' ? getLanguages('users/content/table/status/true') : getLanguages('users/content/table/status/false')) : '' }}
            </td>
          </ng-container> -->

          <ng-container matColumnDef="Approver">
            <th mat-header-cell *matHeaderCellDef> {{ getLanguages('users/content/table/approver') }} </th>
            <td mat-cell *matCellDef="let element" (click)="togleCreateUserEdit($event, true, element.uuid)">
              <mat-slide-toggle
                *ngIf=" !element?.is_admin_master "
                [color]="task.color"
                [checked]="element.is_approval"
                class="checkbox"
                [disabled]="!getSystemPermission('users', 'choose_approval')"
                (click)="setUserApprover($event, element)">
              </mat-slide-toggle>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4"></td>
          </tr>
        </table>

        <app-loading *ngIf="isLoading"></app-loading>

        <div class="no-data" *ngIf="!isData()">

          <div class="circle">
            <i class="fas fa-exclamation"></i>
          </div>

          {{ getLanguages('errors/noData') }}
        </div>
    </div>

    <div class="div-paginator" *ngIf="isData() && !isLoading">
      <div class="paginator">
        <span>
          {{ getLanguages('businessSolution/modal/strategy/itensPage') }}
        </span>
        <button [matMenuTriggerFor]="menu" class="itens-per-page">
          {{ getItemsPerPage() }}
        </button>
        <mat-menu #menu="matMenu" >
          <button mat-menu-item (click)="choseItemsPage(5)">5</button>
          <button mat-menu-item (click)="choseItemsPage(10)">10</button>
        </mat-menu>
        <span>
          {{ getLanguages('businessSolution/modal/strategy/totalPages') }}
        </span>
        <span class="totalPages">
          {{ getTotalPages()}}
        </span>
        <button
          [ngClass]="getCurrentPages() === 1 ? 'disabled-arrow' : 'arrow'"
          [disabled]="getCurrentPages() === 1"
          (click)="onPageChange(getCurrentPages() - 1)">
          <i class="fa-solid fa-angle-left"></i>
        </button>
        <input type="number" [ngModel]="getCurrentPages()" disabled (blur)="checkValue($event)">
        <button
          [ngClass]="getCurrentPages() == getTotalPages() ? 'disabled-arrow' : 'arrow'"
          [disabled]="getCurrentPages() == getTotalPages()"
          (click)="onPageChange(getCurrentPages() + 1)">
          <i class="fa-solid fa-angle-right"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="background-modals" *ngIf="isShowUserCreate || isShowUserEdit">
    <ng-container *ngIf="isShowUserCreate">
      <app-create-user (toggleUserModal)="togleCreateUser()" [properties]="properties" [parameters]="parameters" (getUsers)="getUsers()"></app-create-user>
    </ng-container>

    <ng-container *ngIf="isShowUserEdit">
      <app-edit-user (toggleUserModalEdit)="togleCreateUserEdit()" [properties]="properties" [parameters]="parameters" (getUsers)="getUsers()"></app-edit-user>
    </ng-container>
  </div>
