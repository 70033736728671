export const processGuidelineJson: any = {
    "level_1": {
        "name": "Jornada",
        "tabs": [
            "strategy",
            "people",
            "transactions",
            "technology",
            "rules",
            "maturity",
            "attachments",
            "indicators",
            "complements",
            "configurations",
            "integrations",
            "project",
            "extendedAttributes",
            "links"
        ],
        "level": 1,
        "acronym": "JO",
        "mandatory": true,
        "description": ""
    },
    "level_2": {
        "name": "Macroprocesso",
        "tabs": [
            "strategy",
            "people",
            "transactions",
            "technology",
            "rules",
            "maturity",
            "attachments",
            "indicators",
            "complements",
            "configurations",
            "integrations",
            "project",
            "extendedAttributes",
            "links"
        ],
        "level": 2,
        "acronym": "MP",
        "mandatory": true,
        "description": ""
    },
    "level_3": {
        "name": "Processo",
        "tabs": [
            "strategy",
            "people",
            "transactions",
            "technology",
            "rules",
            "maturity",
            "attachments",
            "indicators",
            "complements",
            "configurations",
            "integrations",
            "project",
            "extendedAttributes",
            "links"
        ],
        "level": 3,
        "acronym": "PR",
        "mandatory": true,
        "description": ""
    },
    "level_4": {
        "name": "Subprocesso",
        "tabs": [
            "strategy",
            "people",
            "transactions",
            "technology",
            "rules",
            "maturity",
            "attachments",
            "indicators",
            "complements",
            "configurations",
            "integrations",
            "project",
            "extendedAttributes",
            "links"
        ],
        "level": 4,
        "acronym": "SP",
        "mandatory": true,
        "description": ""
    },
    "level_5": {
        "name": "Cenário de Subprocesso",
        "tabs": [
            "strategy",
            "people",
            "transactions",
            "technology",
            "rules",
            "maturity",
            "attachments",
            "indicators",
            "complements",
            "configurations",
            "integrations",
            "project",
            "extendedAttributes",
            "links"
        ],
        "level": 5,
        "acronym": "CS",
        "mandatory": true,
        "description": ""
    },
    "level_6": {
        "name": "Atividade",
        "tabs": [
            "strategy",
            "people",
            "transactions",
            "technology",
            "rules",
            "maturity",
            "attachments",
            "indicators",
            "complements",
            "configurations",
            "integrations",
            "project",
            "extendedAttributes",
            "links"
        ],
        "level": 6,
        "acronym": "AT",
        "mandatory": true,
        "description": ""
    }
};

export const guidelineArray: any = [
    {
      level: 1
    },
    {
      level: 2
    },
    {
      level: 3
    },
    {
      level: 4
    },
    {
      level: 5
    },
    {
      level: 6
    },
    {
      level: 7
    },
    {
      level: 8
    },
    {
      level: 9
    },
    {
      level: 10
    }
]