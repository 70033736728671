import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IResponse } from '../../interfaces/IResponse';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { to64decode } from "../../helpers/base64";
import { apiOfflineAsync } from "../../layouts/pages/pages.component";

@Injectable({
  providedIn: 'root'
})
export class UserEnvironmentsService {
  API_PATH: string

  private header: any = []
  private requestOptions: any = []

  constructor(
    private httpClient: HttpClient,
    private router: Router,
  ) {
    this.API_PATH = environment.apiUrl;

    this.header['Content-Type'] = 'application/json';
    this.requestOptions = { headers: new HttpHeaders(this.header) };
  }

  private getURLSearchParams({ environment, corporation }: any, setId: boolean = false) {
    let params = new URLSearchParams();

    if (!environment) {
      environment = to64decode(localStorage.getItem('environment'))
    }

    if (!corporation) {
      corporation = to64decode(localStorage.getItem('corporation'))
    }

    if (environment && (environment.uuid || environment.slug)) {
      params.set('environment', setId ? environment.uuid : environment.slug);
    }

    if (corporation && (corporation.uuid || corporation.slug)) {
      params.set('corporation', setId ? corporation.uuid : corporation.slug);
    }

    return params.toString();
  }

  async navigate(route: string) {
    return await this.router.navigate([route]);
  }

  async redirectError(error: any) {
    if (error.status == 401) {
      return await this.navigate('/login');
    }
    return error.error;
  }

  async getUserEnvironments(user: number, environments: any) {
    return await this.httpClient.get<IResponse>(
      `${this.API_PATH}users-environments?user=${user}&environment=${JSON.stringify(environments)}`
    ).toPromise();
  }

  async getUserEnvironmentsMe(user: number) {
    return await this.httpClient.get<any>(`${this.API_PATH}users-environments/me?user=${user}`).toPromise();
  }

  async setUsersEnvironments (environments: any, users: any) {
    try {
      return await this.httpClient.post<IResponse>(
        `${this.API_PATH}users-environments`,
        {
          environments: environments,
          users: users
        },
        this.requestOptions
      ).toPromise();
    } catch (error: any) {
      return await apiOfflineAsync(error)
    }
  }

  async getUserCorporations() {
    return await this.httpClient.get<any>(`${this.API_PATH}users-corporations`).toPromise();
  }

}
