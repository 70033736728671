import { Component, OnInit } from '@angular/core';
import { IAuth } from 'src/app/interfaces/IAuth';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { CookieService } from "ngx-cookie-service";
import { cookieToJson, cookieToJsonAsync, localStorageToJsonAsync } from "../../helpers/cookieToJson";
import { to64decode, to64encode } from "../../helpers/base64";
import { getLanguageCookie, getLanguage } from 'src/assets/language'; // Certifique-se de importar getLanguage
import { apiOfflineAsync, navigate } from 'src/app/layouts/pages/pages.component';
import { version_ui } from 'src/app/environments/tag_version';
import { UsersService } from "../../services/users/users.service";
import { setLogoLocalStorage } from "../../helpers/helpers";
import { UserEnvironmentsService } from 'src/app/services/userEnvironments/user-environments.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  static queueAlert: any = []
  static queueTime: any = []

  isLoading = false;
  language: any

  flagArray: any = [
    { lang: 'en', file: 'us.svg' },
    { lang: 'pt', file: 'br.svg' }
  ]

  public static is_login = true
  public static properties: any = {
    environment: {},
    corporation: {},
    me: {},
    modules: [],
    screens: {},
    profile: {},
    permissions: {},
    language: {}
  }

  email = '';
  emailConfirm = '';
  password = '';
  rememberMe = false;
  showPass = false;
  showConfirm = false;

  queueAlert: any = []
  queueTime: any = []

  versions: any = {}

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private authService: AuthService,
    private userService: UsersService,
    private userEnvironmentService: UserEnvironmentsService
  ) {
    this.versions.version_ui = version_ui
  }

  async ngOnInit() {
    if (environment.development) {
      this.email = ''
      this.password = ''
    }

    if (localStorage.getItem('language')) {
      this.language = to64decode(localStorage.getItem('language'));
    } else {
      this.language = this.flagArray[1]
    }

    await this.setVersions()
    await this.selectFlag()
  }

  async setVersions() {
    try {
      const response: any = await this.authService.setVersions(this.versions.version_ui)
      if (response.data) {
        this.versions = response.data
      }
    } catch (error) {
      await this.apiOfflineAsyncLogin(error)
    }
  }

  getLanguages(file: string) {
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  async selectFlag(flag: any = null) {
    if (flag) {
      this.language = flag;
    }
    localStorage.setItem('language', to64encode(this.language))
  }

  async login() {
    this.isLoading = true;
    try {
      const response: any = await this.authService.login({ email: this.email, password: this.password });
      if (![201].includes(response.status)) {
        this.isLoading = false;
        return this.addAlertLogin('orange', response.message)
      }

      localStorage.setItem('token', response.token);
      this.isLoading = false;

      return await this.getMe()
    } catch (error: any) {
      this.isLoading = false;
      await this.apiOfflineAsyncLogin(error)
    }
  }

  async getMe() {
    try {
      const response = await this.authService.getMe();

      if (![200].includes(response.status)) {
        return this.addAlertLogin('danger', response.message)
      }

      response.data.users_environments.environments.forEach((item: any) => {
        if (item.uuid == response.data.environment.uuid && item.favorite_corporation) {
          if (response.data.users_corporations.ids.includes(item.favorite_corporation.uuid)) {
            delete item.favorite_corporation.children
            response.data.corporation = item.favorite_corporation
          } else {
            delete item.favorite_corporation
            response.data.corporation = response.data.users_corporations.corporations[0]
          }
        }
      });

      if (response.data.corporation.url) {
        localStorage.setItem('image_corporation', response.data.corporation.url)
      }
      delete response.data.corporation.url

      LoginComponent.properties = {
        ...response.data,
        config: { is_login: true }
      }
      await this.authService.setCookie(LoginComponent.properties)

      // await this.userService.setUserMe(LoginComponent.properties)
      if (response.data.language) {
        localStorage.setItem('language', to64encode(response.data.language))
      }
      return await this.router.navigate(['/']);
    } catch (error: any) {
      if (error.error) {
        this.addAlertLogin('danger', error.error.message)
      }
      await apiOfflineAsync(error)
    }
  }

  async navigate(route: string) {
    await this.router.navigate([route]);
  }

  async sendResetPassword() {
    this.isLoading = true;
    try {
      const response: any = await this.authService.sendResetPassword(this.emailConfirm)
      if (response && response.status && [200].includes(response.status)) {
        this.addAlertLogin('success', response.message)
        setTimeout(() => {
          this.email = ''
          this.showConfirm = false
        }, 3000)
      }
    } catch (error: any) {
      await this.apiOfflineAsyncLogin(error)
    }
    this.isLoading = false;
  }

  addAlertLogin(type: string = '', text: string = '') {
    const someAlert = this.queueAlert.find((alert: { text: string }) => alert.text === text);

    if (someAlert) {
      this.removeAlertLogin(someAlert.id)
      clearTimeout(this.queueTime[someAlert.id]);
    }

    this.queueAlert.push({ id: this.queueAlert.length, type, text })
    this.queueTime[this.queueAlert.length] = setTimeout(() => {
      this.removeAlertLogin(this.queueAlert.length)
    }, 3000)
  }

  removeAlertLogin(id: number, event?: Event) {
    this.queueAlert.splice(this.queueAlert.findIndex((item: { id: number; }) => item.id == id), 1)
  }

  async apiOfflineAsyncLogin(error: any) {
    if (!error.status) {
      return
    }

    let cookie = await localStorageToJsonAsync(true)

    if (error.status == 401) {
      return navigate('/login');
    }

    this.addAlertLogin(
      error.status == 0 ? 'danger' : 'orange',
      error.status == 0 ? getLanguage('errors/system/out', cookie['language']['lang']) : error?.error?.message
    )
  }
}