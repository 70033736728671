<div class="all-content">
    <div class="content-dimension">
        <div class="no-data" *ngIf="!isData()">
          <div class="circle">
            <i class="fas fa-exclamation"></i>
          </div>
          {{ getLanguages('errors/noData') }}
        </div>

        <div class="table-attachment" *ngIf="isData()">
            <table *ngIf="getDisplayedRows().length !== 0"  mat-table [dataSource]="getDisplayedRows()" class="table">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef class="td-80">
                        <span> {{ getLanguages('attachment/name') }} </span>
                    </th>
                    <td mat-cell *matCellDef="let row" class="td-80">
                        <span class="pointer" (click)="selectRule(row)"> {{ row.name }} </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="td-20">
                        <span> {{ getLanguages('attachment/action') }} </span>
                    </th>
                    <td mat-cell *matCellDef="let row" class="td-20">
                        <button (click)="deleteRule(row.uuid)" [disabled]="!parameters.isEdit">
                            <i class="fas fa-trash" [ngClass]="!parameters.isEdit ? 'disabled-icon' : 'icon'"></i>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4"></td>
                </tr>
            </table>
        </div>
        <div class="div-paginator" *ngIf="isData()">
          <div class="paginator">
            <span>
              {{ getLanguages('businessSolution/modal/strategy/itensPage') }}
            </span>
            <button [matMenuTriggerFor]="menu" class="itens-per-page">
              {{ getItemsPerPage() }}
            </button>
            <mat-menu #menu="matMenu" >
              <button mat-menu-item (click)="choseItemsPage(5)">5</button>
              <button mat-menu-item (click)="choseItemsPage(10)">10</button>
            </mat-menu>
            <span>
              {{ getLanguages('businessSolution/modal/strategy/totalPages') }}
            </span>
            <span class="totalPages">
              {{ getTotalPages()}}
            </span>
            <button
              [ngClass]="getCurrentPages() === 1 ? 'disabled-arrow' : 'arrow'"
              [disabled]="getCurrentPages() === 1"
              (click)="onPageChange(getCurrentPages() - 1, 'strategy')">
              <i class="fa-solid fa-angle-left"></i>
            </button>
            <input type="number" [ngModel]="getCurrentPages()"  (blur)="checkValue()">
            <button
              [ngClass]="getCurrentPages() == getTotalPages() ? 'disabled-arrow' : 'arrow'"
              [disabled]="getCurrentPages() == getTotalPages()"
              (click)="onPageChange(getCurrentPages() + 1, 'strategy')">
              <i class="fa-solid fa-angle-right"></i>
            </button>
          </div>
        </div>
    </div>

    <div class="row row-end">
        <div class="button-upload" (click)="newRule()" [ngClass]="!parameters.isEdit ? 'disabled' : 'pointer'">
            {{ getLanguages('businessSolution/modal/rules/new_rule') }}
        </div>
        <div class="button-upload" (click)="saveRule()" [ngClass]="!parameters.isEdit ? 'disabled' : 'pointer'">
            {{ getLanguages('businessSolution/modal/rules/save_rule') }}
        </div>
    </div>

    <div class="row">
        <div class="item item-2">
          <span> {{ getLanguages('businessSolution/modal/rules/name') }} </span>
          <input [disabled]="!parameters.isEdit" type="text"  [(ngModel)]="currentRule.name">
        </div>

        <div class="item item-2"> 
          <span>{{ getLanguages('businessSolution/modal/rules/type') }}</span>
          <button class="select-nav" [matMenuTriggerFor]="select" [disabled]="!parameters.isEdit" [ngClass]="!parameters.isEdit ? 'disabled no-pointer' : '' ">
            <span>
                {{ currentRule?.type ? currentRule.type : getLanguages('search')  }}
            </span>
            <i class="fas fa-caret-down" *ngIf="parameters.isEdit"></i>
          </button>
          <mat-menu #select="matMenu">
            <div class="menu">
              <div class="input" (click)="$event.stopPropagation()" >
              <input type="text" [(ngModel)]="searchType" placeholder="{{ getLanguages('search') }}">
              <i class="fa-solid fa-magnifying-glass"></i>
              </div>
              <div class="scroll">
              <ng-container *ngFor="let type of typeArray">
                  <ng-container *ngIf="type?.name?.includes(searchType.toLowerCase())">
                  <div class="item-menu" (click)="choseType(currentRule, type)">
                      <span [ngClass]="{'valid-span' : type.name == (currentRule.type)}">
                        {{ type.name }}
                      </span>
                  </div>
                  </ng-container>
              </ng-container>
              </div>
          </div>
          </mat-menu> 
      </div>
    </div>

    <div class="row">
        <div class="item item-1">
            <section class="position-relative">
              <span class="button-tooltip"> {{ getLanguages('businessSolution/modal/addCode/description') }} <b class="icon-ai"> ai </b> </span>
              <div class="toggle-tooltip margin-left-tooltip">
                <div class="item-op">
                  {{ getLanguages('ai/info') }}
                </div>
              </div>
            </section>
            <textarea [disabled]="!parameters.isEdit" [(ngModel)]="currentRule.description"></textarea>
        </div>
    </div>
</div>
<div class="background-modals" *ngIf="createType">
  <div class="modal-create-type">
    <div class="modal-create-top">
      <div class="modal-create-toggle"></div>

      <div class="div-icon-button">
        <div class="save" (click)="setCorporationTypeProcedure()">
          {{ getLanguages('save')}}
        </div>
        <i class="fas fa-xmark" (click)="createType = false"></i>
      </div>
    </div>

    <div class="modal-create-row">
      <div class="modal-create-title">
        {{ getLanguages('businessSolution/modal/rules/titleNewType') }}
      </div>
    </div>

    <div class="modal-create-row">
      <div class="modal-create-item">
        <span> {{ getLanguages('businessSolution/modal/rules/titleNewType') }}</span>
        <input type="text" [(ngModel)]="newType.name">
      </div>
    </div>
  </div>
</div>

