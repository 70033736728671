<div class="all-content">
    <div class="top">
        {{ getLanguages('log') }}
    </div>
    <div class="row">
        <div class="div-search">
            <div class="search">
                <input type="text" [(ngModel)]="searchText">
            </div>
            <button (click)="filterData()">
                {{ getLanguages('search') }}
            </button>
        </div>

        <div class="roll">
            <div class="arrow"  *ngIf="filterArray.length > 0">
                <i class="fas fa-chevron-left" (click)="scrollLeft()"></i>
            </div>
            <div class="itens"  #scrollContainer>
                <div class="item" *ngFor="let filter of filterArray; let i = index">
                    <span> {{ getLanguages('slugs/'+ filter.type) }} {{ filter.value ? ':' : '' }} {{ filter.value }}</span>
                    <i class="fas fa-xmark" (click)="removeFilter(filter.type)"></i>
                </div>
            </div>
            <div class="arrow"  *ngIf="filterArray.length > 0">
                <i class="fas fa-chevron-right" (click)="scrollRight()"></i>
            </div>
            <div class="arrow">
                <i class="fas fa-sliders" (click)="toggle()"></i>
            </div>
            <div class="toggle"></div>
        </div>
    </div>

    <div class="div-filter shadow" [ngClass]="openFilter ? 'filter-open' : 'filter-close'" >
        <div class="close">
          <i class="fas fa-xmark" (click)="toggle()"></i>
        </div>

        <div class="div-buttons-clear-filter">
            <div class="clean pointer" (click)="clearFilter()">
                <span>
                    {{ getLanguages('clear') }}
                </span>
            </div>
            <div class="save pointer" (click)="filterData()">
                {{ getLanguages('filter') }}
            </div>
        </div>

        <div class="item" *ngFor="let option of optionArray; let i = index">
            <span matTooltip="{{getMatTollTip(i)}}" *ngIf="validPermission(option)">
                {{ getNames(option.name) }}
                <i class="fas" [ngClass]="getActive(option) ? 'fa-minus' : 'fa-plus' "  (click)="toggleOption(i)"></i>
            </span>

            <ng-container *ngIf="filter[option.slug] && validPermission(option)">
                <button class="select" [matMenuTriggerFor]="select" [disabled]="!getActive(option)" [ngClass]="!getActive(option) ? 'select-disabled' : '' ">
                    <span>
                        <ng-container *ngIf="filter[option.slug].selectedNames.length > 0; else searchBlock">
                            <ng-container *ngFor="let name of filter[option.slug].selectedNames; let last = last">
                                {{ name }}{{ !last ? ', ' : '' }}
                            </ng-container>
                        </ng-container>
                        <ng-template #searchBlock>
                            {{getLanguages('search')}}
                        </ng-template>
                    </span>
                    <i class="fas fa-caret-down"></i>
                </button>
            </ng-container>

            <mat-menu #select="matMenu">
                <div class="menu" *ngIf="filter[option.slug]">
                  <div class="input" (click)="$event.stopPropagation()" >
                    <input type="text" [(ngModel)]="filter[option.slug].search" placeholder="{{getLanguages('search')}}" (input)="filterByArray(option.slug)">
                    <i class="fa-solid fa-magnifying-glass"></i>
                  </div>
                  <div class="scroll">
                    <ng-container *ngFor="let item of filter[option.slug].filteredArray">
                        <div
                        (click)="toggleSelectByArray(item, option.slug, $event)"
                        [ngClass]="item.click ? 'clicked-instance' : '' "
                        class="item-menu"
                        *ngIf="!item.info"
                        >
                            <input checked type="checkbox" id="checkbox" [(ngModel)]="item.click" [ngClass]="{'valid-check' : item.click}">
                            <span [ngClass]="{'valid-span' : item.click}"> {{ ['screen'].includes(option.slug) ? getNames(item.name) : item.name }} </span>
                        </div>
                        <ng-container *ngIf="item.info">
                            <span class="title-parent">  {{ item.name }} </span>
                            <ng-container *ngFor="let child of item.info">
                                <div
                                    (click)="toggleSelectByArray(child, 'corporation', $event)"
                                    [ngClass]="child.click ? 'clicked-instance' : '' "
                                    class="item-menu"
                                    *ngIf="!child.info"
                                >
                                <input checked type="checkbox" id="checkbox" [(ngModel)]="child.click" [ngClass]="{'valid-check' : child.click}">
                                <span [ngClass]="{'valid-span' : child.click}"> {{ child.name }} </span>
                                </div>
                                <ng-container *ngIf="child.info">
                                    <span class="title-child">  {{ child.name }} </span>
                                        <ng-container *ngFor="let profile of child.info">
                                                <div
                                                (click)="toggleSelectByArray(profile, 'profile', $event, child.name)"
                                                [ngClass]="profile.click ? 'clicked-instance' : '' "
                                                class="item-menu item-profile"
                                                >
                                                <input checked type="checkbox" id="checkbox" [(ngModel)]="profile.click" [ngClass]="{'valid-check' : profile.click}">
                                                <span [ngClass]="{'valid-span' : profile.click}"> {{ profile.name }} </span>
                                                </div>
                                        </ng-container>
                                </ng-container>

                            </ng-container>
                        </ng-container>
                    </ng-container>
                  </div>

                </div>
            </mat-menu>
        </div>

        <div class="interval">
            <span class="title">
                {{getLanguages('log/interval')}}
                <i class="fas pointer" [ngClass]="interval.active ? 'fa-minus' : 'fa-plus' "  (click)="interval.active = !interval.active"></i>
            </span>

            <div class="content">
               <div class="item">
                    <div class="select" [ngClass]="!interval.active ? 'select-disabled' : '' ">
                        <mat-date-range-input class="text" [formGroup]="range" [rangePicker]="picker" [disabled]="!interval.active">
                            <input matStartDate formControlName="start">
                            <input matEndDate formControlName="end">
                        </mat-date-range-input>
                        <div class="picker">
                            <mat-datepicker-toggle matIconSuffix [for]="picker" class="icon" [disabled]="!interval.active"></mat-datepicker-toggle>
                        </div>
                    </div>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </div>
            </div>
        </div>
    </div>

    <div class="div-table" *ngIf="getSystemPermission('activities', 'view')">
        <div class="table">
            <table mat-table [dataSource]="getDisplayedRows()" *ngIf="!isLoading && !noData">
                <ng-container matColumnDef="user">
                    <th mat-header-cell *matHeaderCellDef> {{ getLanguages('activities/user') }} </th>
                    <td mat-cell *matCellDef="let row"> {{ row.user.name }} </td>
                </ng-container>
                <ng-container matColumnDef="profile">
                    <th mat-header-cell *matHeaderCellDef> {{ getLanguages('activities/profile') }} </th>
                    <td mat-cell *matCellDef="let row"> {{ row.profile.name }} </td>
                </ng-container>
                <ng-container matColumnDef="screen">
                    <th mat-header-cell *matHeaderCellDef> {{ getLanguages('activities/screen') }} </th>
                    <td mat-cell *matCellDef="let row"> {{ getNames(row.screen.name) }} </td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef> {{ getLanguages('activities/action') }} </th>
                    <td mat-cell *matCellDef="let row"> {{ getNames(row.action.name) }} </td>
                </ng-container>
                <ng-container matColumnDef="environment">
                    <th mat-header-cell *matHeaderCellDef> {{ getLanguages('activities/environment') }} </th>
                    <td mat-cell *matCellDef="let row"> {{ row.environment.name }} </td>
                </ng-container>
                <ng-container matColumnDef="corporation">
                    <th mat-header-cell *matHeaderCellDef> {{ getLanguages('activities/corporation') }} </th>
                    <td mat-cell *matCellDef="let row"> {{ row.corporation.name }} </td>
                </ng-container>
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef> {{ getLanguages('activities/date') }} </th>
                    <td mat-cell *matCellDef="let row"> {{ row.datetime.date }} </td>
                </ng-container>
                <ng-container matColumnDef="time">
                    <th mat-header-cell *matHeaderCellDef> {{ getLanguages('activities/time') }} </th>
                    <td mat-cell *matCellDef="let row"> {{ row.datetime.time }} </td>
                </ng-container>
                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef> {{ getLanguages('activities/description') }} </th>
                    <td mat-cell *matCellDef="let row"> {{ getNames(row.description) }} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4"></td>
                </tr>
            </table>
            <div class="loading" *ngIf="isLoading">
                <section>
                    <div class="loading-spinner"></div>
                    <span class="span">
                        {{ getLanguages('loading') }}
                    </span>
                </section>
            </div>

            <div class="noData" *ngIf="noData">
                <div class="icon">
                    <i class="fa-solid fa-exclamation"></i>
                </div>
                <span>
                    {{ getLanguages('errors/noData')}}
                </span>
            </div>

        </div>


        <div class="div-paginator" *ngIf="!noData">
            <div class="paginator">
                <span>
                    {{ getLanguages('businessSolution/modal/strategy/itensPage') }}
                </span>
                <button [matMenuTriggerFor]="menu" class="itens-per-page">
                    {{ itemsPerPage }}
                </button>
                <mat-menu #menu="matMenu" >
                    <button mat-menu-item (click)="choseItemsPage(10)">10</button>
                    <button mat-menu-item (click)="choseItemsPage(20)">20</button>
                    <button mat-menu-item (click)="choseItemsPage(30)">30</button>
                </mat-menu>
                <span>
                    {{ getLanguages('businessSolution/modal/strategy/totalPages') }}
                </span>
                <span class="totalPages" >
                    {{ getTotalPages()}}
                </span>
                <button [ngClass]="currentPage === 1 ? 'disabled-arrow' : 'arrow' " [disabled]="currentPage === 1 || isLoading" (click)="onPageChange(currentPage - 1)">
                    <i class="fa-solid fa-angle-left"></i>
                </button>
                <input type="number" [(ngModel)]="currentPageReq" (keydown)="checkValue($event)" disabled>
                <button [ngClass]="currentPage == getTotalPages() ? 'disabled-arrow' : 'arrow' "  [disabled]="currentPage == getTotalPages() || isLoading" (click)="onPageChange(currentPage + 1)">
                    <i class="fa-solid fa-angle-right"></i>
                </button>
            </div>
        </div>
    </div>

</div>
