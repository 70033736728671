import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { getLanguageCookie } from 'src/assets/language';
import { to64decode } from 'src/app/helpers/base64';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivitiesService } from 'src/app/services/activities/activities.service';
import { Subscription } from 'rxjs';
import { asyncForEach } from 'src/app/helpers/asyncForEach';
import { AdminGroup } from "../../app.module";
import { localStorageToJsonAsync } from "../../helpers/cookieToJson";
import { apiOfflineAsync } from "../../layouts/pages/pages.component";
import { sortArrayOfObjects } from 'src/app/helpers/sortArrayOfObjects';
import { systemPermissionsNew } from 'src/app/helpers/systemPermissions';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss']
})

export class ActivitiesComponent {
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  private subscription: Subscription = new Subscription();

  protected readonly AdminGroup = AdminGroup;

  isLoading = false;

  range: any

  displayedColumns: string[] = ['user', 'profile', 'screen', 'action', 'environment', 'corporation', 'date', 'time', 'description'];
  dataSource!: MatTableDataSource<any>;
  filteredDatasource!: MatTableDataSource<any>;
  currentPage = 1;
  currentPageReq = 1;
  itemsPerPage = 10;
  itemsTotal = 0

  searchText: string = '';
  openFilter = false;

  properties: any = {}

  filter: any
  filters: any
  filterArray: any = []

  optionArray: any = []

  noData = true;

  interval: any = {
    active: false
  }


  constructor(
    private activitiesService: ActivitiesService
  ) {
    // const users = Array.from({length: 20}, (_, k) => createNewUser(k + 1));

    this.dataSource = new MatTableDataSource();
    this.filteredDatasource = new MatTableDataSource();

    this.range = new FormGroup({
      start: new FormControl<Date | null>(null),
      end: new FormControl<Date | null>(null),
    });
  }

  async ngOnInit() {
    this.properties = await localStorageToJsonAsync()

    await this.initFilter()
    await this.getFilterStructure()
    await this.filterData()
  }

  getSystemPermission(screen: string, action?: string) {
    return systemPermissionsNew(screen, action)
  }

  async initFilter() {
    this.filter = {
      environment: {
        array: [],
        search: '',
        filteredArray: [],
        selectedNames: [],
        selectedArray: []
      },
      corporation: {
        array: [],
        search: '',
        filteredArray: [],
        selectedNames: [],
        selectedArray: []
      },
      profile: {
        array: [],
        search: '',
        filteredArray: [],
        selectedNames: [],
        selectedArray: []
      },
      user: {
        array: [],
        search: '',
        filteredArray: [],
        selectedNames: [],
        selectedArray: []
      },
      screen: {
        array: [],
        search: '',
        filteredArray: [],
        selectedNames: [],
        selectedArray: []
      },
      action: {
        array: [],
        search: '',
        filteredArray: [],
        selectedNames: [],
        selectedArray: []
      },
    }

    this.range = new FormGroup({
      start: new FormControl<Date | null>(null),
      end: new FormControl<Date | null>(null),
    });
    await this.getFilter()

  }

  getLanguages(file: string) {
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  getNames(name: any) {
    return name[to64decode(localStorage.getItem('language')).lang]
  }

  ngAfterViewInit() {
    this.scrollContainer.nativeElement.scrollLeft = 0;
  }


  async removeFilter(slug: string) {
    const filter = this.filterArray.findIndex((item: any) => item.type == slug)
    if (filter >= 0) {
      if (slug == 'date') {
        this.interval.active = false;
        this.filterArray.splice(filter, 1);
        await this.filterData()

        return
      }
      await asyncForEach(this.optionArray, async (item: any) => {
        if (item.slug == slug) {
          item.active = false
        }
      })
      if (slug == 'environment') {
        await this.removeFilter('corporation')
      }
      if (slug == 'corporation') {
        await this.removeFilter('profile')
      }
    } else {
      // if(slug == 'date'){
      //   this.interval.active = false;
      //   this.filterArray.splice(filter, 1);
      // }
    }

    await this.filterData()
  }

  scrollLeft() {
    this.scrollContainer.nativeElement.scrollLeft -= 50;
  }

  scrollRight() {
    this.scrollContainer.nativeElement.scrollLeft += 50;
  }

  toggle() {
    this.openFilter = !this.openFilter
  }

  toggleOption(i: number) {
    if (this.optionArray[i].active) {
      this.optionArray[i].active = false;
      return
    }
    this.optionArray[i].active = true;
  }

  choseItemsPage(number: number) {
    this.itemsPerPage = number;
    this.filterData()
  }

  onPageChange(newPage: number) {
    this.currentPage = newPage;
    this.currentPageReq = this.currentPage;
    this.filterData()
  }

  getDisplayedRows(): any[] {
    // const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    // const endIndex = startIndex + this.itemsPerPage;
    // return this.filteredDatasource.data.slice(startIndex, endIndex);
    return this.filteredDatasource.data
  }

  getTotalPages() {
    return Math.ceil(this.itemsTotal / this.itemsPerPage);
  }

  async checkValue(event: any) {
    if (event.key === "Enter") {
      if (this.currentPageReq > this.getTotalPages()) {
        this.currentPageReq = this.getTotalPages()
        this.currentPage = this.currentPageReq
        return
      }

      if (this.currentPageReq < 1) {
        this.currentPageReq = 1
        this.currentPage = this.currentPageReq
        return
      }

      this.currentPage = this.currentPageReq
    }
  }


  getMatTollTip(i: number): string {
    let text: string = '';

    if (i == 1 &&
      (!this.optionArray[0].active ||
        this.filter.environment.selectedNames.length == 0)) {
      text = this.getLanguages('log/selectInstance')
    } else if (i == 2 &&
      (!this.optionArray[i].active ||
        this.filter.corporation.selectedNames.length == 0)) {
      text = this.getLanguages('log/selectCorporate')
    }

    return text
  }

  filterByArray(slug: string) {
    this.filter[slug].filteredArray = this.filter[slug].array.filter((item: { name: string }) =>
      item.name.toLowerCase().includes(this.filter[slug].search.toLowerCase())
    )

    this.filter[slug].filteredArray = sortArrayOfObjects(this.filter[slug].filteredArray)
  }

  async toggleSelectByArray(item: any, slug: string, event?: Event, corporation?: string) {
    if (event) {
      event.stopPropagation()
    }

    if (['environment', 'corporation'].includes(slug)) {
      if (slug == 'environment') {
        this.filter.corporation.selectedNames = []
        this.filter.corporation.selectedArray = []
      }
      this.filter.profile.selectedNames = []
      this.filter.profile.selectedArray = []
    }

    if (slug == 'corporation' || slug == 'profile') {
      item.click = !item.click;

      if (item.click) {
        if (!this.filter[slug].selectedNames.includes(item.name)) {
          if (slug == 'profile') {
            this.filter[slug].selectedNames.push(`${item.name} (${corporation})`);
          } else {
            this.filter[slug].selectedNames.push(item.name);
          }
        }
        this.filter[slug].selectedArray.push(item.uuid);
      } else {
        let index = -1;
        if (slug == 'profile') {
          index = this.filter[slug].selectedNames.indexOf(`${item.name} (${corporation})`);
        } else {
          index = this.filter[slug].selectedNames.indexOf(item.name);
        }
        if (index > -1) {
          this.filter[slug].selectedNames.splice(index, 1);
          this.filter[slug].selectedArray.splice(index, 1);
        }
      }
    } else {
      const clicked = this.filter[slug].array.find((inst: any) => { return inst.uuid === item.uuid });

      if (clicked) {
        clicked.click = !clicked.click;

        if (clicked.click) {
          if (slug == 'action') {
            if (!this.filter[slug].selectedNames.includes(clicked.name)) {
              this.filter[slug].selectedNames.push(clicked.name);
            }
            this.filter[slug].selectedArray.push(clicked.action);
          } else {
            if (slug == 'screen') {
              if (!this.filter[slug].selectedNames.includes(this.getNames(clicked.name))) {
                this.filter[slug].selectedNames.push(this.getNames(clicked.name));
              }
            } else {
              if (!this.filter[slug].selectedNames.includes(clicked.name)) {
                this.filter[slug].selectedNames.push(clicked.name);
              }
            }
            this.filter[slug].selectedArray.push(clicked.uuid);
          }
        } else {
          if (slug == 'action') {
            const indexToRemove = this.filter[slug].selectedNames.indexOf(clicked.name);
            this.filter[slug].selectedNames.splice(indexToRemove, 1);
            this.filter[slug].selectedArray.splice(indexToRemove, 1);
          } else {
            if (slug == 'screen') {
              const indexToRemove = this.filter[slug].selectedNames.indexOf(clicked.name);
              this.filter[slug].selectedNames.splice(indexToRemove, 1);
            } else {
              const index = this.filter[slug].selectedNames.indexOf(clicked.name);
              if (index > -1) {
                this.filter[slug].selectedNames.splice(index, 1);
                this.filter[slug].selectedArray.splice(index, 1);
              }
            }
          }

        }
      }
    }

    await this.getFilter()
  }

  getActive(option: any) {
    // if(option.slug == 'corporation' && this.properties.me.is_admin == AdminGroup.MASTER){
    if (option.slug == 'corporation' && this.properties.me.is_admin_master) {
      if (this.optionArray[0].active && this.filter.environment.selectedNames.length > 0 && option.active) {
        option.active = true
      } else {
        option.active = false
      }
    }

    // if(option.slug == 'profile' && this.properties.me.is_admin != AdminGroup.OTHERS){
    if (option.slug == 'profile' && !this.properties.me.is_admin_master) {
      if (this.optionArray[1].active && this.filter.corporation.selectedNames.length > 0 && option.active) {
        option.active = true
      } else {
        option.active = false
      }
    }

    return option.active
  }

  async getFilter(slug: any = null) {
    const formatData = 'DD/MM/YYYY';
    let start: any | null = this.range.get('start')?.value;
    let end: any | null = this.range.get('end')?.value;

    let data: any = {}
    data.environment = []
    data.environment.push(this.properties.environment.uuid)

    // if (this.properties.me.is_admin == AdminGroup.MASTER) {
    if (this.properties.me.is_admin_master) {
      data.environment = this.filter.environment.selectedArray
    }

    data.corporation = []
    data.corporation.push(this.properties.corporation.uuid)
    // if (this.properties.me.is_admin != AdminGroup.OTHERS) {
    if (!this.properties.me.is_admin_master) {
      data.corporation = this.filter.corporation.selectedArray
    }

    data.profile = this.filter.profile.selectedArray
    data.user = this.filter.user.selectedArray
    data.screen = this.filter.screen.selectedArray
    data.action = this.filter.action.selectedArray
    data.data = {
      start: start && this.interval.active ? start.format(formatData) : null,
      end: end && this.interval.active ? end.format(formatData) : null,
    }

    try {
      const response: any = await this.activitiesService.getFilter(data).toPromise()

      if (response.status == 200 && response && response.data) {

        await asyncForEach(Object.keys(this.filter), async (item: any) => {
          if (this.filter[item].selectedArray.length > 0 || slug == item) {
            return
          }
          this.filter[item].array = response.data[`${item}s`]
          this.filter[item].filteredArray = sortArrayOfObjects(this.filter[item].array)
        })
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }


  async getFilterStructure() {
    try {
      const response: any = await this.activitiesService.getFilterStructure().toPromise()
      if (response.status == 200 && response && response.data) {
        this.optionArray = response.data
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  async filterData() {
    const formatData = 'DD/MM/YYYY';
    let start: any | null = this.range.get('start')?.value;
    let end: any | null = this.range.get('end')?.value;

    this.filters = {}
    this.filters.environment = []
    this.filters.environment.push(this.properties.environment.uuid)
    // if (this.properties.me.is_admin == AdminGroup.MASTER) {
    if (this.properties.me.is_admin_master) {
      this.filters.environment =
        this.filter.environment.selectedArray &&
          this.optionArray.find((item: any) => { return item.slug == 'environment' }).active
          ? this.filter.environment.selectedArray : null
    }

    this.filters.corporation = []
    this.filters.corporation.push(this.properties.corporation.uuid)
    // if (this.properties.me.is_admin != AdminGroup.OTHERS) {
    if (!this.properties.me.is_admin_master) {
      this.filters.corporation =
        this.filter.corporation.selectedArray &&
          this.optionArray.find((item: any) => { return item.slug == 'corporation' }).active
          ? this.filter.corporation.selectedArray : null
    }
    this.filters.profile = this.filter.profile.selectedArray && this.optionArray.find((item: any) => { return item.slug == 'profile' }).active ? this.filter.profile.selectedArray : null
    this.filters.user = this.filter.user.selectedArray && this.optionArray.find((item: any) => { return item.slug == 'user' }).active ? this.filter.user.selectedArray : null
    this.filters.screen = this.filter.screen.selectedArray && this.optionArray.find((item: any) => { return item.slug == 'screen' }).active ? this.filter.screen.selectedArray : null
    this.filters.action = this.filter.action.selectedArray && this.optionArray.find((item: any) => { return item.slug == 'action' }).active ? this.filter.action.selectedArray : null
    this.filters.date = {
      start: start && this.interval.active ? start.format(formatData) : null,
      end: end && this.interval.active ? end.format(formatData) : null
    }
    this.filters.total = this.itemsPerPage
    this.filters.current = this.currentPage - 1
    this.filters.text = this.searchText

    await this.initFilterView()

    this.openFilter = false;
    await this.getActivities()
  }

  validPermission(option: any) {
    let result = (
      // this.properties.me.is_admin == AdminGroup.MASTER ||
      // (this.properties.me.is_admin == AdminGroup.ENVIRONMENT && !['environment'].includes(option.slug)) ||
      // (this.properties.me.is_admin == AdminGroup.OTHERS && !['environment', 'corporation'].includes(option.slug))
      this.properties.me.is_admin_master ||
      (!this.properties.me.is_admin_master && !['environment'].includes(option.slug)) ||
      (!this.properties.me.is_admin_master && !['environment', 'corporation'].includes(option.slug))
    )
    // console.log(result, option.slug)
    return result
  }

  async initFilterView() {
    this.filterArray = []
    Object.keys(this.filter).forEach((key: any) => {
      let option = this.optionArray.find((item: any) => item.slug == key)
      if (option.active && this.filter[key].selectedArray.length > 0) {
        this.filterArray.push({ type: option.slug, value: this.filter[key].selectedArray.length > 1 ? null : this.filter[key].selectedNames[0] })
      }
    });

    if (this.range.get('end').value && this.range.get('start').value && this.filters.date.start && this.filters.date.end) {
      this.filterArray.push({ type: 'date', value: this.filters.date.start + (this.filters.date.end ? ' - ' + this.filters.date.end : '') })
    }

  }

  clearFilter() {
    this.optionArray.forEach((item: any) => {
      item.active = false
    });
    this.interval.active = false
    this.initFilter()
  }

  async getActivities() {
    this.isLoading = true;
    this.subscription.add(
      this.activitiesService.getActivities(this.filters)
        .subscribe(
          (response: any) => {
            if (response && response.data) {
              this.dataSource = new MatTableDataSource(response.data.result);
              this.filteredDatasource = new MatTableDataSource(response.data.result);
              this.itemsTotal = response.data.quantity
              this.isLoading = false;
              if (response.data.result.length == 0) {
                this.noData = true
                return
              }
              this.noData = false
            } else {
              this.isLoading = false;
              throw new Error('A resposta não contém o campo "data"');
            }
          },
          (error) => {
            console.error(error);
            this.isLoading = false;
          }
        )
    );

  }
}

// function createNewUser(id: number): any {

//   return {
//     id: id.toString(),
//     user: NAMES[Math.round(Math.random() * (NAMES.length - 1))],
//     profile: PROFILE[Math.round(Math.random() * (PROFILE.length - 1))],
//     screen: SCREENS[Math.round(Math.random() * (SCREENS.length - 1))],
//     action: ACTION[Math.round(Math.random() * (ACTION.length - 1))],
//     module: MODULES[Math.round(Math.random() * (MODULES.length - 1))],
//     environment: INSTANCES[Math.round(Math.random() * (INSTANCES.length - 1))],
//     corporation: CORPORATION[Math.round(Math.random() * (CORPORATION.length - 1))],
//     date: DATE[Math.round(Math.random() * (DATE.length - 1))],
//     time: TIME[Math.round(Math.random() * (TIME.length - 1))],
//     description: DESCRIPTION[Math.round(Math.random() * (DESCRIPTION.length - 1))]
//   };
// }

