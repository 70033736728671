import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IResponse } from '../../interfaces/IResponse';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { Observable, Subject, takeUntil } from 'rxjs';
import { apiOfflineAsync } from "../../layouts/pages/pages.component";


@Injectable({
  providedIn: 'root'
})
export class UsersService {
  API_PATH: string
  private ngUnsubscribe: Subject<void> = new Subject<void>()
  parameters: any = {}

  constructor(
    private httpClient: HttpClient,
    private router: Router,
  ) {
    this.API_PATH = environment.apiUrl;
  }

  private requestOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  private getURLSearchParams(param: any = {}) {
    let params = new URLSearchParams();

    if (param && param.environment && param.environment.slug) {
      params.set('environment', param.environment.slug);
    }

    if (param && param.corporation && param.corporation.slug) {
      params.set('corporation', param.corporation.slug);
    }

    // if (param && param.is_admin != null) {
    //   params.set('is_admin', param.is_admin);
    // }

    if (param && param.is_admin_master != null) {
      params.set('is_admin_master', param.is_admin_master);
    }

    if (param && param.owner) {
      params.set('owner', param.owner);
    }

    return params.toString();
  }

  async navigate(route: string) {
    return await this.router.navigate([route]);
  }

  async redirectError(error: any) {
    if (error.status == 401) {
      return await this.navigate('/login');
    }
    return error.error;
  }

  async getUsersSelectedEnvironment(selectedEnvironments: any) {
    return await this.httpClient.get<IResponse>(
      `${this.API_PATH}users/selected/environment?environment=${JSON.stringify(selectedEnvironments)}`
    ).toPromise();
  }

  getUsers(status: any = null, params: any = {}): Observable<IResponse> {
    let url = `${this.API_PATH}users?status=${status}&${this.getURLSearchParams(params)}`

    this.ngUnsubscribe.next()
    return this.httpClient.get<IResponse>(url).pipe(takeUntil(this.ngUnsubscribe));
  }

  async getUsersQuantity(params: any = {}) {
    return await
      this.httpClient.get<IResponse>(`${this.API_PATH}users/quantity?${this.getURLSearchParams(params)}`).toPromise()
  }

  async getUser(id: any, remember_password: boolean = false) {
    return await this.httpClient.get<IResponse>(`${this.API_PATH}users/${id}${remember_password ? `?remember_password=true` : ``}`).toPromise();
  }

  async getUsersAllSystem(environment_uuid: string) {
    return await this.httpClient
      .get<IResponse>(`${this.API_PATH}users/users-all-system`, {
        params: { environment_uuid }
      })
      .toPromise();
  }

  async setUserMe (properties: any = {}) {
    return await this.httpClient.post<IResponse>(`${environment.apiUrl}me`, properties, this.requestOptions).toPromise();
  }

  async setUsers(parameters: any = {}, body: any, isNew: boolean = true) {
    try {
      let url = `${this.API_PATH}users`
      if(isNew){
        return await this.httpClient.post<IResponse>(`${url}?${this.getURLSearchParams(parameters)}`, body, this.requestOptions).toPromise();
      }
      url += `/${body.user.uuid}`
      return await this.httpClient.put<IResponse>(`${url}?${this.getURLSearchParams(parameters)}`, body, this.requestOptions).toPromise();
    } catch (error: any) {
      return await apiOfflineAsync(error)
    }
  }

  async setUsersCorporationProfile(body: any, status: string) {
    let header: any = []
    header['Content-Type'] = 'application/json';
    let requestOptions = { headers: new HttpHeaders(header) };
    try {
      return await this.httpClient.put<IResponse>(
        `${this.API_PATH}users/corporations/update/${status}`,
        body,
        requestOptions
      ).toPromise();
    } catch (error: any) {
      return await this.redirectError(error);
    }
  }


  async setUserStatus(body: any, status: string) {
    let header: any = []
    header['Content-Type'] = 'application/json';

    let requestOptions = { headers: new HttpHeaders(header) };

    try {
      return await this.httpClient.put<IResponse>(
        `${this.API_PATH}users/status/${status}`,
        body,
        requestOptions
      ).toPromise();
    } catch (error: any) {
      return await apiOfflineAsync(error)
    }
  }

  async setUserProfile(body: any, profile: any, slug?: any) {
    let header: any = []
    header['Content-Type'] = 'application/json';

    let requestOptions = { headers: new HttpHeaders(header) };

    let query = slug == null ? '' : `?corporation=${slug}`

    try {
      return await this.httpClient.put<IResponse>(
        `${this.API_PATH}users/profiles/${profile.uuid}${query}`,
        body,
        requestOptions
      ).toPromise();
    } catch (error: any) {
      return await apiOfflineAsync(error)
    }
  }

  async setUserCorporationApprover(user: any){
    try {
      return await this.httpClient.put<IResponse>(
        `${this.API_PATH}users-corporations/${user.uuid}`,
        user,
        this.requestOptions
      ).toPromise();
    } catch (error: any) {
      return await apiOfflineAsync(error)
    }
  }

}
