import { Component, Input } from '@angular/core';
import { to64decode } from 'src/app/helpers/base64';
import { getLanguageCookie } from 'src/assets/language';
import { MatTableDataSource } from '@angular/material/table';
import { v4 } from "uuid";
import {addAlert} from "../../../../layouts/pages/pages.component";
import { sortArrayOfObjects } from 'src/app/helpers/sortArrayOfObjects';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss']
})
export class LinkComponent {
  @Input() parameters: any = {}
  @Input() dimensions: any = {}

  selectedFlag: any = {
    lang: 'en',
    file: 'us.svg'
  };

  dataSource!: MatTableDataSource<any>;
  displayedColumns: string[] = ['name', 'organization', 'type', 'action'];
  currentPage = 1;
  currentPageReq = 1;
  itemsPerPage = 10;

  is_new_link: boolean = true;
  objToExtract: string = ''
  currentLink: any = {}
  searchType: string = ''
  typeArray: any = []

  constructor(
  ) {
    this.dataSource = new MatTableDataSource();
  }

  async ngOnInit(){
    await this.getOptionsArray()
    this.dataSource.data = this.parameters.item.links ?? []
  }

  getNames(slug: any){
    let name: any
    this.typeArray.forEach((item: any) => {
      if(item.slug == slug){
        name = item.name
      }
    });
    return name[to64decode(localStorage.getItem('language')).lang]
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  isData(){
    return this.dataSource.data.length > 0
  }

  getDisplayedRows(): any[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.dataSource.data.slice(startIndex, endIndex);
  }

  getColumns(){
    return this.displayedColumns.slice(1)
  }

  getItemsPerPage(){
    return this.itemsPerPage
  }

  getCurrentPages(): number{
    return this.currentPage
  }

  choseItemsPage(number: number){
    this.itemsPerPage = number;
    this.getDisplayedRows()
  }

  onPageChange(newPage: number, text: string): void {
    this.currentPage = newPage;
  }

  getTotalPages(): number {
    return Math.ceil(this.dataSource.data.length / this.itemsPerPage);
  }

  async checkValue() {
    let total = this.getTotalPages()
    if (this.currentPage > total) this.currentPage = total;
  }

  async getOptionsArray() {
    this.typeArray = sortArrayOfObjects(this.parameters.optionsArray.typeLinkArray)
  }

  async saveLink(){
    let flag = false;

    if(this.objToExtract){
      const obj = to64decode(this.objToExtract)

      if(obj?.node?.uuid == this.parameters?.item?.uuid){
        addAlert('orange', this.getLanguages('businessSolution/modal/links/error/same'))
        return
      }

      this.dataSource.data.find((item: any) => {
        if(item?.node?.uuid == obj?.node?.uuid){
          addAlert('orange', this.getLanguages('businessSolution/modal/links/error/exists'))
          flag = true
          return
        }
      })

      if(flag){
        return
      }

      if(!obj?.node?.uuid || !obj?.node?.label || !obj?.corporation?.uuid || !obj?.corporation?.name){
        addAlert('orange', this.getLanguages('businessSolution/modal/links/error/format'))
        return
      }

      this.currentLink = {...obj}
      this.objToExtract = ''
    }else{
      addAlert('orange', this.getLanguages('businessSolution/modal/links/error/empty'))
      return
    }

    if(this.currentLink?.node?.uuid){
      this.dataSource.data.push(this.currentLink)
    }
  }

  async deleteLink(uuid: string) {
    this.dataSource.data = this.dataSource.data.filter(item => item.node.uuid !== uuid);
    this.parameters.item.links = this.dataSource.data
  }

  choseType(row: any, type: any){
    row.type = type.slug
  }
}
