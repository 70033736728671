import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/app/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AttachmentsService {
  apiUrl: any = '';

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl;
  }

  downloadAttachment(url: string): Observable<Blob> {
    return this.http.get(`${this.apiUrl}google-cloud/download?url=${url}`, {
      responseType: 'blob'
    });
  }
}