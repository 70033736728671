import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { to64decode } from "../../helpers/base64";
import { IResponse } from "../../interfaces/IResponse";
import { Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CorporationDimensionsService {
  API_PATH: string
  private ngUnsubscribe: Subject<void> = new Subject<void>()

  constructor(
    private httpClient: HttpClient,
    private router: Router,
  ) {
    this.API_PATH = environment.apiUrl;
  }

  private requestOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
      // outros cabeçalhos personalizados aqui, se necessário
    })
  };

  private getURLSearchParams({ environment, corporation, dimension }: any) {
    let params = new URLSearchParams();

    if (!environment) {
      environment = to64decode(localStorage.getItem('environment'))
    }

    if (!corporation) {
      corporation = to64decode(localStorage.getItem('corporation'))
    }

    if (environment && environment.slug) {
      params.set('environment', environment.slug);
    }

    if (corporation && corporation.slug) {
      params.set('corporation', corporation.slug);
    }

    if (dimension) {
      params.set('dimension', dimension);
    }
    return params.toString();
  }

  async navigate(route: string) {
    return await this.router.navigate([route]);
  }

  async redirectError(error: any) {
    if (error.status == 401) {
      return await this.navigate('/login');
    }
    return error.error;
  }

  async getDimensions(params: any = {}) {
    return await this.httpClient.get<IResponse>(
      `${environment.apiUrl}corporations-dimensions?${this.getURLSearchParams(params)}`
    ).toPromise();
  }

  async setDimensions(body: any, params: any = {}) {
    return await this.httpClient.post<IResponse>(
      `${this.API_PATH}corporations-dimensions?${this.getURLSearchParams(params)}`,
      body,
      this.requestOptions
    ).toPromise()
  }
}
