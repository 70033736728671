export const filterArraySubstringTree = (nodes: any[], substring: string = ''): any[] => {
    let result: string[] = [];
    if(substring == ''){
        return result
    }
    const cleanSubstring = substring.replace(/\s+/g, '').toLowerCase();
  
    function traverse(node: any) {
      const cleanLabel = node.label.replace(/\s+/g, '').toLowerCase();
  
      if (cleanLabel.includes(cleanSubstring)) {
        result.push(node.uuid);
      }
    
      if (node.children) {
        node.children.forEach((child: any) => traverse(child));
      }
    }
  
    nodes.forEach(node => traverse(node)); 
    return result;
  };

  