import { Component, EventEmitter, Input, Output } from '@angular/core';
import { getLanguageCookie } from 'src/assets/language';

@Component({
  selector: 'app-changes-link-users',
  templateUrl: './changes-link-users.component.html',
  styleUrls: ['./changes-link-users.component.scss']
})
export class ChangesLinkUsersComponent {
  @Output() toggleAlert: EventEmitter<void> = new EventEmitter<void>();
  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  exitModal(){
    this.toggleAlert.emit()
  }

  closeAction(){
    this.close.emit()
  }
}
