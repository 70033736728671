<div class="all-content">
    <div class="content-dimension">
        <div class="no-data" *ngIf="!isData()">

          <div class="circle">
            <i class="fas fa-exclamation"></i>
          </div>

          {{ getLanguages('errors/noData') }}
        </div>

        <div class="table-attachment" *ngIf="isData()">
            <table *ngIf="getDisplayedRows().length !== 0"  mat-table [dataSource]="getDisplayedRows()" class="table">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef class="td-30">
                        <span> {{ getLanguages('attachment/name') }} </span>
                    </th>
                    <td mat-cell *matCellDef="let row" class="td-30">
                        <span> {{ row.node?.label }} </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="organization">
                    <th mat-header-cell *matHeaderCellDef class="td-30">
                        <span> {{ getLanguages('activities/corporation') }} </span>
                    </th>
                    <td mat-cell *matCellDef="let row" class="td-30">
                        <span> {{ row.corporation?.name }} </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="type">
                  <th mat-header-cell *matHeaderCellDef class="td-30">
                  <span> {{ getLanguages('attachment/type') }} </span>
                  </th>
                  <td mat-cell *matCellDef="let row" class="td-30">
                      <button class="select-nav nav-table" [matMenuTriggerFor]="selectType" [disabled]="!parameters.isEdit">
                          <span>
                              <ng-container>
                                  {{ row.type ? getNames(row.type) : getLanguages('search') }}
                              </ng-container>
                          </span>
                          <i class="fas fa-caret-down"></i>
                      </button>
                      <mat-menu #selectType="matMenu">
                      <div class="menu">
                          <div class="input" (click)="$event.stopPropagation()" >
                          <input type="text" [(ngModel)]="searchType" placeholder="{{ getLanguages('search') }}">
                          <i class="fa-solid fa-magnifying-glass"></i>
                          </div>
                          <div class="scroll">
                          <ng-container *ngFor="let type of typeArray">
                              <ng-container *ngIf="type.slug.includes(searchType.toLowerCase())">
                              <div class="item-menu" (click)="choseType(row, type)">
                                  <span [ngClass]="{'valid-span' : type.slug == (row.type)}">
                                      {{ getNames(type.slug) }}
                                  </span>
                              </div>
                              </ng-container>
                          </ng-container>
                          </div>
                      </div>
                      </mat-menu>
                  </td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="td-10">
                        <span> {{ getLanguages('attachment/action') }} </span>
                    </th>
                    <td mat-cell *matCellDef="let row" class="td-10">
                        <button (click)="deleteLink(row?.node?.uuid)" [disabled]="!parameters.isEdit">
                            <i class="fas fa-trash" [ngClass]="!parameters.isEdit ? 'disabled-icon' : 'icon'"></i>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4"></td>
                </tr>
            </table>
        </div>
        <div class="div-paginator" *ngIf="isData()">
          <div class="paginator">
            <span>
              {{ getLanguages('businessSolution/modal/strategy/itensPage') }}
            </span>
            <button [matMenuTriggerFor]="menu" class="itens-per-page">
              {{ getItemsPerPage() }}
            </button>
            <mat-menu #menu="matMenu" >
              <button mat-menu-item (click)="choseItemsPage(5)">5</button>
              <button mat-menu-item (click)="choseItemsPage(10)">10</button>
            </mat-menu>
            <span>
              {{ getLanguages('businessSolution/modal/strategy/totalPages') }}
            </span>
            <span class="totalPages">
              {{ getTotalPages()}}
            </span>
            <button
              [ngClass]="getCurrentPages() === 1 ? 'disabled-arrow' : 'arrow'"
              [disabled]="getCurrentPages() === 1"
              (click)="onPageChange(getCurrentPages() - 1, 'strategy')">
              <i class="fa-solid fa-angle-left"></i>
            </button>
            <input type="number" [ngModel]="getCurrentPages()"  (blur)="checkValue()">
            <button
              [ngClass]="getCurrentPages() == getTotalPages() ? 'disabled-arrow' : 'arrow'"
              [disabled]="getCurrentPages() == getTotalPages()"
              (click)="onPageChange(getCurrentPages() + 1, 'strategy')">
              <i class="fa-solid fa-angle-right"></i>
            </button>
          </div>
        </div>
    </div>

    <div class="row row-end">
        <div class="button-upload" (click)="saveLink()" [ngClass]="!parameters.isEdit ? 'disabled' : 'pointer'">
            {{ getLanguages('businessSolution/modal/links/save_link') }}
        </div>
    </div>

    <div class="row">
        <div class="item td-100" *ngIf="is_new_link && parameters.isEdit">
            <span> {{ getLanguages('businessSolution/modal/links/pastHere') }} </span>
            <input type="text"  [(ngModel)]="objToExtract">
        </div>
    </div>
</div>

