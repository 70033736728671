import { asyncForEach } from "./asyncForEach";

export async function invertDate (value: string = '', type: string = 'us') {
  if (value == '') return ''

  const carac = value.indexOf('/') >= 0 ? '/' : '-'
  const newCarac = type == 'us' ? '-' : '/'

  const split = value.split(carac)
  const indexSplit = split.findIndex((item: any) => item.length == 4)

  let array: any = split
  if ((indexSplit == 2 && type == 'us') || (indexSplit == 0 && type == 'br')) {
    array = split.reverse()
  }

  let result = ''
  await asyncForEach(array, async (item: string, index: number) => {
    result += index == 0 ? `${item}` : `${newCarac}${item}`
  })
  return result
}
