import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CookieService } from 'ngx-cookie-service';
import { to64decode } from 'src/app/helpers/base64';
import { systemPermissionsNew } from 'src/app/helpers/systemPermissions';
import {addAlert, apiOfflineAsync} from 'src/app/layouts/pages/pages.component';
import { VersionsService } from 'src/app/services/versions/versions.service';
import { getLanguageCookie } from 'src/assets/language';

@Component({
  selector: 'app-versions',
  templateUrl: './versions.component.html',
  styleUrls: ['./versions.component.scss']
})
export class VersionsComponent {

  searchText: string = ''

  isLoading!: boolean;
  noData!: boolean;

  dataSource!: MatTableDataSource<any>;
  filteredDatasource!: MatTableDataSource<any>;
  displayedColumns: string[] = ['current', 'name' , 'last_change', 'actions'];

  currentPage = 1;
  currentPageReq = 1;
  itemsPerPage = 10;

  clicked: any = {}
  clickedByUser: any = {}

  objectModal: any = {}

  isShowConfirm: boolean = false;
  isShowDelete: boolean = false;
  isShowCreateNameModal = false;

  constructor(
    private cookieService: CookieService,
    private versionsService: VersionsService
    ){
      this.dataSource = new MatTableDataSource();
      this.filteredDatasource = new MatTableDataSource();
  }

  async ngOnInit(){
    await this.getVersions()
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  getNames(name: any){
    return name[to64decode(localStorage.getItem('language')).lang]
  }

  search() {
    const searchText = this.searchText.toLowerCase()
    this.filteredDatasource.data = this.dataSource.data.filter( (item: { last_change: any;}) => item.last_change.toLowerCase().includes(searchText));
  }

  getSystemPermission(screen: string, action: string){
    return systemPermissionsNew(screen, action)
  }
  
  async getVersions(event?: Event){
    // 'YYYY-MM-DD HH:MI:SS'
    this.isLoading = true;

    try {
      const response: any = await this.versionsService.getVersions().toPromise();

      if (response && response.data) {
        this.dataSource.data = response.data;
        this.filteredDatasource.data = response.data
        this.isLoading = false;

        if(this.dataSource.data.length == 0){
          this.noData = true
        }else{
          this.noData = false
        }
      } else {
        this.isLoading = false;

        throw new Error('Erro ao consultar àrvore de negócios!\nConsulte o administrador do sistema!');
      }
    } catch (error: any) {
      this.isLoading = false;

      // console.error(error);
    }

    const currentTimestampObj = this.dataSource.data.find(item => item.is_active === true);

    if (currentTimestampObj) {
      this.clicked = currentTimestampObj;
    } else {
      this.clicked = null;
    }


  }

  getDisplayedRows(): any[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.filteredDatasource.data.slice(startIndex, endIndex);
  }

  choseItemsPage(number: number){
    this.itemsPerPage = number;
  }

  onPageChange(newPage: number){
    this.currentPage = newPage;
    this.currentPageReq = this.currentPage;
  }

  getTotalPages() {
    return Math.ceil(this.filteredDatasource.data.length / this.itemsPerPage);
  }

  async checkValue(event: any) {
    if (event.key === "Enter") {
      if(this.currentPageReq > this.getTotalPages()){
        this.currentPageReq = this.getTotalPages()
        this.currentPage = this.currentPageReq
        return
      }

      if(this.currentPageReq < 1){
        this.currentPageReq = 1
        this.currentPage = this.currentPageReq
        return
      }

      this.currentPage = this.currentPageReq
    }
  }

  getRadioClass(bool: boolean){
    return bool ? 'check' : 'no-check'
  }

  choseRadio(item: any){
    if (item === this.clicked){
      return
    }

    this.clickedByUser = item
    this.isShowConfirm = true
    //preciso enviar para o back o clicked, e preciso calcular o clicked quando receber o getVersions

  }

  async choseRadioConfirm(){
    const item = this.clickedByUser

    this.filteredDatasource.data.forEach((item: any) => {
      item.is_active = false
    })

    const element = this.filteredDatasource.data.find(element => element.uuid === item.uuid)
    element.is_active = true
    this.clicked = element
    this.isShowConfirm = false

    try {
      const response: any = await this.versionsService.setVersions(item)
      if ([201, 202].includes(response.status)) {
        addAlert('success', response.message)
      } else{
        addAlert('danger', response.message)
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }

  }

  exitModal(id: number, event?: any){
    if(id === 1){
      this.isShowConfirm = false
      return
    }

    this.isShowDelete = false
  }

  delete(item: any){
    this.isShowDelete = true;
    this.objectModal = item;
  }

  async edit(item: any){
    this.objectModal = item;

    await this.toggleNameVersions()
  }

  async toggleNameVersions(){
    this.isShowCreateNameModal = !this.isShowCreateNameModal
  }

}
