import { Component, ViewChild } from '@angular/core';
import { IBusinessSolution } from 'src/app/interfaces/IBusinessSolution';
import { CorporationService } from 'src/app/services/corporation/corporation.service';
import { EventService } from 'src/app/services/event/event.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { UsersService } from 'src/app/services/users/users.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { getLanguageCookie } from 'src/assets/language';
import { Subscription } from 'rxjs';
import { systemPermissionsNew } from 'src/app/helpers/systemPermissions';
import { localStorageToJsonAsync } from "../../helpers/cookieToJson";
import { addAlert, apiOffline, apiOfflineAsync } from "../../layouts/pages/pages.component";
import { AdminGroup } from "../../app.module";

export interface User {
  id: number;
  name: string;
  lastname: string;
  email: string;
  document: string;
  profile: any;
  corporation: string;
  is_active: string;
  telephone: string;
}

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})


export class UsersComponent {
  properties: any = {}
  parameters: any = {}

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  USER_DATA: any[] = [];
  private subscription: Subscription = new Subscription();

  statusArray: any = [
    {id: 0, name: 'WAITING'},
    {id: 1, name: 'ACTIVE'},
    {id: 2, name: 'INACTIVE'},
  ]

  task: any = { color: 'accent' };

  // searchText: string = '';
  isTreeEmpty = true;

  profiles: any = [];
  selectedProfile: any;

  objectModal: any = {
    readonly: false
  };

  // filteredDataSource!: MatTableDataSource<any>;

  isShowUserCreate: boolean = false;
  isShowUserEdit: boolean = false;

  // isLoading: boolean = true;
  selectedCorporation: any = { name: 'Loading...', slug: ''};
  environment?: any;
  corporation?: any;
  corporations: any = [];

  // dataSource: any[] = [];
  selectionUsers = new SelectionModel<any>(true, []);

  quantityUsers: any = { active: 0, inactive: 0, total: 0 }

  currentRowIndex: any = 0
  currentPage: any = 1
  itemsPerPage: any = 10
  searchText: any = ''
  isLoading: any = false
  displayedColumns: any = ['Name', 'Email', 'Profile', 'Approver']
  dataSource: any
  filteredDataSource: any


  profilesEnvironment: any[] = []

  constructor(
    private eventService: EventService,
    private corporationService: CorporationService,
    private usersService: UsersService,
    private profileService: ProfileService,
  ) {
    this.dataSource = new MatTableDataSource<any>([])
    this.filteredDataSource = new MatTableDataSource<any>([]) 
  }

  async ngOnInit() {
    this.properties = await localStorageToJsonAsync()
    await this.getUsers()
    await this.getCorporations();
    await this.selectButton()
    await this.getProfiles()

 

    if (this.getSystemPermission('users', 'edit_access')) {
      this.displayedColumns.unshift('Select')
    }
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  customFilterPredicate(): (data: any, filter: string) => boolean {
    const filterFunction = function (data: any, filter: string): boolean {
      const searchTermLower = filter.toLowerCase();
      return (
        data.name.toLowerCase().includes(searchTermLower) ||
        data.email.toLowerCase().includes(searchTermLower) ||
        (data.corporation && data.corporation.name.toLowerCase().includes(searchTermLower)) ||
        data.profile.toLowerCase().includes(searchTermLower)
      );
    };
    return filterFunction;
  }

  async selectButton(selectNumber: number = 0) {
    if(selectNumber == 0){
      await this.getUsers()
      return
    }
  }

  isAllSelectedUsers() {
    const numSelected = this.selectionUsers.selected.length;
    const numRows = this.filteredDataSource.data.length;
    return numSelected === numRows;
  }

  toggleAllRowsUsers() {

    if (this.isAllSelectedUsers()) {
      this.selectionUsers.clear();
      return;
    }

    this.selectionUsers.selected.splice(this.selectionUsers.selected.indexOf(this.properties.me.uuid))

    this.selectionUsers.select(...this.filteredDataSource.data);
  }

  checkboxLabelUsers(index: number = 0, row?: User): string {
    if (!row) {
      return `${this.isAllSelectedUsers() ? 'deselect' : 'select'} all`;
    }
    return `${this.selectionUsers.isSelected(row) ? 'deselect' : 'select'} row ${index + 1}`;
  }

  async getCorporations() {
    try {
      const response: any = await this.corporationService.getCorporationTree().toPromise();
      if (response && response.data && response.data.result) {
        this.corporations = response.data.result;
      } else {
        addAlert('danger', response.message)
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  togleCreateUser(event?: Event) {
    if (this.corporations.length == 0) {
      return;
    }

    this.parameters = {
      ...this.objectModal,
    }

    this.parameters.profilesEnvironment = this.profilesEnvironment

    this.isShowUserCreate = !this.isShowUserCreate;

    if(event){
      event.stopPropagation();
    }
  }

  async togleCreateUserEdit(event?: Event, readonly: boolean = false, uuid?: string): Promise<any> {
    if(!this.getSystemPermission('users', 'view_user') && readonly){
      return
    }
    
    if(event) {
      event.stopPropagation();
    }

    let selectedIds: any[] = [];

    this.parameters = {}
    this.parameters.readonly = readonly;
    this.parameters.isEditUser = true;
    this.parameters.quantityUsers = this.selectionUsers.selected.length;
    this.parameters.isOneUser = this.selectionUsers.selected.length == 1 || uuid;
    this.parameters.user = {}
    this.parameters.corporationsprofiles = []
    this.parameters.profilesEnvironment = this.profilesEnvironment

    if (uuid || this.parameters.isOneUser) {
      await this.getUser(uuid ?? this.selectionUsers.selected[0].uuid)
      this.isShowUserEdit = !this.isShowUserEdit;
      return
    }
    if (!uuid && !this.parameters.isOneUser) {
      if(this.selectionUsers.selected.length > 0){
        this.selectionUsers.selected.forEach((user: any) => {
          selectedIds.push(user.uuid);
        });
      }

      this.parameters.users = selectedIds;
      this.isShowUserEdit = !this.isShowUserEdit;
    }
  }

  isShowEdit(): boolean {
    this.parameters.quantityUsers = this.selectionUsers.selected.length;

    return this.parameters.quantityUsers > 0
  }

  async getUsers(status: any = null) {
    await this.getUsersQuantity()
    if (this.corporations.length == 0) {
      return;
    }
    this.isLoading = true;
    this.subscription.add(
      this.usersService.getUsers(status)
        .subscribe(
          (response: any) => {
            if (response && response.data) {
              this.USER_DATA = response.data;
             
              this.dataSource = new MatTableDataSource<any>(response.data)
              this.filteredDataSource = new MatTableDataSource<any>(response.data)
              
              this.selectionUsers = new SelectionModel<any>(true, []);
            } else {
              throw new Error('Erro ao consultar usuários!\nConsulta o administrador do sistema!');
            }
          },
          (error) => {
            apiOffline(error)
          }
        )
    );
    this.isLoading = false;
  }

  async getUsersQuantity () {
    try {
      const response = await this.usersService.getUsersQuantity()

      if (response && response.data) {
        this.quantityUsers = response.data
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  async getUser(id: number) {
    this.isLoading = true;

    try {
      const response: any = await this.usersService.getUser(id);
      if (response && response.data) {
        this.isLoading = false;
        this.parameters.user = response.data;
      }
    } catch (error: any) {
      this.isLoading = false;
      await apiOfflineAsync(error)
    }
  }

  async choseDomainData(corporation: any) {

    if (corporation.access){
      await this.selectButton()
      await this.getUsers()
      await this.getUsersQuantity();
      return
    }
  }

  hasChild = (_: number, node: IBusinessSolution) => !!node.info && node.info.length > 0;

  choseItemsPage(number: number){
    return this.itemsPerPage = number;
  }

  onPageChange(newPage: number){
    return this.currentPage = newPage;
  }

  getDisplayedRows(): any[] {
    // this.searchUser()
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.filteredDataSource.data.slice(startIndex, endIndex);
  }

  getTotalPages(): number {
    return Math.ceil(this.filteredDataSource.data.length / this.itemsPerPage);
  }

  async checkValue(event: any) {
    let total = this.getTotalPages()
    if (this.currentPage > total) {
      this.currentPage = total;
    }
    return
  }

  searchUser() {
    let searchText = this.searchText.toLowerCase()
    this.filteredDataSource.data = this.dataSource.data.filter(
      (item: { id: any; name: any; telephone: any; email: any; }) =>
      (item.name && item.name.toLowerCase().includes(searchText))
      || (item.email && item.email.toLowerCase().includes(searchText))
      || (item.telephone && item.telephone.toLowerCase().includes(searchText))
    )
  }

  getSystemPermission(screen: string, action: string){
    return systemPermissionsNew(screen, action)
  }

  getItemsPerPage(){
    return this.itemsPerPage
  }

  getCurrentPages(): number{
    return this.currentPage
  }

  setSelection(element: any){
    return this.selectionUsers.toggle(element)
  }

  getSelection(element: any){
    return this.selectionUsers.isSelected(element)
  }

  toggleAllRow() {
    return this.toggleAllRowsUsers()
  }

  isAllSelected(){
    return this.isAllSelectedUsers()
  }

  noData(): boolean{
    if(this.filteredDataSource.data && this.filteredDataSource.data.length > 0){
      return false;
    }else{
      return true;
    }
  }

  isData() {
    return this.filteredDataSource.data.length > 0
  }

  async setUserApprover(event: Event, user: any){
    if(event){
      event.stopPropagation()
    }
    try {
      user.is_approval = !user.is_approval
      const response:any = await this.usersService.setUserCorporationApprover(user);
      if ((response && [201, 202].includes(response.status)) || [400].includes(response.status)) {
        addAlert('success', response.message)
        await this.selectButton([400].includes(response.status) ? 2 : 0)
        await this.getUsers();
      } else {
        addAlert('danger', response.message)
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  isCheckboxVisible(element: any): boolean {
    if (!element || !this.properties?.me) return false;
  
    const isAdminMaster = this.properties.me.is_admin_master;
    const elementProfile = element.profile;
    const meProfile = this.properties.me.profile;
  
    if (element.uuid === this.properties.me.uuid) {
      return false; // Não exibe para o próprio usuário
    }
  
    if (!this.getSystemPermission('users', 'edit_access')) {
      return false; // Verifica a permissão
    }
  
    // Regras de edição baseadas no tipo de perfil
    if (element.is_admin_master) {
      return isAdminMaster; // Apenas outro `is_admin_master` pode editar
    }
  
    if (elementProfile?.type === 'environment') {
      return isAdminMaster || meProfile?.type === 'environment';
    }
  
    if (elementProfile?.type === 'organization') {
      return (
        isAdminMaster ||
        meProfile?.type === 'environment' ||
        meProfile?.type === 'organization'
      );
    }

    // Outras regras (antigas, se necessário)
    // if (
    //   (this.properties.me.is_admin === AdminGroup.MASTER && element.is_admin !== AdminGroup.MASTER) ||
    //   (this.properties.me.is_admin === AdminGroup.ENVIRONMENT && element.is_admin === AdminGroup.OTHERS) ||
    //   (this.properties.profile?.slug === 'administrator' && elementProfile && elementProfile.slug !== 'administrator')
    // ) {
    //   return true;
    // }

    if (this.properties.me.is_admin_master) {
      return true;
    }

    return false;
  }

  getProfile(user_profile: any): string{
    if(!user_profile){
      return ''
    }

    let flag: string = ''

    user_profile.forEach((item: any) => {
      if(item.corporation_uuid == this.properties?.corporation?.uuid){
        flag = item.profileNew?.name ?? ''
        return
      }
    });

    return flag
  }

  async getProfiles() {
    try{
      const response: any = await this.profileService.getProfiles();
      if (response?.data) {
        if (this.properties.me.profile?.type === 'environment' || this.properties.me.is_admin_master) {
          const filteredData = response.data.filter((profile: { type: string; }) => profile.type === 'environment');
          this.profilesEnvironment = filteredData;
        }
      }
    }catch(error: any){
      console.log(error)
    }
  }
}
