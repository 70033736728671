<div class="row">
    <div class="attachments">
      <i class="fa-regular fa-circle-question" matTooltip="{{ getLanguages('businessSolution/modal/attachments/validFormats')}}"></i>
      <div class="button-upload" (click)="openFileInput()" [ngClass]="!parameters.isEdit ? 'disabled' : 'pointer'">
        <i class="fas fa-plus"></i>
        {{ getLanguages('businessSolution/modal/addCode/attachments') }}
      </div>
      <input type="file" #fileInput style="display: none" (change)="handleFileInput($event)" multiple [disabled]="!parameters.isEdit">
    </div>
</div>
<div class="table-attachment">
    <table *ngIf="getDisplayedRowsAttachment().length !== 0"  mat-table [dataSource]="getDisplayedRowsAttachment()" class="table">
    <ng-container matColumnDef="extension">
        <th mat-header-cell *matHeaderCellDef>
        <span> {{ getLanguages('attachment/extension') }} </span>
        </th>
        <td mat-cell *matCellDef="let row">
        <i [ngClass]="getClassIcon(row.name)"></i>
        </td>
    </ng-container>
    <ng-container matColumnDef="surname">
        <th mat-header-cell *matHeaderCellDef>
        <span> {{ getLanguages('attachment/surname') }} </span>
        </th>
        <td mat-cell *matCellDef="let row">
        <input contenteditable="true" [(ngModel)]="row.surname" (change)="saveAttachment(row)" [disabled]="!parameters.isEdit">
        </td>
    </ng-container>
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>
        <span> {{ getLanguages('attachment/name') }} </span>
        </th>
        <td mat-cell *matCellDef="let row">
        <span> {{ row.name }} </span>
        </td>
    </ng-container>
    <ng-container matColumnDef="tab">
        <th mat-header-cell *matHeaderCellDef>
        <span> {{ getLanguages('attachment/tab') }} </span>
        </th>
        <td mat-cell *matCellDef="let row">
            <button class="select-nav nav-table" [matMenuTriggerFor]="selectTab" [disabled]="!parameters.isEdit">
                <span>
                    <ng-container>
                        {{ row.tab ? getLanguages('businessSolution/modal/'+ row.tab +'/title') : getLanguages('search') }}
                    </ng-container>
                </span>
                <i class="fas fa-caret-down"></i>
            </button>
            <mat-menu #selectTab="matMenu">
            <div class="menu">
                <div class="input" (click)="$event.stopPropagation()" >
                <input type="text" [(ngModel)]="searchTab" placeholder="{{ getLanguages('search') }}">
                <i class="fa-solid fa-magnifying-glass"></i>
                </div>
                <div class="scroll">
                <ng-container *ngFor="let tab of tabArray">
                    <ng-container *ngIf="tab.slug.includes(searchTab.toLowerCase()) && tab.slug != 'attachments'">
                    <div class="item-menu" (click)="choseTab(row, tab)">
                        <span [ngClass]="{'valid-span' : tab.slug == (row.tab)}">
                            {{ getLanguages('businessSolution/modal/'+ tab.slug +'/title') }}
                        </span>
                    </div>
                    </ng-container>
                </ng-container>
                </div>
            </div>
            </mat-menu>

        </td>
    </ng-container>
    <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>
        <span> {{ getLanguages('attachment/type') }} </span>
        </th>
        <td mat-cell *matCellDef="let row">
            <button class="select-nav nav-table" [matMenuTriggerFor]="selectType" [disabled]="!parameters.isEdit">
                <span>
                    <ng-container>
                        {{ row.typeAttachment ? getLanguages('attachment/type/'+ row.typeAttachment) : getLanguages('search') }}
                    </ng-container>
                </span>
                <i class="fas fa-caret-down"></i>
            </button>
            <mat-menu #selectType="matMenu">
                <div class="menu">
                    <div class="input" (click)="$event.stopPropagation()" >
                    <input type="text" [(ngModel)]="searchType" placeholder="{{ getLanguages('search') }}">
                    <i class="fa-solid fa-magnifying-glass"></i>
                    </div>
                    <div class="scroll">
                    <ng-container *ngFor="let type of typeArray">
                        <ng-container *ngIf="type.slug.includes(searchType.toLowerCase())">
                        <div class="item-menu" (click)="choseType(row, type)">
                            <span [ngClass]="{'valid-span' : type.slug == (row.typeAttachment)}">
                                {{ getLanguages('attachment/type/'+ type.slug) }}
                            </span>
                        </div>
                        </ng-container>
                    </ng-container>
                    </div>
                </div>
            </mat-menu>
        </td>
    </ng-container>
    <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef>
        <span> {{ getLanguages('attachment/action') }} </span>
    </th>
    <td mat-cell *matCellDef="let row">
        <div class="icons-table">
        <button (click)="saveAttachment(row, true)" [disabled]="!parameters.isEdit">
            <i class="fas fa-trash" [ngClass]="!parameters.isEdit ? 'disabled-icon' : ''"></i>
        </button>
        <button (click)="downloadAttachment(row)">
            <i class="fas fa-download"></i>
        </button>
        </div>
    </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4"></td>
    </tr>
    </table>
    <div class="no-data" *ngIf="getDisplayedRowsAttachment().length === 0">
        <div class="circle">
            <i class="fas fa-exclamation"></i>
        </div>
        {{ getLanguages('errors/noData') }}
    </div>
</div>

<div class="background-modals" *ngIf="isLoading">
    <app-loading></app-loading>
</div>