import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatTreeModule } from '@angular/material/tree';
import { MatButtonModule } from '@angular/material/button';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PagesComponent } from './layouts/pages/pages.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { SpinnerModule } from '@coreui/angular';
import { CreateComponent } from './components/modal/tree/create/create.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './pages/login/login.component';
import { BusinessTreeComponent } from './pages/business-tree/business-tree.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { GlobalVariableInterceptor } from './guards/interceptors/global-variable.interceptor';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PeopleComponent } from './components/modal/tree/people/people.component';
import { TechnologyComponent } from './components/modal/tree/technology/technology.component';
import { StrategyComponent } from './components/modal/tree/strategy/strategy.component';
import { AddCodeComponent } from './components/modal/tree/add-code/add-code.component';
import { MatMenuModule } from '@angular/material/menu';
import { CorporationComponent } from './pages/corporation/corporation.component';
import { CreateFoundationComponent } from './components/modal/foundation/create-foundation/create-foundation.component';
import { EditFoundationComponent } from './components/modal/foundation/edit-foundation/edit-foundation.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RegisterComponent } from './pages/auth/register/register.component';
import { CreatePasswordComponent } from './pages/auth/create-password/create-password.component';
import { EditComponent } from './components/modal/tree/edit/edit.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { UsersComponent } from './pages/users/users.component';
import { CreateUserComponent } from './components/modal/user/create-user/create-user.component';
import { EditUserComponent } from './components/modal/user/edit-user/edit-user.component';
import { LoadingInstanceComponent } from './components/loading-instance/loading-instance.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { A11yModule } from '@angular/cdk/a11y';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { PaginatorModule } from 'primeng/paginator';
import { CreateDimensionsComponent } from './components/modal/foundation/create-dimensions/create-dimensions.component';
import { InstancesComponent } from './pages/instances/instances.component';
import { CreateInstanceComponent } from './components/modal/instance/create-instance/create-instance.component';
import { EditInstanceComponent } from './components/modal/instance/edit-instance/edit-instance.component';
import { UserInstancesComponent } from './components/modal/instance/user-instances/user-instances.component';
import { ViewUserComponent } from './components/modal/instance/user-instances/view-user/view-user.component';
import { ImportComponent } from './components/modal/import/import/import.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { NgChartsConfiguration, NgChartsModule } from 'ng2-charts';
import { HttpInterceptor } from '@angular/common/http';
import { NgOptimizedImage } from "@angular/common";
import { AlertComponent } from './components/alert/alert.component';
import { ActivitiesComponent } from './pages/activities/activities.component';
import { MAT_DATE_FORMATS, DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { IndicatorComponent } from './components/indicator/indicator.component';
import { LoadingComponent } from './components/loading/loading.component';
import { DeleteComponentComponent } from './components/delete-component/delete-component.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TreeModule } from 'primeng/tree';
import { TreeSelectModule } from 'primeng/treeselect';
import { TreeTableModule } from 'primeng/treetable';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { VersionsComponent } from './pages/versions/versions.component';
import { MaturityComponent } from './pages/maturity/maturity.component';
import { AbaMaturityComponent } from './components/modal/tree/maturity/maturity.component';
import { SliderModule } from 'primeng/slider';
import { AttachmentsComponent } from './components/modal/tree/attachments/attachments.component';
import { IndicatorsComponent } from './components/modal/tree/indicators/indicators.component';
import { ComplementsComponent } from './components/modal/tree/complements/complements.component';
import { ConfigurationsComponent } from './components/modal/tree/configurations/configurations.component';
import { GeneralSettingsComponent } from './components/modal/configurations/configurations.component';
import { IntegrationsComponent } from './components/modal/tree/integrations/integrations.component';
import { RulesComponent } from './components/modal/tree/rules/rules.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { StandardsComponent } from './pages/standards/standards.component';
import { ClipboardComponent } from './pages/clipboard/clipboard.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PersonasComponent } from './pages/personas/personas.component';
import { PersonasCrudComponent } from './components/modal/personas/personas-crud/personas-crud.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NameVersionsComponent } from './components/modal/versions/name-versions/name-versions.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SkillsCrudComponent } from './components/modal/personas/skills-crud/skills-crud.component';
import { IconsComponent } from './components/modal/tree/icons/icons.component';
import { TransactionComponent } from './components/modal/tree/transaction/transaction.component';
import { ChangesTreeComponent } from './components/modal/alert/changes-tree/changes-tree.component';
import { FormStandardsComponent } from './components/modal/standards/form-standards/form-standards.component';
import { PageTechnologyComponent } from './pages/page-technology/page-technology.component';
import { CrudTechnologiesComponent } from './components/modal/technologies/crud-technologies/crud-technologies.component';
import { RemoveCookieInterceptor } from './interceptors/remove-cookie.interceptor';
import { PageProjectComponent } from './pages/page-project/page-project.component';
import { CrudProjectsComponent } from './components/modal/project/crud-projects/crud-projects.component';
import { ProjectComponent } from './components/modal/tree/project/project.component';
import { MatRadioModule } from '@angular/material/radio';
import { ExtendedAttributesComponent } from './components/modal/tree/extended-attributes/extended-attributes.component';
import { NewTransactionComponent } from './components/modal/tree/new-transaction/new-transaction.component';
import { LinkComponent } from './components/modal/tree/link/link.component';
import { FormProfilesComponent } from './components/modal/profiles/form-profiles/form-profiles.component';
import { LinkUserComponent } from './components/modal/instance/user-instances/link-user/link-user.component';
import { LinkProfileComponent } from './components/modal/instance/user-instances/link-profile/link-profile.component';
import { ChangesLinkUsersComponent } from './components/modal/alert/changes-link-users/changes-link-users.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export enum AdminGroup {
  MASTER = 'MASTER',//Administrador do Sistema
  ENVIRONMENT = 'ENVIRONMENT',//Administrador do Ambiente
  OTHERS = 'OTHERS'//Usuários da corporação
}

@NgModule({
  declarations: [
    AppComponent,
    PagesComponent,
    SideBarComponent,
    BreadcrumbComponent,
    CreateComponent,
    LoginComponent,
    BusinessTreeComponent,
    PeopleComponent,
    TechnologyComponent,
    StrategyComponent,
    AddCodeComponent,
    CorporationComponent,
    CreateFoundationComponent,
    EditFoundationComponent,
    RegisterComponent,
    CreatePasswordComponent,
    EditComponent,
    UsersComponent,
    EditUserComponent,
    CreateUserComponent,
    LoadingInstanceComponent,
    CreateDimensionsComponent,
    InstancesComponent,
    CreateInstanceComponent,
    EditInstanceComponent,
    UserInstancesComponent,
    ViewUserComponent,
    ImportComponent,
    ProfileComponent,
    AlertComponent,
    ActivitiesComponent,
    IndicatorComponent,
    LoadingComponent,
    DeleteComponentComponent,
    VersionsComponent,
    MaturityComponent,
    AbaMaturityComponent,
    AttachmentsComponent,
    IndicatorsComponent,
    ComplementsComponent,
    ConfigurationsComponent,
    IntegrationsComponent,
    RulesComponent,
    StandardsComponent,
    ClipboardComponent,
    PageNotFoundComponent,
    PersonasComponent,
    PersonasCrudComponent,
    NameVersionsComponent,
    DashboardComponent,
    SkillsCrudComponent,
    IconsComponent,
    TransactionComponent,
    ChangesTreeComponent,
    GeneralSettingsComponent,
    AlertComponent,
    GeneralSettingsComponent,
    FormStandardsComponent,
    PageTechnologyComponent,
    CrudTechnologiesComponent,
    PageProjectComponent,
    CrudProjectsComponent,
    ProjectComponent,
    ExtendedAttributesComponent,
    NewTransactionComponent,
    LinkComponent,
    FormProfilesComponent,
    LinkUserComponent,
    LinkProfileComponent,
    ChangesLinkUsersComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatIconModule,
    RouterModule.forRoot([]),
    MatTreeModule,
    MatButtonModule,
    MatIconModule,
    HttpClientModule,
    SpinnerModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    MatMenuModule,
    MatCheckboxModule,
    MatNativeDateModule,
    MatDatepickerModule,
    NgxChartsModule,
    A11yModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    TableModule,
    CheckboxModule,
    ButtonModule,
    PaginatorModule,
    NgChartsModule.forRoot(),
    NgChartsModule,
    NgOptimizedImage,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    TreeModule,
    TreeSelectModule,
    TreeTableModule,
    AvatarModule,
    AvatarGroupModule,
    SliderModule,
    MatSlideToggleModule,
    OverlayPanelModule,
    ImageCropperModule,
    ScrollingModule,
    MatRadioModule,
  ],


  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalVariableInterceptor,
      multi: true
    },
    { provide: NgChartsConfiguration, useValue: { generateColors: false }},
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: DateAdapter, useClass: MomentDateAdapter },
    { provide: HTTP_INTERCEPTORS, useClass: RemoveCookieInterceptor, multi: true }

    // { provide: HttpInterceptor, useClass: Bisom },

  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
