<div class="all-content">
  <div class="top">
    <div class="toggle-user"></div>
    <div class="div-icon-button">
      <i class="fas fa-xmark" (click)="exitModal()" id="exit-icon-environment"></i>
    </div>
  </div>

  <div class="row">
    <div class="title">
      {{ getLanguages('instances/modal/user/title')}}
    </div>
  </div>

  <div class="row">
    <span class="name-environment"> {{ parameters.environment.name }} </span>
  </div>

  <div class="row config">
    <div class="div-search">
      <div class="search">
        <input type="text" [(ngModel)]="searchText" (keyup.enter)="searchUser()">
      </div>
      <button (click)="searchUser()">
        {{ getLanguages('search') }}
      </button>
    </div>

    <div class="div-search button-new-user">
      <button (click)="togleLinkUser()" *ngIf="properties?.me?.is_admin_master">
        {{ getLanguages('instances/modal/user/linkUsers') }}
      </button>
      <button (click)="togleCreateUser()" *ngIf="getSystemPermission('environments', 'create_user')">
        {{ getLanguages('instances/modal/user/newUser') }}
      </button>
    </div>

  </div>

  <div class="row content">
    <div class="div-table" *ngIf="isData() && !isLoading">
      <table mat-table [dataSource]="getDisplayedRows()" class="table">
        <ng-container matColumnDef="Name">
          <th mat-header-cell *matHeaderCellDef>
            <span> {{ getLanguages('instances/modal/user/name') }} </span>
          </th>
          <td mat-cell *matCellDef="let element" (click)="initModalViewUser(element)" class="pointer">
            <span> {{element.name}} </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="Email">
          <th mat-header-cell *matHeaderCellDef>
            <span> {{ getLanguages('instances/modal/user/email') }} </span>
          </th>
          <td mat-cell *matCellDef="let element" (click)="initModalViewUser(element)" class="pointer">
            <span> {{element.email}} </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="userInstance.displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: userInstance.displayedColumns;"></tr>
      </table>
    </div>
    <div class="no-data" *ngIf="!isData() && !isLoading">

      <div class="circle">
        <i class="fas fa-exclamation"></i>
      </div>

      {{ getLanguages('errors/noData') }}
    </div>
    <div class="loading" *ngIf="isLoading">
      <section>
        <div class="loading-spinner"></div>
        <span class="span">
          {{ getLanguages('loading') }}
        </span>
      </section>
    </div>
  </div>

  <div class="row" *ngIf="isData() && !isLoading">
    <div class="div-paginator">
      <div class="paginator">
        <span>
          {{ getLanguages('businessSolution/modal/strategy/itensPage') }}
        </span>
        <button [matMenuTriggerFor]="menu" class="itens-per-page">
          {{ getItemsPerPage() }}
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="choseItemsPage(5)">5</button>
          <button mat-menu-item (click)="choseItemsPage(10)">10</button>
        </mat-menu>
        <span>
          {{ getLanguages('businessSolution/modal/strategy/totalPages') }}
        </span>
        <span class="totalPages">
          {{ getTotalPages()}}
        </span>
        <button [ngClass]="getCurrentPages() === 1 ? 'disabled-arrow' : 'arrow'" [disabled]="getCurrentPages() === 1"
          (click)="onPageChange(getCurrentPages() - 1)">
          <i class="fa-solid fa-angle-left"></i>
        </button>
        <input type="number" [ngModel]="getCurrentPages()" (blur)="checkValue($event)">
        <button [ngClass]="getCurrentPages() == getTotalPages() ? 'disabled-arrow' : 'arrow'"
          [disabled]="getCurrentPages() == getTotalPages()" (click)="onPageChange(getCurrentPages() + 1)">
          <i class="fa-solid fa-angle-right"></i>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="background-modals" style="background-color: transparent !important;" *ngIf="isShowUser || isShowCreateUser || isShowLinkUser">
  <app-view-user *ngIf="isShowUser" (toggleInstanceViewUserModal)="toggleInstanceViewUserModal($event)" [parameters]="dataUser"></app-view-user>
  <app-create-user *ngIf="isShowCreateUser" (toggleUserModal)="togleCreateUser()" [properties]="properties" [parameters]="parameters" (getUsers)="getUsers()"></app-create-user>
  <app-link-user *ngIf="isShowLinkUser" (toggleModal)="togleLinkUser($event)" [parameters]="parameters"></app-link-user>
</div>