import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { to64decode } from 'src/app/helpers/base64';
import { getLanguageCookie } from 'src/assets/language';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { localStorageToJsonAsync } from 'src/app/helpers/cookieToJson';
import { AdminGroup} from "../../../../app.module";
import { PeopleComponent } from '../people/people.component';
import { TreeNode } from 'primeng/api';
import { sortArrayOfObjects } from 'src/app/helpers/sortArrayOfObjects';
import { systemPermissionsNew } from 'src/app/helpers/systemPermissions';

@Component({
  selector: 'app-add-code',
  templateUrl: './add-code.component.html',
  styleUrls: ['./add-code.component.scss']
})
export class AddCodeComponent {
  @Input() properties: any = {}
  @Input() parameters: any = {}
  @Input() dimensions: any = {}

  @Output() parametersParent = new EventEmitter<string>();
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  @ViewChild(PeopleComponent) peopleComponent!: PeopleComponent;

  protected readonly AdminGroup = AdminGroup;

  private subscription: Subscription = new Subscription();

  that: any = {}

  formData: FormData = new FormData();
  // attachmentArray: any = []


  dataSource!: MatTableDataSource<any>;
  displayedColumns: string[] = ['extension','surname', 'name', 'action'];

  currentPage = 1;
  currentPageReq = 1;
  itemsPerPage = 10;

  viewTab!: number;
  viewDimension: string = 'strategy';

  selectedFlag: any = {
    lang: 'en',
    file: 'us.svg'
  };

  files!: TreeNode[];

  strategy: any = {
    currentPage: 1,
    itemsPerPage: 5,
    columns: [],
    columnsName: {},
    rowsStrategy: [],
    strategyRows: new MatTableDataSource<any>([]),
  }

  people: any = {
    currentPage: 1,
    itemsPerPage: 5,
    columns: [],
    columnsName: {},
    rowsStrategy: [],
    peopleRows: new MatTableDataSource<any>([]),
  }

  technology: any = {
    currentPage: 1,
    itemsPerPage: 5,
    columns: [],
    columnsName: {},
    rowsStrategy: [],
    technologyRows: new MatTableDataSource<any>([]),
  }

  statusArray: any = []
  searchStatus: string = '';
  selectedStatus: any = {
    name: {
      // en: 'New',
      // pt: 'Novo',
      // slug: 'new'
    }
  }

  importanceArray: any = []
  searchImportance: string = '';
  selectedImportance: any = {
    name: {
      // en: 'Desirable',
      // pt: 'Desejável',
      // slug: 'desirable'
    }
  }

  frequencyArray: any = []
  searchFrequency: string = '';
  selectedFrequency: any = {
    name: {
      // en: 'Daily',
      // pt: 'Diária',
      // slug: 'daily'
    }
  }

  slaUnitArray: any = []
  searchSlaUnit: string = '';
  selectedSlaUnit: any = {
    name: {
      // en: 'Days',
      // pt: 'Dias',
      // slug: 'days'
    }
  }

  classificationArray: any = []
  searchClassification: string = '';
  selectedClassification: any = {
    name: {
      // en: 'Core',
      // pt: 'Essencial',
      // slug: 'core'
    }
  }

  processOwnerArray: any = []
  searchProcessOwner: string = '';
  selectedProcessOwner: any = {
    name: ''
  }

  searchOrganogramResponsible: string = '';
  selectedOrganogramResponsible: any = {
    label: ''
  }

  searchOrganogramExecuting: string = '';
  selectedOrganogramExecuting: any = {
    label: ''
  }

  rpaArray: any = []
  searchRPA: string = '';
  selectedRPA: any = {
    value: ''
  }

  isShowModalIcon: boolean = false

  constructor() {
    this.dataSource = new MatTableDataSource();
  }

  async ngOnInit(){
    await this.getOptionsArray();

    this.properties = await localStorageToJsonAsync()
    this.statusArray.forEach((status:any) => {
      if(status.slug == this.parameters.item.status){
        this.selectedStatus = status
      }
    });
    this.statusArray = sortArrayOfObjects(this.statusArray)

    this.importanceArray.forEach((importance:any) => {
      if(importance.slug == this.parameters.item.description.importance){
        this.selectedImportance = importance
      }
    });
    this.importanceArray = sortArrayOfObjects(this.importanceArray)

    this.frequencyArray.forEach((frequency:any) => {
      if(frequency.slug == this.parameters.item.description.frequency){
        this.selectedFrequency = frequency
      }
    });
    this.frequencyArray = sortArrayOfObjects(this.frequencyArray)

    this.slaUnitArray.forEach((slaUnit:any) => {
      if(slaUnit.slug == this.parameters.item.description.sla_unit){
        this.selectedSlaUnit = slaUnit
      }
    });
    this.slaUnitArray = sortArrayOfObjects(this.slaUnitArray)

    this.classificationArray.forEach((classification:any) => {
      if(classification.slug == this.parameters.item.description.classification){
        this.selectedClassification = classification
      }
    });
    this.classificationArray = sortArrayOfObjects(this.classificationArray)

    // this.selectedProcessOwner = this.parameters.item.description.processOwner

    // this.processOwnerArray.forEach((processOwner:any) => {
    //   if(this.parameters.item.description.processOwner &&  this.parameters.item.description.processOwner.uuid && processOwner.uuid == this.parameters.item.description.processOwner.uuid){
    //     this.selectedProcessOwner = processOwner
    //   }
    // });

    this.parameters.arrayOrganograms.forEach((item:any) => {
      if(this.parameters.item.description.organogram_responsible &&  this.parameters.item.description.organogram_responsible.uuid && item.uuid == this.parameters.item.description.organogram_responsible.uuid){
        this.selectedOrganogramResponsible = item
      }
      if(this.parameters.item.description.organogram_executing &&  this.parameters.item.description.organogram_executing.uuid && item.uuid == this.parameters.item.description.organogram_executing.uuid){
        this.selectedOrganogramExecuting = item
      }
    });
    this.parameters.arrayOrganograms = sortArrayOfObjects(this.parameters.arrayOrganograms)

    this.rpaArray.forEach((rpa:any) => {
      if(this.parameters.item.description.rpa && this.parameters.item.description.rpa.value && rpa.value == this.parameters.item.description.rpa.value){
        this.selectedRPA = rpa
      }
    });

    if (this.parameters.item.rows.strategy && Object.keys(this.parameters.item.rows.strategy).length > 0) {
      this.strategy.columns = await this.parameters.item.columns.strategy.columns;
      this.strategy.columnsName = await this.parameters.item.columns.strategy.columnsName;
      this.strategy.rowsStrategy = this.parameters.item.rows.strategy[0].name != '' ? await this.parameters.item.rows.strategy : [];
      this.strategy.strategyRows = new MatTableDataSource<any>(this.strategy.rowsStrategy);
    }

    if (this.parameters.item.rows.people && Object.keys(this.parameters.item.rows.people).length > 0) {
      this.people.columns = await this.parameters.item.columns.people.columns;
      this.people.columnsName = await this.parameters.item.columns.people.columnsName;
      this.people.rowsPeople = this.parameters.item.rows.people[0].name != '' ? await this.parameters.item.rows.people : [];
      this.people.peopleRows = new MatTableDataSource<any>(this.people.rowsPeople);
    }

    if (this.parameters.item.rows.technology && Object.keys(this.parameters.item.rows.technology).length > 0) {
      this.technology.columns = await this.parameters.item.columns.technology.columns;
      this.technology.columnsName = await this.parameters.item.columns.technology.columnsName;
      this.technology.rowsTechnology = this.parameters.item.rows.technology[0].name != '' ? await this.parameters.item.rows.technology : [];
      this.technology.technologyRows = new MatTableDataSource<any>(this.technology.rowsTechnology);
    }

    if (this.parameters.item.attachments?.length > 0 && !this.parameters?.is_new) {
      this.dataSource = new MatTableDataSource<any>(this.parameters.item.attachments)
    }

    if(!this.parameters.item.icon){
      this.parameters.item.icon = 'fa-solid fa-building-columns'
    }

    if(this.parameters?.item?.description?.fte == ''){
      this.parameters.item.description.fte = 0
    }

    if(this.parameters?.item?.description?.fte_cost == ''){
      this.parameters.item.description.fte_cost = 0
    }

    if(this.parameters?.item?.description?.fte_one == ''){
      this.parameters.item.description.fte_one = 0
    }

    if(this.parameters?.item?.description?.fte_cost_one == ''){
      this.parameters.item.description.fte_cost_one = 0
    }

    // await this.sumFte()
  }

  async getOptionsArray() {
    this.statusArray = this.parameters.optionsArray.status
    this.importanceArray = this.parameters.optionsArray.importance
    this.frequencyArray = this.parameters.optionsArray.frequency
    this.slaUnitArray = this.parameters.optionsArray.unitSLA
    this.classificationArray = this.parameters.optionsArray.classification
    this.rpaArray = this.parameters.optionsArray.rpaPercentage
    this.processOwnerArray = this.parameters.optionsArray.usersArray
  }

  choseItemSelect(type: string, value: any){
    switch (type) {
      case 'status':
        this.selectedStatus = value;
        this.parameters.item.status = value.slug;
        break;
      case 'importance':
        this.selectedImportance = value;
        this.parameters.item.description.importance = value.slug;
        break;
      case 'frequency':
        this.selectedFrequency = value;
        this.parameters.item.description.frequency = value.slug;
        break;
      case 'sla_unit':
        this.selectedSlaUnit = value;
        this.parameters.item.description.sla_unit = value.slug;
        break;
      case 'classification':
        this.selectedClassification = value;
        this.parameters.item.description.classification = value.slug;
        break;
      case 'processOwner':
        this.selectedProcessOwner = value;
        this.parameters.item.description.processOwner = value;
        break;
      case 'rpa':
        this.selectedRPA = value;
        this.parameters.item.description.rpa = value;
        break;
      case 'organogram_responsible':
        this.selectedOrganogramResponsible = value;
        this.parameters.item.description.organogram_responsible = value;
        break;
      case 'organogram_executing':
        this.selectedOrganogramExecuting = value;
        this.parameters.item.description.organogram_executing = value;
        break;
      default:
        break;
    }
  }

  getNames(name: any){
    return name[to64decode(localStorage.getItem('language')).lang]
  }

  getSystemPermission(screen: string, action: string){
    return systemPermissionsNew(screen, action)
  }

  setThat() {
    this.that = this
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  toggleViewDimension(dimension: string){
    this.viewDimension = dimension
  }

  choseTabs(number: number){
    this.viewTab = number;
  }

  choseItemsPage(number: number){
    this.setThat()

    this.that[this.viewDimension].itemsPerPage = number;
    this.getDisplayedRows()
  }

  onPageChange(newPage: number, text: string): void {
    this.setThat()

    this.that[text].currentPage = newPage;
  }

  getDisplayedRows(): any[] {
    this.setThat()
    const startIndex = (this.that[this.viewDimension].currentPage - 1) * this.that[this.viewDimension].itemsPerPage;
    const endIndex = startIndex + this.that[this.viewDimension].itemsPerPage;
    return this.that[this.viewDimension][`${this.viewDimension}Rows`].data.slice(startIndex, endIndex);
  }

  getTotalPages(): number {
    return Math.ceil(this.that[this.viewDimension][`${this.viewDimension}Rows`].data.length / this.that[this.viewDimension].itemsPerPage);
  }

  async checkValue() {
    this.setThat()

    let total = this.getTotalPages()
    if (this.that[this.viewDimension].currentPage > total) this.that[this.viewDimension].currentPage = total;
  }

  isData(){
    this.setThat()
    return this.that[this.viewDimension][`${this.viewDimension}Rows`].data.length > 0
  }

  getColumns(){
    this.setThat()
    return this.that[this.viewDimension].columns.slice(1)
  }

  getField(column: string){
    this.setThat()
    return this.that[this.viewDimension].columnsName[column].field
  }

  getElement(element: any, column: string){
    this.setThat()

    if (typeof element[this.that[this.viewDimension].columnsName[column].slug] == 'object') {
      return element[this.that[this.viewDimension].columnsName[column].slug]['name']
    }

    return element[this.that[this.viewDimension].columnsName[column].slug]
  }

  getItemsPerPage(){
    this.setThat()
    return this.that[this.viewDimension].itemsPerPage
  }

  getCurrentPages(): number{
    this.setThat()
    return this.that[this.viewDimension].currentPage
  }

  openFileInput() {
    this.fileInput.nativeElement.click();
  }

  updateIcon(event: any){
    // console.log(event)
  }

  toggleModalIcon(event?: any){
    this.isShowModalIcon = !this.isShowModalIcon;
    if(event){
      // console.log(event)
      this.parameters.item.icon = event
    }
  }

//   async getUsers(status: any = null) {
//     try {
//       const response: any = await this.usersService.getUsers(status, { owner: true}).toPromise();

//       if (response && response.data) {
//         this.processOwnerArray = response.data
//       } else {
//         throw new Error('Erro ao consultar array de status!\nConsulte o administrador do sistema!');
//       }
//     } catch (error: any) {
//       await apiOfflineAsync(error)
//     }
//   }
  showModalIcon(){
    this.isShowModalIcon = !this.isShowModalIcon
  }

  async sumFte(){
    this.parameters.item.description.fte_cost -= this.parameters.item.description.fte_cost_one
    this.parameters.item.description.fte -= this.parameters.item.description.fte_one

    this.parameters.item.description.fte_cost_one = 0
    this.parameters.item.description.fte_one = 0

    this.parameters.item.personas?.forEach((persona: any) => {
      if (this.findNodeByUUID(this.parameters.personas, persona.uuid) != null && !this.parameters?.arrayOrganograms?.find((item: any) => item.uuid === persona.uuid)) {
        this.parameters.item.description.fte_cost_one += (iterateTreeAndFindNode(this.parameters?.personas, persona.uuid) * persona.cost);
        this.parameters.item.description.fte_one += persona.cost;
      }
    });    

    this.parameters.item.description.fte += this.parameters.item.description.fte_one
    this.parameters.item.description.fte_cost += this.parameters.item.description.fte_cost_one
  }

  findNodeByUUID(nodes: any[], uuid: string): any {
    for (let node of nodes) {
      if (node.uuid === uuid) {
        return node;
      }
      if (node.children) {
        const found = this.findNodeByUUID(node.children, uuid);
        if (found) return found;
      }
    }
    return null;
  }
}

function iterateTreeAndFindNode(tree: any[], uuidToFind: string): number{
  for (const node of tree) {
    if (node.uuid === uuidToFind) {
      return (node.data.cost_min + node.data.cost_max) / 2;
    }

    if (node.children && node.children.length > 0) {
      const foundNode = iterateTreeAndFindNode(node.children, uuidToFind);
      if (foundNode) {
        return foundNode;
      }
    }
  }

  return 0;
}

