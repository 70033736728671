import { Component, EventEmitter, Input, Output } from '@angular/core';
import { iconsJson } from 'src/assets/const/iconsJson';
import { getLanguageCookie } from 'src/assets/language';
@Component({
  selector: 'app-icons',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.scss']
})
export class IconsComponent {
  @Input() parameters: any = {}

  @Output() toggleModalIcon: EventEmitter<any> = new EventEmitter<any>();

  selectedIcon: string = ''

  icons: any = iconsJson

  constructor(){}

  ngOnInit(){
    this.selectedIcon = this.parameters.icon
  }
  choseIcon(param: string){
    this.selectedIcon = param
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  async exitModal(){
   return this.toggleModalIcon.emit(this.selectedIcon);
  }
}
