import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { asyncForEach } from 'src/app/helpers/asyncForEach';
import { to64decode } from 'src/app/helpers/base64';
import { localStorageToJsonAsync } from 'src/app/helpers/cookieToJson';
import { addAlert, apiOfflineAsync } from 'src/app/layouts/pages/pages.component';
import { CorporationService } from 'src/app/services/corporation/corporation.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { getLanguageCookie } from 'src/assets/language';

@Component({
  selector: 'app-link-profile',
  templateUrl: './link-profile.component.html',
  styleUrls: ['./link-profile.component.scss']
})
export class LinkProfileComponent {
  @Output() togleLinkProfile: EventEmitter<string> = new EventEmitter<string>();
  @Input() parameters: any

  properties: any = {}

  task: any = {
    color: 'accent'
  };

  isShowAlert: boolean = false;
  typeExit?: string = ''

  data: any = {
    is_admin_master: false,
  }
  statusArray = [ { value: true , label: {en: 'Active', pt: 'Ativo'} }, { value: false , label: {en: 'Inactive', pt: 'Inativo'}}]
  displayedColumns: any = ['Select', 'Corporation', 'Profile', 'Status']

  selectedProfileEnvironment: any = { name: 'Select' }
  searchProfileEnvironment: string = ''
  profilesEnvironment: any[] = []
  dataSource: any;
  setSelectionCorporationsProfilesArray: any = [];
  isTreeEmpty!: boolean;

  constructor(private profileService: ProfileService, private corporationsService: CorporationService) {

  }

  async ngOnInit() {
    this.properties = await localStorageToJsonAsync();
    this.parameters.arrayUsers = JSON.parse(JSON.stringify(this.parameters.arrayUsers))
    await this.getProfiles()
    await this.getCorporationsProfiles()
  }

  getLanguages(file: string) {
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  exitModal(slug?: string) {
    this.typeExit = slug;
    this.isShowAlert = true
  }

  close() {
    if (this.typeExit == 'all') {
      this.togleLinkProfile.emit('all')
      return
    }
    this.togleLinkProfile.emit()
  }

  toggleAlert() {
    this.isShowAlert = false
  }

  remove(uuid: string) {
    if (this.parameters.arrayUsers.length == 1) {
      this.exitModal()
      return
    }

    const index = this.parameters.arrayUsers.findIndex((u: any) => u.uuid === uuid);
    if (index > -1) {
      this.parameters.arrayUsers.splice(index, 1);
    }
  }

  async validationProfile () {
    let inputs: any = []

    if(!this.data.is_admin_master){
      if(!this.selectedProfileEnvironment?.uuid){
        if (this.setSelectionCorporationsProfilesArray.length == 0) {
          addAlert('orange', `Por favor selecione uma corporação!`)
          return false
        }
  
        let selectedCount: number = 0
        await asyncForEach(this.setSelectionCorporationsProfilesArray, async (item: any) => {
          if (item.selected != false) selectedCount++
          if (item.selected != false && (!item.status || !item.profile)) {
            inputs.push(`${item.corporation.name}`)
          }
        })
  
        if (selectedCount == 0) {
          addAlert('orange', `Por favor selessscione uma corporação!`)
          return false
        }
      }
    }

    if (inputs.length > 0) {
      addAlert('orange', `Por favor selecione um perfil|status para as corporações: ${inputs.join(`, `)}`)
      return false
    }
    return true
  }

  async save() {
    if (!await this.validationProfile()) {
      return;
    }

    let body = {
      users: this.parameters.arrayUsers,
      is_admin_master: this.data.is_admin_master,
      profileEnvironment: this.selectedProfileEnvironment?.uuid ? this.selectedProfileEnvironment : null,
      profileCorporation: this.setSelectionCorporationsProfilesArray
    }

    if (body.profileEnvironment) {
      body.profileCorporation = []
    }

    try {
      const response: any = await this.profileService.saveUsersProfiles(body);

      if (response && response.status == 200) {
        addAlert('success', response.message);
        await new Promise(resolve => setTimeout(resolve, 2000));
        window.location.reload();
      } else {
        addAlert('danger', response.message);
      }
    } catch (error: any) {
      console.log(error);
    }
  }

  getAccessProfileMaster(): boolean {
    return this.properties?.me?.is_admin_master
  }

  getAccessProfileEnvornment(): boolean {
    return this.properties?.me?.is_admin_master || this.properties?.profile?.type == 'environment'
  }

  choseProfileEnvironment(profile: any) {
    this.selectedProfileEnvironment = profile
  }

  async getProfiles() {
    try {
      const response: any = await this.profileService.getProfiles();
      if (response?.data) {
        if (this.properties.me.profile?.type === 'environment' || this.properties.me.is_admin_master) {
          const filteredData = response.data.filter((profile: { type: string; }) => profile.type === 'environment');
          this.profilesEnvironment = filteredData;
        }
      }
    } catch (error: any) {
      console.log(error)
    }
  }

  async setSelectionCorporationProfile(element: any = null, profile: any = null, index: number = -1, { isAll = false, status }: any = {}) {
    if (isAll) element.selected = status == 'ACTIVE'

    if (element && !profile && !status) {
      let indexRemove: any = -1;

      await asyncForEach(this.setSelectionCorporationsProfilesArray, async (item: any, item_index: any) => {
        if (item.corporation.uuid == element.corporation.uuid) indexRemove = item_index
      })

      if (indexRemove >= 0) {
        if (!isAll) element.selected = !element.selected

        if (!element.selected) this.setSelectionCorporationsProfilesArray[indexRemove].selected = false
        return;
      }

      if (!isAll) element.selected = true;

      this.setSelectionCorporationsProfilesArray.push({ corporation: element.corporation })
      element.index = this.setSelectionCorporationsProfilesArray.length - 1
    }

    if (!element && profile && !status && index >= 0) {
      this.setSelectionCorporationsProfilesArray[index] = { ...this.setSelectionCorporationsProfilesArray[index], profile }
    }

    if (!element && !profile && status && index >= 0) {
      this.setSelectionCorporationsProfilesArray[index] = { ...this.setSelectionCorporationsProfilesArray[index], status }
    }
  }
  
  getNames(name: any){
    return name[to64decode(localStorage.getItem('language')).lang]
  }

  async getCorporationsProfiles() {
    this.dataSource = new MatTableDataSource<any>();
    try {
      const response: any = await this.corporationsService.getCorporationsProfilesTree(this.parameters);
      if (response && response.data){
        this.dataSource = new MatTableDataSource<any>(response.data)
        this.isTreeEmpty = response.data.length == 0;
      } else {
        throw new Error('Erro ao consultar àrvore de corporations!\nConsulta o administrador do sistema!');
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }
}
