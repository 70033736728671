import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from 'src/app/environments/environment';
import { IResponse } from 'src/app/interfaces/IResponse';
import { addAlert } from 'src/app/layouts/pages/pages.component';
import { getLanguageCookie } from 'src/assets/language';
@Component({
  selector: 'app-new-transaction',
  templateUrl: './new-transaction.component.html',
  styleUrls: ['./new-transaction.component.scss']
})
export class NewTransactionComponent {
  @Output() updateFiles: EventEmitter<any> = new EventEmitter<any>();
  @Output() transactionChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() uuidModule!: string;
  @Input() isNewTransaction!: boolean;
  @Output() updatedTechnologies: EventEmitter<any[]> = new EventEmitter<any[]>();

  parameters: any = {}
  newNode: any = {}
  API_PATH: string
  transactionLabel: string = '';
  transactionDescription: string = '';

  constructor(
    private httpClient: HttpClient,
  ) {
    this.API_PATH = environment.apiUrl;
  }

  getLanguages(file: string) {
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  exitModalNewTransaction() {
    this.isNewTransaction = !this.isNewTransaction;
    this.transactionChange.emit(this.isNewTransaction);
  }

  async saveTransaction() {
    if(this.transactionLabel == 'New +'){
      addAlert('danger', this.getLanguages('auth/alert/nameReservedWord'))
      return
    }

    if (this.transactionLabel === '') {
      addAlert('orange', `${this.getLanguages('mandatoryField')}${this.getLanguages('technologies/id_trasaction')}`)
      return
    }

    const body = {
      "uuid_module": this.uuidModule,
      "label": this.transactionLabel,
      "description": this.transactionDescription
    }

    const response = await this.httpClient.post<IResponse>(
      `${this.API_PATH}technologies/newTransactionInModule`,
      body,
    ).toPromise();

    if (response && response.status == 200 && response.data && response.data.result) {
      this.updatedTechnologies.emit(response.data.result);
      this.exitModalNewTransaction()
      addAlert('success', this.getLanguages('technologies/new_transaction'));
      return
    }

    addAlert('danger', this.getLanguages('technologies/new_transaction_error'))
  }
}
