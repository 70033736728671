<div class="all-content">
  <div class="top">
    {{ getLanguages('businessSolution/content/top') }}
  </div>
  <div class="row">
    <div class="div-search" [ngClass]="isSearch ? 'div-search-fixed' : ''">
      <div class="search">
          <input type="text" [(ngModel)]="text" (keyup.enter)="searchNodes()">
          <i class="fas fa-xmark cancel-search" *ngIf="isSearch" (click)="clearFilter()"></i>
      </div>
      <button (click)="searchNodes()">
          {{ getLanguages('search') }}
      </button>
  
      <div class="filter-section count-proccess" *ngIf="isSearch">
        <div class="filter" *ngIf="filterArray?.length > 0">
          {{ filterCursor }}           
          {{ getLanguages('businessSolution/search/of') }}        
          {{ filterArray?.length }}
          <button class="button-navigation" (click)="previous()" [class.disabled-button-filter]="filterCursor === 1" [disabled]="filterCursor === 1"> <i class="fas fa-chevron-left"></i> </button>
          <button class="button-navigation" (click)="next()"  [class.disabled-button-filter]="filterCursor === filterArray.length" [disabled]="filterCursor === filterArray.length"> <i class="fas fa-chevron-right"></i> </button>
        </div>
        <span *ngIf="filterArray?.length == 0">
          {{ getLanguages('businessSolution/search/noResult') }}        
        </span>
      </div>  
    </div>
  </div>
  
  <div class="title-journey" *ngIf="journeyArray?.length >= 1">
    <span class="title-journey-span"> {{ getLanguages('businessSolution/top/indicators') }} </span>
    <i class="fa-solid fa-chevron-right" [ngClass]="!openJourneys ? 'openned' : 'closed'" (click)="flipJourneys()"></i>
  </div>
  <div class="row row-journey" [ngClass]="openJourneys ? 'openned-journeys' : 'closed-journeys'" *ngIf="journeyArray?.length >= 1 && openJourneys" [ngStyle]="{ 'padding': '1vh 5vh' , 'justify-content': 'center' }">
    <div class="arrow arrow-left" (click)="scrollLeft('journeys')">
        <i class="fas fa-angle-left"></i>
    </div>
    <div class="cards" #scrollContainerJourneys>
        <div class="column first-column">
            <div class="row">
                <div class="card-journey shadow-2">
                    <div class="toggle-left"></div>
                    <div class="title">
                        {{ guidelineArray[0]?.name ? guidelineArray[0]?.name : '' }}
                    </div>
                    <div class="i">
                        <i class="fas fa-signs-post"></i>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="card-indicators shadow-2">
                    <div class="toggle-left"></div>
                    <div class="title">
                        {{ getLanguages('home/analytical')}}
                    </div>
                    <div class="i">
                        <i class="fa-solid fa-chart-line"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="column no-first-column" *ngFor="let journey of journeyArray; let i = index">
            <div class="row">
                <div class="card-journey shadow-2 pointer" [ngClass]="viewJourney == i ? 'clicked-journey' : '' " (click)="choseTreeJourney(i)">
                    <div class="i">
                        <i [ngClass]="journey.icon ? journey.icon : 'fas fa-building-columns' "></i>
                    </div>
                    <div class="title">
                        {{ journey.name }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="card-indicators shadow-2">
                    <ng-container *ngIf="(journey.indicators && journey.indicators.length) || journey.personas || journey.total_process">
                        <div class="one-indicator" *ngFor="let indicator of journey.indicators">
                            <div class="top">
                              <span> <i class="fas fa-square-check"></i> {{ indicator.name }} </span>
                            </div>
                            <div class="values">
                                <span *ngIf="indicator.absolute != '' && indicator.absolute != null">
                                    {{ getLanguages('businessSolution/modal/indicators/absolute') }} : {{ indicator.absolute }}
                                </span>
                                <span *ngIf="indicator.maximum != '' && indicator.maximum != null">
                                    {{ getLanguages('businessSolution/modal/indicators/maximum') }} : {{ indicator.maximum }}
                                </span>
                                <span *ngIf="indicator.minimum != '' && indicator.minimum != null">
                                    {{ getLanguages('businessSolution/modal/indicators/minimum') }} : {{ indicator.minimum }}
                                </span>
                                <span *ngIf="indicator.average != '' && indicator.average != null">
                                    {{ getLanguages('businessSolution/modal/indicators/average') }} : {{ indicator.average }}
                                </span>
                                <span *ngIf="indicator.frequency != '' && indicator.frequency != null">
                                    {{ getLanguages('businessSolution/modal/indicators/frequency') }} : {{ getNameFrequency(indicator.frequency) }}
                                </span>
                            </div>
                        </div>
                        <div class="one-indicator" *ngIf="journey.personas">
                          <div class="top">
                            <i class="fas fa-square-check"></i> <span> {{ getLanguages('corporations/info/personas') }} : {{ journey.personas }} </span>
                          </div>
                        </div>
                        <div class="one-indicator" *ngIf="journey.total_process">
                          <div class="top">
                            <i class="fas fa-square-check"></i> <span> {{ getLanguages('businessSolution/top/info/totalNumberProcess') }} : {{ journey.total_process }} </span>
                          </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!((journey.indicators && journey.indicators.length) || journey.personas || journey.total_process)">
                        <div class="noData">
                            <div class="icon">
                                <i class="fa-solid fa-exclamation"></i>
                            </div>
                            <span [ngStyle]="{'font-size': '1.3vh'}">
                                {{ getLanguages('errors/noData')}}
                            </span>
                          </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <div class="arrow arrow-right" (click)="scrollRight('journeys')">
        <i class="fas fa-angle-right"></i>
    </div>
  </div>
  <div class="row" *ngIf="!showGuidelineLink">
    <div class="div-buttons">
      <div class="count-proccess">
        {{ getLanguages('businessSolution/top/info/totalNumberProcess') }} : {{ totalNumberProccess }}
      </div>
      <div class="button-save">
          <button (click)="finishedCopyClone()" *ngIf="isPaste" class="finished-button button-tooltip">
            {{ getLanguages('businessSolution/top/info/finished') }}
          </button>
          <button (click)="saveTree(null, business_solution.uuid)" [disabled]="!isEdited" class="left-button button-tooltip" [ngClass]="!isEdited ? 'disabled no-pointer' : ''" *ngIf="getSystemPermission('business_solutions', 'save_tree') && isEdited">
            {{ getLanguages('save') }}
          </button>
          <button [disabled]="!isEdited" class="right-button" [ngClass]="!isEdited ? 'disabled no-pointer' : ''"  [matMenuTriggerFor]="select" *ngIf="getSystemPermission('business_solutions', 'save_tree') && isEdited">
            <i class="fas fa-angle-down"></i>
          </button>
          <button (click)="toggleNameVersions()" class="left-button button-tooltip" *ngIf="getSystemPermission('business_solutions', 'save_tree') && !isEdited">
            {{ getLanguages('saveAs') }}
          </button>
          <mat-menu #select="matMenu">
            <button (click)="toggleNameVersions()" class="menu-button">
              {{ getLanguages('saveAs') }}
            </button>
          </mat-menu>
      </div>
      <button (click)="toggleUpload()"  class="button-tooltip left-button"  *ngIf="getSystemPermission('business_solutions', 'import')">
        {{ getLanguages('businessSolution/top/info/import') }}
      </button>
      <div class="button-save" *ngIf="getSystemPermission('business_solutions', 'export')">
        <button class="save" [matMenuTriggerFor]="selectExport">
          {{ getLanguages('businessSolution/top/info/export') }}
          <i class="fas fa-angle-down" style="margin-left: 2vh;"></i>
        </button>
        <mat-menu #selectExport="matMenu">
          <button (click)="getTreeXls('json')" class="menu-button">
            {{ getLanguages('businessSolution/top/info/export/json') }}
          </button>
          <button (click)="getTreeXls('xls')" class="menu-button">
            {{ getLanguages('businessSolution/top/info/export/xls') }}
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="isEdited">
    <div class="alert-save">
      <div class="toggle-save"></div>
      {{ getLanguages('auth/alert/isEdited') }}
    </div>
  </div>
  <div class="content">
    <div class="title" *ngIf="!showGuidelineLink">
      <span>
        {{ getLanguages('businessSolution/content/processTree') }}
      </span>
      <section>
        <i class="fa-solid fa-network-wired" class="button-tooltip"></i>
        <div class="toggle-tooltip">
          <div class="item-op">
            {{ getLanguages('businessSolution/top/info/diagram') }}
          </div>
        </div>
      </section>
      <i class="fas fa-network-wired" (click)="activateBpmn(filesOld)" *ngIf="getSystemPermission('business_solutions', 'bpmn')"></i>
      <i class="fas fa-plus" (click)="verifyAddNewLevel(1, {}, $event, menuTrigger)" *ngIf="getSystemPermission('business_solutions', 'create_process')"></i>
      <mat-menu #selectNewLevel="matMenu">
        <button (click)="loadModal(1, getModifiedNode({}, item?.level - 1 ))"  [ngClass]="item?.level == -1 ? 'fixed-level-button' : ''" class="menu-button" *ngFor="let item of menuLevelArray">
          {{ item.name }}
        </button>
      </mat-menu>                    
      <button [matMenuTriggerFor]="selectNewLevel" #menuTrigger="matMenuTrigger" style="width: 0; height: 0;border: none; padding: 0;"></button>
    </div>
    <span class="version-text">{{ getLanguages('businessSolution/version/name') }}: {{ nameVersion }}</span>
    <div class="content-content" *ngIf="!showGuidelineLink">
      <div class="tree" [ngStyle]="{'width': isResponsive() && (isShow || isShowEdit) ? '50%' : '100%'}">
        <div class="noData" *ngIf="noData && !isLoading">
          <div class="icon">
            <i class="fa-solid fa-exclamation"></i>
          </div>
          <span>
            {{ getLanguages('errors/noData')}}
          </span>
        </div>

        <p-tree class="ptree"
          [value]="files"
          *ngIf="!isLoading && !noData"
          selectionMode="single"
          [(selection)]="selectedFile"
          (onNodeSelect)="loadModal(2, $event)"
          (onNodeExpand)="onNodeExpand($event)"
          >
            <ng-template let-node pTemplate="default">
              <div class="pcontent" id="{{ node.uuid }}">
                <div class="ptitle">
                  <section>
                    <!-- <i [ngClass]="getClass(node)" class="button-tooltip"></i> -->
                    <div class="div-acronym" [ngClass]="node?.is_cut == true ? 'background-rose-2' : 'background-rose-1'"> {{ getIconLevel(node?.level) }} </div>
                    <div class="toggle-tooltip">
                      <div class="item-op">
                        {{ (node && node.label ? node.label : '') }}
                      </div>
                    </div>
                  </section>

                  <div class="circle-status" [ngClass]="getCircleClass(node)"></div>
                  <i class="fa-solid fa-thumbs-up" *ngIf="node.is_approved"></i>

                  <section>
                    <div [class.error_node]="hasError(node.uuid)" class="button-tooltip">
                      <ng-container *ngFor="let itemArray of node.arrayText">
                        <div>{{ itemArray }}</div>
                      </ng-container>
                      <ng-container *ngIf="!node.arrayText || node.arrayText.length === 0" >
                        <span *ngIf='hasError(node.uuid)' class="point_error">
                          *
                        </span> 
                        <span [class.first-column]="isFirstColumn(node)" [ngClass]="filterArray.includes(node.uuid) ? 'title-destak' : ''">
                          {{ (node && node.label ? node.label : '') }}
                        </span>
                      </ng-container>
                    </div>
                    <div class="toggle-tooltip" *ngIf="getNodeIntegrations(node).length">
                      <div *ngFor="let integration of getNodeIntegrations(node)" class="item-op">
                        {{ integration.type }} - {{ integration.name }}
                      </div>
                    </div>
                  </section>
                </div>
                <div class="picons">

                  <section>                    
                    <div class="button button-tooltip" (click)="activateBpmn(node.children, node)"
                    *ngIf="getSystemPermission('business_solutions', 'bpmn')">
                    <i class="fas fa-network-wired"></i>
                  </div>
                    <div class="toggle-tooltip">
                      <div class="item-op">
                        {{ getLanguages('businessSolution/top/info/diagram') }}
                      </div>
                    </div>
                  </section>


                  <section>
                    <div class="button button-tooltip" (click)="showDeleteItemModal(node, $event)"
                    *ngIf="getSystemPermission('business_solutions', 'delete_process') &&
                    (!node.children || node.children.length == 0) &&
                    !isPaste && (!node.clones || node.clones.length == 0)">
                      <i class="fas fa-trash i"></i>
                    </div>
                    <div class="toggle-tooltip">
                      <div class="item-op">
                        {{ getLanguages('businessSolution/top/info/delete_process') }}
                      </div>
                    </div>
                  </section>

                  <section>
                    <div class="button button-tooltip" (click)="copyClipboard(node, $event)"
                      *ngIf="getSystemPermission('business_solutions', 'transfer_process') && !isPaste && !isEdited && !isLoadingClipboard && node.master != 'ROOT'">
                      <i class="fas fa-clipboard"></i>
                    </div>
                    <div class="toggle-tooltip">
                      <div class="item-op">
                        {{ getLanguages('businessSolution/top/info/copy_to_clipboard') }}
                      </div>
                    </div>
                  </section>

                  <section *ngIf="showPaste(node) && (!node.is_cut || node?.is_cut == false)">
                    <div class="button button-tooltip" (click)="pasteCopyNode(node, $event)"
                      *ngIf="(getSystemPermission('business_solutions', 'copy_process') || getSystemPermission('business_solutions', 'clone_process'))&& isPaste && node.level > 0">
                      <i class="fas fa-paste i"></i>
                    </div>
                    <div class="toggle-tooltip margin-right-tooltip">
                      <div class="item-op">
                        {{ getLanguages('businessSolution/top/info/paste') }}
                      </div>
                    </div>
                  </section>

                  <section *ngIf="!showPaste(node) && !(!node.is_cut || node?.is_cut == true)">
                    <div class="button button-tooltip no-pointer">
                      <i class="fa-solid fa-circle-info"></i>                    
                    </div>
                    <div class="toggle-tooltip margin-right-tooltip">
                      <div class="item-op">
                        {{ getLanguages('errors/businessTree/SameNameElement') }}
                      </div>
                    </div>
                  </section>

                  <section>
                    <div class="button button-tooltip" (click)="copyNode(node, $event)"
                      *ngIf="getSystemPermission('business_solutions', 'copy_process') && !isPaste">
                      <i class="fas fa-copy i"></i>
                    </div>
                    <div class="toggle-tooltip">
                      <div class="item-op">
                        {{ getLanguages('businessSolution/top/info/copy') }}
                      </div>
                    </div>
                  </section>

                  <section>
                    <div class="button button-tooltip" (click)="copyNode(node, $event, 'cut')"
                      *ngIf="getSystemPermission('business_solutions', 'cut_proccess') && !isPaste">
                      <i class="fa-solid fa-scissors"></i>
                    </div>
                    <div class="toggle-tooltip">
                      <div class="item-op">
                        {{ getLanguages('businessSolution/top/info/cut') }}
                      </div>
                    </div>
                  </section>

                  <section>
                    <div class="button button-tooltip" (click)="cloneNode(node, $event)"
                      *ngIf="getSystemPermission('business_solutions', 'clone_process') && !isPaste">
                      <i class="fas fa-clone i"></i>
                  </div>
                    <div class="toggle-tooltip">
                      <div class="item-op">
                        {{ getLanguages('businessSolution/top/info/clone') }}
                      </div>
                    </div>
                  </section>

                  <section>
                    <div class="button button-tooltip button-relative" (click)="verifyAddNewLevel(1, node, $event, menuTrigger)"
                      *ngIf="getSystemPermission('business_solutions', 'create_process') && !isPaste && node.level > 0" >
                      <i class='fas fa-plus i'></i>
                      <mat-menu #selectNewLevel="matMenu">
                        <button (click)="loadModal(1, getModifiedNode(node, item.level - 1))" class="menu-button" [ngClass]="item?.level == -1 ? 'fixed-level-button' : ''" *ngFor="let item of menuLevelArray">
                          {{ item.name }}
                        </button>
                      </mat-menu>                    
                      <button [matMenuTriggerFor]="selectNewLevel" #menuTrigger="matMenuTrigger" style="width: 0; height: 0;border: none; padding: 0;"></button>
                    </div>

                    <div class="toggle-tooltip margin-right-tooltip">
                      <div class="item-op">
                        {{ getLanguages('businessSolution/top/info/add') }}
                      </div>
                    </div>
                  </section>

                </div>
              </div>
            </ng-template>
        </p-tree>
      </div>
      <div class="form-modal"  *ngIf="isShow || isShowEdit" [ngStyle]="{'width': !isResponsive() ? '100%' : 'calc(50% - 1vh)'}">
        <app-create *ngIf="isShow"  (toggleModal)="loadModal(0)" (getBusinessTree)="getBusinessTree()" [properties]="properties" [parameters]="objectModal" (updateFiles)="updateFiles($event)"></app-create>
        <app-edit *ngIf="isShowEdit" (emitNavigateOriginNode)="navigateOriginNode($event)" (toggleModalEdit)="loadModal(0)" (getBusinessTree)="getBusinessTree()" [properties]="properties" [parameters]="objectModal" (updateFiles)="updateFiles($event)"></app-edit>
      </div>
    </div>

    <div class="guidelineLink" *ngIf="showGuidelineLink">
      <div class="i">
        <i class="fa-solid fa-exclamation"></i>
      </div>    

      <span (click)="navigate('guideline')" *ngIf="getSystemPermission('standards')">
        {{ getLanguages('businessSolution/content/info/guideline') }}
      </span>

      <button (click)="navigate('guideline')" *ngIf="getSystemPermission('standards')">
        <i class="fas fa-sliders"></i>
        {{ getLanguages('businessSolution/content/info/goToGuideline') }}
      </button>
    </div>
  </div>
</div>

<div class="background-modals" *ngIf="isBpmn || isShowDelete || isShowImport || isLoadingExport || isShowCreateNameModal || isLoading || sharedData.isShowModalChangeTree">
  <app-delete-component *ngIf="isShowDelete" (toggleDelete)="openCloseModalDelete(false)" (updateFiles)="updateFiles($event)" [parameters]="objectModal" [type]="1"></app-delete-component>
  <app-import *ngIf="isShowImport" (toggleImportModal)="toggleUpload()" (updateFiles)="updateFiles($event)" [properties]="properties" [parameters]="parameters" [type]="'business_solution'"> </app-import>
  <app-loading *ngIf="isLoadingExport || isLoading"></app-loading>
  <app-name-versions *ngIf="isShowCreateNameModal" (toggleModal)="toggleNameVersions()" (saveTree)="saveTree($event)" [properties]="properties"></app-name-versions>
  <app-changes-tree *ngIf="sharedData.isShowModalChangeTree" (toggleModal)="closeModalChange()" (save)="saveTree()" (close)="toggleChangeTree()"></app-changes-tree>
  <div id="external-bpmn-component" *ngIf="isBpmn">
    <div class="top-external-bpmn">
      <div class="logo">
        <div class="image"></div>
        <span>OnePage</span>
      </div>
      <span> {{ nameBpmn }} </span>
      <section *ngIf="getSystemPermission('business_solutions', 'bpmn')">
        <i class="fas fa-xmark" (click)="isBpmn = !isBpmn"></i>
      </section>
    </div>
  </div>
</div>


