import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IResponse } from '../../interfaces/IResponse';
import { environment } from '../../environments/environment';
import { Router } from "@angular/router";
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { apiOfflineAsync } from "../../layouts/pages/pages.component";

@Injectable({
  providedIn: 'root'
})

export class BusinessSolutionsService {
  parameters: any = {}
  isXls: boolean = false;
  isJson: boolean = false
  API_PATH: string

  private ngUnsubscribe: Subject<void> = new Subject<void>()

  requestOptionsFormData: any = new HttpHeaders({ 'Content-Type': 'multipart/form-data' })

  private requestOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  public attachmentArray: any = []

  constructor(
    private httpClient: HttpClient,
    private router: Router,
  ) {
    this.API_PATH = environment.apiUrl;
  }

  async redirectError(error: any) {
    if (error.status == 401) {
      return await this.navigate('/login');
    }
    return null;
  }

  async navigate(route: string) {
    return await this.router.navigate([route]);
  }

  private getURLSearchParams({ dimension, environment, corporation, search }: any = {}) {
    let params = new URLSearchParams();

    if (!environment) {
      environment = this.parameters.environment
    }

    if (!corporation) {
      corporation = this.parameters.corporation
    }

    if (environment && environment.slug) {
      params.set('environment', environment.slug);
    }

    if (corporation && corporation.slug) {
      params.set('corporation', corporation.slug);
    }

    if (dimension) {
      params.set('dimension', dimension);
    }

    if (search) {
      params.set('search', search);
    }

    if (this.isXls) {
      params.set('xls', this.isXls.toString());
    }

    if (this.isJson) {
      params.set('json', this.isJson.toString());
    }

    return params.toString();
  }

  async setAttachmentArray(row: any) {
    const index = this.attachmentArray.findIndex((item: any) => item.uuid == row.uuid)
    if (index >= 0) {
      return this.attachmentArray[index] = row
    }
    this.attachmentArray.push(row)
  }

  getBusinessTree(parameters: any, mode: boolean = false): Observable<IResponse> {
    this.parameters = parameters;
    let url = `${this.API_PATH}business-solutions`
    if (mode) {
      url += `?mode=${mode}`;
    }
    this.ngUnsubscribe.next()
    return this.httpClient.get<IResponse>(url)
      .pipe(
        takeUntil(this.ngUnsubscribe)
      );
  }

  async getJourneys() {
    const url = `${this.API_PATH}business-solutions/journeys`;
    this.ngUnsubscribe.next()
    return await this.httpClient.get<IResponse>(url)
      .toPromise()
  }


  async getOptionsArray() {
    const url = `${this.API_PATH}business-solutions/options/array`;
    this.ngUnsubscribe.next()
    return await this.httpClient.get<IResponse>(url)
      .toPromise()
  }

  async getDiagram(uuid: string) {
    const url = `${this.API_PATH}business-solutions-xml/${uuid}`; // Inclui o uuid na URL
    this.ngUnsubscribe.next();
    return await this.httpClient.get<IResponse>(url).toPromise();
  }


  async setXmlDiagram(obj: any, uuid: any = null) {
    if (obj?.type != 'put') {
      return await this.httpClient.post<IResponse>(
        `${this.API_PATH}business-solutions-xml`,
        {
          uuid: uuid,
          xml: obj?.xml,
        },
        this.requestOptionsFormData
      ).toPromise();
    }
    return await this.httpClient.put<IResponse>(
      `${this.API_PATH}business-solutions-xml/${uuid}`,
      {
        uuid: uuid,
        xml: obj?.xml,
      },
      this.requestOptionsFormData
    ).toPromise();
  }

  async setBusinessTree(body: any, uuid: any = null) {
    this.attachmentArray = []

    if (!uuid) {
      return await this.httpClient.post<IResponse>(
        `${this.API_PATH}business-solutions`,
        body,
        this.requestOptionsFormData
      ).toPromise();
    }
    return await this.httpClient.put<IResponse>(
      `${this.API_PATH}business-solutions/${uuid}`,
      body,
      this.requestOptionsFormData
    ).toPromise();
  }

  async getTreeXls(parameters: any): Promise<any> {
    this.parameters = parameters

    this.isJson = this.parameters.json
    this.isXls = this.parameters.xls

    try {
      let response = await this.httpClient.get<any>(
        `${this.API_PATH}business-solutions?${this.getURLSearchParams()}`,
        { responseType: 'blob' as 'json' }
      ).toPromise();

      this.isJson = false
      this.isXls = false
      return response;
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }
  async downloadJson(response: any, fileName: string) {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(response);
    link.download = `${fileName}.json`; // Substitua pelo nome desejado para o arquivo
    link.click();
    window.URL.revokeObjectURL(link.href);
  }
  async downloadJsonXls(response: any, fileName: string, type: string) {
    let blob: Blob;
    if (type === 'json') {
      return this.downloadJson(response, fileName);
    } else if (type === 'xls') {
      blob = new Blob([response], { type: 'application/vnd.ms-excel' });
    } else {
      throw new Error('Invalid type specified. Only "json" and "xls" are supported.');
    }

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${fileName}.${type}`;

    link.click();
    window.URL.revokeObjectURL(link.href);
  }

  async setBusinessTreeImportXls(file: any) {

    let formData = new FormData()
    formData.append('file', file)

    return await this.httpClient.post<any>(
      `${this.API_PATH}business-solutions/import/xls`,
      formData,
      this.requestOptionsFormData
    ).toPromise()
  }

  findMasterKey(files: any): any {
    let nodeMasterKey: any;
    let valueMasterKey = Number.MIN_VALUE;

    const foreachTree = (node: any) => {
      if (node.key !== undefined) {
        if (node.key > valueMasterKey) {
          valueMasterKey = node.key;
          nodeMasterKey = node;
        }
      }

      if (node.children && node.children.length > 0) {
        node.children.forEach(foreachTree);
      }
    };

    files.forEach(foreachTree);
    return valueMasterKey;
  }

}
