import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { getLanguageCookie } from 'src/assets/language';
import { systemPermissionsNew } from 'src/app/helpers/systemPermissions';
import { localStorageToJsonAsync } from  "../../../../helpers/cookieToJson";
import { v4 } from 'uuid';
import { Router } from '@angular/router';
import { sortArrayOfObjects } from 'src/app/helpers/sortArrayOfObjects';

@Component({
  selector: 'app-technology',
  templateUrl: './technology.component.html',
  styleUrls: ['./technology.component.scss']
})
export class TechnologyComponent {
  @Input() parameters: any = {}
  @Input() dimensions: any = {}
  @Output() parametersParent = new EventEmitter<string>();
  @ViewChild('scrollContainerBox') scrollContainerBox!: ElementRef;

  showArrowLevel1: boolean = false;

  selectedLevel1: any = {
    uuid: '',
    name: ''
  }

  searchLandscape: string = ''

  currentPageTechnologies = 1;
  itemsPerPageTechnologies = 5;
  totalPagesTechnologies = 1;
  indexTableTechnologies: any = { startIndex: 0, endIndex: 0 }

  task: any = {
    color: 'Accent'
  }

  properties: any = {}

  searchTechnology: string = '';
  searchModule: string = '';
  searchTransaction: string = '';

  isNewTransaction: boolean = false;
  uuidModule: string = ''

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {}

  async ngOnInit() {  
    if(this.parameters?.arrayTechnologies?.length){
      this.parameters.arrayTechnologies = sortArrayOfObjects(this.parameters.arrayTechnologies)
      this.activateLevel1(this.parameters.arrayTechnologies[0])
    }

    await this.getDisplayedRows()
    this.properties = await localStorageToJsonAsync()
  }

  async ngAfterViewInit() {
    if(!this.scrollContainerBox || !this.scrollContainerBox.nativeElement){
      return
    }

    this.scrollContainerBox.nativeElement.scrollLeft = 0;

    await this.checkOverflow(1)
  }

  ngOnDestroy() {
    const technologies = this.parameters.item.technologies_table;
  
    for (const key in technologies) {
      if (technologies.hasOwnProperty(key)) {
        if (Array.isArray(technologies[key])) {
          technologies[key] = technologies[key]?.filter((item: any) => 
            item.technology !== '');
        }
      }
    }
  }  

  scrollLeft(param?: string) {
    const viewportWidth = window.innerWidth;
    const vwToPixels = viewportWidth / 100;

    if(param == 'box'){
      this.scrollContainerBox.nativeElement.scrollLeft -= 25 * vwToPixels;
    }
  }

  scrollRight(param?: string) {
    const viewportWidth = window.innerWidth;
    const vwToPixels = viewportWidth / 100;

    if(param == 'box'){
      this.scrollContainerBox.nativeElement.scrollLeft += 25 * vwToPixels;
    }
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  getSystemPermission(screen: string, action: string){
    return systemPermissionsNew(screen, action)
  }

  async checkOverflow(type: number){
    let div
    if(type == 1){
      
      if(!this.scrollContainerBox || !this.scrollContainerBox.nativeElement){
        return
      }
      
      div = this.scrollContainerBox.nativeElement;
      this.showArrowLevel1 = div.scrollWidth > div.clientWidth;
      this.cdr.detectChanges();
    }
  }

  async activateLevel1(item: any){
    this.selectedLevel1 = {
      uuid: item.uuid,
      label: item.label,
      children: item.children
    }

    this.selectedLevel1.children = sortArrayOfObjects(this.selectedLevel1.children)

    if(!this.parameters?.item?.technologies_table[item.uuid]?.length){
      this.parameters.item.technologies_table[item.uuid] = []
    }

    await this.getTotalPages(true);
    await this.getDisplayedRows()
    await this.checkOverflow(2)
  }

  async addRow(){
    this.parameters.item.technologies_table[this.selectedLevel1.uuid].push(
      {
        uuid: v4(),
        technology: '',
        module: '',
        routine: '',
        aplication: '',
        transaction: '',
        clip: false,
      }
    )
    await this.getTotalPages(true);
    await this.getDisplayedRows()
  }

  async getDisplayedRows(){
    this.indexTableTechnologies.startIndex = (this.currentPageTechnologies - 1) * this.itemsPerPageTechnologies;
    this.indexTableTechnologies.endIndex = this.indexTableTechnologies.startIndex + this.itemsPerPageTechnologies;
  }

  getRows(){
    return this.parameters.item.technologies_table[this.selectedLevel1.uuid]?.slice(this.indexTableTechnologies.startIndex, this.indexTableTechnologies.endIndex)
  }

  async getTotalPages(isLastPage: boolean = false){
    if(!this.selectedLevel1.uuid){
      return
    }

    this.totalPagesTechnologies = Math.ceil(this.parameters?.item?.technologies_table[this.selectedLevel1?.uuid]?.length / this.itemsPerPageTechnologies)
    if (this.totalPagesTechnologies == 0) this.totalPagesTechnologies = 1
    if ((this.currentPageTechnologies >= this.totalPagesTechnologies && this.totalPagesTechnologies > 0) || isLastPage) {
      this.currentPageTechnologies = this.totalPagesTechnologies;
    }

    await this.getDisplayedRows()
  }

  async choseItemsPage(number: number) {    
    this.currentPageTechnologies = 1;
    this.itemsPerPageTechnologies = number;
  }

  getCurrentPages(): number{
    return this.currentPageTechnologies
  }

  setCurrentPages(param: number){
    return this.currentPageTechnologies = param
  }

  getItemsPerPage(): number{
    return this.itemsPerPageTechnologies
  }

  getTotalNumberPages(){
    return this.totalPagesTechnologies
  }

  async removeItem(uuid: string){
    this.parameters.item.technologies_table[this.selectedLevel1.uuid] = this.parameters.item.technologies_table[this.selectedLevel1.uuid].filter((item: any) => item.uuid != uuid)
    await this.getTotalPages(true);
    await this.getDisplayedRows()
  }

  choseItemSelect(type: string, value: any, element: any){
    switch (type) {
      case 'technology':
        element.technology = {
          uuid: value.uuid,
        };
        delete element.module
        delete element.transaction
        break;
      case 'module':
        element.module = {
          uuid: value.uuid,
        };
        delete element.transaction
        break;
      case 'transaction':
        element.transaction = {
          uuid: value.uuid
        };
        break;
      default:
        break;
    }
  }

  openModalNewTransaction(uuid: string) {
    this.uuidModule = uuid;
    this.isNewTransaction = true;
  }

  handleUpdatedTechnologies(updatedTechnologies: any[]) {
    this.parameters.arrayTechnologies = updatedTechnologies;
  }

  getNameSelectedBox(uuid: string): string {
    const response = 'null';
  
    const searchTree = (nodes: any[]): string | null => {
      for (const node of nodes) {
        if (node.uuid === uuid) {
          return node.label;
        }
        if (node.children) {
          const result = searchTree(node.children);
          if (result) {
            return result;
          }
        }
      }
      return null;
    };
  
    const result = searchTree(this.parameters.arrayTechnologies);
    return result || response;
  }
  

  getArray(uuid: string): any[] {
    const response: any[] = [];

    const searchTree = (nodes: any[]): any[] | null => {
      for (const node of nodes) {
        if (node.uuid === uuid) {
          return sortArrayOfObjects(node.children);
        }
        if (node.children) {
          const result = searchTree(node.children);
          if (result) {
            return sortArrayOfObjects(result);
          }
        }
      }
      return null;
    };

    const result = searchTree(this.parameters.arrayTechnologies);
    if (result) {
      return sortArrayOfObjects(result);
    } else {
      return response;
    }
  }

  getAccessGuideline(){
    let flag: boolean = false;

    this.properties?.screens?.settings?.forEach((module: any)=> {
      if(module?.slug == 'technologies'){
        flag = true
      }
    })

    return flag
  }

  navigate(url: string){
    this.router.navigate([url])
  }

  handleTransactionChange(event: boolean) {
    this.isNewTransaction = event;
  }
}

