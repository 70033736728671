export async function setLogoLocalStorage (object: any, name: string = '', property: any = null) {
  localStorage.removeItem(name)
  if (object?.url) {
    // localStorage.setItem(name, btoa(object.url))
    localStorage.setItem(name, object.url)
    delete object.url
  }
  if (object[property] && property) {
    localStorage.setItem(name, object[property])
  }
  return object
}
