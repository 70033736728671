<div class="all-content">
    <div class="top">
        <div class="title">
            {{ getLanguages('guideline/entity') }} - {{ getLanguages('guideline/level') }} {{ parameters?.item?.level ?? '' }}
        </div>

        <i class="fas fa-xmark" (click)="exit()"></i>
    </div>

    <div class="row" *ngIf="showBanner">
        <div class="banner">
          <div class="toggle-left"></div>
          <i class="fas fa-lightbulb"></i>
          <span>
            {{ getLanguageBannerLevel() ? getLanguages(getLanguageBannerLevel()) : '' }}
          </span>
          <i class="fas fa-xmark" (click)="showBanner = false"></i>
        </div>
    </div>

    <div class="row">
        <div class="item item-3">
            <span> {{ getLanguages('guideline/form/name') }} <b>*</b> </span>
            <input [disabled]="!parameters.isEdit" type="text" *ngIf="parameters?.item?.name != null"  [(ngModel)]="parameters.item.name">
        </div>

        <div class="item item-3">
            <span> {{ getLanguages('guideline/form/acronym') }} <b>*</b> </span>
            <input [disabled]="!parameters.isEdit" type="text" maxlength="2"  *ngIf="parameters?.item?.acronym != null" [(ngModel)]="parameters.item.acronym">
        </div>

        <div class="item item-3">
            <span> {{ getLanguages('guideline/form/obligatoriness') }} <b>*</b> </span>
            <button class="button-menu" [disabled]="!parameters.isEdit || parameters?.item?.level == 1 || parameters?.item?.is_filled" [matMenuTriggerFor]="obligatorinessMenu" [ngClass]="!parameters.isEdit || parameters?.item?.level == 1 || parameters?.item?.is_filled ? 'disabled' : ''">
              <span>
                {{  getSelectedObligatoriness() ? getNames(getSelectedObligatoriness()) : '' }}
              </span>
              <i class="fa-solid fa-caret-down" *ngIf="parameters.isEdit && parameters?.item?.level > 1 "></i>
            </button>
      
            <mat-menu #obligatorinessMenu="matMenu">
              <div class="menu">
                <div class="scroll">
                  <ng-container *ngFor="let obligatoriness of mandatoryArray">
                    <ng-container *ngIf="getNames(obligatoriness)?.toLowerCase().includes(searchObligatoriness?.toLowerCase())">
                      <div class="item-menu" (click)="choseItemSelect('obligatoriness', obligatoriness)">
                        <span [ngClass]="getNames(obligatoriness) == getNames(selectedObligatoriness) ? 'valid-span' : 'invalid-span'" *ngIf="selectedObligatoriness">
                          {{ getNames(obligatoriness) }}
                        </span>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </mat-menu>
        </div>
    </div>

    <div class="row">
        <div class="item item-1">
          <span> {{ getLanguages('businessSolution/modal/addCode/description') }} </span>
          <textarea [disabled]="!parameters.isEdit" *ngIf="parameters?.item?.description!= null" [(ngModel)]="parameters.item.description" ></textarea>
        </div>
    </div>

    <div class="row">
        <div class="item item-1">
            <span>
                {{ getLanguages('guideline/level_composition') }}
            </span>
        </div>
    </div>

    <div class="row-table">
        <div class="div-th">
            <span> {{ getLanguages('guideline/form/form_areas') }} </span>
            <div>
              <span> {{ getLanguages('guideline/form/display') }} </span>
              <mat-checkbox
                  (change)="$event ? toggleAllRows() : null"
                  [color]="task.color"
                  [checked]="isAllSelected()"
                  [aria-label]="checkboxLabel()"
                  class="checkbox"
                  [disabled]="!parameters.isEdit"
                  >
              </mat-checkbox>
            </div>
        </div>
        <div class="content-table">
            <div class="div-tr" *ngFor="let tab of dataSource.data">
                <span> {{ getLanguages('businessSolution/modal/'+ tab.slug +'/title') }} </span>
                <mat-checkbox
                    class="checkbox"
                    (click)="$event.stopPropagation(); setSelection(tab.slug)"
                    [color]="task.color"
                    [checked]="getSelection(tab.slug)"
                    [disabled]="!parameters.isEdit"
                    >
                </mat-checkbox>
            </div>
        </div>
    </div>
    
         
    <div class="row-buttons">
        <div class="save" (click)="parameters.isEdit = true" *ngIf="!parameters.isEdit">
          {{ getLanguages('edit')}}
        </div>
        <div class="save cancel" (click)="cancel()" *ngIf="parameters.isEdit">
          {{ getLanguages('cancel')}}
        </div>
        <div class="save" (click)="save()" *ngIf="parameters.isEdit">
          {{ getLanguages('save')}}
        </div>
    </div>
</div>

<div class="background-modals" *ngIf="isShowChangeFormGuideline">
  <app-changes-tree *ngIf="isShowChangeFormGuideline" (toggleModal)="closeModalChange()" (save)="save()" (close)="toggleChangeTree()"></app-changes-tree>
</div>
