import { Component, Input } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { to64decode } from 'src/app/helpers/base64';
import { getLanguageCookie } from 'src/assets/language';
import { MatTableDataSource } from '@angular/material/table';
import { v4 } from "uuid";
import { addAlert, apiOfflineAsync } from "../../../../layouts/pages/pages.component";
import { ExtendedAttributeService } from 'src/app/services/extended_attribute/extended-attribute.service';
import { sortArrayOfObjects } from 'src/app/helpers/sortArrayOfObjects';
@Component({
  selector: 'app-extended-attributes',
  templateUrl: './extended-attributes.component.html',
  styleUrls: ['./extended-attributes.component.scss']
})
export class ExtendedAttributesComponent {
  @Input() parameters: any = {}
  @Input() dimensions: any = {}

  selectedFlag: any = {
    lang: 'en',
    file: 'us.svg'
  };

  dataSource!: MatTableDataSource<any>;
  displayedColumns: string[] = ['name','content', 'inclusion_date', 'last_change_date', 'action'];
  currentPage = 1;
  currentPageReq = 1;
  itemsPerPage = 10;

  currentAttribute: any = {}

  searchAttribute: string = ''
  attributeArray: any = []

  isShowCreate: boolean = false; 

  newAttributeJson: any = {
    uuid: '',
    name: '',
    type: '',
    values: '',
  }

  attributeTypeArray: any = []
  searchType: string = ''
  selectedType: any
  searchContent: string = ''

  constructor(
    private extendedAttribute: ExtendedAttributeService
  ) {
    this.dataSource = new MatTableDataSource();
  }

  async ngOnInit(){
    this.dataSource.data = this.parameters.item.extendedAttributes ?? []
    this.attributeTypeArray = this.parameters.optionsArray.attributeTypeEnum
    await this.getCorporationExtendedAttribute()
  }

  async getCorporationExtendedAttribute() {
    try {
      const response: any = await this.extendedAttribute.getCorporationExtendedAttribute();
      if(response.data){
        this.attributeArray = []
        this.attributeArray.push({uuid: '-1', name: 'New +'})
        this.attributeArray.push(...sortArrayOfObjects(response.data))
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  getNames(name: any){
    return name[to64decode(localStorage.getItem('language')).lang]
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  isData(){
    return this.dataSource.data.length > 0
  }

  getDisplayedRows(): any[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.dataSource.data.slice(startIndex, endIndex);
  }

  getColumns(){
    return this.displayedColumns.slice(1)
  }

  getItemsPerPage(){
    return this.itemsPerPage
  }

  getCurrentPages(): number{
    return this.currentPage
  }

  choseItemsPage(number: number){
    this.itemsPerPage = number;
    this.getDisplayedRows()
  }

  onPageChange(newPage: number, text: string): void {
    this.currentPage = newPage;
  }

  getTotalPages(): number {
    return Math.ceil(this.dataSource.data.length / this.itemsPerPage);
  }

  async checkValue() {
    let total = this.getTotalPages()
    if (this.currentPage > total) this.currentPage = total;
  }

  async newAttribute(){
    this.currentAttribute = {
      uuid: v4(),
      name: '',
      type: '',
      content: '',
      created_date: '',
      updated_date: '',
    };
    this.dataSource.data.push(this.currentAttribute)
  }

  choseItemSelect(type: string, value: any, element: any){
    switch (type) {
      case 'attribute':
        if(value.uuid == '-1'){
          this.isShowCreate = true
        }else{
          element.name = value.name
          element.type = value.type
          element.content = ''
        }
        break;
      case 'content':
        element.content = value
        break;
      case 'attributeType':
        this.selectedType = value
        this.newAttributeJson.type = value.slug
        break;
      default:
        break;
    }
  }

  async deleteAttribute(uuid: string) {
    if(!this.parameters.isEdit){
      return
    }

    this.dataSource.data = this.dataSource.data.filter(item => item.uuid !== uuid);
    this.parameters.item.extendedAttributes = this.dataSource.data
  }

  setValidsInputs(){
    let inputs: any = []

    if (!this.newAttributeJson.name || this.newAttributeJson.name == '') {
      inputs.push(this.getLanguages('businessSolution/modal/extendedAttributes/name'))
    }

    if (!this.newAttributeJson.type || this.newAttributeJson.type == '') {
      inputs.push(this.getLanguages('businessSolution/modal/extendedAttributes/type'))
    }

    if (this.newAttributeJson.type == 'restricted-text' && (!this.newAttributeJson.values || this.newAttributeJson.values == '')) {
      inputs.push(this.getLanguages('businessSolution/modal/extendedAttributes/possibleValues'))
    }
    
    if (inputs.length > 0) {
      addAlert('orange', `${this.getLanguages('mandatoryField')}${inputs.join(`, `)}`)
      return false
    }
    return true
  }


  async setAttribute(){
    if (!this.setValidsInputs()) {
      return;
    }
    let flag = false;

    this.attributeArray.forEach((item: any) => {
      if(item.name == this.newAttributeJson.name){
        addAlert('orange', this.getLanguages('businessSolution/modal/extendedAttributes/extended_attribute_already_registered'))
        flag = true
        return
      }
    });

    if(flag){
      return
    }

    if(this.newAttributeJson.name == 'New +'){
      addAlert('danger', this.getLanguages('auth/alert/nameReservedWord'))
      return
    }

    if(this.newAttributeJson.type != 'restricted-text'){
      this.newAttributeJson.values = ''
    }

    if(typeof(this.newAttributeJson.values) === 'string'){
      this.newAttributeJson.values = this.newAttributeJson.values
      .split(';')
      .map((value: string) => value.trim())
      .filter((value: string) => value !== '');
    }
    
    this.newAttributeJson.uuid = v4()
    
    try {
      const response:any = await this.extendedAttribute.setCorporationExtendedAttribute(this.newAttributeJson);
      if (response && [201, 202].includes(response.status) ) {
        addAlert('success', response.message)
        this.newAttributeJson = {
          uuid: '',
          name: '',
          type: '',
          values: '',
        }
        delete this.selectedType
        this.isShowCreate = false
        await this.getCorporationExtendedAttribute();
      } else {
        addAlert('danger', response.message)
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  autoResize(event: Event, row?: any): void {
    const textarea = event.target as HTMLTextAreaElement;
    const tr = textarea.closest('tr');
    
    if (!tr) {
      return;
    }

    const textareas = tr.querySelectorAll('textarea');
    let maxHeight = 0;

    textareas.forEach((ta: HTMLTextAreaElement) => {
      ta.style.height = 'auto'; 
      const taHeight = ta.scrollHeight;
      if (taHeight > maxHeight) {
        maxHeight = taHeight;
      }
    });

    textareas.forEach((ta: HTMLTextAreaElement) => {
      ta.style.height = `${maxHeight}px`;
    });
  }
  
  getArrayContent(name: string): any[]{
    let array: any = []
    this.attributeArray.forEach((item: any) => {
      if(item.name == name){
        array = item.values
      }
    });
    return array
  }

  formatDate(date: Date): string {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);
  
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  }
}
