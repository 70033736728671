<div class="all-content">
  <div class="row">
    <div class="item" [ngClass]="parameters?.item?.master == 'ROOT' ? 'item-85' : 'item-1' ">
      <span> {{ getLanguages('name') }} </span>
      <input [disabled]="!parameters.isEdit" type="text"  [(ngModel)]="parameters.item.label">
    </div>

    <div class="item item-15" *ngIf="parameters?.item?.master == 'ROOT'">
      <span> {{ getLanguages('businessSolution/modal/addCode/icon') }} </span>
      <!-- <button class="button-menu" (click)="showModalIcon()" [disabled]="(properties.me.is_admin == 'OTHERS' && properties.profile.slug != 'administrator') || !parameters.isEdit" [ngClass]="(properties.me.is_admin == 'OTHERS' && properties.profile.slug != 'administrator') || !parameters.isEdit ? 'disabled' : ''"> -->
      <button class="button-menu" (click)="showModalIcon()" [disabled]="!parameters.isEdit" [ngClass]="!parameters.isEdit ? 'disabled' : ''">
        <span>
          <i [ngClass]="parameters.item.icon"></i>
        </span>
        <i class="fa-solid fa-caret-down" *ngIf="parameters.isEdit"></i>
      </button>
    </div>
  </div>

  <div class="row" *ngIf="!parameters.is_new">
    <div class="item item-2">
      <span> {{ getLanguages('businessSolution/modal/addCode/userCreated') }} </span>
      <button class="button-menu disabled" disabled>
        <span>
          {{ parameters.item?.userCreated }}
        </span>
      </button>

      <!-- <mat-menu #processOwnerMenu="matMenu">
        <div class="menu">
          <div class="input" (click)="$event.stopPropagation()">
            <input type="text" [(ngModel)]="searchProcessOwner" placeholder="{{ getLanguages('search') }}">
            <i class="fa-solid fa-magnifying-glass"></i>
          </div>
          <div class="scroll">
            <ng-container *ngFor="let processOwner of processOwnerArray">
              <ng-container *ngIf="processOwner.name.toLowerCase().includes(searchProcessOwner.toLowerCase())">
                <div class="item-menu" (click)="choseItemSelect('processOwner', processOwner)">
                  <span [ngClass]="processOwner.uuid == selectedProcessOwner.uuid ? 'valid-span' : 'invalid-span'">
                    {{ processOwner.name }}
                  </span>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </mat-menu> -->
    </div>

    <div class="item item-2">
      <span> {{ getLanguages('businessSolution/modal/addCode/created_at') }} </span>
      <button class="button-menu disabled" disabled>
        <span>
          {{ parameters.item?.created_at }}
        </span>
      </button>
    </div>
  </div>

  <div class="row" *ngIf="!parameters.is_new">
    <div class="item item-2">
      <span> {{ getLanguages('businessSolution/modal/addCode/userUpdated') }} </span>
      <button class="button-menu disabled" disabled>
        <span>
          {{ parameters.item?.userUpdated }}
        </span>
      </button>
    </div>

    <div class="item item-2">
      <span> {{ getLanguages('businessSolution/modal/addCode/updated_at') }} </span>
      <button class="button-menu disabled" disabled>
        <span>
          {{ parameters.item?.updated_at }}
        </span>
      </button>
    </div>
  </div>

  <div class="row">
    <div class="item item-2">
      <span> {{ getLanguages('businessSolution/modal/addCode/organogram_responsible') }} </span>
      <button class="button-menu" [disabled]="!parameters.isEdit" [ngClass]="!parameters.isEdit ? 'disabled' : ''" [matMenuTriggerFor]="organogram_responsible">
        <span>
          {{ selectedOrganogramResponsible.label }}
        </span>
        <i class="fa-solid fa-caret-down" *ngIf="parameters.isEdit"></i>
      </button>

      <mat-menu #organogram_responsible="matMenu">
        <div class="menu">
          <div class="input" (click)="$event.stopPropagation()">
            <input type="text" [(ngModel)]="searchOrganogramResponsible" placeholder="{{ getLanguages('search') }}">
            <i class="fa-solid fa-magnifying-glass"></i>
          </div>
          <div class="scroll">
            <ng-container *ngFor="let item of parameters.arrayOrganograms">
              <ng-container *ngIf="item.label.toLowerCase().includes(searchOrganogramResponsible.toLowerCase())">
                <div class="item-menu" (click)="choseItemSelect('organogram_responsible', item)">
                  <span [ngClass]="item.uuid == selectedOrganogramResponsible.uuid ? 'valid-span' : 'invalid-span'">
                    {{ item.label }}
                  </span>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </mat-menu>
    </div>

    <div class="item item-2">
      <span> {{ getLanguages('businessSolution/modal/addCode/organogram_executing') }} </span>
      <button class="button-menu" [disabled]="!parameters.isEdit" [ngClass]="!parameters.isEdit ? 'disabled' : ''" [matMenuTriggerFor]="organogram_executing">
        <span>
          {{ selectedOrganogramExecuting.label }}
        </span>
        <i class="fa-solid fa-caret-down" *ngIf="parameters.isEdit"></i>

      </button>

      <mat-menu #organogram_executing="matMenu">
        <div class="menu">
          <div class="input" (click)="$event.stopPropagation()">
            <input type="text" [(ngModel)]="searchOrganogramExecuting" placeholder="{{ getLanguages('search') }}">
            <i class="fa-solid fa-magnifying-glass"></i>
          </div>
          <div class="scroll">
            <ng-container *ngFor="let item of parameters.arrayOrganograms">
              <ng-container *ngIf="item.label.toLowerCase().includes(searchOrganogramExecuting.toLowerCase())">
                <div class="item-menu" (click)="choseItemSelect('organogram_executing', item)">
                  <span [ngClass]="item.uuid == selectedOrganogramExecuting.uuid ? 'valid-span' : 'invalid-span'">
                    {{ item.label }}
                  </span>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </mat-menu>
    </div>
  </div>

  <div class="row">
    <div class="item item-4">
      <span> {{ getLanguages('businessSolution/modal/addCode/status') }} </span>
      <button class="button-menu" [disabled]="!parameters.isEdit" [matMenuTriggerFor]="statusMenu" [ngClass]="!parameters.isEdit ? 'disabled' : ''">
        <span>
          {{  selectedStatus.name ? getNames(selectedStatus.name) : '' }}
        </span>
        <i class="fa-solid fa-caret-down" *ngIf="parameters.isEdit"></i>
      </button>

      <mat-menu #statusMenu="matMenu">
        <div class="menu">
          <div class="input" (click)="$event.stopPropagation()">
            <input type="text" [(ngModel)]="searchStatus" placeholder="{{ getLanguages('search') }}">
            <i class="fa-solid fa-magnifying-glass"></i>
          </div>
          <div class="scroll">
            <ng-container *ngFor="let status of statusArray">
              <ng-container *ngIf="getNames(status.name).toLowerCase().includes(searchStatus.toLowerCase())">
                <div class="item-menu" (click)="choseItemSelect('status', status)">
                  <span [ngClass]="getNames(status.name) == getNames(selectedStatus.name) ? 'valid-span' : 'invalid-span'">
                    {{ getNames(status.name) }}
                  </span>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </mat-menu>
    </div>
    <div class="item item-4">
      <span> {{ getLanguages('businessSolution/modal/addCode/importance') }} </span>
      <button class="button-menu" [disabled]="!parameters.isEdit" [matMenuTriggerFor]="importanceMenu" [ngClass]="!parameters.isEdit ? 'disabled' : ''">
        <span>
          {{  selectedImportance.name ? getNames(selectedImportance.name) : '' }}
        </span>
        <i class="fa-solid fa-caret-down" *ngIf="parameters.isEdit"></i>
      </button>

      <mat-menu #importanceMenu="matMenu">
        <div class="menu">
          <div class="input" (click)="$event.stopPropagation()">
            <input type="text" [(ngModel)]="searchImportance" placeholder="{{ getLanguages('search') }}">
            <i class="fa-solid fa-magnifying-glass"></i>
          </div>
          <div class="scroll">
            <ng-container *ngFor="let importance of importanceArray">
              <ng-container *ngIf="getNames(importance.name).toLowerCase().includes(searchImportance.toLowerCase())">
                <div class="item-menu" (click)="choseItemSelect('importance', importance)">
                  <span [ngClass]="getNames(importance.name) == getNames(selectedImportance.name) ? 'valid-span' : 'invalid-span'">
                    {{ getNames(importance.name) }}
                  </span>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </mat-menu>
    </div>
    <div class="item item-4">
      <span> {{ getLanguages('businessSolution/modal/addCode/classification') }} </span>
      <button class="button-menu" [disabled]="!parameters.isEdit" [matMenuTriggerFor]="classificationMenu" [ngClass]="!parameters.isEdit ? 'disabled' : ''">
        <span>
          {{  selectedClassification.name ? getNames(selectedClassification.name) : '' }}
        </span>
        <i class="fa-solid fa-caret-down" *ngIf="parameters.isEdit"></i>
      </button>

      <mat-menu #classificationMenu="matMenu">
        <div class="menu">
          <div class="input" (click)="$event.stopPropagation()">
            <input type="text" [(ngModel)]="searchClassification" placeholder="{{ getLanguages('search') }}">
            <i class="fa-solid fa-magnifying-glass"></i>
          </div>
          <div class="scroll">
            <ng-container *ngFor="let classification of classificationArray">
              <ng-container *ngIf="getNames(classification.name).toLowerCase().includes(searchClassification.toLowerCase())">
                <div class="item-menu" (click)="choseItemSelect('classification', classification)">
                  <span [ngClass]="getNames(classification.name) == getNames(selectedClassification.name) ? 'valid-span' : 'invalid-span'">
                    {{  classification.name ? getNames(classification.name) : '' }}
                  </span>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </mat-menu>
    </div>
    <div class="item item-4" style="position: relative;">
      <!-- <section style="position: relative;"> -->
        <span class="button-tooltip">
          {{ getLanguages('businessSolution/modal/addCode/rpa_percentage') }}
          <i class="fa-regular fa-circle-question"></i>
        </span>
        <div class="toggle-tooltip">
          <div class="item-op">
            {{getLanguages('businessSolution/modal/addCode/rpa_percentage/info')}}
          </div>
        </div>
      <!-- </section> -->

      <button class="button-menu" [disabled]="!parameters.isEdit" [matMenuTriggerFor]="rpaMenu" [ngClass]="!parameters.isEdit ? 'disabled' : ''">
        <span>
          {{ selectedRPA.value }}
        </span>
        <i class="fa-solid fa-caret-down" *ngIf="parameters.isEdit"></i>
      </button>

      <mat-menu #rpaMenu="matMenu">
        <div class="menu">
          <div class="input" (click)="$event.stopPropagation()">
            <input type="text" [(ngModel)]="searchRPA" placeholder="{{ getLanguages('search') }}">
            <i class="fa-solid fa-magnifying-glass"></i>
          </div>
          <div class="scroll">
            <ng-container *ngFor="let rpa of rpaArray">
              <ng-container *ngIf="rpa.value.toLowerCase().includes(searchRPA.toLowerCase())">
                <div class="item-menu" (click)="choseItemSelect('rpa', rpa)">
                  <span [ngClass]="rpa.value == selectedRPA.value ? 'valid-span' : 'invalid-span'">
                    {{ rpa.value }}
                  </span>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </mat-menu>
    </div>
  </div>

  <div class="row">
    <div class="item item-4">
      <span> {{ getLanguages('businessSolution/modal/addCode/fte') }} </span>
      <input type="number" min="0" disabled [(ngModel)]="parameters.item.description.fte">
    </div>

    <div class="item item-4">
      <span> {{ getLanguages('businessSolution/modal/addCode/fte_cost') }} </span>
      <input type="number" min="0" disabled [(ngModel)]="parameters.item.description.fte_cost">
    </div>

    <div class="item item-4">
      <span> {{ getLanguages('businessSolution/modal/addCode/frequency') }} </span>
      <button class="button-menu" [disabled]="!parameters.isEdit" [matMenuTriggerFor]="frequencyMenu" [ngClass]="!parameters.isEdit ? 'disabled' : ''">
        <span>
          {{ getNames(selectedFrequency.name) }}
        </span>
        <i class="fa-solid fa-caret-down" *ngIf="parameters.isEdit"></i>
      </button>

      <mat-menu #frequencyMenu="matMenu">
        <div class="menu">
          <div class="input" (click)="$event.stopPropagation()">
            <input type="text" [(ngModel)]="searchFrequency" placeholder="{{ getLanguages('search') }}">
            <i class="fa-solid fa-magnifying-glass"></i>
          </div>
          <div class="scroll">
            <ng-container *ngFor="let frequency of frequencyArray">
              <ng-container *ngIf="getNames(frequency.name).toLowerCase().includes(searchFrequency.toLowerCase())">
                <div class="item-menu" (click)="choseItemSelect('frequency', frequency)">
                  <span [ngClass]="getNames(frequency.name) == getNames(selectedFrequency.name) ? 'valid-span' : 'invalid-span'">
                    {{ getNames(frequency.name) }}
                  </span>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </mat-menu>
    </div>

    <div class="item item-4">
      <span> {{ getLanguages('businessSolution/modal/addCode/sla') }} </span>
      <input [disabled]="!parameters.isEdit" type="number" min="0"  [(ngModel)]="parameters.item.description.sla">
    </div>

  </div>

  <div class="row">
    <div class="item item-1">
      <span> {{ getLanguages('businessSolution/modal/addCode/description') }} </span>
      <textarea [disabled]="!parameters.isEdit" [(ngModel)]="parameters.item.description.description" ></textarea>
    </div>
  </div>

  <!-- <div class="row">
    <div class="item item-1">
      <span> {{ getLanguages('businessSolution/modal/addCode/link') }} </span>
      <input [disabled]="!parameters.isEdit" type="text"  [(ngModel)]="parameters.item.description.link">
    </div>
  </div>

  <div class="row">
    <div class="item item-1">
      <section class="position-relative">
        <span class="button-tooltip"> {{ getLanguages('businessSolution/modal/addCode/strategy') }} <b class="icon-ai"> ai </b> </span>
        <div class="toggle-tooltip margin-left-tooltip">
          <div class="item-op">
            {{ getLanguages('ai/info') }}
          </div>
        </div>
      </section>

      <textarea [disabled]="!parameters.isEdit" [(ngModel)]="parameters.item.description.strategy"></textarea>
    </div>
  </div> -->
</div>

<div class="background-modals" *ngIf="isShowModalIcon">
  <app-icons [parameters]="parameters.item" (toggleModalIcon)="toggleModalIcon($event)"></app-icons>
</div>
