<div class="all-content">
    <div class="top">
      {{ getLanguages('profiles/title') }}
    </div>
    <div class="row">
      <div class="div-search">
        <div class="search">
            <input type="text" [(ngModel)]="searchText" (keyup.enter)="searchProfiles()">
        </div>
        <button (click)="searchProfiles()">
            {{ getLanguages('search') }}
        </button>
      </div>
    </div>
    <div class="row">
      <div class="buttons">
        <button (click)="toggleModal()" *ngIf="getSystemPermission('profiles', 'create_profile')"> {{ getLanguages('profiles/modal/create') }}</button>
      </div>
    </div>
  
    <div class="div-table">
      <div class="table">
          <table mat-table [dataSource]="getDisplayedRows()" *ngIf="!isLoading && !noData">
  
            <!-- <ng-container matColumnDef="Status">
              <th mat-header-cell *matHeaderCellDef> {{ getLanguages('instances/content/table/status') }} </th>
              <td mat-cell *matCellDef="let row" [ngClass]="getSystemPermission('environments', 'update') ? 'pointer' : 'no-pointer'">
                <div (click)="toggleInstanceEditModal(row)" class="tag" [ngClass]="row.is_active ? 'active-item' : 'inactive-item'"></div>
              </td>
            </ng-container> -->
  
            <ng-container matColumnDef="Name">
              <th mat-header-cell *matHeaderCellDef> {{ getLanguages('instances/content/table/name') }}  </th>
              <td mat-cell *matCellDef="let row" (click)="toggleModalEdit(row)" [ngClass]="getSystemPermission('profiles', 'edit_profile') ? 'pointer' : 'no-pointer'">
                <span> {{row.name}} </span>
              </td>
            </ng-container>
  
            <ng-container matColumnDef="Type">
              <th mat-header-cell *matHeaderCellDef> {{ getLanguages('profiles/modal/profileType') }}  </th>
              <td mat-cell *matCellDef="let row" (click)="toggleModalEdit(row)" [ngClass]="getSystemPermission('profiles', 'edit_profile') ? 'pointer' : 'no-pointer'">
                <span> {{row.type}} </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="Status">
              <th mat-header-cell *matHeaderCellDef> {{ getLanguages('profiles/modal/status') }}  </th>
              <td mat-cell *matCellDef="let row" (click)="toggleModalEdit(row)" [ngClass]="getSystemPermission('profiles', 'edit_profile') ? 'pointer' : 'no-pointer'">
                <span> {{row.status}} </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="Created_at">
              <th mat-header-cell *matHeaderCellDef> {{ getLanguages('businessSolution/modal/addCode/created_at') }}  </th>
              <td mat-cell *matCellDef="let row" (click)="toggleModalEdit(row)" [ngClass]="getSystemPermission('profiles', 'edit_profile') ? 'pointer' : 'no-pointer'">
                <span> {{ formatDateTime(row.created_at) }} </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="Updated_at">
              <th mat-header-cell *matHeaderCellDef> {{ getLanguages('businessSolution/modal/addCode/updated_at') }}  </th>
              <td mat-cell *matCellDef="let row" (click)="toggleModalEdit(row)" [ngClass]="getSystemPermission('profiles', 'edit_profile') ? 'pointer' : 'no-pointer'">
                <span> {{ formatDateTime(row.updated_at) }} </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="Action">
              <th mat-header-cell *matHeaderCellDef> {{ getLanguages('activities/action') }}  </th>
              <td mat-cell *matCellDef="let row" class="delete-td">
                <i class="fas fa-trash" *ngIf="row.editable" (click)="showDelete(row)"></i>
              </td>
            </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="displayedColumns" ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
          </table>
  
          <ng-template *ngIf="isLoading">
            <app-loading></app-loading>
          </ng-template>
  
          <div class="no-data" *ngIf="noData">
  
            <div class="circle">
              <i class="fas fa-exclamation"></i>
            </div>
  
            {{ getLanguages('errors/noData') }}
          </div>
      </div>
  
      <div class="div-paginator" *ngIf="!noData && !isLoading">
        <div class="paginator">
          <span>
            {{ getLanguages('businessSolution/modal/strategy/itensPage') }}
          </span>
          <button [matMenuTriggerFor]="menu" class="itens-per-page">
            {{ getItemsPerPage() }}
          </button>
          <mat-menu #menu="matMenu" >
            <button mat-menu-item (click)="choseItemsPage(10)">10</button>
            <button mat-menu-item (click)="choseItemsPage(20)">20</button>
            <button mat-menu-item (click)="choseItemsPage(30)">30</button>
          </mat-menu>
          <span>
            {{ getLanguages('businessSolution/modal/strategy/totalPages') }}
          </span>
          <span class="totalPages">
            {{ getTotalPages()}}
          </span>
          <button
            [ngClass]="getCurrentPages() === 1 ? 'disabled-arrow' : 'arrow'"
            [disabled]="getCurrentPages() === 1"
            (click)="onPageChange(getCurrentPages() - 1)">
            <i class="fa-solid fa-angle-left"></i>
          </button>
          <input type="number" [ngModel]="getCurrentPages()" disabled (blur)="checkValue($event)">
          <button
            [ngClass]="getCurrentPages() == getTotalPages() ? 'disabled-arrow' : 'arrow'"
            [disabled]="getCurrentPages() == getTotalPages()"
            (click)="onPageChange(getCurrentPages() + 1)">
            <i class="fa-solid fa-angle-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  
  <div class="background-modals" *ngIf="isShowModal || isShowDelete">
    <ng-container *ngIf="isShowModal">
      <app-form-profiles (toggleModal)="toggleModal()" [properties]="properties" (getProfiles)="getProfiles()" [parameters]="parameters"></app-form-profiles>
    </ng-container>

    <ng-container>
      <app-delete-component *ngIf="isShowDelete" (toggleDelete)="isShowDelete = false" (updateFiles)="getProfiles($event)" [parameters]="parameters" [type]="8"></app-delete-component>
    </ng-container>
  </div>
  