export const menuJson: any = {
    modules: [
      {
        "isOpen": false,
        "uuid": "5c89ebc9-1941-4f2f-ae29-ffd975d57ed2",
        "name": {
          "en": "Home",
          "es": "Página de inicio",
          "pt": "Página inicial"
        },
        "slug": "home",
        "is_active": true,
        "is_admin": false,
        "icon": null,
        "order": 1,
        "created_at": "2024-10-30T12:23:06.000Z",
        "updated_at": "2024-10-30T12:23:06.000Z"
      },
      {
        "isOpen": false,
        "uuid": "1201b42e-01c2-4ffa-a3c5-867172b97669",
        "name": {
          "en": "Operation",
          "es": "Operación",
          "pt": "Operação"
        },
        "slug": "operation",
        "is_active": true,
        "is_admin": false,
        "icon": "fa-solid fa-gear",
        "order": 2,
        "created_at": "2024-10-30T12:23:06.000Z",
        "updated_at": "2024-10-30T12:23:06.000Z"
      },
      {
        "isOpen": false,
        "uuid": "a55b85c2-e394-42af-85da-63df0e79e24c",
        "name": {
          "en": "Settings",
          "es": "Ajustes",
          "pt": "Configurações"
        },
        "slug": "settings",
        "is_active": true,
        "is_admin": false,
        "icon": "fa-solid fa-gear",
        "order": 3,
        "created_at": "2024-10-30T12:23:06.000Z",
        "updated_at": "2024-10-30T12:23:06.000Z"
      },
      {
        "isOpen": false,
        "uuid": "fd649270-2bd5-4c4e-9517-fb716fa919b5",
        "name": {
          "en": "Administrator",
          "es": "Administrador",
          "pt": "Administrador"
        },
        "slug": "administrator",
        "is_active": true,
        "is_admin": true,
        "icon": "fa-solid fa-user-gear",
        "order": 5,
        "created_at": "2024-10-30T12:23:06.000Z",
        "updated_at": "2024-10-30T12:23:06.000Z"
      }
    ],
    screens:{
      "home": [
        {
          "uuid": "b8d895d9-c926-43f3-9b31-70ae857352ce",
          "url": "/home",
          "icon": "fa-solid fa-house",
          "slug": "home",
          "name": {
            "en": "Home",
            "es": "Página de inicio",
            "pt": "Página inicial"
          },
          "screens": []
        }
      ],
      "operation": [
        {
          "uuid": "dcc64bdb-eb9b-4f46-8e16-0d485740c3ee",
          "url": "/business_solutions",
          "icon": "fa-regular fa-folder-tree",
          "slug": "business_solutions",
          "name": {
            "en": "Business Tree",
            "es": "Árbol de negocios",
            "pt": "Árvore de negócios"
          },
          "screens": []
        },
        {
          "uuid": "16b86773-2d25-434e-a63f-a1ec0030532b",
          "url": "/clipboard",
          "icon": "fa-solid fa-clipboard",
          "slug": "clipboard",
          "name": {
            "en": "Clipboard",
            "es": "Área de transferencia",
            "pt": "Área de transferência"
          },
          "screens": []
        },
        {
          "uuid": "a4dc4d89-509c-4f50-a1c2-e822372b4cf7",
          "url": "/versioning",
          "icon": "fa-solid fa-code-pull-request",
          "slug": "versioning",
          "name": {
            "en": "Versioning",
            "es": "Versionando",
            "pt": "Versionamento"
          },
          "screens": []
        }
      ],
      "settings": [
        {
          "uuid": "53c2fb81-c1c2-4d87-aa79-866ec93835f5",
          "url": "/corporations",
          "icon": "fa-regular fa-building",
          "slug": "corporations",
          "name": {
            "en": "Organization",
            "es": "Organização",
            "pt": "Organização"
          },
          "screens": []
        },
        {
          "uuid": "96142801-acf6-4932-bb11-f78aa6216d5a",
          "url": "/organizational_structure",
          "icon": "fa-solid fa-users",
          "slug": "organizational_structure",
          "name": {
            "en": "Organizational structure",
            "es": "Organizational structure",
            "pt": "Estrutura organizacional"
          },
          "screens": []
        },
        {
          "uuid": "11afffb0-3903-43b3-9df6-01121a81a12f",
          "url": "/standards",
          "icon": "fa-solid fa-sliders",
          "slug": "standards",
          "name": {
            "en": "Process guideline",
            "es": "Guideline de processo",
            "pt": "Guideline de processo"
          },
          "screens": []
        },
        {
          "uuid": "dc4dfe1d-0f3b-4b55-8072-03b10ab9c3de",
          "url": "/technologies",
          "icon": "fa-solid fa-microchip",
          "slug": "technologies",
          "name": {
            "en": "Technologies",
            "es": "Tecnologias",
            "pt": "Tecnologias"
          },
          "screens": []
        },
        {
          "uuid": "ee1af9b2-37a9-4e88-99b0-78c8172480ab",
          "url": "/maturity",
          "icon": "fa-solid fa-list",
          "slug": "maturity",
          "name": {
            "en": "Maturity",
            "es": "Madurez",
            "pt": "Maturidade"
          },
          "screens": []
        },
        {
          "uuid": "a412203e-f7f7-48c3-aaf1-2867477cf778",
          "url": "/project_guideline",
          "icon": "fa-solid fa-diagram-project",
          "slug": "project_guideline",
          "name": {
            "en": "Project guideline",
            "es": "Project guideline",
            "pt": "Guideline de projeto"
          },
          "screens": []
        }
      ],
      "administrator": [
        {
          "uuid": "852c64fe-c6fd-414b-99e6-9b68d5dec891",
          "url": "/environments",
          "icon": "fa-solid fa-hotel",
          "slug": "environments",
          "name": {
            "en": "Environment",
            "es": "Entornos",
            "pt": "Ambiente"
          },
          "screens": []
        },
        {
          "uuid": "5676160d-9175-4b7d-82f7-ffa81501ceac",
          "url": "/activities",
          "icon": "fa-solid fa-pen-clip",
          "slug": "activities",
          "name": {
            "en": "Activity log",
            "es": "Registro de actividades",
            "pt": "Log de atividades"
          },
          "screens": []
        },
        {
          "uuid": "6dee90d5-a112-46c7-873b-d472c8e75422",
          "url": "/users",
          "icon": "fa-solid fa-user",
          "slug": "users",
          "name": {
            "en": "Users",
            "es": "Usuarios",
            "pt": "Usuários"
          },
          "screens": []
        },
        {
          "uuid": "2b3b4d66-a5a6-41a7-8ec0-81acb057baea",
          "url": "/profiles",
          "icon": "fa-solid fa-address-card",
          "slug": "profiles",
          "name": {
            "en": "Profiles",
            "es": "Perfis",
            "pt": "Perfis"
          },
          "screens": []
        }
      ],
      "services": [
        {
          "uuid": "852c64fe-c6fd-414b-99e6-9b68d5dec891",
          // "url": "/environments",
          "icon": "fa-solid fa-hotel",
          "slug": "environments",
          "name": {
            "en": "Environment",
            "es": "Entornos",
            "pt": "Ambiente"
          },
          "screens": []
        },
      ]
  
    }
  }