import { Component, EventEmitter, Input, Output } from '@angular/core';
import { localStorageToJsonAsync }
 from 'src/app/helpers/cookieToJson';
import { CorporationService } from 'src/app/services/corporation/corporation.service';
import { getLanguageCookie } from 'src/assets/language';
import { addAlert, apiOfflineAsync } from "../../layouts/pages/pages.component";
import { VersionsService } from 'src/app/services/versions/versions.service';
import { ProfileService } from 'src/app/services/profile/profile.service';

@Component({
  selector: 'app-delete-component',
  templateUrl: './delete-component.component.html',
  styleUrls: ['./delete-component.component.scss']
})
export class DeleteComponentComponent {
  @Input() type!: number;
  @Input() parameters: any = {}

  @Output() toggleDelete: EventEmitter<void> = new EventEmitter<void>();
  @Output() updateFiles: EventEmitter<any> = new EventEmitter<any>();

  @Output() toggleFoundationModalDelete: EventEmitter<void> = new EventEmitter<void>();
  @Output() getCorporationTree: EventEmitter<void> = new EventEmitter<void>();

  @Output() toggleDeleteVersions: EventEmitter<void> = new EventEmitter<void>();
  @Output() updateVersions: EventEmitter<void> = new EventEmitter<void>()

  @Output() toggleDeletePersonas: EventEmitter<void> = new EventEmitter<void>();
  @Output() updatePersonas: EventEmitter<void> = new EventEmitter<void>()

  @Output() toggleDeleteTechnology: EventEmitter<void> = new EventEmitter<void>();

  @Output() toggleDeleteGidelineProject: EventEmitter<void> = new EventEmitter<void>(); 

  properties: any = {}
  component!: string;

  json: any
  jsonOld: any

  constructor(
    private corporationService: CorporationService,
    private versionsService: VersionsService,
    private profileService: ProfileService,
  ){}

  async ngOnInit() {
    this.properties = await localStorageToJsonAsync()
    this.initComponent()
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  async exitModal(){
    if (this.type == 1 || this.type == 7 || this.type == 8) return this.toggleDelete.emit();
    if (this.type == 2) return this.toggleFoundationModalDelete.emit();
    if (this.type == 3) return this.toggleDeleteVersions.emit();
    if (this.type == 4) return this.toggleDelete.emit();
    if (this.type == 5) return this.toggleDeleteTechnology.emit();
    if (this.type == 6) return this.toggleDeleteGidelineProject.emit();
  }

  delete(){
    if (this.type == 1) return this.deleteItem()
    if (this.type == 2) return this.deleteCorporation()
    if (this.type == 3) return this.deleteVersion()
    if (this.type == 4 || this.type == 5 || this.type == 6) return this.deleteItem()
    if (this.type == 7) return this.deleteCorporationLandscape()
    if (this.type == 8) return this.deleteProfile()

    return
  }

  initComponent(){
    if(this.type == 1 || this.type == 4 || this.type == 5 || this.type == 6) return this.component = this.parameters.item.label
    if(this.type == 2 || this.type == 3 || this.type == 7 || this.type == 8) return this.component = this.parameters.name
  }

  async deleteItem() {
    this.json = [];
    this.jsonOld = [];

    this.parameters.filesOld.forEach((item: any) => {
      let modifiedItem = { ...item, children: [] };
      if (item.uuid != this.parameters.item.uuid && item?.clone_by != this.parameters.item.uuid ) {
        this.json.push(modifiedItem);
      }

      if (item.children && item.children.length > 0) {
        this.recursiveForEachDelete(item.children, this.json[this.json.length - 1]);
      }
    });

    this.updateFiles.emit({
      files: this.json,
      filesOld: this.json,
      item_deleted: this.parameters.item,
      isShow: false
    });

    await this.exitModal();
  }

  recursiveForEachDelete(children: any[], parent: any) {
    children.forEach((item: any) => {
      let modifiedItem = { ...item, children: [] };
      if (item.uuid != this.parameters.item.uuid && item?.clone_by != this.parameters.item.uuid) {
        parent.children.push(modifiedItem);
      }

      if (item.children && item.children.length > 0) {
        this.recursiveForEachDelete(item.children, modifiedItem);
      }
    });
  }

  recursiveForEach(children: any[], parent: any) {
    children.forEach((item: any) => {
      let modifiedItem = { ...item, children: [] };
      if (item.uuid == this.parameters.item.uuid) {
        modifiedItem = { ...modifiedItem, is_delete: true }
      }

      parent.children.push(modifiedItem);
      if (item.children && item.children.length > 0) {
        this.recursiveForEach(item.children, modifiedItem);
      }
    });
  }

  async deleteCorporation() {
    try {
      const response = await this.corporationService.deleteCorporation(this.parameters.uuid);
      if (response && [201, 202].includes(response.status)) {
        addAlert('success', response.message);
        window.location.reload()
        await this.exitModal()
      } else {
        addAlert('danger', response.message);
      }
      this.getCorporationTree.emit();
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  async deleteCorporationLandscape() {
    try {
      const response = await this.corporationService.deleteCorporationLandscape(this.parameters.uuid);
      if (response && [200, 201, 202].includes(response.status)) {
        addAlert('success', response.message);
        await this.exitModal()
      } else {
        addAlert('danger', response.message);
      }
      this.updateFiles.emit();
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  async deleteVersion() {
    try {
      const response = await this.versionsService.deleteVersion(this.parameters.uuid);
      if (response && [201, 202].includes(response.status)) {
        addAlert('success', response.message);
        await this.exitModal()
      } else {
        addAlert('danger', response.message);
      }
      this.updateVersions.emit();
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }
  

  async deletePersonas() {
    this.json = [];
    this.jsonOld = [];

    this.parameters.filesOld.forEach((item: any) => {
      let modifiedItem = { ...item, children: [] };
      if (item.uuid != this.parameters.item.uuid) {
        this.json.push(modifiedItem);
      }

      if (item.children && item.children.length > 0) {
        this.recursiveForEachDelete(item.children, this.json[this.json.length - 1]);
      }
    });

    this.updateFiles.emit({
      files: this.json,
      filesOld: this.json,
      item_deleted: this.parameters.item,
      isShow: false
    });

    await this.exitModal();
  }

  async deleteProfile() {
    try {
      const response = await this.profileService.deleteProfile(this.parameters.uuid);
      if (response && [200].includes(response.status)) {
        addAlert('success', response.message);
        await this.exitModal()
      } else {
        addAlert('danger', response.message);
      }
      this.updateFiles.emit();
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

}
