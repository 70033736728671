<!-- <div class="all-content">
  <div class="left">
    <img src="../../../assets/logo_title_white.png" class="logo-title">
  </div>
  <div class="right" *ngIf="!showConfirm">
    <div class="title">
      <span>
         Login
      </span>
      <div class="tog"></div>
    </div>

    <div class="inputs">
      <div class="input">
        <input type="text" placeholder="{{ getLanguages('auth/login/email')}} " (keyup.enter)="login()" name='E-mail' [(ngModel)]="email" autocapitalize="none">
      </div>
      <div class="password">
        <input type="text" placeholder="{{ getLanguages('auth/login/password')}} " (keyup.enter)="login()" name='Password'  [type]="showPass ? 'text' : 'password'" [(ngModel)]="password" id="inputPassword" autocapitalize="none">
        <i [ngClass]="showPass ? 'fa-regular fa-eye' : 'fa-regular fa-eye-slash' " (click)="showPass = !showPass"></i>
      </div>
      <div class="forgot" >
        <span (click)="showConfirm = !showConfirm" name="button-forgot"> {{getLanguages('auth/login/forgot')}} </span>
      </div>
      <button class="buttom" (click)="login()" name="button-login">
        {{ getLanguages('auth/login') }}
      </button>
      <div class="create">
       
      </div>
    </div>
  </div>

  <div class="right" *ngIf="showConfirm">

    <div class="back">
      <i class="fas fa-arrow-left" (click)="showConfirm = !showConfirm"></i>
    </div>

    <div class="title">
      <span>
        {{getLanguages('auth/forgot')}}
      </span>
      <div class="tog"></div>
      <span class="sub">
        {{getLanguages('auth/forgot/sub')}}
      </span>
    </div>

    <div class="inputs">
      <div class="input">
        <input type="text" placeholder="{{getLanguages('auth/forgot/type')}}" name='E-mail' (keyup.enter)="sendResetPassword()" [(ngModel)]="emailConfirm" autocapitalize="none">
      </div>

      <button class="buttom" (click)="sendResetPassword()" name="button-reset">
        {{getLanguages('auth/forgot/send')}}
      </button>
      <div class="create">
        <a (click)="showConfirm = !showConfirm" id="buttonRegister"> {{getLanguages('auth/login')}} </a>
      </div>
    </div>
  </div>

  <div class="bottom">
    <div class="tag">
      <span>UI: {{ versions?.version_ui }}</span>
      <span>API: {{ versions?.version_api }}</span>
    </div>

    <button [matMenuTriggerFor]="flagMenu" matTooltip="{{getLanguages('pages/info/language')}}" class="flag-button">
      <i class="fas fa-globe"></i>
      <span> {{ language && language.lang ? language.lang.toUpperCase() : 'en' }} </span>
      <i class="fas fa-caret-down"></i>
    </button>

    <mat-menu #flagMenu="matMenu" class="flag-menu">
      <button *ngFor="let flag of flagArray" (click)="selectFlag(flag)" class="button-icon">
        <span> {{ flag.lang.toUpperCase() }} </span>
      </button>
    </mat-menu>
  </div>

  <div class="loading" *ngIf="isLoading">
    <div class="loading-spinner"></div>
    <span>
      {{ getLanguages('loading') }}
    </span>
  </div>
</div>
-->

<div class="all-content">
  <div class="left"></div>
  <div class="right" *ngIf="!showConfirm">
    <div class="top">
      <div class="img"></div>
    </div>
    <div class="content">
      <span class="title"> LOGIN </span>
      <div class="inputs">
        <input type="text" placeholder="{{ getLanguages('auth/login/email')}} " (keyup.enter)="login()" name='E-mail' [(ngModel)]="email" autocapitalize="none">
        <section>
          <input type="text" placeholder="{{ getLanguages('auth/login/password')}} " (keyup.enter)="login()" name='Password'  [type]="showPass ? 'text' : 'password'" [(ngModel)]="password" id="inputPassword" autocapitalize="none">
          <i [ngClass]="showPass ? 'fa-regular fa-eye' : 'fa-regular fa-eye-slash' " (click)="showPass = !showPass"></i>
        </section>
        <span class="forgot" (click)="showConfirm = !showConfirm">{{ getLanguages('auth/login/forgot') }}</span>
        <button (click)="login()" name="button-login">
          {{ getLanguages('auth/login') }}
        </button>
      </div>
    </div>
  </div>

  <div class="right" *ngIf="showConfirm">
    <div class="top">
      <i class="fas fa-arrow-left" (click)="showConfirm = !showConfirm"></i>
      <div class="img"></div>
    </div>
    <div class="content">
      <div class="title"> 
        {{ getLanguages('auth/forgot') }} 
        <div class="subtitle"> {{ getLanguages('auth/forgot/sub') }} </div>
      </div>

      <div class="inputs">
        <input type="text" placeholder="{{getLanguages('auth/forgot/type')}}" name='E-mail' (keyup.enter)="sendResetPassword()" [(ngModel)]="emailConfirm" autocapitalize="none">

        <button (click)="sendResetPassword()" name="button-reset">
          {{ getLanguages('auth/forgot/send') }}
        </button>
      </div>
    </div>
  </div>

  <div class="bottom">
    <div class="tag">
      <span>UI: {{ versions?.version_ui }}</span>
      <span>API: {{ versions?.version_api }}</span>
    </div>

    <button [matMenuTriggerFor]="flagMenu" matTooltip="{{getLanguages('pages/info/language')}}" class="flag-button">
      <i class="fas fa-globe"></i>
      <span> {{ language && language.lang ? language.lang.toUpperCase() : 'en' }} </span>
      <i class="fas fa-caret-down"></i>
    </button>

    <mat-menu #flagMenu="matMenu" class="flag-menu">
      <button *ngFor="let flag of flagArray" (click)="selectFlag(flag)" class="button-icon">
        <span> {{ flag.lang.toUpperCase() }} </span>
      </button>
    </mat-menu>
  </div>

  <div class="loading" *ngIf="isLoading">
    <div class="loading-spinner"></div>
    <span>
      {{ getLanguages('loading') }}
    </span>
  </div>
</div>

<div class="alerts">
  <ng-container *ngFor="let alert of queueAlert">
    <app-alert text="{{alert.text}}" type="{{alert.type}}" (remove)="removeAlertLogin(alert.id, $event)"></app-alert>
  </ng-container>
</div> 
