import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IResponse } from '../../interfaces/IResponse';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  API_PATH: string

  private requestOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private httpClient: HttpClient, private router: Router,
  ) {
    this.API_PATH = environment.apiUrl;
  }

  async navigate(route: string) {
    return await this.router.navigate([route]);
  }

  async redirectError(error: any) {
    if (error.status == 401) {
      return await this.navigate('/login');
    }
    return error.error;
  }

  async getProfiles() {
    return await this.httpClient.get<IResponse>(`${this.API_PATH}profiles`).toPromise();
  }

  async setProfile(body: any, isNew: boolean = true) {
    try {
      if (isNew) {
        return await this.httpClient.post<IResponse>(
          `${this.API_PATH}profiles`,
          body,
          this.requestOptions
        ).toPromise()
      }
      return await this.httpClient.put<IResponse>(
        `${this.API_PATH}profiles/${body.uuid}`,
        body,
        this.requestOptions
      ).toPromise()
    } catch (error: any) {
      return await this.redirectError(error);
    }
  }

  async deleteProfile(uuid: string) {
    try {
      return await this.httpClient.delete<IResponse>(`${this.API_PATH}profiles/${uuid}`).toPromise()
    } catch (error: any) {
      return await this.redirectError(error);
    }
  }

  async saveUsersProfiles(body: any) {
    try {
      return await this.httpClient.post<IResponse>(`${this.API_PATH}profiles/saveUsers`, body).toPromise()
    } catch (error: any) {
      return await this.redirectError(error);
    }
  }
}
