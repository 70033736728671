import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { getLanguageCookie } from 'src/assets/language';
import { addAlert, apiOfflineAsync } from "../../../../layouts/pages/pages.component";
import { systemPermissionsNew } from 'src/app/helpers/systemPermissions';
import { stringify, v4 } from 'uuid';
import { to64decode } from 'src/app/helpers/base64';
import { sortArrayOfObjects } from 'src/app/helpers/sortArrayOfObjects';
import { CorporationService } from 'src/app/services/corporation/corporation.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-personas-crud',
  templateUrl: './personas-crud.component.html',
  styleUrls: ['./personas-crud.component.scss']
})
export class PersonasCrudComponent {
  @Output() toggleModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() getPersonasTree: EventEmitter<void> = new EventEmitter<void>();
  @Output() updateFiles: EventEmitter<any> = new EventEmitter<any>();

  @Input() properties: any = {}
  @Input() parameters: any = {}

  protected readonly Object = Object;

  selectedFlag: any = { lang: 'en', file: 'us.svg' };

  isEdit: boolean = false;
  isShowSkills: boolean = false;
  item: any

  skillsArray: any
  openSkills: boolean = true;
  selectedSegment: any = { name: 'Select' };
  createSegment = false;
  segmentArray: any = []
  corporationTypeArray: any = []
  selectedCorporationType: any = { name: 'Select' };
  createType = false;
  statusArray = [{ value: true, label: { en: 'Active', pt: 'Ativo' } }, { value: false, label: { en: 'Inactive', pt: 'Inativo' } }]
  selectedStatus: any;

  temp: any = {}

  newNode: any

  personasArray: any = [
    // {
    //   slug: 'organization',
    //   name: {
    //     en: 'Organization',
    //     pt: 'Organização',
    //   }
    // },
    {
      slug: 'organogram',
      name: {
        en: 'Organogram',
        pt: 'Organograma',
      }
    },    
    {
      slug: 'persona',
      name: {
        en: 'Position/Function',
        pt: 'Cargo/Função',
      }
    }

  ]
  searchPersonas: string = '';
  selectedPersonas: any = {
    name: {
      en: 'Organogram',
      pt: 'Organogram',
    },
    slug: 'organogram'
  }
  searchSeniority: string = ''
  seniorityArray: any = [
    {
      slug: 'specialist',
      name: {
        en: 'Specialist',
        pt: 'Especialista',
      }
    },
    {
      slug: 'senior',
      name: {
        en: 'Senior',
        pt: 'Sênior',
      }
    },
    {
      slug: 'mid',
      name: {
        en: 'Mid-level',
        pt: 'Pleno',
      }
    },
    {
      slug: 'junior',
      name: {
        en: 'Junior',
        pt: 'Junior',
      }
    },
  ];
  selectedSeniority: any = {
    name: {
      en: '',
      pt: ''
    },
    slug: ''
  }
  

  // searchFollow: string = '';
  // searchType: string = '';
  // newType = { name: '' };
  // newSegment = { name: null };
  // displayedImage: any;
  // imageChangedEvent: any = '';
  // croppedImage: any = '';
  // showCrop = false;
  // formData: any
  // file: any = null

  constructor(private corporationService: CorporationService
  ) {
  }

  async ngOnInit() {
    this.parameters.item.data.type = this.parameters.item.data.type ?? this.selectedPersonas.slug
    this.parameters.item.data.seniority = this.parameters.item.data.seniority ? this.parameters.item.data.seniority : this.selectedSeniority.slug

    this.temp = JSON.parse(JSON.stringify(this.parameters.item));
    this.personasArray.forEach((item: any) => {
      if (item?.slug == this.parameters?.item?.data?.type) {
        this.choseItemSelect('personas', item)

        if (item.slug != 'persona') {
          this.parameters.item.data.cost = 0
        }
      }
    });

    this.seniorityArray.forEach((item: any) => {
      if (item?.slug == this.parameters?.item?.data?.seniority) {
        this.choseItemSelect('seniority', item)
      }
    });

    await this.getCorporationType()
    await this.getCorporationSegments()

    this.statusArray.forEach((item: any) => {
      if (item.value == this.parameters.item.data.is_active) {
        this.choseStatus(item)
      }
    })

    this.segmentArray.forEach((item: any) => {
      if (item.name != 'New +' && item.uuid == this.parameters.item.data.follow_up) {
        this.chooseSegment(item)
      }
    })

    this.corporationTypeArray.forEach((item: any) => {
      if (item.name != 'New +' && item.uuid == this.parameters.item.data.corporation_type_uuid) {
        this.choseTypeCorporation(item)
      }
    })

    if (!this.parameters.item || !this.parameters.item.rows || !this.parameters.item.rows.skills) {
      this.parameters.item.rows.skills = []
    }
    this.skillsArray = this.parameters.item.rows.skills ?? []
    // this.formData = this.parameters.formData ? this.parameters.formData : new FormData()

    // for (const key of this.formData.keys()) {
    //   const match = key.match(/^image_organization_(.+)$/);
    //   if (match) {
    //     const uuid = match[1];
    //     if (uuid === this.parameters.item.uuid) {
    //       const file = this.formData.get(key);
    //       if (file instanceof File) {
    //         this.displayedImage = URL.createObjectURL(file);
    //       }
    //     }
    //   }
    // }
  }

  async initSegment() {
    this.segmentArray = []
    if (this.getSystemPermission('corporations', 'create_segment')) {
      this.segmentArray.push({ name: 'New +' })
    }
  }

  async getCorporationSegments() {
    await this.initSegment();
    try {
      const response: any = await this.corporationService.getCorporationSegments();
      if (response && response.data) {
        await this.initSegment();
        this.segmentArray.push(...sortArrayOfObjects(response.data))
      } else {
        throw new Error('Erro ao consultar àrvore de corporations!\nConsulta o administrador do sistema!');
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  async initCorporationType() {
    this.corporationTypeArray = []
    if (this.getSystemPermission('corporations', 'create_type')) {
      this.corporationTypeArray.push({ name: 'New +' })
    }
  }

  async getCorporationType() {
    try {
      const response: any = await this.corporationService.getCorporationType();
      if (response && response.data) {
        await this.initCorporationType();
        this.corporationTypeArray.push(...sortArrayOfObjects(response.data))

      } else {
        throw new Error('Erro ao consultar àrvore de corporations!\nConsulta o administrador do sistema!');
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
  }

  choseItemSelect(type: string, value: any) {
    switch (type) {
      case 'personas':
        this.selectedPersonas = value;
        this.parameters.item.data.type = value.slug
        break;
      case 'seniority':
        this.selectedSeniority = value;
        this.parameters.item.data.seniority = value.slug
        break;
      default:
        break;
    }
  }

  getNames(name: any) {
    return name[to64decode(localStorage.getItem('language')).lang]
  }

  getLanguages(file: string) {
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  exitModal(hide: boolean = true): void {
    if (!hide) {
      return
    }
    this.toggleModal.emit();
  }

  async modalClose() {
    await Promise.all([this.exitModal()]);
  }

  async editBusinessTree() {
    this.isEdit = !this.isEdit;
    this.parameters.isEdit = this.isEdit

    if (!this.isEdit) {
      this.parameters.item = JSON.parse(JSON.stringify(this.temp));
      this.ngOnInit()
    }
  }

  async parametersValidation() {
    let inputs: any = []

    if (!this.parameters.item.label) {
      inputs.push(this.getLanguages('personas/name'))
    }

    if (!this.parameters.item.data.cost) {
      this.parameters.item.data.cost = 0
    }

    if (inputs.length > 0) {
      addAlert('orange', `${this.getLanguages('mandatoryField')}${inputs.join(`, `)}`)
      return false
    }
    return true
  }

  // async setPersonasTree() {
  //   this.parameters.item.rows.skills = this.skillsArray
  //   this.formData.append(`image_organization_${this.parameters.item.uuid}`, this.file)

  //   delete this.parameters.item.data.cost
  //   delete this.parameters.item.data.image

  //   if(this.parameters.item.data.type != 'organization'){
  //     delete this.parameters.item.data.acronym
  //     delete this.parameters.item.data.follow_up
  //     delete this.parameters.item.data.corporation_type_uuid
  //     delete this.parameters.item.data.is_active
      
  //     this.formData = Array.from(this.formData.entries()).reduce((newFormData: FormData, entry) => {
  //       const [key, value] = entry as [string, any];
  //       if (key !== `image_organization_${this.parameters.item.uuid}`) {
  //         newFormData.append(key, value);
  //       }
  //       return newFormData;
  //     }, new FormData());
  //   }
  //   if(this.parameters.item.data.type != 'persona'){
  //     delete this.parameters.item.data.cost_min
  //     delete this.parameters.item.data.cost_max
  //     delete this.parameters.item.rows
  //   }
  //   if (this.parameters.isCreate) {
  //     if (!await this.parametersValidation()) {
  //       return
  //     }

  //     this.newNode = {
  //       uuid: v4(),
  //       data: this.parameters.item.data,
  //       label: this.parameters.item.label,
  //       master: this.parameters.item.master,
  //       rows: this.parameters.item.rows,
  //       children: [],
  //     }

  //     if (this.newNode.master == 'ROOT') {
  //       this.parameters.personas.push(this.newNode)
  //     } else {
  //       this.recursiveForEach(this.parameters.personas)
  //     }

  //     this.updateFiles.emit({ files: this.parameters.personas, formData: this.formData })
  //     await this.modalClose()
  //     return
  //   }
  //   this.updateTree(this.parameters.personas, this.parameters.item);

  //   this.updateFiles.emit({ files: this.parameters.personas, item: this.parameters.item, formData: this.formData });
  //   await this.modalClose();
  // }

  async setPersonasTree() {
    this.parameters.item.rows.skills = this.skillsArray

    if (this.parameters.isCreate){
      if (!await this.parametersValidation()) {
        return
      }
      this.newNode = {
        uuid: v4(),
        data: this.parameters.item.data,
        label: this.parameters.item.label,
        master: this.parameters.item.master,
        rows: this.parameters.item.rows,
        children: [],
      }

      if (this.newNode.master == 'ROOT') {
        this.parameters.personas.push(this.newNode)
      } else {
        this.recursiveForEach(this.parameters.personas)
      }

      this.updateFiles.emit({ files: this.parameters.personas } )
      await this.modalClose()
      return
    }

    this.updateTree(this.parameters.personas, this.parameters.item);

    this.updateFiles.emit({ files: this.parameters.personas, item: this.parameters.item });
    await this.modalClose();
  }


  recursiveForEach(array: any) {
    array.forEach((item: any) => {
      if (item.uuid == this.parameters.item.master) {
        item.children.push(this.newNode)
      } else {
        if (item.children.length > 0) {
          this.recursiveForEach(item.children)
        }
      }
    });
  }

  updateTree(array: any, updatedItem: any) {
    for (let i = 0; i < array.length; i++) {
      const item = array[i];

      if (item.uuid === updatedItem.uuid) {
        array[i] = {
          ...item,
          ...updatedItem,
        };
      } else if (item.children && item.children.length > 0) {
        this.updateTree(item.children, updatedItem);
      }
    }
  }

  getSystemPermission(screen: string, action?: string) {
    return systemPermissionsNew(screen, action)
  }

  exitModalSkills() {
    this.parameters.is_search = true
    this.isShowSkills = !this.isShowSkills;
  }

  getArraySkillsPersona(event: any) {
    let tempArray = this.skillsArray.filter((item: any) => { return !item.created_at })

    this.skillsArray = []
    event.forEach((item: any) => {
      this.skillsArray.push(item)
    });
    tempArray.forEach((item: any) => {
      this.skillsArray.push(item)
    });

    this.parameters.item.rows.skills = this.skillsArray
  }

  flipSkills() {
    this.openSkills = !this.openSkills
  }

  addSkill() {
    if (!this.parameters.isEdit) {
      return
    }
    this.openSkills = true
    this.skillsArray.push(
      {
        uuid: v4(),
        name: '',
        // is_created_now: true
      }
    )
  }

  removeSkill(uuid: number) {
    if (!this.parameters.isEdit) {
      return
    }
    let array_temp: any = []

    this.skillsArray.forEach((item: any) => {
      if (item.uuid != uuid) {
        array_temp.push(item)
      }
    });

    this.skillsArray = array_temp
    this.parameters.item.rows.skills = array_temp
  }

  chooseSegment(type: any) {
    if (type.name == "New +") {
      this.createSegment = true;
      return
    }
    this.selectedSegment.name = type.name;
    this.parameters.item.data.follow_up = type.uuid
  }


  choseTypeCorporation(type: any) {
    if (type.name == "New +") {
      this.createType = true;
      return
    }
    this.selectedCorporationType.name = type.name;
    this.parameters.item.data.corporation_type_uuid = type.uuid
  }

  choseStatus(state: any) {
    this.parameters.item.data.is_active = state.value;
    this.selectedStatus = { name: state.value ? { en: 'Active', pt: 'Ativo' } : { en: 'Inactive', pt: 'Inativo' } }
  }

  // async setCorporationType() {
  //   if (this.newType.name == 'New +') {
  //     addAlert('danger', this.getLanguages('auth/alert/nameReservedWord'))
  //     return
  //   }
  //   try {
  //     const response: any = await this.corporationService.setCorporationType(this.newType);
  //     if (response && [201, 202].includes(response.status)) {
  //       addAlert('success', response.message)
  //       await this.getCorporationType();
  //     } else {
  //       addAlert('danger', response.message)
  //     }
  //   } catch (error: any) {
  //     await apiOfflineAsync(error)
  //   }
  //   this.createType = false;
  //   this.newType.name = ''
  // }

  // async setSegment() {
  //   if (this.newSegment.name == 'New +') {
  //     addAlert('danger', this.getLanguages('auth/alert/nameReservedWord'))
  //     return
  //   }
  //   try {
  //     const response: any = await this.corporationService.setSegment(this.newSegment)
  //     if (response && [201, 202].includes(response.status)) {
  //       addAlert('success', response.message);
  //       this.createSegment = false;
  //       await this.getCorporationSegments();
  //     } else {
  //       addAlert('danger', response.message)
  //     }
  //   } catch (error: any) {
  //     await apiOfflineAsync(error)
  //   }
  //   this.createSegment = false;
  //   this.newSegment.name = null
  // }

  // @ViewChild('fileInput') fileInput!: ElementRef;
  // openFileInput() {
  //   this.fileInput.nativeElement.click();
  // }

  // fileChangeEvent(event: any): void {
  //   const fileInput = event.target;
  //   if (fileInput.files.length > 0) {
  //     const fileSize = fileInput.files[0].size;
  //     const maxSizeInBytes = 2 * 1024 * 1024;

  //     if (fileSize > maxSizeInBytes) {
  //       addAlert('orange', this.getLanguages('error/maxSizeBytes'))
  //       fileInput.value = '';

  //       return
  //     }
  //   }


  //   const allowedFormats = ['image/png', 'image/jpeg', 'image/jpg'];
  //   const file = event.target.files[0];

  //   if (!allowedFormats.includes(file.type)) {
  //     addAlert('orange', this.getLanguages('error/onlyPng'))
  //     return;
  //   }

  //   this.imageChangedEvent = event;
  //   this.showCrop = true;
  // }

  // saveAndClose(flag: boolean = true) {
  //   this.showCrop = false;

  //   if (!flag) {
  //     return
  //   }

  //   this.displayedImage = URL.createObjectURL(this.croppedImage.blob);


  //   const blob = this.croppedImage.blob;
  //   const fileReader = new FileReader();

  //   fileReader.readAsArrayBuffer(blob);
  //   fileReader.onload = () => {
  //     let arrayBuffer = fileReader.result as ArrayBuffer;
  //     this.file = new File([arrayBuffer], `${v4()}.png`, { type: 'image/png' })
  //   };
  // }

  // imageCropped(event: ImageCroppedEvent) {
  //   this.croppedImage = event;
  // }

  validateLevel(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
  
    if (value === '') {
      this.parameters.item.data.level = null;
      return;
    }
  
    const numericValue = parseInt(value, 10);
  
    if (!isNaN(numericValue)) {
      if (numericValue < 1) {
        this.parameters.item.data.level = 1;
        inputElement.value = '1';
      } else if (numericValue > 10) {
        this.parameters.item.data.level = 10;
        inputElement.value = '10';
      } else {
        this.parameters.item.data.level = numericValue;
      }
    }
  }
  
  allowOnlyNumbers(event: KeyboardEvent): void {
    const key = event.key;
    if (!/^[0-9]$/.test(key) && !['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(key)) {
      event.preventDefault();
    }
  }
  
}


