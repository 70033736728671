import { Component, Input } from '@angular/core';
import { to64decode } from 'src/app/helpers/base64';
import { getLanguageCookie } from 'src/assets/language';
import { MatTableDataSource } from '@angular/material/table';
import { v4 } from "uuid";
import { addAlert } from "../../../../layouts/pages/pages.component";


@Component({
  selector: 'app-configurations',
  templateUrl: './configurations.component.html',
  styleUrls: ['./configurations.component.scss']
})
export class ConfigurationsComponent {
  @Input() parameters: any = {}
  @Input() dimensions: any = {}


  selectedFlag: any = {
    lang: 'en',
    file: 'us.svg'
  };

  dataSource!: MatTableDataSource<any>;
  displayedColumns: string[] = ['question','actions'];
  currentPage = 1;
  currentPageReq = 1;
  itemsPerPage = 10;

  typeArray: any = []
  searchType: string = '';
  selectedType: any = {
    name: ''
  }

  nodeArray: any = []
  searchNode: string = '';
  selectedNode: any = {
    label: ''
  }

  is_new_configuration: boolean = true;

  currentConfiguration: any = {}


  constructor() {
    this.dataSource = new MatTableDataSource();
  }

  async ngOnInit(){
    this.dataSource.data = this.parameters.item.configurations ?? []
    this.newConfiguration()
  }

  getNames(name: any){
    return name[to64decode(localStorage.getItem('language')).lang]
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  isData(){
    return this.dataSource.data.length > 0
  }

  getDisplayedRows(): any[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.dataSource.data.slice(startIndex, endIndex);
  }

  getColumns(){
    return this.displayedColumns.slice(1)
  }

  getItemsPerPage(){
    return this.itemsPerPage
  }

  getCurrentPages(): number{
    return this.currentPage
  }

  choseItemsPage(number: number){
    this.itemsPerPage = number;
    this.getDisplayedRows()
  }

  onPageChange(newPage: number, text: string): void {
    this.currentPage = newPage;
  }

  getTotalPages(): number {
    return Math.ceil(this.dataSource.data.length / this.itemsPerPage);
  }

  async checkValue() {
    let total = this.getTotalPages()
    if (this.currentPage > total) this.currentPage = total;
  }

  async selectConfiguration(item: any){
    this.currentConfiguration = { ...item }
    this.is_new_configuration = false;
  }

  async newConfiguration(){
    this.currentConfiguration = {
      uuid: v4(),
      question: '',
      answer: ''
      // answerArray: []
    };

    this.selectedNode = {...{}}

    this.is_new_configuration = true;
  }

  async saveConfiguration(){
    if(!this.parameters.isEdit){
      return
    }
    let inputs: any = []

    if (!this.currentConfiguration.question || this.currentConfiguration.question == '') {
      inputs.push('Question') // verificar onde faz a troca de linguagens
    }

    if (inputs.length > 0) {
      addAlert('orange', `${this.getLanguages('mandatoryField')}${inputs.join(`, `)}`)
      return
    }

    // await this.verifyVoidAnswers();
    this.dataSource.data.find((item: any, index: number) => {
      if(item.uuid == this.currentConfiguration.uuid){
        this.dataSource.data[index] = this.currentConfiguration
        this.is_new_configuration = false
      }
    })

    if(this.is_new_configuration){
      this.dataSource.data.push(this.currentConfiguration)
    }
    this.parameters.item.configurations = this.dataSource.data
    await this.newConfiguration()
  }

  async deleteComplement(uuid: string) {
    this.dataSource.data = this.dataSource.data.filter(item => item.uuid !== uuid);
    this.parameters.item.configurations = this.dataSource.data
    await this.newConfiguration()
  }

  isNodeInComplements(node: any): boolean {
    return this.parameters.item.configurations.includes(node.label);
  }

  // newAnswer(){
  //   this.currentConfiguration.answerArray.push(
  //     {
  //       uuid: v4(),
  //       answer: '',
  //       action: ''
  //     }
  //   )
  // }

  // removeAnswer(uuid: string){
  //   if (!this.parameters.isEdit){
  //     return
  //   }

  //   let array: any[] = []

  //   this.currentConfiguration.answerArray.forEach((element: any) => {
  //     if(element.uuid && element.uuid != uuid){
  //       array.push(element)
  //     }
  //   });

  //   this.currentConfiguration.answerArray = array
  // }

  // async verifyVoidAnswers(){
  //   let array: any = []
  //   this.currentConfiguration.answerArray.forEach((element: any) => {
  //     if(element.answer){
  //       array.push(element)
  //     }
  //   })
  //   this.currentConfiguration.answerArray = array
  // }
}
