<div class="all-content">
  <div class="top">
    {{ getLanguages('instances/title') }}
  </div>
  <div class="row">
    <div class="div-search">
      <div class="search">
          <input type="text" [(ngModel)]="searchText" (keyup.enter)="searchInstance()">
      </div>
      <button (click)="searchInstance()">
          {{ getLanguages('search') }}
      </button>
    </div>
  </div>
  <div class="row">
    <div class="buttons">
      <button (click)="toggleInstanceCreateModal()" *ngIf="getSystemPermission('environments', 'create')"> {{ getLanguages('instances/modal/create/title') }}</button>
    </div>
  </div>

  <div class="div-table">
    <div class="table">
        <table mat-table [dataSource]="getDisplayedRows()" *ngIf="!isLoading && !noData">

          <ng-container matColumnDef="Status">
            <th mat-header-cell *matHeaderCellDef> {{ getLanguages('instances/content/table/status') }} </th>
            <td mat-cell *matCellDef="let row"  [ngClass]="getSystemPermission('environments', 'edit') ? 'pointer' : 'no-pointer'">
              <div (click)="toggleInstanceEditModal(row)" class="tag" [ngClass]="row.is_active ? 'active-item' : 'inactive-item'" *ngIf="getSystemPermission('environments', 'edit')"></div>
            </td>
          </ng-container>

          <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef> {{ getLanguages('instances/content/table/name') }}  </th>
            <td mat-cell *matCellDef="let row" [attr.id]="row.slug" (click)="toggleInstanceUserModal(row)" [ngClass]="getSystemPermission('environments', 'link_user') ? 'pointer' : 'no-pointer'">
              <span> {{row.name}} </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="Activate On">
              <th mat-header-cell *matHeaderCellDef > {{ getLanguages('instances/content/table/activateOn') }} </th>
              <td mat-cell *matCellDef="let row" (click)="toggleInstanceUserModal(row)"  [ngClass]="getSystemPermission('environments', 'link_user') ? 'pointer' : 'no-pointer'">
                <span> {{row.created_at_moment}} </span>
              </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns" ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </table>

        <ng-template *ngIf="isLoading">
          <app-loading></app-loading>
        </ng-template>

        <div class="no-data" *ngIf="noData">

          <div class="circle">
            <i class="fas fa-exclamation"></i>
          </div>

          {{ getLanguages('errors/noData') }}
        </div>
    </div>

    <div class="div-paginator" *ngIf="!noData && !isLoading">
      <div class="paginator">
        <span>
          {{ getLanguages('businessSolution/modal/strategy/itensPage') }}
        </span>
        <button [matMenuTriggerFor]="menu" class="itens-per-page">
          {{ getItemsPerPage() }}
        </button>
        <mat-menu #menu="matMenu" >
          <button mat-menu-item (click)="choseItemsPage(10)">10</button>
          <button mat-menu-item (click)="choseItemsPage(20)">20</button>
          <button mat-menu-item (click)="choseItemsPage(30)">30</button>
        </mat-menu>
        <span>
          {{ getLanguages('businessSolution/modal/strategy/totalPages') }}
        </span>
        <span class="totalPages">
          {{ getTotalPages()}}
        </span>
        <button
          [ngClass]="getCurrentPages() === 1 ? 'disabled-arrow' : 'arrow'"
          [disabled]="getCurrentPages() === 1"
          (click)="onPageChange(getCurrentPages() - 1)">
          <i class="fa-solid fa-angle-left"></i>
        </button>
        <input type="number" [ngModel]="getCurrentPages()" disabled (blur)="checkValue($event)">
        <button
          [ngClass]="getCurrentPages() == getTotalPages() ? 'disabled-arrow' : 'arrow'"
          [disabled]="getCurrentPages() == getTotalPages()"
          (click)="onPageChange(getCurrentPages() + 1)">
          <i class="fa-solid fa-angle-right"></i>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="background-modals" *ngIf="isShowInstanceCreate || isShowInstanceEdit || isShowInstanceUser">
  <ng-container *ngIf="isShowInstanceCreate">
    <app-create-instance (toggleInstanceCreateModal)="toggleInstanceCreateModal()" [properties]="properties" (getInstances)="getInstances()"></app-create-instance>
  </ng-container>

  <ng-container *ngIf="isShowInstanceEdit">
    <app-edit-instance (toggleInstanceEditModal)="toggleInstanceEditModal()" [properties]="properties" [parameters]="parameters" (getInstances)="getInstances()"></app-edit-instance>
  </ng-container>

  <ng-container *ngIf="isShowInstanceUser">
    <app-user-instances (toggleInstanceUserModal)="toggleInstanceUserModal()" [properties]="properties" [parameters]="parameters" (getInstances)="getInstances()"> </app-user-instances>
  </ng-container>
</div>
