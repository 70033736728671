<div class="all-content">
  <div class="top">
    <div class="title">
      {{ parameters.isEdit ? getNameEntity('edit') : getNameEntity('view') }} {{ getDimension() }}
      <span class="copy-uuid" (click)="copyLink()" *ngIf="getSystemPermission('business_solutions', 'copy_identifier')">         
        <i class="fa-solid fa-link"></i>
        {{ getLanguages('businessSolution/modal/addCode/copyLink') }}
      </span>
    </div>
    <span *ngIf="parameters.item.clone_by" class="cloned-title">
      <i class="fa-solid fa-circle-exclamation"></i>
      {{ getLanguages('businessSolution/top/info/cloned') }}
    </span>
    <section>
      <span *ngIf="parameters.item.is_cloned == true" class="cloned-title button-tooltip">
        <i class="fa-solid fa-circle-exclamation" ></i>
        {{ getLanguages('businessSolution/top/info/is_cloned') }}
      </span>
      <div class="toggle-tooltip">
        <div class="item-op">
          {{ getLanguages('businessSolution/top/info/is_cloned/info') }}
        </div>
      </div>
    </section>
    
    <i class="fas fa-xmark" (click)="exitModal()"></i>
  </div>
  <div class="div-checkbox" *ngIf="properties.user_corporation.is_approval">
    <span> {{ getLanguages('users/modal/approve')}} </span>
    <input type="checkbox" id="checkbox" [checked]="parameters.item.is_approved" (click)="approveProcess()" [ngClass]="{'valid-check' : parameters.item.is_approved}">
  </div>
  <div class="buttons">
    <div class="save" (click)="setBusinessTree()" *ngIf="isEdit">
      {{ getLanguages('save')}}
    </div>
    <div class="save" [ngClass]="isEdit ? 'edit' : '' " (click)="editBusinessTree()" *ngIf="getSystemPermission('business_solutions', 'edit_process') && !parameters.item.clone_by">
      {{ isEdit ? getLanguages('cancel') : getLanguages('edit') }}
    </div>
    <div class="navigate-node-origin" *ngIf="parameters?.item?.clone_by">
      <span (click)="goToOriginNode()">
        {{ getLanguages('businessSolution/top/info/goToOriginNode') }}
      </span>
    </div>
  </div>

  <div class="row">
    <div class="roll">
      <div class="arrow">
          <i class="fas fa-chevron-left" (click)="scrollLeft()"></i>
      </div>
      <div class="itens" #scrollContainer>
          <div class="item" *ngFor="let tab of tabArray; let i = index" [class.active]="viewDimension === tab.dimension" (click)="toggleDimension(tab.dimension)">
              <span> {{ getLanguages('businessSolution/modal/'+ tab.slug +'/title') }} </span>
          </div>
      </div>
      <div class="arrow">
          <i class="fas fa-chevron-right" (click)="scrollRight()"></i>
      </div>
    </div>  
  </div>

  <div class="row content">
    <app-add-code *ngIf="viewDimension == 1 && Object.keys(parameters).length > 0 && Object.keys(dimensions).length > 0" [properties]="properties" [parameters]="parameters" [dimensions]="dimensions"></app-add-code>
    <app-strategy *ngIf="viewDimension == 2 && Object.keys(parameters).length > 0 && Object.keys(dimensions).length > 0" [parameters]="parameters" [dimensions]="dimensions"></app-strategy>
    <app-people *ngIf="viewDimension == 3 && Object.keys(parameters).length > 0 && Object.keys(dimensions).length > 0" [parameters]="parameters" [dimensions]="dimensions"></app-people>
    <app-transaction *ngIf="viewDimension == 4" [parameters]="parameters"></app-transaction>
    <app-technology *ngIf="viewDimension == 5 && Object.keys(parameters).length > 0 && Object.keys(dimensions).length > 0" [parameters]="parameters" [dimensions]="dimensions"></app-technology>
    <app-rules *ngIf="viewDimension == 6" [parameters]="parameters" [dimensions]="dimensions"></app-rules>
    <app-aba-maturity *ngIf="viewDimension == 7" [parameters]="parameters" [dimensions]="dimensions"></app-aba-maturity>
    <app-attachments *ngIf="viewDimension == 8" [parameters]="parameters" [dimensions]="dimensions"></app-attachments>
    <app-indicators *ngIf="viewDimension == 9" [parameters]="parameters" [dimensions]="dimensions"></app-indicators>  
    <app-complements *ngIf="viewDimension == 10" [parameters]="parameters" [dimensions]="dimensions"></app-complements> 
    <app-configurations *ngIf="viewDimension == 11" [parameters]="parameters" [dimensions]="dimensions"></app-configurations>
    <app-integrations *ngIf="viewDimension == 12" [parameters]="parameters" [dimensions]="dimensions"> </app-integrations>
    <app-project *ngIf="viewDimension == 13" [parameters]="parameters"></app-project>
    <app-extended-attributes *ngIf="viewDimension == 14" [parameters]="parameters"></app-extended-attributes>
    <app-link *ngIf="viewDimension == 15" [parameters]="parameters"></app-link>
  </div>
</div>

<div class="background-modals" *ngIf="isChangeTree">
  <app-changes-tree *ngIf="isChangeTree" (toggleModal)="toggleChangeTree($event)" (close)="toggleModalEdit.emit()" (save)="setBusinessTree()"></app-changes-tree>
</div>
