import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { to64decode, to64encode } from 'src/app/helpers/base64';
import { getLanguageCookie } from 'src/assets/language';
import { EnvironmentsService } from "../../../../services/environments/environments.service";
import { systemPermissionsNew } from 'src/app/helpers/systemPermissions';
import { PagesComponent, addAlert, apiOfflineAsync } from "../../../../layouts/pages/pages.component";
import { stringify } from "circular-json";
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { v4 } from 'uuid';
import { setLogoLocalStorage } from "../../../../helpers/helpers";

@Component({
  selector: 'app-edit-instance',
  templateUrl: './edit-instance.component.html',
  styleUrls: ['./edit-instance.component.scss']
})
export class EditInstanceComponent {
  @Input() properties: any = {}
  @Input() parameters: any = {}

  @Output() toggleInstanceEditModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() getInstances: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('fileInput') fileInput!: ElementRef;


  selectedFlag: any = {
    lang: 'en',
    file: 'us.svg'
  };

  isEdit = false;
  haveCorporate = true;

  statusArray = [{ value: true, label: { en: 'Active', pt: 'Ativo' } }, { value: false, label: { en: 'Inactive', pt: 'Inativo' } }]
  selectedStatus: any = {};

  temp: any = {}

  imageChangedEvent: any = '';
  croppedImage: any = '';
  displayedImage: any;

  formData: FormData = new FormData()
  file: any = null
  showCrop = false;
  isLoading: boolean = false

  constructor(
    private cookieService: CookieService,
    private environmentService: EnvironmentsService,
    private pagesComponent: PagesComponent
  ) {
  }

  async ngOnInit() {
    // this.selectedStatus = this.parameters.environment.is_active ? 'ACTIVE' : 'INACTIVE'
    this.selectedStatus.name = await getLanguageCookie(`users/content/table/status/${this.parameters.environment.is_active.toString()}`, localStorage.getItem('language'), true)
    this.temp = { ...this.parameters.environment }
    this.temp.selectedStatus = { ...this.selectedStatus }
    this.displayedImage = this.parameters.environment.url
  }

  getLanguages(file: string) {
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  choseStatus(status: any) {
    // this.parameters.environment.is_active = status.value;
    this.temp.is_active = status.value
    this.selectedStatus.name = status.label;
  }

  exitModal(hide: boolean = true): void {
    if (!hide) {
      return
    }

    this.getInstances.emit()
    this.toggleInstanceEditModal.emit();
  }

  async modalClose() {
    await Promise.all([this.exitModal()]);
  }

  classValidation() {
    let inputs: any = []

    if (!this.parameters.environment.name) {
      inputs.push(getLanguageCookie('instances/content/table/name', localStorage.getItem('language')))
    }

    if (inputs.length > 0) {
      addAlert('orange', `${this.getLanguages('mandatoryField')}${inputs.join(`, `)}`)
      return false;
    }
    return true
  }

  async setEnvironment() {
    this.parameters.environment.is_active = this.selectedStatus.name.en == 'Active'
    this.parameters.environment = this.temp
    this.isLoading = true;

    try {
      if (!this.classValidation()) {
        return;
      }
      await this.setFormData()
      const response = await this.environmentService.setEnvironment(this.formData, false, this.parameters.environment.uuid)
      if (response && [201, 202].includes(response.status)) {
        if (this.temp.uuid == this.properties.environment.uuid) {
          response.data.environment = await setLogoLocalStorage(response.data.environment, 'environment_logo')
          localStorage.setItem('environment', to64encode(response.data.environment));
        }
        addAlert('success', response.message);
        await this.modalClose()
      } else {
        addAlert('danger', response.message);
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }

    this.isLoading = false;
  }

  async setFormData() {
    this.formData = new FormData()
    this.formData.append('body', stringify(this.parameters.environment))
    this.formData.append('file', this.file)
  }

  async deleteEnvironment() {
    try {
      const response = await this.environmentService.deleteEnvironment(this.parameters.environment.uuid);
      if (response && response.status == 202) {
        addAlert('success', response.message)
      } else {
        addAlert('danger', response.message)
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
    await this.modalClose()
  }

  getSystemPermission(screen: string, action?: string) {
    return systemPermissionsNew(screen, action)
  }

  getNames(name: any) {
    return name[to64decode(localStorage.getItem('language')).lang]
  }

  async editCancel() {
    this.isEdit = !this.isEdit

    if (!this.isEdit) {
      await this.cancel()
      await this.ngOnInit()
    }
  }

  async cancel() {
    this.temp = { ...this.parameters.environment }
    this.temp.is_active = await getLanguageCookie(`users/content/table/status/${this.parameters.environment.is_active.toString()}`, localStorage.getItem('language'), true)
    this.selectedStatus.name = await getLanguageCookie(`users/content/table/status/${this.parameters.environment.is_active.toString()}`, localStorage.getItem('language'), true)
    // this.temp.is_active = this.selectedStatus.name.en == 'Active'
  }

  getName() {
    return this.parameters.environment.name.substring(0, 2).toUpperCase()
  }

  fileChangeEvent(event: any): void {
    const fileInput = event.target;
    if (fileInput.files.length > 0) {
      const fileSize = fileInput.files[0].size;
      const maxSizeInBytes = 2 * 1024 * 1024;

      if (fileSize > maxSizeInBytes) {
        addAlert('orange', this.getLanguages('error/maxSizeBytes'))
        fileInput.value = '';

        return
      }
    }

    const allowedFormats = ['image/png', 'image/jpeg', 'image/jpg'];
    const file = event.target.files[0];

    if (!allowedFormats.includes(file.type)) {
      addAlert('orange', this.getLanguages('error/onlyPng'))
      return;
    }

    this.imageChangedEvent = event;
    this.showCrop = true;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event;
  }

  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  openFileInput() {
    this.fileInput.nativeElement.click();
  }

  saveAndClose(flag: boolean = true) {
    this.showCrop = false;

    if (!flag) {
      return
    }

    this.displayedImage = URL.createObjectURL(this.croppedImage.blob);


    const blob = this.croppedImage.blob;
    const fileReader = new FileReader();

    fileReader.readAsArrayBuffer(blob);
    fileReader.onload = () => {
      let arrayBuffer = fileReader.result as ArrayBuffer;
      this.file = new File([arrayBuffer], `${v4()}.png`, { type: 'image/png' })
    };
  }

}


