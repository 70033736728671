
<div class="all-content" id="all-content-create-user">
  <div class="top">
    <div class="toggle"></div>

    <div class="button-x">
      <div class="save" (click)="setUser()">
        {{ getLanguages('save')}}
      </div>
      <i class="fas fa-xmark" (click)="exitModal()"></i>
    </div>
  </div>
  <div class="row">
    <div class="title">
      {{ getLanguages('users/modal/create/title') }}
    </div>
  </div>

  <div class="row">
    <div class="item w-3">
      <span>{{ getLanguages('users/modal/create/name') }} <b> * </b> </span>
      <input id="user_name" type="text" [(ngModel)]="user.name" >
    </div>
    <div class="item w-3">
      <span>{{ getLanguages('users/modal/create/email') }} <b> * </b>  </span>
      <input id="user_email" type="text" [(ngModel)]="user.email">
    </div>
    <div class="item w-3">
      <span>{{ getLanguages('statusSystem') }} </span>
      <div class="select-nav" [matMenuTriggerFor]="status">
        <span>
            <ng-container>
              {{ getNames( selectedStatus.name ) }}
            </ng-container>
        </span>
        <i class="fas fa-caret-down"></i>
      </div>
      <mat-menu #status="matMenu">
        <div class="menu">
          <div class="scroll">
            <ng-container *ngFor="let status of statusArray">
                <div class="item-menu" (click)="choseStatus(status)">
                  <span [ngClass]="{'valid-span' : status.value == user.is_active}">
                    {{ getNames(status.label) }}
                  </span>
                </div>
            </ng-container>
          </div>
        </div>
      </mat-menu>
    </div>
  </div>

  <div class="row" *ngIf="getAccessProfileMaster()">
    <div class="display-flex">
      <span>{{ getLanguages('users/modal/create/profile_master') }} </span>
      <mat-checkbox class="check" [ngModel]="user.is_admin_master" (change)="user.is_admin_master = !user.is_admin_master" ></mat-checkbox>
    </div>
  </div>

  <div class="row" *ngIf="getAccessProfileEnvornment()">
    <div class="item w-1">
      <span>{{ getLanguages('users/modal/create/profile_environment') }} </span> 

      <div class="display-flex">
        <button class="select-nav" style="width: 33%!important;" [ngClass]="user.is_admin_master ? 'disabled' : ''" [disabled]="user.is_admin_master" [matMenuTriggerFor]="profileEnvironment">
          <span>
              <ng-container>
                {{ selectedProfileEnvironment?.uuid ? selectedProfileEnvironment.name : getLanguages('search')  }}
              </ng-container>
          </span>
          <i class="fas fa-caret-down"></i>
        </button>
        <span *ngIf="selectedProfileEnvironment?.uuid && !user.is_admin_master" class="clear" (click)="selectedProfileEnvironment = {name: 'Select'}"> {{ getLanguages('clear') }} </span>
        <mat-menu #profileEnvironment="matMenu">
          <div class="menu">
            <div class="input" (click)="$event.stopPropagation()" >
              <input type="text" [(ngModel)]="searchProfileEnvironment" placeholder="{{getLanguages('search')}}">
              <i class="fa-solid fa-magnifying-glass"></i>
            </div>
            <div class="scroll">
              <ng-container *ngFor="let profile of parameters.profilesEnvironment">
                <ng-container *ngIf="profile.name.toLowerCase().includes(searchProfileEnvironment.toLowerCase())">
                    <div class="item-menu" (click)="choseProfileEnvironment(profile)">
                      <span [ngClass]="{'valid-span' : profile.uuid == selectedProfileEnvironment.uuid}">
                        {{ profile.name }}
                      </span>
                    </div>
                  </ng-container>
                </ng-container>
            </div>
          </div>
        </mat-menu>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="item w-1">
      <span>{{ getLanguages('users/modal/create/profile_corporation') }} </span>
    </div>
  </div>
  <div class="table">
    <table mat-table [dataSource]="dataSource" [ngClass]="user.is_admin_master || selectedProfileEnvironment?.uuid ? 'table-disabled' : '' ">
      <ng-container matColumnDef="Select">
        <th mat-header-cell *matHeaderCellDef>
        </th>
        <td mat-cell *matCellDef="let element; let i = index;">
          <mat-checkbox
            class="checkbox"
            [color]="task.color"
            [(ngModel)]="element.selected"
            (change)="element.selected = !element.selected;setSelectionCorporationProfile(element)"
            *ngIf="!user.is_admin_master && !selectedProfileEnvironment?.uuid "
            [id]="'id_checkbox_'+ element.corporation.slug"
            >
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="Corporation">
        <th mat-header-cell *matHeaderCellDef>
          <span> {{ getLanguages('users/modal/create/corporation') }} </span>
        </th>
        <td mat-cell *matCellDef="let element; let i = index;">
          <span class="title-corporation">
              {{element.corporation.name}}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="Profile">
        <th mat-header-cell *matHeaderCellDef>
          <span> {{ getLanguages('users/modal/create/profile') }} </span>
        </th>
        <td mat-cell *matCellDef="let element; let i = index;">
            <button class="select-nav nav-table" id="select_profile" [matMenuTriggerFor]="selectProfile" [disabled]="!element.selected" [ngClass]="!element.selected ? 'disabled no-pointer': ''">
              <span> 
                  <ng-container>
                    {{ setSelectionCorporationsProfilesArray.length > 0 && element.index >= 0 && setSelectionCorporationsProfilesArray[element.index] && setSelectionCorporationsProfilesArray[element.index].profile ? setSelectionCorporationsProfilesArray[element.index].profile.name : getLanguages('search') }}
                  </ng-container>
              </span>
              <i class="fas fa-caret-down"></i>
            </button>
            <mat-menu #selectProfile="matMenu">
              <div class="menu">
                <div class="input" (click)="$event.stopPropagation()" >
                  <input type="text" [(ngModel)]="element.searchProfile" placeholder="{{getLanguages('search')}}">
                  <i class="fa-solid fa-magnifying-glass"></i>
                </div>
                <div class="scroll">
                  <ng-container *ngFor="let profile of element.profiles">
                    <ng-container *ngIf="profile.name.toLowerCase().includes(element.searchProfile.toLowerCase())">
                      <div class="item-menu" (click)="setSelectionCorporationProfile(null, profile, element.index)">
                        <span [attr.id]="profile.slug" [ngClass]="{'valid-span' : profile.name == (setSelectionCorporationsProfilesArray[element.index] && setSelectionCorporationsProfilesArray[element.index].profile && setSelectionCorporationsProfilesArray[element.index].profile.name)}">
                          {{ profile.name }}
                        </span>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </mat-menu>
        </td>
      </ng-container>
      <ng-container matColumnDef="Status">
        <th mat-header-cell *matHeaderCellDef>
          <span> {{ getLanguages('users/modal/create/status') }} </span>
        </th>
        <td mat-cell *matCellDef="let element; let i = index;">
          <button class="select-nav nav-table" id="select_status" [matMenuTriggerFor]="status" [disabled]="!element.selected || parameters.readonly" [ngClass]="!element.selected || parameters.readonly ? 'no-pointer': ''">
            <span>
                <ng-container>
                  {{ setSelectionCorporationsProfilesArray.length > 0 && element.index >= 0 && setSelectionCorporationsProfilesArray[element.index] && setSelectionCorporationsProfilesArray[element.index].status ? getNames(setSelectionCorporationsProfilesArray[element.index].status.label) : getLanguages('search') }}
                </ng-container>
            </span>
            <i class="fas fa-caret-down"></i>
          </button>
          <mat-menu #status="matMenu">
            <div class="menu">
              <div class="scroll">
                <ng-container *ngFor="let status of statusArray">
                    <div class="item-menu" (click)="setSelectionCorporationProfile(null, null, element.index, { status })">
                      <span [attr.id]="status.label.en" [ngClass]="{'valid-span' : status.label == (setSelectionCorporationsProfilesArray[element.index] && setSelectionCorporationsProfilesArray[element.index].status && setSelectionCorporationsProfilesArray[element.index].status.label )}">
                        {{ getNames(status.label) }}
                      </span>
                    </div>
                </ng-container>
              </div>
            </div>
          </mat-menu>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>



