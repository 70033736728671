<div class="all-content">
  <div class="top">
    <div class="title">
      {{ getNameEntity() }} {{ getDimension() }}
    </div>

    <i class="fas fa-xmark" (click)="exitModal()"></i>
  </div>

  <div class="row">
    <div class="save" (click)="setBusinessTree()" *ngIf="getSystemPermission('business_solutions', 'create_process')">
      {{ getLanguages('save')}}
    </div>
  </div>

  <div class="row">
    <div class="roll">
      <div class="arrow">
          <i class="fas fa-chevron-left" (click)="scrollLeft()"></i>
      </div>
      <div class="itens" #scrollContainer>
          <div class="item" *ngFor="let tab of tabArray; let i = index" [class.active]="viewDimension === tab.dimension" (click)="toggleDimension(tab.dimension)">
              <span> {{ getLanguages('businessSolution/modal/'+ tab.slug +'/title') }} </span>
          </div>
      </div>
      <div class="arrow">
          <i class="fas fa-chevron-right" (click)="scrollRight()"></i>
      </div>
    </div>  
  </div>

  <div class="row content">
    <app-add-code *ngIf="viewDimension == 1 && Object.keys(parameters).length > 0 && Object.keys(dimensions).length > 0" [properties]="properties" [parameters]="parameters" [dimensions]="dimensions"></app-add-code>
    <app-strategy *ngIf="viewDimension == 2 && Object.keys(parameters).length > 0 && Object.keys(dimensions).length > 0" [parameters]="parameters" [dimensions]="dimensions"></app-strategy>
    <app-people *ngIf="viewDimension == 3 && Object.keys(parameters).length > 0 && Object.keys(dimensions).length > 0" [parameters]="parameters" [dimensions]="dimensions"></app-people>
    <app-transaction *ngIf="viewDimension == 4" [parameters]="parameters"></app-transaction>
    <app-technology *ngIf="viewDimension == 5 && Object.keys(parameters).length > 0 && Object.keys(dimensions).length > 0" [parameters]="parameters" [dimensions]="dimensions"></app-technology>
    <app-rules *ngIf="viewDimension == 6" [parameters]="parameters" [dimensions]="dimensions"></app-rules>
    <app-aba-maturity *ngIf="viewDimension == 7" [parameters]="parameters" [dimensions]="dimensions"></app-aba-maturity>
    <app-attachments *ngIf="viewDimension == 8" [parameters]="parameters" [dimensions]="dimensions"></app-attachments>
    <app-indicators *ngIf="viewDimension == 9" [parameters]="parameters" [dimensions]="dimensions"></app-indicators>  
    <app-complements *ngIf="viewDimension == 10" [parameters]="parameters" [dimensions]="dimensions"></app-complements> 
    <app-configurations *ngIf="viewDimension == 11" [parameters]="parameters" [dimensions]="dimensions"></app-configurations>
    <app-integrations *ngIf="viewDimension == 12" [parameters]="parameters" [dimensions]="dimensions"> </app-integrations>
    <app-project *ngIf="viewDimension == 13" [parameters]="parameters"></app-project>
    <app-extended-attributes *ngIf="viewDimension == 14" [parameters]="parameters"></app-extended-attributes>
    <app-link *ngIf="viewDimension == 15" [parameters]="parameters"></app-link>
  </div>
</div>

<div class="background-modals" *ngIf="isChangeTree">
  <app-changes-tree *ngIf="isChangeTree" (toggleModal)="toggleChangeTree($event)" (close)="toggleModal.emit()" (save)="setBusinessTree()"></app-changes-tree>
</div>
