import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, startWith } from 'rxjs/operators';
import { getLanguageCookie } from 'src/assets/language';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  items: { label: string; url: string }[] = [];

  selectedFlag: any = {
    lang: 'en',
    file: 'us.svg'
  };

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        startWith(true) // Inicializa o breadcrumb quando o componente é carregado
      )
      .subscribe(() => {
        this.items = this.createBreadcrumb(this.route.root);
      });
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  createBreadcrumb(route: ActivatedRoute): { label: string; url: string }[] {
    const breadcrumb: { label: string; url: string }[] = [];
    while (route) {
      const routeData = route.snapshot.data;
      if (routeData && routeData['breadcrumb']) {
        const item = routeData['breadcrumb'];
        if (Array.isArray(item)) {
          // Se for um array, adiciona cada item do array ao breadcrumb
          for (const label of item) {
            breadcrumb.push({ label, url: '' }); // Lembre-se de definir o URL correto, se necessário
          }
        } else {
          // Caso contrário, adiciona o item único ao breadcrumb
          breadcrumb.push({ label: item, url: '' }); // Lembre-se de definir o URL correto, se necessário
        }
      }
      route = route.firstChild as ActivatedRoute;
    }
    return breadcrumb;
  }
}
