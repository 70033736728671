<div class="all-content">

    <div class="content-dimension">
        <div class="no-data" *ngIf="!isData()">

          <div class="circle">
            <i class="fas fa-exclamation"></i>
          </div>

          {{ getLanguages('errors/noData') }}
        </div>

        <div class="table-attachment" *ngIf="isData()">
            <table *ngIf="getDisplayedRows().length !== 0"  mat-table [dataSource]="getDisplayedRows()" class="table">
                <ng-container matColumnDef="question">
                    <th mat-header-cell *matHeaderCellDef class="td-80">
                        <span> {{ getLanguages('attachment/name') }} </span>
                    </th>
                    <td mat-cell *matCellDef="let row" class="td-80">
                        <span class="pointer" (click)="selectConfiguration(row)"> {{ row.question }} </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef class="td-20">
                        <span> {{ getLanguages('attachment/action') }} </span>
                    </th>
                    <td mat-cell *matCellDef="let row" class="td-20">
                        <button (click)="deleteComplement(row.uuid)" [disabled]="!parameters.isEdit">
                            <i class="fas fa-trash" [ngClass]="!parameters.isEdit ? 'disabled-icon' : 'icon'"></i>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4"></td>
                </tr>
            </table>
        </div>
        <div class="div-paginator" *ngIf="isData()">
          <div class="paginator">
            <span>
              {{ getLanguages('businessSolution/modal/strategy/itensPage') }}
            </span>
            <button [matMenuTriggerFor]="menu" class="itens-per-page">
              {{ getItemsPerPage() }}
            </button>
            <mat-menu #menu="matMenu" >
              <button mat-menu-item (click)="choseItemsPage(5)">5</button>
              <button mat-menu-item (click)="choseItemsPage(10)">10</button>
            </mat-menu>
            <span>
              {{ getLanguages('businessSolution/modal/strategy/totalPages') }}
            </span>
            <span class="totalPages">
              {{ getTotalPages()}}
            </span>
            <button
              [ngClass]="getCurrentPages() === 1 ? 'disabled-arrow' : 'arrow'"
              [disabled]="getCurrentPages() === 1"
              (click)="onPageChange(getCurrentPages() - 1, 'strategy')">
              <i class="fa-solid fa-angle-left"></i>
            </button>
            <input type="number" [ngModel]="getCurrentPages()"  (blur)="checkValue()">
            <button
              [ngClass]="getCurrentPages() == getTotalPages() ? 'disabled-arrow' : 'arrow'"
              [disabled]="getCurrentPages() == getTotalPages()"
              (click)="onPageChange(getCurrentPages() + 1, 'strategy')">
              <i class="fa-solid fa-angle-right"></i>
            </button>
          </div>
        </div>
    </div>

    <div class="row row-end">
        <div class="button-upload" (click)="newConfiguration()" [ngClass]="!parameters.isEdit ? 'disabled' : 'pointer'">
            {{ getLanguages('businessSolution/modal/configurations/new_question') }}
        </div>
        <div class="button-upload" (click)="saveConfiguration()" [ngClass]="!parameters.isEdit ? 'disabled' : 'pointer'">
            {{ getLanguages('businessSolution/modal/configurations/save_question') }}
        </div>
    </div>

    <div class="row">
        <div class="item item-1">
            <span> {{ getLanguages('businessSolution/modal/configurations/question') }} </span>
            <textarea [disabled]="!parameters.isEdit" [(ngModel)]="currentConfiguration.question"></textarea>
        </div>
    </div>

    <div class="row">
      <div class="item item-1">
          <span> {{ getLanguages('businessSolution/modal/configurations/answer') }} </span>
          <textarea [disabled]="!parameters.isEdit" [(ngModel)]="currentConfiguration.answer"></textarea>
      </div>
    </div>

    <!-- <div class="row row-end">
      <div class="button-upload" (click)="newAnswer()" [ngClass]="!parameters.isEdit ? 'disabled' : 'pointer'">
          {{ getLanguages('businessSolution/modal/configurations/new_answer') }}
      </div>
    </div>

    <div class="group-answer" *ngFor="let item of currentConfiguration.answerArray">
      <div class="row">
        <div class="button-trash" >
          <i class="fas fa-trash" [ngClass]="!parameters.isEdit ? 'disabled-icon' : 'pointer'"  (click)="removeAnswer(item.uuid)"></i>
        </div>
        <div class="item item-1">
            <span> {{ getLanguages('businessSolution/modal/configurations/answer') }} </span>
            <textarea [disabled]="!parameters.isEdit" [(ngModel)]="item.answer"></textarea>
        </div>
      </div>

      <div class="row">
          <div class="item item-1">
              <span> {{ getLanguages('businessSolution/modal/configurations/action') }} </span>
              <textarea [disabled]="!parameters.isEdit" [(ngModel)]="item.action"></textarea>
          </div>
      </div>
    </div> -->
</div>

