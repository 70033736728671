import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { UsersService } from "../../../services/users/users.service";
import { to64decode, to64encode } from 'src/app/helpers/base64';
import { getLanguageCookie } from 'src/assets/language';
import { apiOfflineAsync} from 'src/app/layouts/pages/pages.component';
import { version_ui } from 'src/app/environments/tag_version';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss']
})
export class CreatePasswordComponent {
  showPass = false;
  showPassConfirm = false;
  uuid!: string;
  password: string = '';
  password_confirm: string = '';
  hasLetters: boolean = false;
  hasNumbers: boolean = false;
  isLengthValid: boolean = false;
  isSame: boolean = false;

  user: any = {}

  isLoading = false;
  language: any

  flagArray: any = [
    { lang: 'en', file: 'us.svg'},
    { lang: 'pt', file: 'br.svg'}
  ]

  queueAlert: any = []
  queueTime: any = []
  versions: any = {}

  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UsersService,
    private route: ActivatedRoute,
  ) {

    if(localStorage.getItem('language')){
      this.language =  to64decode(localStorage.getItem('language'));
    }else{
      this.language = this.flagArray[1]
    }
    this.versions.version_ui = version_ui

    this.selectFlag()
  }

  async ngOnInit() {
    this.route.params.subscribe(params => {
      this.uuid = params['uuid'];
    });

    await this.setVersions()
    // await this.getUser()
  }

  async setVersions () {
    try {
      const response: any = await this.authService.setVersions(this.versions.version_ui)
      if (response.data) {
        this.versions = response.data
      }
    } catch (error) {
      await apiOfflineAsync(error)
    }
  }

  getLanguages(file: string){
    return getLanguageCookie(file, localStorage.getItem('language'))
  }

  async selectFlag(flag: any = null) {
    if(flag){
      this.language = flag;
    }
     localStorage.setItem('language', to64encode(this.language))
  }

  async navigate(route: string){
    await this.router.navigate([route]);
  }

  validatePassword() {
    this.hasLetters = /[a-zA-Z]/.test(this.password);
    this.hasNumbers = /\d/.test(this.password);
    this.isLengthValid = this.password.length >= 6 && this.password.length <= 12;
    this.isSame = this.password === this.password_confirm;
  }

  // async getUser () {
  //   try {
  //     const response: any = await this.userService.getUser(this.uuid, true)
  //     if (response && response.data) {
  //       this.user = response.data

  //       if (!this.user.remember_password) {
  //         this.addAlertLogin('orange', response.message)
  //         await this.navigate('/login')
  //       }
  //     }
  //   } catch (error: any) {
  //     console.log(error)
  //     await apiOfflineAsync(error)
  //   }
  // }

  async setUserPassword () {
    this.isLoading = true
    try {
      const response: any = await this.authService.setUserPassword(this.uuid, this.password)
      if (response && [201, 202].includes(response.status)) {
        this.addAlertLogin('success', response.message)
        setTimeout( () => {
          this.navigate('/login')
        }, 3000)
      }else{
        this.addAlertLogin('orange', response.message)
      }
    } catch (error: any) {
      await apiOfflineAsync(error)
    }
    this.isLoading = false
  }

  removeAlertLogin(id: number, event?: Event){
    this.queueAlert.splice(this.queueAlert.findIndex((item: {id: number;}) => item.id == id), 1)
  }

  addAlertLogin(type: string = '', text: string = '') {
    const someAlert = this.queueAlert.find((alert: { text: string }) => alert.text === text);

    if (someAlert) {
      this.removeAlertLogin(someAlert.id)
      clearTimeout(this.queueTime[someAlert.id]);
    }

    this.queueAlert.push({ id: this.queueAlert.length, type, text })
    this.queueTime[this.queueAlert.length] = setTimeout(() => {
      this.removeAlertLogin(this.queueAlert.length)
    }, 3000)
  }
}
